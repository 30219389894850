import { useSelector } from 'react-redux';
import { useGetMe } from '../../../hooks/api/user/useGetMe';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import SettingEmailTemplate from '../../templates/auth/setting/SettingEmailTemplate';
import LoginPage from '../login/LoginPage';

const SettingEmailPage = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  const { data: user } = useGetMe();

  const { values, handlers: formHandlers, errors: formErrors } = useAuthForm();
  const {
    handlers: apiHandlers,
    errors: apiErrors,
    loadings: apiLoadings,
  } = useAuthActions({
    values,
  });

  if (!isLoggedIn) return <LoginPage />;
  if (!user) return null;

  return (
    <SettingEmailTemplate
      isSP={isXS}
      result={false}
      email={user.email}
      values={values}
      formHandlers={formHandlers}
      apiErrors={apiErrors}
      apiLoadings={apiLoadings}
      formErrors={formErrors}
      handleUpdateEmail={() =>
        apiHandlers.handleResetEmail({
          onSuccess: () => {
            PageNavigation.goToVerifyEmailSendPage();
          },
        })
      }
    />
  );
};

export default SettingEmailPage;
