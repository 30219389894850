import React from 'react';
import { useBodyScrollLock } from '../../../hooks/useBodyScrollLock';
import { SpinnerProps } from '../../atoms/spinner/Spinner.type';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import './LoadingOverlaySpinner.css';

export const LoadingOverlaySpinner: React.FC<SpinnerProps> = React.memo(
  (props) => {
    useBodyScrollLock(true);
    return (
      <div className={`loading-overlay-spinner`}>
        <DefaultSpinnerIons {...props} />
      </div>
    );
  },
);

export default LoadingOverlaySpinner;
