import { RequestCardType } from '../../../../types/api/request/RequestTypes';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import RequestCardMolecules from '../../../molecules/card/request/RequestCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import './RequestCardGrid.css';

interface RequestCardGridProps {
  isSP: boolean;
  requests: RequestCardType[];
  title: string;
  num: number;
  page: number;
  maxPage: number;
  columns: number;
}

const RequestCardGridWithHeader: React.FC<RequestCardGridProps> = (props) => {
  return (
    <section className="card-grid-section">
      <div className={`card-grid-header ${props.isSP ? '' : 'pc'}`}>
        <div className="card-grid-header-title">
          <BodyTextBoldIons
            text={props.title}
            size={props.isSP ? 'normal' : 'medium'}
          />
          <BodySubTextIons text={`(${props.num}件)`} size="small" />
        </div>
      </div>

      {props.requests.length > 0 ? (
        <div
          data-testid="card-grid"
          className={`card-grid-container columns-${props.columns}`}
        >
          {props.requests.map((request) => (
            <RequestCardMolecules
              width={'100%'}
              isSP={props.isSP}
              request={request}
            />
          ))}
        </div>
      ) : (
        <div className="card-grid-no-item">
          <h4 className="card-grid-no-item-text">
            アイデアが見つかりませんでした
          </h4>
        </div>
      )}
      <PageNationButtons num={props.page} max_num={props.maxPage} />
    </section>
  );
};

export default RequestCardGridWithHeader;
