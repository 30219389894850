import React from 'react';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../atoms/text/Text.type';
import CircleRainbowButtonIons from '../../ions/button/circle/rainbow/CircleRainbowButton.Ions';
import DefaultCarouselIons from '../../ions/carousel/DefaultCarousel.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { SakairiIdeaCardMolecules } from '../../molecules/card/idea/SakairiIdeaCard.Molecules';
import './IdeaCarousel.css';

interface IdeaCarouselProps {
  slide_min_width: number;
  card_width: number;
  ideas: IdeaCardType[];
  isSP: boolean;

  isTwoRows?: boolean;
  title_size?: TextSizeType;
  subTitle_size?: TextSizeType;
  button_size?: 'medium' | 'large' | 'extra-large';
  moreOnClick?: () => void;

  playOnInit?: boolean;
}

const SakairiIdeaCarousel: React.FC<IdeaCarouselProps> = (props) => {
  return (
    <section className="idea-calousel-wrapper">
      <DefaultCarouselIons
        slide_min_width={props.slide_min_width}
        isTwoRows={false}
        items={[
          ...props.ideas.map((idea) => (
            <SakairiIdeaCardMolecules
              idea={idea}
              hover_effect={!props.isSP}
              width={props.card_width}
              text_size={props.isSP ? 'small' : 'small'}
              display_description={!props.isSP}
            />
          )),
        ]}
        displayButton={false}
        playOnInit={props.playOnInit ?? true}
        autoScroll
      />

      <div className="sakairi-idea-carousel-more-button">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            gap: '8px',
          }}
          onClick={() => props.moreOnClick && props.moreOnClick()}
        >
          <BodyTextIons
            text={'もっと見る'}
            className="sakairi-idea-carousel-text"
            size={props.isSP ? 'small' : 'normal'}
            color="grey"
          />

          <CircleRainbowButtonIons>
            <BodyTextIons
              text={'→'}
              color="grey"
              size={props.isSP ? 'medium' : 'large'}
              bold="bold"
              rows={1}
              className="request-card-button-text"
            />
          </CircleRainbowButtonIons>
        </div>
      </div>
    </section>
  );
};

export default SakairiIdeaCarousel;
