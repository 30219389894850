import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { createIdeaCommentAPIProps } from '../../../../services/api/idea/comment/createIdeaCommentAPI.type';
import { deleteIdeaCommentAPIProps } from '../../../../services/api/idea/comment/deleteIdeaCommentAPI.type';
import {
  likeIdeaCommentAPIProps,
  unlikeIdeaCommentAPIProps,
} from '../../../../services/api/idea/comment/like/likeIdeaCommentAPI.type';
import {
  IdeaCommentType,
  IdeaDetailType,
} from '../../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import IdeaComments from '../../../organisms/idea/comment/IdeaComments';
import './../IdeaTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  idea_comments: IdeaCommentType[];
  numOfAllComments: number;
  page: number;
  title_size?: TextSizeType;
  isSP?: boolean;
  sendIdeaComment: (input: createIdeaCommentAPIProps) => void;
  likeIdeaComment: (input: likeIdeaCommentAPIProps) => void;
  deleteIdeaComment: (input: deleteIdeaCommentAPIProps) => void;
  unlikeIdeaComment: (input: unlikeIdeaCommentAPIProps) => void;
}

const IdeaCommentTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <div className="idea-comment-template">
      <BlackTitleTextIons text="みんなの声" size={props.title_size} />
      <IdeaComments
        page={props.page}
        isSP={props.isSP}
        idea={props.idea}
        ideaId={props.idea.ideaId}
        idea_comments={props.idea_comments}
        numOfAllComments={props.numOfAllComments}
        login_user_img_src={user?.imgSrc || ''}
        login_userName={user?.userName || ''}
        onSendComment={props.sendIdeaComment}
        onLikeComment={props.likeIdeaComment}
        onUnlikeComment={props.unlikeIdeaComment}
        onDeleteComment={props.deleteIdeaComment}
        isLoggedIn={isLoggedIn}
      />
    </div>
  );
};

export default IdeaCommentTemplate;
