import { IdeaErrorType } from '../../../../types/api/idea/IdeaError.type';
import { editIdeaType } from './editIdeaAPI.type';

// アイデアを編集するAPI
export const editIdeaAPI = async (
  input: editIdeaType,
  onSuccess?: () => void,
  onError?: () => void,
): Promise<IdeaErrorType | null> => {
  const formData = new FormData();

  // 各プロパティを FormData に追加
  Object.entries(input).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      // ideaIdというカラムは削りたい。編集対象ではないから。
      if (key === 'ideaId') {
        return;
      }
      // Fileオブジェクトの場合はそのまま追加、それ以外は文字列として追加
      if (value instanceof Blob || value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    }
  });

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/ideas/${input.ideaId}`,
      {
        method: 'PUT',
        credentials: 'include',
        body: formData,
      },
    );

    if (!response.ok) {
      if (onError) onError();
      const result: IdeaErrorType = await response.json();
      return result;
    } else {
      if (onSuccess) onSuccess();
      return null;
    }
  } catch (error) {
    return { code: 'UNKNOWN_ERROR', message: 'エラーが発生しました' };
  }
};
