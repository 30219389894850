import { RefObject, useCallback } from 'react';

interface ScrollOptions {
  delay?: number;
  behavior?: 'auto' | 'smooth' | 'instant';
}

export const useScroll = (
  options: ScrollOptions = {},
  ref?: RefObject<HTMLElement>,
) => {
  const { delay = 100, behavior = 'smooth' } = options;

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      ref?.current?.scrollTo({
        top: 0,
        behavior: behavior as ScrollBehavior,
      });
    }, delay);
  }, [ref, delay, behavior]);

  const windowScrollToTop = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: behavior as ScrollBehavior,
      });
    }, delay);
  }, [delay, behavior]);

  return { scrollToTop, windowScrollToTop };
};
