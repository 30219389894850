import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import DefaultDividerIons from '../../../ions/divider/DefaultDivider.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import LikeCardMolecules from '../../../molecules/card/like/LikeCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import './HistoryIdeaCardGrid.css';

interface PurchaseCardGridProps {
  isSP: boolean;
  page: number;
  maxPage: number;
  ideas: IdeaCardType[];
  title: string;
  num: number;
}

const HistoryIdeaCardGridWithHeader: React.FC<PurchaseCardGridProps> = (
  props,
) => {
  return (
    <section className="card-grid-section">
      <div className={`card-grid-header ${props.isSP ? '' : 'pc'}`}>
        <div className="card-grid-header-title">
          <BodyTextBoldIons
            text={props.title}
            size={props.isSP ? 'normal' : 'medium'}
          />
          <BodySubTextIons text={`(${props.num}件)`} size="small" />
        </div>
      </div>

      {props.ideas.length > 0 ? (
        <div className="card-grid-item">
          {props.ideas.map((idea, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LikeCardMolecules
                isSP={props.isSP}
                title={idea.title}
                description={idea.description}
                imgSrc={idea.imgSrc}
                onClick={() => PageNavigation.goToIdeaPage(idea.ideaId)}
              />
              {index + 1 !== props.ideas.length && (
                <DefaultDividerIons width={'90%'} />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="card-grid-no-item">
          <h4 className={`card-grid-no-item-text ${props.isSP ? '' : 'pc'}`}>
            アイデアが見つかりませんでした
          </h4>
        </div>
      )}
      <PageNationButtons num={props.page} max_num={props.maxPage} />
    </section>
  );
};

export default HistoryIdeaCardGridWithHeader;
