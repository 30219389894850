import { AuthErrorType } from '../../../../../types/api/auth/AuthError.type';
import { mailLoginAPIInputType } from './mailLoginAPI.type';

// ログインAPI
// ログインできたらok(true)を返す
export const mailLoginAPI = async (
  input: mailLoginAPIInputType,
): Promise<AuthErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/signin`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(input),
      },
    );
    if (!response.ok) {
      if (input.onError) {
        input.onError();
      }
      return {
        code: 'UNKNOWN_ERROR',
        message: '該当のユーザーが存在しません',
      };
    } else {
      input.onSuccess && input.onSuccess();
      return null;
    }
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '該当のユーザーが存在しません',
    };
  }
};
