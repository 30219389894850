export type IdeasSortQuery =
  | 'new'
  | 'popular'
  | 'viewed'
  | 'featured'
  | 'like'
  | 'adopted'
  | 'realized'
  | 'invested'
  | 'liked'
  | 'posted';
export type IdeaPageViewQuery = 'home' | 'comment' | 'activity';

// 配列とラベルを連携させるオブジェクト
export const ideasSortQueryMap = {
  new: '新しい',
  popular: '人気の',
  viewed: '最近見た',
  featured: 'おすすめの',
  like: 'いいねが多い',
  adopted: '商品化が決定した',
  realized: '商品化した',
  invested: '出資した',
  liked: 'いいねした',
  posted: '投稿した',
};
export const ideaPageViewQueryMap = {
  home: 'ホーム',
  comment: 'コメント',
  activity: '活動報告',
};

// パース関数：sortを適切な値にパースする
export const parseIdeasSortQuery = (
  sort: string | null | undefined,
  init: IdeasSortQuery,
): IdeasSortQuery => {
  if (sort && Object.keys(ideasSortQueryMap).includes(sort)) {
    return sort as IdeasSortQuery;
  } else {
    return init;
  }
};
export const parseIdeaViewQuery = (view?: string | null): IdeaPageViewQuery => {
  if (view && Object.keys(ideaPageViewQueryMap).includes(view)) {
    return view as IdeaPageViewQuery;
  } else {
    return 'home';
  }
};

// ラベル取得関数
export const getIdeasSortLabel = (query: IdeasSortQuery): string | null =>
  query ? ideasSortQueryMap[query] : null;
export const getIdeaPageTitle = (query: IdeaPageViewQuery): string =>
  ideaPageViewQueryMap[query] || '不明';
