// ログイン用の型定義

import { AuthErrorType } from '../../../../../types/api/auth/AuthError.type';
import { mailRegisterAPIProps } from './mailRegisterAPI.type';

// 新規会員登録API
export const mailRegisterAPI = async (
  input: mailRegisterAPIProps,
): Promise<AuthErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/signup`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input.info),
      },
    );

    if (!response.ok) {
      const errorData: AuthErrorType = await response.json();
      return errorData;
    }

    return null;
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
