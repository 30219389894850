import React from 'react';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import EnTitleSection from '../../../organisms/section/EnTitleSection';
import './AccountErrorTemplate.css';

interface PopupProps {
  isSP: boolean;
}

// メール認証完了後の遷移ページ
const GoogleAccountNotFoundTemplate: React.FC<PopupProps> = React.memo(
  (props) => {
    const { isSP } = props;
    return (
      <div
        className={`google-account-not-found-template-container ${isSP ? 'sp' : ''}`}
      >
        <div
          className={`google-account-not-found-template ${isSP ? 'sp' : ''}`}
        >
          <header className="google-account-not-found-template-header">
            <HeaderTitle
              title="Account Not Found"
              subTitle="アカウントが見つかりませんでした"
              titleSize="medium"
            />
          </header>

          <section className="google-account-not-found-template-main">
            <EnTitleSection
              title="Please use Another Account"
              subTitle="別のアカウントを使用してください"
              isSP={isSP}
            >
              <section className="google-account-not-found-template-main-content">
                <ul className="google-account-not-found-template-list">
                  {[
                    '指定された Google アカウントは、本サービスに登録されていないため、ログインすることができません。',
                    'お手数をおかけいたしますが、既に登録されている別の Google アカウントをご使用のうえ、ログインしていただきますようお願いいたします。',
                    'なお、まだアカウントを作成されていない場合は、新規会員登録が必要となります。',
                    '下記のボタンより、該当のアカウントで新規会員登録を行っていただきますようお願いいたします。',
                  ].map((item) => (
                    <li>
                      <BodyTextIons
                        text={item}
                        bold="light"
                        size="extra-small"
                      />
                    </li>
                  ))}
                </ul>
                <div className="google-account-not-found-template-main-buttons">
                  <RoundPurpleButtonIons
                    onClick={PageNavigation.goToSignInPage}
                    padding={'10px 20px'}
                    width={'90%'}
                  >
                    <BodyTextIons
                      text="他のアカウントでログイン"
                      size="small"
                      bold="light"
                      color="white"
                    />
                  </RoundPurpleButtonIons>
                  <RoundWhiteButtonIons
                    onClick={PageNavigation.goToSignUpPage}
                    padding={'10px 20px'}
                    width={'90%'}
                  >
                    <BodyTextIons
                      text="新規会員登録"
                      size="small"
                      bold="light"
                    />
                  </RoundWhiteButtonIons>
                </div>
              </section>
            </EnTitleSection>
          </section>
        </div>
      </div>
    );
  },
);

export default GoogleAccountNotFoundTemplate;
