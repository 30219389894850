import React from 'react';
import './UserAgreementTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const UserAgreementTemplate: React.FC<HomeTemplateProps> = (props) => {
  // リスト内容の定義
  const list6 = [
    '本サービスと同様のサービスを提供している企業に勤務または関係する職業に従事されている本人またはその家族',
    '既に登録されている会員と同一のメールアドレス、LINEのアカウント、Appleのアカウントその他の会員情報を利用して会員登録する場合',
    '本人以外の者による代理登録または成りすましによる登録の場合',
    '登録手続きにおいて当社に申告した情報の全部または一部に虚偽、誤記または記載漏れがあると当社が判断した場合',
    '反社会的勢力（暴力団、暴力団員、右翼団体、反社会勢力その他これに準ずる者をいい、以下同様とします。）であり、または資金提供その他の方法により反社会的勢力等と何らかの交流または関与を行っていると当社が判断した場合',
    '過去に当社との契約に違反した者またはその関係者であると当社が判断した場合',
    'その他当社が登録を不適当と判断した場合',
  ];

  const list8 = [
    '公序良俗に反する行為',
    '法律、条例その他の法令に違反する行為',
    'LINEの会員規約・ルールに反する行為',
    'Googleの会員規約・ルールに反する行為',
    'Appleの会員規約・ルールに反する行為',
    '当社、パートナー企業、他の会員または第三者の著作権を侵害する行為',
    '当社、パートナー企業、他の会員または第三者を誹謗、中傷する行為',
    '当社、パートナー企業、他の会員または第三者に不利益を与える行為',
    '本サービスの運営を妨害する行為',
    '虚偽または事実に反する情報の書き込み、回答をする行為',
    '当社が承諾をしている場合を除き、本サービス内における営業行為もしくはその性格を有する虞のある行為または営利目的で利用する行為',
    '同一人物による重複登録、またはなりすまし登録をする行為',
    'ログインIDまたはパスワードを不正使用する行為',
    'この規約または個別規則により課される守秘義務に違反する行為',
    'その他当社が不適当と判断した行為',
  ];

  const list9 = [
    '当社の予見可能性の有無を問わず、会員が本サービスを利用したこと、または利用できなかったことにより発生した一切の損害（損失、逸失利益、間接損害、特別損害を含みます。）',
    '本サービスに関する当社の規約、個別規則の変更、中止または終了により発生した一切の損害',
    '会員による情報の発信、意見の表明もしくは交換またはこれらの共有等を利用して行った行為に起因する一切の損害（当社または第三者の情報漏洩、ウィルス感染、システム障害、不正アクセスを含みます。）',
    '会員による他の会員または第三者の情報の発信、意見の表明もしくは交換またはこれらの共有等を利用して行った行為に起因する一切の損害',
    '会員による不正使用、不正アクセス等の行為に起因する一切の損害',
    '当社による本サービスの一時停止、中断または終了',
    '会員の投稿内容の信頼性、正確性、有用性等に関する一切の事項',
  ];

  const list12 = [
    '本規約の変更が、会員の一般の利益に適合するとき。',
    '本規約の変更が、本サービス利用契約の目的に反せず、かつ変更の必要性、変更後の内容の相当性および変更内容に係る事情その他の変更に係る事情に照らして合理的なものであるとき。',
  ];

  return (
    <main className="user-agreement-container">
      <section
        className={`user-agreement-content ${props.size === 'sp' ? 'sp' : ''}`}
      >
        <h1 className="user-agreement-title">利用規約</h1>

        <article className="user-agreement-details">
          <h2 className="agreement-chapter">第1章　総則</h2>
          <div className="agreement-section">
            <section className="agreement-article">
              <h3 className="article-title">第1条（目的）</h3>
              <p className="article-text">
                本規約は、株式会社RE-IDEA（以下「当社」といいます。）が運営する会員（第6条所定の会員をいい、以下同様とします。）による情報の発信、意見の表明やこれらの共有等を行う情報サービスプラットフォーム『RE-IDEA』（以下「本サービス」といいます。）に関して会員と当社との間に適用される契約関係を定めることを目的とします。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第2条（本サービス）</h3>
              <p className="article-text">
                本サービスは、当社または当社が委託を受けた第三者（以下「パートナー企業」といいます。）が提示する各種テーマについて、会員による情報の発信、意見の表明もしくは交換またはこれらの共有等を行うための場を提供するサービスです。
                <br />
                <br />
                本サービスは、会員自らが所有するメールアドレス、LINEのアカウント、Googleのアカウント、Appleのアカウントの認証を利用します。
                <br />
                <br />
                当社による第6条所定の会員登録の承認は、当社が当該会員に対して本サービスにより提示される各種テーマに係る情報の発信、意見の表明もしくは交換または、これらの共有等を行うことを義務付けるものではなく、かつ、当該会員が当該各種テーマに対する情報の発信、意見の表明もしくは交換またはこれらの共有等を行う機会を保証するものではありません。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第3条（規約の追加・変更等）</h3>
              <p className="article-text">
                当社は、この規約のほか、本サービスに関連して提供される個々のサービスに適用される個別の規約、条件、その他の規則（以下「個別規則」といいます。）を定めることができるものとします。当社が別途指定する方法により個別規則を会員に通知し、または本サービスに係るWebサイト（以下「本Webサイト」といいます。）に掲示した場合には、会員はこの規約に加えて、個別規則も遵守するものとします。
                <br />
                <br />
                当社は、一定の予告期間を設けた上で、会員に公表または通知することにより、この規約および個別規則を改定することができるものとします。会員は、この規約および個別規則の改定に同意できない場合、本サービスの利用を中止するものとします。
                <br />
                <br />
                本サービスに関連して提供される個々のサービスに関して、個別規則にこの規約の定めと矛盾、齟齬のある規定がある場合は、個別規則の規定が優先して適用されるものとします。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第4条（規約への同意）</h3>
              <p className="article-text">
                この規約は、会員登録希望者による会員登録手続きが完了した時点で会員による同意があったものとみなされ、会員に対して適用されるものとします。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第5条（会員登録情報）</h3>
              <p className="article-text">
                当社は、当社が本サービスに関して取得した会員の登録情報その他の個人情報を当社が本Webサイトに掲載する「RE-IDEAサービスにおけるプライバシーポリシー」（以下「プライバシーポリシー」といいます。）に従って適切に管理するものとします。会員は、本サービスを利用する事前に本Webサイトでプライバシーポリシーを確認するものとし、会員登録希望者による会員登録手続きが完了した時点で会員によるプライバシーポリシーへの同意があったものとみなされるものとします。
              </p>
            </section>
          </div>

          <h2 className="agreement-chapter">第2章　会員資格・会員登録</h2>
          <div className="agreement-section">
            <section className="agreement-article">
              <h3 className="article-title">第6条（会員資格）</h3>
              <p className="article-text">
                会員とは、この規約およびプライバシーポリシーに同意のうえ、当社所定の会員登録手続きを完了した者をいいます。なお、会員登録希望者が以下に該当する場合は会員登録をすることができないものとします。
              </p>
              <ol className="article-list">
                {list6.map((item, index) => (
                  <li key={index} className="article-list-item">
                    {item}
                  </li>
                ))}
              </ol>
              <p className="article-text">
                当社は、会員が前項各号に該当する場合には、会員登録後であっても、会員登録の取り消しまたは会員資格の抹消を行うことができるものとします。かかる会員資格の取り消しまたは抹消により、会員が損害、損失等を被った場合でも当社は故意、重過失または消費者契約法違反がない限り如何なる責任も負わないものとします。
                会員登録希望者は、本規約に同意の後、当社指定の会員登録ページにおいて当社が指定する必要事項を入力のうえ会員登録を行うものとします。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">
                第7条（ログインIDおよびパスワードの管理）
              </h3>
              <p className="article-text">
                本サービスの利用にあたり必要となるログインＩＤおよびパスワードの管理とその使用に関しては、全て会員本人がその責任を負うものとします。
                <br />
                <br />
                会員は、本サービスの利用にあたり必要となるログインＩＤおよびパスワードを譲渡、貸与、名義変更、売買その他形式を問わず第三者に対して提供してはならないものとします。
              </p>
            </section>
          </div>

          <h2 className="agreement-chapter">第3章　会員の義務</h2>
          <div className="agreement-section">
            <section className="agreement-article">
              <h3 className="article-title">第8条（会員の禁止行為）</h3>
              <p className="article-text">
                会員は、以下に該当する行為またはその虞のある行為を行ってはならないものとします。
              </p>
              <ol className="article-list">
                {list8.map((item, index) => (
                  <li key={index} className="article-list-item">
                    {item}
                  </li>
                ))}
              </ol>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第9条（当社の免責事項）</h3>
              <p className="article-text">
                当社は、以下の事項に関して如何なる責任も負わないものとします。ただし、当社の故意、重過失または消費者契約法違反による場合はこの限りではありません。
              </p>
              <ol className="article-list">
                {list9.map((item, index) => (
                  <li key={index} className="article-list-item">
                    {item}
                  </li>
                ))}
              </ol>
            </section>
          </div>

          <h2 className="agreement-chapter">第4章 その他</h2>
          <div className="agreement-section">
            <section className="agreement-article">
              <h3 className="article-title">第10条（知的財産権）</h3>
              <p className="article-text">
                本サービスにおいて提供されるコンテンツ（テキスト、画像、映像、ソースコード等を含むがこれに限られません。）に関する著作権、商標権、その他の知的財産権は、当社または当社に当該コンテンツを提供する権利を有する者に帰属します。会員は、当社の事前の承諾を得ることなく、いかなる方法においてもこれらのコンテンツを使用することはできません。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第11条（通知または連絡）</h3>
              <p className="article-text">
                会員と当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、会員から、当社が別途定める方法に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして、当該連絡先へ通知または連絡を行い、これらは、発信時に会員へ到達したものとみなします。
              </p>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第12条（規約の変更）</h3>
              <p className="article-text">
                当社は、次の場合には本規約の内容を変更することができるものとします。
              </p>
              <ol className="article-list">
                {list12.map((item, index) => (
                  <li key={index} className="article-list-item">
                    {item}
                  </li>
                ))}
              </ol>
            </section>

            <section className="agreement-article">
              <h3 className="article-title">第13条（準拠法および合意管轄）</h3>
              <p className="article-text">
                本規約の準拠法は日本法とします。また、本規約または本サービスに関連して会員と当社との間に生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </p>
            </section>
          </div>

          <p className="establishment-date">制定日：2024年5月22日</p>
        </article>
      </section>
    </main>
  );
};

export default UserAgreementTemplate;
