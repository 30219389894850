import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { usePageNavigation } from '../usePageNavigation';

export const useAuthGuard = (redirectUrl: string) => {
  const dispatch = useDispatch();
  const { goToLoginPage } = usePageNavigation();
  const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!isLoggedIn) {
      goToLoginPage(redirectUrl);
    }
  }, [isLoggedIn, dispatch]);

  return { isLoggedIn, user };
};
