import React from 'react';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import './EnTitleSection.css';

interface RequestHeaderProps {
  title: string;
  subTitle: string;
  isSP: boolean;
  children?: React.ReactNode;
}

const EnTitleSection: React.FC<RequestHeaderProps> = React.memo((props) => {
  return (
    <section className={`en-title-section`}>
      <div className="en-title-section-heading-wrapper">
        <BodyTextIons
          text={props.title}
          className="en-title-section-heading-title"
        />

        <BodyTextBoldIons
          text={props.subTitle}
          color="purple"
          className="en-title-section-heading-subtitle"
        />
      </div>

      {props.children}
    </section>
  );
});

export default EnTitleSection;
