import { getIsLoggedInAPI } from '../../../services/api/user/get/getIsLoggedInAPI';
import { getMeAPI } from '../../../services/api/user/get/getMeAPI';
import { AppDispatch, RootState } from '../../store';
import { signin, signout } from '../authSlice';

// サーバーから取得したユーザー認証情報(セッション)を用いて、Redux ストアを更新する
export const updateAuthThunk =
  () =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<boolean> => {
    try {
      const auth = getState().auth;
      const _isLoggedIn = await getIsLoggedInAPI(); // ログイン状態を取得
      if (!_isLoggedIn) {
        await dispatch(signout()); // ログインしていない場合はログアウト
        return false;
      } else {
        // reduxにユーザー情報がある場合は何もしない
        if (auth.isLoggedIn && auth.user) {
          return true;
        } else {
          const user = await getMeAPI(); // ログインユーザー情報を取得
          await dispatch(signin(user)); // 成功時に Redux ストアを更新
          return true;
        }
      }
    } catch (error) {
      await dispatch(signout()); // エラー時はログアウト
      return false;
    }
  };
