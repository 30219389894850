import React from 'react';
import {
  likeIdeaAPIProps,
  unlikeIdeaAPIProps,
} from '../../../services/api/idea/like/likeIdeaAPI.type';
import { ButtonSizeType } from '../../atoms/button/Button.type';
import CirclePinkButtonIons from '../../ions/button/circle/pink/CirclePinkButton.Ions';
import CircleWhiteButtonIons from '../../ions/button/circle/white/CircleWhiteButton.Ions';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import './IdeaFooter.css';

interface IdeaFooterProps {
  text: string;
  onClickMainButton: () => void;
  ideaId: string;
  onClick?: () => void;
  isLiked?: boolean;
  likeIdea: (props: likeIdeaAPIProps) => void;
  unlikeIdea: (props: unlikeIdeaAPIProps) => void;
  numOfLikes?: number;
  isAdopted?: boolean;
}

const IdeaFooter: React.FC<IdeaFooterProps> = React.memo((props) => {
  const button_props: {
    size: ButtonSizeType;
    onClick: () => void;
  } = {
    size: 'extra-small',
    onClick: props.isLiked
      ? () => props.unlikeIdea({ ideaId: props.ideaId })
      : () => props.likeIdea({ ideaId: props.ideaId }),
  };
  return (
    <footer className={`idea-footer-wrapeer`}>
      <div className="idea-footer-heart-buttons">
        {props.isLiked ? (
          <CirclePinkButtonIons {...button_props}>
            <BodyTextBoldIons color="white" text="♡" />
          </CirclePinkButtonIons>
        ) : (
          <CircleWhiteButtonIons {...button_props}>
            <BodyTextBoldIons text="♡" />
          </CircleWhiteButtonIons>
        )}
        <BodyTextIons
          text={String(props.numOfLikes ?? 0)}
          color={props.isLiked ? 'red' : 'black'}
          size="extra-small"
        />
      </div>
      <RoundPurpleButtonIons
        size="medium"
        width={'80%'}
        onClick={props.onClickMainButton}
        disabled={props.isAdopted}
      >
        {props.text}
      </RoundPurpleButtonIons>
    </footer>
  );
});

export default IdeaFooter;
