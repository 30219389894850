import { GetIdeasTasksQueryType } from './getIdeaTasksAPI.type';

// アイデアを作成するAPI
export const getIdeaTasksAPI = async (query?: GetIdeasTasksQueryType) => {
  const params = new URLSearchParams();

  // オプショナルなパラメータを追加
  if (query?.status) params.set('status', String(query.status));

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/tasks${query?.status ? '?' : ''}${params.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
