import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useGetMe } from '../../../../hooks/api/user/useGetMe';
import { useAuthActions } from '../../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../../redux/store';
import { CategoryType } from '../../../../types/api/category/CategoryTypes';
import LoadingFullSpinner from '../../../molecules/spinner/LoadingFullSpinner';
import MyEditCorpTemplate from '../../../templates/auth/edit/MyEditCorpTemplate';
import MyEditTemplate from '../../../templates/auth/edit/MyEditTemplate';
import { MyEditTemplateProps } from '../../../templates/auth/edit/MyEditTemplate.type';

const MyEditPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  const { data: user } = useGetMe();
  const [searchParams] = useSearchParams();

  const isCorp: boolean = searchParams.get('corp') === 'true';

  // 入力値を管理
  const {
    values,
    loadings: formLoadings,
    handlers: formHandlers,
    errors: formErrors,
  } = useAuthForm({
    userName: user?.userName || '',
    corpName: user?.corpName || '',
    email: user?.email || '',
    introduction: user?.introduction || '',
    imgSrc: user?.imgSrc || '',
    bannerImgSrc: user?.bannerImgSrc || '',
    zipCode: user?.zipCode || '',
    address: user?.address || '',
    phoneNumber: user?.phoneNumber || '',
    websiteUrl: user?.websiteUrl || '',
    snsUrl1: user?.snsUrl1 || '',
    snsUrl2: user?.snsUrl2 || '',
    snsUrl3: user?.snsUrl3 || '',
    categoryIds: (user?.categories || []).map(
      (category: CategoryType) => category.categoryId,
    ),
    password: '***********',
  });

  // APIの呼び出し
  const {
    handlers: apiHandlers,
    errors: apiErrors,
    loadings: apiLoadings,
  } = useAuthActions({
    values: values,
  });

  if (!user) return <LoadingFullSpinner />;

  const props: MyEditTemplateProps = {
    isSP: isXS,
    user: user,
    values: values,
    formLoadings: formLoadings,
    formHandlers: formHandlers,
    formErrors: formErrors,
    apiHandlers: apiHandlers,
    apiErrors: apiErrors,
    apiLoadings: apiLoadings,
  };

  return isCorp ? (
    <MyEditCorpTemplate {...props} />
  ) : (
    <MyEditTemplate {...props} />
  );
};

export default MyEditPage;
