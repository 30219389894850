import { GET_IDEA_COMMENT_MAX_LIMIT } from '../../../../constants/max';
import { useGetIdea } from '../../../../hooks/api/idea/useGetIdea';
import { useGetIdeaActivities } from '../../../../hooks/api/idea/useGetIdeaActivities';
import { useGetIdeaComments } from '../../../../hooks/api/idea/useGetIdeaComments';
import { useGetIdeas } from '../../../../hooks/api/idea/useGetIdeas';
import { useGetInvestments } from '../../../../hooks/api/useGetInvestments';
import {
  IdeaActivityType,
  IdeaCardType,
  IdeaCommentType,
  IdeaDetailType,
} from '../../../../types/api/idea/IdeaTypes';
import { InvestmentType } from '../../../../types/api/investment/InvestmentTypes';
import { IdeaPageViewQuery } from '../../../../types/query/ideasQuery';

interface UseIdeaDataReturn {
  idea: IdeaDetailType | undefined;
  ideaComments: IdeaCommentType[];
  numOfAllComments: number;
  ideaActivities: IdeaActivityType[];
  investments: InvestmentType[];
  suggestIdeas: IdeaCardType[];
  queryKeys: {
    ideaKey: string[];
    ideaCommentsKey: string[];
    ideaActivitiesKey: string[];
  };
}

export const useIdeaData = (input: {
  ideaId?: string;
  view: IdeaPageViewQuery;
  page: number;
}): UseIdeaDataReturn => {
  const { data: idea, queryKey: ideaKey } = useGetIdea(input.ideaId);
  const { data: ideaCommentsResult, queryKey: ideaCommentsKey } =
    useGetIdeaComments({
      ideaId: input.ideaId,
      enabled: input.ideaId !== undefined,
      limit: GET_IDEA_COMMENT_MAX_LIMIT * input.page,
      page: 1,
    });
  const { data: ideaActivities, queryKey: ideaActivitiesKey } =
    useGetIdeaActivities({
      ideaId: input.ideaId,
      enabled: input.ideaId !== undefined,
    });
  const { data: investments } = useGetInvestments({ ideaId: input.ideaId });
  const { data: suggestIdeas } = useGetIdeas({
    sort: 'new',
    limit: 10,
  });

  return {
    idea,
    ideaComments: ideaCommentsResult?.comments || [],
    numOfAllComments: ideaCommentsResult?.numOfAllComments || 0,
    ideaActivities: ideaActivities || [],
    investments: investments || [],
    suggestIdeas: suggestIdeas?.ideas || [],
    queryKeys: {
      ideaKey,
      ideaCommentsKey,
      ideaActivitiesKey,
    },
  };
};
