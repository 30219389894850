import React from 'react';
import {
  AuthFormErrors,
  AuthFormHandlers,
  AuthFormState,
} from '../../../hooks/auth/useAuthForm.hooks';
import { AuthErrorType } from '../../../types/api/auth/AuthError.type';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIonsClickable } from '../../ions/text/body/BodyTextClickable.Ions';
import DefaultTextFieldIons from '../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../molecules/card/error/ErrorCard.Molecules';
import HeaderTitle from '../../molecules/text/headerTitle/HeaderTitle';
import './AuthTemplate.css';

interface PopupProps {
  isSP: boolean;
  apiError: AuthErrorType | null;
  values: AuthFormState;
  formErrors: AuthFormErrors;
  formHandlers: AuthFormHandlers;
  handleCorpRegister: () => void;
}

const RegisterCorpTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP, handleCorpRegister, values, formErrors, formHandlers } = props;

  return (
    <div className={`auth-template-container ${isSP ? 'sp' : ''}`}>
      <div className={`auth-template-card ${isSP ? 'sp' : ''}`}>
        <header className={`auth-template-header `}>
          <HeaderTitle
            title="Sign up"
            subTitle="新規会員登録(事業者)"
            titleSize="large"
          />
        </header>

        <main className={`auth-template-main `}>
          <form className={`auth-template-form `}>
            <DefaultTextFieldIons
              label="会社名(ユーザーネーム)"
              size={isSP ? 'small' : 'medium'}
              value={values.userName}
              onChange={formHandlers.handleUserNameChange}
              error={formErrors.userName}
            />
            <DefaultTextFieldIons
              label="メールアドレス"
              size={isSP ? 'small' : 'medium'}
              value={values.email}
              type="email"
              onChange={formHandlers.handleEmailChange}
              error={formErrors.email}
            />
            <DefaultTextFieldIons
              label="パスワード"
              size={isSP ? 'small' : 'medium'}
              value={values.password}
              type="password"
              onChange={formHandlers.handlePasswordChange}
              error={formErrors.password}
            />
            {props.apiError?.message && (
              <ErrorCardMolecules
                text={props.apiError?.message}
                isSP={isSP}
                padding="5px 10px"
                margin="10px 0"
                bold="light"
              />
            )}
          </form>

          <nav className="auth-template-nav-links">
            <BodyTextIonsClickable
              text="既にアカウントをお持ちの方はこちら"
              size="extra-small"
              onClick={PageNavigation.goToSignInPage}
            />
            <BodyTextIonsClickable
              text="一般ユーザーで登録したい方はこちら"
              size="extra-small"
              onClick={PageNavigation.goToSignUpPage}
            />
          </nav>

          <div className="auth-template-submit-button">
            <RoundPurpleButtonIons
              width={'100%'}
              size={'normal'}
              onClick={handleCorpRegister}
            >
              メール認証へ
            </RoundPurpleButtonIons>
          </div>
        </main>
      </div>
    </div>
  );
});

export default RegisterCorpTemplate;
