import SendIcon from '@mui/icons-material/Send';
import React from 'react';
import './CommentTextField.Atoms.css';
import { CommentTextFieldAtomsProps } from './CommentTextField.type';

const CommentTextFieldAtoms: React.FC<CommentTextFieldAtomsProps> = React.memo(
  ({
    value,
    onChange,
    onKeyDown,
    placeholder = '',
    className = '',
    disabled,
    error,
    max,
    width,
    isSP,
    mainColor,
  }) => {
    return (
      <div className="textfield-container" style={{ width }}>
        <div className={`comment-textfield-wrapper-atoms ${mainColor}`}>
          <div className={`comment-textfield-text-wrapper ${isSP ? 'sp' : ''}`}>
            <textarea
              disabled={disabled}
              value={value}
              onChange={onChange}
              onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (
                  event.key === 'Enter' &&
                  (event.metaKey || event.ctrlKey) &&
                  onKeyDown
                ) {
                  onKeyDown(value);
                }
              }}
              placeholder={placeholder}
              className={`textfield-atoms comment-textfield-input ${className}`}
            />
          </div>
          <div
            className={`comment-textfield-button-wrapper ${isSP ? 'sp' : ''}`}
          >
            <SendIcon
              onClick={() => onKeyDown && onKeyDown(value)}
              className={`comment-textfield-button ${mainColor} ${isSP ? 'sp' : ''}`}
              sx={{ fontSize: isSP ? 32 : 38 }}
            />
          </div>
        </div>
        <section className="textfield-error-max-container">
          {error && <div className="textfield-error">{error}</div>}
          {max && (
            <div
              className={`textfield-counter ${value.length >= max ? 'max' : ''}`}
            >
              {value.length} / {max}
            </div>
          )}
        </section>
      </div>
    );
  },
);

export default CommentTextFieldAtoms;
