import { createIdeaCommentType } from './createIdeaCommentAPI.type';

// アイデアのコメントを生成するAPI
export const createIdeaCommentAPI = async (
  input: createIdeaCommentType,
  onSuccess?: () => void,
): Promise<void> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${input.ideaId}/comments`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(input),
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  if (onSuccess) onSuccess();
};
