import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getUserByTokenAPI } from '../../../services/api/user/get/getUserByTokenAPI';
import { UserDetailType } from '../../../types/api/user/UserTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetUserResult = UseQueryResult<UserDetailType> & {
  queryKey: string[];
};

// ユーザー一覧情報を取得するためのカスタムフック
export const useGetUserByToken = (input: {
  token?: string | null;
  enabled?: boolean;
}): UseGetUserResult => {
  const queryKey = ['tokens', input.token];

  const result = useQuery<UserDetailType>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!input.token && input.enabled,
    queryFn: async () => {
      if (!input.token) throw new Error('userId is required');
      return getUserByTokenAPI(input.token);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetUserResult;
};
