export const unPublishIdeaAPI = async (
  ideaId: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${ideaId}/unpublish`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    if (onError) onError();
  } else {
    if (onSuccess) onSuccess();
  }
};
