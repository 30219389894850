export type ContactType = 'ideas' | 'theme' | 'others';
export const ContactTypeValues: ContactType[] = ['ideas', 'theme', 'others'];
export type ContactTypeLabel = 'お題の相談' | 'アイデアについて' | 'その他';

export const getContactTypeLabel = (type: ContactType): ContactTypeLabel => {
  switch (type) {
    case 'theme':
      return 'お題の相談';
    case 'ideas':
      return 'アイデアについて';
    case 'others':
      return 'その他';
  }
};
