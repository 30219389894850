import React from 'react';
import { IDEA_APEALPOINT_MAX_LENGTH } from '../../../../../constants/max';
import RadioButtonAtoms from '../../../../atoms/radio/RadioButton.Atoms';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../../ions/textfield/DefaultTextField.Ions';
import NumAndTitle from '../../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../../molecules/text/requireTitle/RequireAndTitle';
import './RadioButtonForm.css';

interface AppealPointsGeneFormProps {
  num?: number;
  generate: boolean;
  require?: boolean | null;
  appealPoint1: string | null;
  appealPoint2: string | null;
  appealPoint3: string | null;
  handleChangeGenerate: (value: boolean) => void;
  handleAppealPoint1Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleAppealPoint2Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleAppealPoint3Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  error1: string | null;
  error2: string | null;
  error3: string | null;
  isSP?: boolean;
}

const AppealPointsGeneForm: React.FC<AppealPointsGeneFormProps> = ({
  num,
  generate,
  appealPoint1,
  appealPoint2,
  appealPoint3,
  require,
  handleChangeGenerate,
  handleAppealPoint1Change,
  handleAppealPoint2Change,
  handleAppealPoint3Change,
  error1,
  error2,
  error3,
  isSP,
}) => {
  return (
    <section className="radio-button-form-button-wrapper">
      {num && (
        <NumAndTitle
          num={num}
          title="アイデアのアピールポイント"
          num_size="small"
          title_size="small"
        />
      )}
      {(require === true || require === false) && (
        <RequireAndTitle
          title="アイデアのアピールポイント"
          require={require}
          hint={
            'タイトルや説明文からアピールポイントを\nAIで生成することができます。'
          }
          hint_placement={isSP ? 'top-right' : 'top-left-center'}
        />
      )}
      <RadioButtonAtoms
        checked={!generate}
        size={'small'}
        onChange={() => {
          handleChangeGenerate(false);
        }}
      >
        <div
          className={`radio-button-form-button-cell ${!generate ? 'selected' : ''} `}
        >
          <BodyTextIons
            text={'アイデアのアピールポイントを自分で入力する'}
            size={'extra-small'}
            className="radio-button-form-button-text"
          />
        </div>
      </RadioButtonAtoms>
      <RadioButtonAtoms
        checked={generate}
        size={'small'}
        onChange={() => {
          handleChangeGenerate(true);
        }}
      >
        <div
          className={`radio-button-form-button-cell ${generate ? 'selected' : ''} `}
        >
          <BodyTextIons
            text={'アピールポイントをAIが自動で生成する'}
            size={'extra-small'}
            className="radio-button-form-button-text"
          />
        </div>
      </RadioButtonAtoms>
      {!generate && (
        <>
          <DefaultTextFieldIons
            value={appealPoint1 || ''}
            onChange={handleAppealPoint1Change}
            placeholder="アイデアのアピールポイント1を入力してください"
            label={null}
            error={error1}
            size="small"
            rows={1}
            max={IDEA_APEALPOINT_MAX_LENGTH}
          />
          <DefaultTextFieldIons
            value={appealPoint2 || ''}
            onChange={handleAppealPoint2Change}
            placeholder="アイデアのアピールポイント2を入力してください"
            label={null}
            error={error2}
            size="small"
            rows={1}
            max={IDEA_APEALPOINT_MAX_LENGTH}
          />
          <DefaultTextFieldIons
            value={appealPoint3 || ''}
            onChange={handleAppealPoint3Change}
            placeholder="アイデアのアピールポイント3を入力してください"
            label={null}
            error={error3}
            size="small"
            rows={1}
            max={IDEA_APEALPOINT_MAX_LENGTH}
          />
        </>
      )}
    </section>
  );
};

export default AppealPointsGeneForm;
