import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNotifications } from '../../../hooks/notifications/useNotifications.hooks';
import { updateNotifyThunk } from '../../../redux/notify/thunk/notifyThunk';
import { RootState } from '../../../redux/store';
import NotifyTemplate from '../../templates/notify/NotifyTemplate';

const NotifyPage = () => {
  const dispatch = useDispatch();
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  const { notifyId } = useParams();
  // 通知の取得
  const { notify } = useNotifications({
    notifyId,
  });

  // 通知状態の更新(reduxに保持)
  useEffect(() => {
    dispatch(updateNotifyThunk() as any);
  }, [dispatch, notify]);

  return (
    <NotifyTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      notify={notify}
    />
  );
};

export default NotifyPage;
