import { createChatType } from './createChatAPI.type';

// アイデアを作成するAPI
export const createChatAPI = async (
  input: createChatType,
  onSuccess?: () => void,
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/rooms/${input.roomId}/chat`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(input),
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to send comment: ${response.statusText}`);
  }

  if (onSuccess) onSuccess();
};
