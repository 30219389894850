import { signoutAPI } from '../../../services/api/auth/logout/signoutAPI';
import { AppDispatch } from '../../store';
import { signout } from '../authSlice';

export const signoutThunk =
  () =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    try {
      await signoutAPI(); // API 呼び出し
      await dispatch(signout()); // 成功時に Redux ストアを更新
      return true; // サインアウト成功
    } catch (error) {
      return false; // サインアウト失敗
    }
  };
