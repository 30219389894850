import './PrivacyPolicyTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const PriavcyPolicyTemplate: React.FC<HomeTemplateProps> = (props) => {
  const { size } = props;

  return (
    <main className="privacy-policy-container">
      <section
        className={`privacy-policy-content ${size === 'sp' ? 'sp' : ''}`}
      >
        <h1 className="privacy-policy-title">プライバシーポリシー</h1>
        <p className="privacy-policy-intro">
          RE-IDEAでは（以下「当社」といいます）は、個人情報の取り扱いについて、以下の個人情報保護方針を定め、これに基づき個人情報の保護・管理を徹底してまいります。
        </p>

        <article className="privacy-policy-details">
          <section className="policy-section">
            <h2 className="policy-section-title">個人情報について</h2>
            <p className="policy-section-text">
              個人情報とは、個人情報の保護に関する法律（個人情報保護法）に規定される個人情報を指し、特定の個人を識別することができる情報、ならびに特定の個人と紐づいて使用されるメールアドレス、パスワード、クレジットカードなどの情報のことをいいます。
            </p>
          </section>

          <section className="policy-section">
            <h2 className="policy-section-title">個人情報の管理について</h2>
            <p className="policy-section-text">
              当社は、その管理下にある個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。当社が信頼に足ると判断し、個人情報の守秘義務契約を結んだ企業に個人情報の取り扱いを委託する場合がありますが、この場合にも個人情報は当社の個人情報保護方針に基づき保護されます。
            </p>
          </section>

          <section className="policy-section">
            <h2 className="policy-section-title">個人情報の利用目的について</h2>
            <p className="policy-section-text">
              当社では、お預かりした個人情報を、以下の目的のために利用させていただきます。
            </p>
            <ul className="policy-list">
              <li className="policy-list-item">
                RE-IDEA（以下「本サービス」といいます。）のサービスを提供するため
              </li>
              <li className="policy-list-item">
                本サービスを改善・改良、または新サービスを開発するため
              </li>
              <li className="policy-list-item">
                本サービスの機能・更新情報およびキャンペーンなど、最新情報のご案内（電子メール、SNS、その他ダイレクトメールの送付を含みます。）のため
              </li>
              <li className="policy-list-item">
                本サービスのメンテナンスや重要なお知らせなど、即時必要なご連絡のため
              </li>
              <li className="policy-list-item">
                本サービスに関する調査のご協力や各種イベントへのご参加をお願いするため
              </li>
            </ul>
          </section>

          <section className="policy-section">
            <h2 className="policy-section-title">
              個人情報の第三者への開示・提供について
            </h2>
            <p className="policy-section-text">
              当社は、以下に定める場合を除き、個人情報を第三者に提供しません。
            </p>
            <ul className="policy-list">
              <li className="policy-list-item">お客様の同意がある場合</li>
              <li className="policy-list-item">
                お客様が希望されるサービスを行うために当社が業務を委託する業者に対して開示する場合
              </li>
              <li className="policy-list-item">法令に基づく場合</li>
            </ul>
          </section>

          <section className="policy-section">
            <h2 className="policy-section-title">セキュリティについて</h2>
            <p className="policy-section-text">
              当社は、個人情報の正確性及び安全性確保のため、セキュリティに万全の対策を講じています。
            </p>
          </section>

          <section className="policy-section">
            <h2 className="policy-section-title">
              個人情報保護方針の改善について
            </h2>
            <p className="policy-section-text">
              当社プライバシーポリシーについては、その内容を適宜見直し、その改善に努めていきます。
            </p>
          </section>

          <section className="policy-section">
            <h2 className="policy-section-title">
              お客様の個人情報に関するお問い合わせ先
            </h2>
            <p className="policy-section-text">
              当社のお客様の個人情報に関するお問い合わせは、下記電子メールアドレスまでご連絡ください。
            </p>
            <div className="contact-info">
              <p className="company-name">RE-IDEA</p>
              <p className="email-info">メールアドレス : info@re-idea.jp</p>
            </div>
          </section>
        </article>
      </section>
    </main>
  );
};

export default PriavcyPolicyTemplate;
