import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getIsVerifiedPasswordTokenAPI } from '../../../services/api/auth/verify/getIsVerifiedPasswordTokenAPI';

// 戻り値の型を拡張して queryKey を含める
type UseGetIsVerifiedPassTokenResult = UseQueryResult<boolean> & {
  queryKey: string[];
};

// パスワードトークン認証の可否を取得するためのカスタムフック
export const useGetIsVerifiedPassToken = (
  token?: string | null,
): UseGetIsVerifiedPassTokenResult => {
  const queryKey = ['is-verified-password-token'];

  const result = useQuery<boolean>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!token,
    queryFn: async () => {
      if (token) return getIsVerifiedPasswordTokenAPI(token);
      else return false;
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetIsVerifiedPassTokenResult;
};
