import React from 'react';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './PurchaseCard.Molecules.css';

type PurchaseCardMoleculesProps = {
  isSP: boolean;
  title: string;
  date: string;
  amount: number;
  onClick?: () => void;
};

export const PurchaseCardMolecules: React.FC<PurchaseCardMoleculesProps> =
  React.memo((props) => {
    return (
      <div className={`purchase-card-container`} onClick={props.onClick}>
        <div className={`purchase-card-info ${props.isSP ? '' : 'pc'}`}>
          <BodyTextBoldIons
            text={props.title}
            size={props.isSP ? 'small' : 'small'}
          />
          <BodySubTextIons
            text={`出資日: ${props.date}`}
            size={props.isSP ? 'extra-small' : 'small'}
          />
        </div>
        <BodySubTextIons text={`￥ ${props.amount}円`} />
      </div>
    );
  });

export default PurchaseCardMolecules;
