import React from 'react';
import useTextfieldMax from '../../../component/organisms/textfield/useTextfieldMax.hooks';
import {
  IDEA_ACTIVITY_DESCRIPTION_MAX_LENGTH,
  IDEA_ACTIVITY_TITLE_MAX_LENGTH,
} from '../../../constants/max';

export interface IdeaActivityFormState {
  title: string;
  description: string;
}

interface IdeaActivityFormErrors {
  title: string | null;
  description: string | null;
}

interface IdeaActivityFormHandlers {
  handleTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleDescriptionChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  clearStorage: () => void;
}

const STORAGE_KEY_PREFIX = 'idea_activity_form_';

export const useIdeaActivityForm = (input: {
  init?: IdeaActivityFormState;
  storageId?: string | null;
}): {
  values: IdeaActivityFormState;
  errors: IdeaActivityFormErrors;
  handlers: IdeaActivityFormHandlers;
} => {
  // ローカルストレージのキーを生成
  const storageKey = `${STORAGE_KEY_PREFIX}${input.storageId}`;

  // ローカルストレージから状態を復元する関数
  const getStoredState = (): Partial<IdeaActivityFormState> | null => {
    if (!input.storageId) return null;
    const stored = localStorage.getItem(storageKey);
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        console.error('Failed to parse stored form state:', e);
        return null;
      }
    }
    return null;
  };

  // 初期状態の設定（ローカルストレージ → input.init → デフォルト値の優先順位）
  const storedState = React.useMemo(() => getStoredState(), [storageKey]);

  // タイトルの値
  const {
    value: title,
    error: title_error,
    handleChange: handleTitleChange,
  } = useTextfieldMax(
    storedState?.title ?? input.init?.title ?? '',
    IDEA_ACTIVITY_TITLE_MAX_LENGTH,
  );

  // サブタイトルの値
  const {
    value: description,
    error: description_error,
    handleChange: handleDescriptionChange,
  } = useTextfieldMax(
    storedState?.description ?? input.init?.description ?? '',
    IDEA_ACTIVITY_DESCRIPTION_MAX_LENGTH,
  );

  const values: IdeaActivityFormState = {
    title,
    description,
  };

  // 値が変更されたときにローカルストレージに保存
  React.useEffect(() => {
    if (input.storageId)
      localStorage.setItem(storageKey, JSON.stringify(values));
  }, [storageKey, values]);

  const clearStorage = () => {
    if (storageKey) {
      localStorage.removeItem(storageKey);
      handleTitleChange({
        target: { value: input.init?.title ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      handleDescriptionChange({
        target: { value: input.init?.description ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const errors: IdeaActivityFormErrors = {
    title: title_error,
    description: description_error,
  };

  const handlers = {
    handleTitleChange,
    handleDescriptionChange,
    clearStorage,
  };

  return { values, errors, handlers };
};
