import React from 'react';
import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import './SettingTemplate.css';

interface PopupProps {
  isSP: boolean;
  result: boolean;
  newPassword: string;
  newPasswordConfirm: string;
  handleChangeNewPassword: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleChangeNewPasswordConfirm: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  newPasswordFormError: string | null;
  newPasswordConfirmFormError: string | null;
  handleUpdatePassword: () => void;
  apiLoading: boolean;
  apiError: AuthErrorType | null;
}

const SettingNewPasswordTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP } = props;
  const compared = props.newPassword === props.newPasswordConfirm;

  return (
    <div className={`setting-template-container `}>
      {props.apiLoading && <LoadingOverlaySpinner />}
      <header className={`setting-template-header `}>
        <HeaderTitle
          title="Setting Password"
          subTitle="パスワードの再設定"
          titleSize="large"
        />
      </header>

      <main className={`setting-template-main ${isSP ? 'sp' : ''}`}>
        <form className={`setting-template-form `}>
          {props.result && (
            <div className="setting-template-success">
              {
                '※ パスワードを変更しました。次回のログインから新しいパスワードをご利用ください。'
              }
            </div>
          )}
          <DefaultTextFieldIons
            label="新しいパスワード"
            size={isSP ? 'small' : 'medium'}
            value={props.newPassword}
            onChange={props.handleChangeNewPassword}
            error={props.newPasswordFormError}
            width={'100%'}
          />
          <DefaultTextFieldIons
            label="新しいパスワード(確認)"
            size={isSP ? 'small' : 'medium'}
            value={props.newPasswordConfirm}
            onChange={props.handleChangeNewPasswordConfirm}
            error={compared ? '' : 'パスワードが一致しません'}
            width={'100%'}
          />
          {props.apiError?.message && (
            <ErrorCardMolecules
              text={props.apiError?.message}
              isSP={isSP}
              padding="5px 10px"
              margin="10px 0"
              bold="light"
            />
          )}
        </form>

        <div className="setting-template-submit-buttons">
          <RoundPurpleButtonIons
            width={'60%'}
            size={'normal'}
            onClick={() => compared && props.handleUpdatePassword()}
          >
            パスワードを変更する
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            width={'60%'}
            size={'normal'}
            onClick={PageNavigation.goBack}
          >
            戻る
          </RoundWhiteButtonIons>
        </div>
      </main>
    </div>
  );
});

export default SettingNewPasswordTemplate;
