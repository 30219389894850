import React from 'react';
import { IdeaCommentType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import IdeaImgIons from '../../../ions/img/IdeaImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import UserIconAndName from '../../user/UserIconAndName';
import './TopCommentCard.Molecules.css';
type IdeaCardProps = {
  comment: IdeaCommentType;
};

export const TopCommentCardMolecules: React.FC<IdeaCardProps> = React.memo(
  (props) => {
    const { comment } = props;

    return (
      <div
        className="top-comment-card-molecules-container"
        onClick={() =>
          PageNavigation.goToIdeaPage(comment.ideaId, { view: 'comment' })
        }
      >
        <div style={{ width: '35%' }}>
          <IdeaImgIons
            img_src={comment?.ideaImgSrc}
            alt={comment?.ideaImgSrc}
            className="top-comment-card-molecules-img"
          />
        </div>
        <main
          className="top-comment-card-molecules-main"
          style={{ width: '65%' }}
        >
          <UserIconAndName
            userName={comment?.user?.userName}
            user_img_src={comment?.user?.imgSrc}
            userId={comment?.user?.userId}
            icon_size="small"
          />
          <BodyTextIons
            size={'small'}
            text={comment?.text}
            rows={1}
            className="top-comment-card-molecules-text"
          />
        </main>
      </div>
    );
  },
);
