import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { RequestCardType } from '../../../../types/api/request/RequestTypes';
import CircleRainbowButtonIons from '../../../ions/button/circle/rainbow/CircleRainbowButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { IdeaCardMolecules } from '../../../molecules/card/idea/IdeaCard.Molecules';
import './../CardGridHeader.css';
import './IdeaCardGrid.css';

interface TagsCheckboxGridProps {
  columns: number;
  ideas: IdeaCardType[];
  card_text_size?: 'extra-small' | 'small';
  isSP: boolean;
  isPublic?: boolean;
  requestId?: string | null;
  themes?: RequestCardType[];
  moreOnClick?: () => void;
}

const IdeaCardGridAtHome: React.FC<TagsCheckboxGridProps> = (props) => {
  return (
    <section className="card-grid-section">
      {(props.ideas || []).length ? (
        <div
          data-testid="card-grid"
          className={`card-grid-container columns-${props.columns}`}
        >
          {props.ideas.map((idea) => (
            <IdeaCardMolecules
              hover_effect={!props.isSP}
              idea={idea}
              text_size={props.card_text_size}
              width={'100%'}
            />
          ))}
        </div>
      ) : (
        <div className="card-grid-no-item">
          <h4 className={`card-grid-no-item-text ${props.isSP ? '' : 'pc'}`}>
            アイデアが見つかりませんでした
          </h4>
        </div>
      )}

      <div className="sakairi-idea-carousel-more-button">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            gap: '8px',
          }}
          onClick={() => props.moreOnClick && props.moreOnClick()}
        >
          <BodyTextIons
            text={'もっと見る'}
            className="sakairi-idea-carousel-text"
            size={props.isSP ? 'small' : 'normal'}
            color="grey"
          />

          <CircleRainbowButtonIons>
            <BodyTextIons
              text={'→'}
              color="grey"
              size={props.isSP ? 'medium' : 'large'}
              bold="bold"
              rows={1}
              className="request-card-button-text"
            />
          </CircleRainbowButtonIons>
        </div>
      </div>
    </section>
  );
};

export default IdeaCardGridAtHome;
