import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import './NotFoundTemplate.css';

const NotFoundTemplate = () => {
  return (
    <div className="not-found-template">
      <div className="not-found-template-container">
        <div className="not-found-template-content">
          <BodyTextBoldIons
            text="ページが見つかりませんでした"
            size="medium"
            className="not-found-template-subtitle"
          />
          <p className="not-found-template-description">
            お探しのページは削除されたか、URLが間違っている可能性があります。
          </p>
          <RoundPurpleButtonIons onClick={PageNavigation.goToHomePage}>
            トップページへ戻る
          </RoundPurpleButtonIons>
        </div>
      </div>
    </div>
  );
};

export default NotFoundTemplate;
