import { IdeaPageViewQuery } from '../types/query/ideasQuery';
import { NotificationsFilterQuery } from '../types/query/notificationsQuery';

export const PageNavigation = {
  // 前のページに戻る
  goBack: () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = '/'; // 履歴がない場合はホームページに遷移
    }
  },

  goToNotFoundPage: () => {
    window.location.href = '/not-found';
  },

  // アイデアの採用ページへ
  goToIdeaAdoptPage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/adopt-request`;
  },

  // アイデアの採用確認ページへ
  goToIdeaAdoptConfirmPage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/adopt-request/confirm`;
  },

  // アイデアの採用完了ページへ
  goToIdeaAdoptCompletePage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/adopt-request/complete`;
  },

  goToNotificationsPage: (
    params?: Record<'filter', NotificationsFilterQuery>,
  ) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/notifications';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/notifications${queryString ? `?${queryString}` : ''}`;
  },

  // 特定のアイデア詳細ページに遷移
  goToIdeaPage: (
    ideaId: string,
    params?: Record<'view', IdeaPageViewQuery>,
  ) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = `/ideas/${ideaId}`;
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/ideas/${ideaId}${queryString ? `?${queryString}` : ''}`;
  },

  // 特定のアイデア編集ページに遷移
  goToIdeaEditPage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/edit`;
  },

  // 特定のアイデア出資ページに遷移
  goToIdeaInvestPage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/invest`;
  },

  // 特定のアイデア出資確定後ページに遷移
  goToIdeaInvestCompletePage: (ideaId: string, investId: string) => {
    window.location.href = `/ideas/${ideaId}/invest/${investId}/complete`;
  },

  // 特定のアイデア出資削除確定後ページに遷移
  goToIdeaInvestDeleteCompletePage: (ideaId: string, investId: string) => {
    window.location.href = `/ideas/${ideaId}/invest/${investId}/delete/complete`;
  },

  // 特定の通知ページに遷移
  goToNotificationPage: (notificationId: string) => {
    window.location.href = `/notifications/${notificationId}`;
  },

  // 認証メール送信完了ページに遷移
  goToVerifyEmailSendPage: () => {
    window.location.href = `/auth/verify/email/send`;
  },

  // 認証完了ページに遷移
  goToAccountVerifyCompletePage: (token: string) => {
    window.location.href = `/auth/verify/${token}`;
  },

  // アカウント登録完了ページに遷移
  goToSignUpCompletePage: () => {
    window.location.href = `/auth/signup/complete`;
  },

  // チャットルーム一覧ページに遷移
  goMyChatRoomPage: () => {
    window.location.href = `/users/my?mode=chat`;
  },

  // ログインページ(共通)に遷移
  goToSignInPage: () => {
    window.location.href = `/login`;
  },

  // 新規会員登録ページ(一般ユーザー)に遷移
  goToSignUpPage: () => {
    window.location.href = `/register`;
  },

  // 新規会員登録ページ(事業者)に遷移
  goToCorpSignUp: () => {
    window.location.href = `/corp/register`;
  },

  // お題相談ページに遷移
  goToContactRequestPage: () => {
    window.location.href = `/contact/theme`;
  },

  // お問い合わせページに遷移
  goToContactPage: () => {
    window.location.href = `/contact`;
  },

  // お問い合わせ完了ページに遷移
  goToContactSuccessPage: () => {
    window.location.href = `/contact/success`;
  },

  // ホームページに遷移
  goToHomePage: () => {
    window.location.href = `/`;
  },

  // 検索ページに遷移
  goToSearchPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/search';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/search${queryString ? `?${queryString}` : ''}`;
  },

  // マイページに遷移
  goToMyPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/users/my';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/users/my${queryString ? `?${queryString}` : ''}`;
  },

  // 他の人のページに遷移(userIdが自分の場合でもマイページに遷移できない)
  goToOtherUserPage: (userId: string, params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/users/' + userId;
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/users/${userId}${queryString ? `?${queryString}` : ''}`;
  },

  // マイページに遷移
  goToIntroPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/introduce';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/introduce${queryString ? `?${queryString}` : ''}`;
  },

  // お題詳細ページに遷移
  goToRequestPage: (requestId: string) => {
    window.location.href = `/requests/${requestId}`;
  },

  // IDEA工房の使い方ページへ移動
  goToHowToUsePage: (isCorp?: boolean) => {
    window.location.href = '/how-to-use' + (isCorp ? '?corp=true' : '');
  },

  // ユーザー情報編集ページへ移動
  goToMyEditPage: (isCorp?: boolean) => {
    window.location.href = '/users/my/edit' + (isCorp ? '?corp=true' : '');
  },

  // メールアドレス再設定ページへ移動
  goToSettingEmailPage: () => {
    window.location.href = '/auth/setting/email';
  },

  // パスワード再設定ページへ移動
  goToSettingPasswordPage: () => {
    window.location.href = '/auth/setting/password';
  },

  // パスワード再設定完了ページへ移動
  goToUpdatePasswordSuccessPage: (token: string) => {
    window.location.href = `/auth/update/password/${token}/success`;
  },

  // プライバシーポリシーページへ
  goToPrivacyPolicyPage: () => {
    window.location.href = `/privacy-policy`;
  },

  // 特商法ページへ
  goToSpecifiedCommercialTransaction: () => {
    window.location.href = '/specified-commercial-transaction';
  },

  // 利用規約ページへ
  goToUserAgreementPage: () => {
    window.location.href = '/user-agreement';
  },
};
