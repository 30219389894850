import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { NOTIFICATIONS_DISPLAY_LENGTH } from '../../../constants/max';
import { useAuthGuard } from '../../../hooks/auth/useAuthGuard';
import { useNotifications } from '../../../hooks/notifications/useNotifications.hooks';
import { RootState } from '../../../redux/store';
import { readAllNotificationsAPI } from '../../../services/api/notifications/readAllNotificationAPI';
import {
  NotificationsFilterQuery,
  parseNotificationsFilterQuery,
} from '../../../types/query/notificationsQuery';
import NotificationsTemplate from '../../templates/notify/NotificationsTemplate';
import LoginPage from '../login/LoginPage';

const NotificationsPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  const { isLoggedIn } = useAuthGuard(window.location.href);
  const [searchParams] = useSearchParams();
  const filter: NotificationsFilterQuery = parseNotificationsFilterQuery(
    searchParams.get('filter'),
    null,
  );
  const page = parseInt(searchParams.get('page') || '1', 10);
  const limit = NOTIFICATIONS_DISPLAY_LENGTH;

  // 通知の取得
  const { notifications } = useNotifications({
    limit,
    page,
    filter,
  });

  // 全ての通知を既読にする
  const handleReadAllNotifications = useCallback(async () => {
    await readAllNotificationsAPI();
    window.location.reload();
  }, []);

  const maxPage = Math.ceil(
    (notifications || []).length / NOTIFICATIONS_DISPLAY_LENGTH,
  );
  console.log((notifications || []).length, limit, maxPage);

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return (
    <NotificationsTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      notifications={notifications || []}
      page={page}
      maxPage={maxPage}
      filter={filter}
      handleReadAllNotifications={handleReadAllNotifications}
    />
  );
};

export default NotificationsPage;
