import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetIdea } from '../../../../hooks/api/idea/useGetIdea';
import { useAuthGuard } from '../../../../hooks/auth/useAuthGuard';
import { useIdeaActions } from '../../../../hooks/idea/useIdeaActions.hooks';
import { RootState } from '../../../../redux/store';
import IdeaAdoptRequestConfirmTemplate from '../../../templates/ideaAdopt/confirm/IdeaAdoptRequestConfirmTemplate';
import NotFoundTemplate from '../../../templates/not-found/NotFoundTemplate';

const IdeaAdoptConfirmPage = () => {
  // 自動リダイレクト付きの認証
  const { isLoggedIn, user } = useAuthGuard(window.location.href);

  // アイデアを取得する
  const { id } = useParams();
  const { data: idea, queryKey: idea_key } = useGetIdea(id ?? '');

  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  const { apiHandlers, apiErrors, apiLoadings } = useIdeaActions({
    isLoggedIn,
    ideaKey: idea_key,
  });

  // 企業アカウントでなければリダイレクトする
  // 企業アカウントであっても、お題が存在して && お題の投稿者でなければリダイレクトする
  if (
    user?.isCorp !== true ||
    (idea?.request && idea?.request.user.userId !== user.userId)
  ) {
    return <NotFoundTemplate />;
  }

  // アイデアの投稿者としてログインできていればアイデア編集画面を表示する
  return (
    <IdeaAdoptRequestConfirmTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
      loading={apiLoadings.adoptIdeaRequest}
      error={apiErrors.adoptIdeaRequest}
      handleAdoptRequestIdea={apiHandlers.handleAdoptIdeaRequest}
    />
  );
};

export default IdeaAdoptConfirmPage;
