import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import AccountAlreadyExistsTemplate from '../../templates/auth/AccountError/AccountAlreadyExistsTemplate';

const AccountAlreadyExistsPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  return <AccountAlreadyExistsTemplate isSP={isXS} />;
};

export default AccountAlreadyExistsPage;
