// パスワードトークンの認証API

import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';
import { updatePasswordAPIProps } from './updatePasswordByTokenAPI.type';

export const updatePasswordByTokenAPI = async (
  input: updatePasswordAPIProps,
): Promise<AuthErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/password/update/${input.info.token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword: input.info.newPassword }),
      },
    );

    if (!response.ok) {
      const result: AuthErrorType = await response.json();
      return result;
    } else {
      return null;
    }
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: 'パスワードの更新に失敗しました',
    };
  }
};
