import { useSelector } from 'react-redux';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import RegisterCorpTemplate from '../../templates/auth/RegisterCorpTemplate';

const RegisterCorpPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // 会員登録のフォームの処理
  const { values, handlers: formHandlers, errors: formErrors } = useAuthForm();

  // 会員登録の処理
  const { handlers: apiHandlers, errors: apiErrors } = useAuthActions({
    values: values,
  });

  return (
    <RegisterCorpTemplate
      isSP={isXS}
      values={values}
      formHandlers={formHandlers}
      formErrors={formErrors}
      handleCorpRegister={apiHandlers.handleCorpRegister}
      apiError={apiErrors.corpRegister}
    />
  );
};

export default RegisterCorpPage;
