import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import { TextSizeType } from '../../atoms/text/Text.type';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import './UserIconAndName.css';

interface UserIconAndNameProps {
  userId: string;
  userName: string;
  user_img_src: string | null;
  icon_size?: 'extra-small' | 'small' | 'medium' | 'large';
  text_size?: TextSizeType;
  bold?: boolean;
}

const UserIconAndName: React.FC<UserIconAndNameProps> = React.memo((props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <section
      className="user-icon-and-name-wrapper"
      onClick={() =>
        user?.userId === props.userId
          ? PageNavigation.goToMyPage()
          : PageNavigation.goToOtherUserPage(props.userId)
      }
    >
      <UserIconImgIons
        userName={props.userName}
        img_src={props.user_img_src}
        size={props.icon_size}
      />
      {props.bold ? (
        <BodyTextBoldIons
          text={props.userName}
          size={props.text_size ?? 'small'}
        />
      ) : (
        <BodyTextIons text={props.userName} size={props.text_size ?? 'small'} />
      )}
    </section>
  );
});

export default UserIconAndName;
