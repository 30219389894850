import React from 'react';
import { PopoverPlacement } from '../../../../atoms/popover/Popover.type';
import DefaultImgIons from '../../../../ions/img/DefaultImg.Ions';
import UserIconImgIons from '../../../../ions/img/icon/UserIconImg.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import UserBannerImgIons from '../../../../ions/img/UserBannerImg.Ions';
import { ImageUploadForm } from '../../../../molecules/form/ImageUploadForm';
import NumAndTitle from '../../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../../molecules/text/requireTitle/RequireAndTitle';
import './RadioButtonForm.css';

interface IdeaImageFormProps {
  num?: number;
  require?: boolean | null;
  loading?: boolean;
  hint?: string;
  hint_placement?: PopoverPlacement;
  title: string;
  imgSrc: string | null;
  imgFile: File | null;
  error: string | null;
  handleImgSrcChange: (src: string | null) => void;
  handleImgFileChange: (file: File | null) => void;
  type?: 'icon' | 'banner' | 'idea';
}

const ImageForm: React.FC<IdeaImageFormProps> = ({
  num,
  imgSrc,
  imgFile,
  hint,
  hint_placement,
  loading,
  title,
  handleImgSrcChange,
  handleImgFileChange,
  require,
  error,
  type,
}) => {
  return (
    <section className="radio-button-form-button-wrapper">
      {num && (
        <NumAndTitle
          num={num}
          title={title}
          num_size="small"
          title_size="small"
        />
      )}
      {(require === true || require === false) && (
        <RequireAndTitle
          title={title}
          require={require}
          hint={hint}
          hint_placement={hint_placement}
        />
      )}
      <div className="image-form">
        {imgSrc !== null || imgFile !== null ? (
          type === 'icon' ? (
            <UserIconImgIons
              img_src={imgSrc}
              imgFile={imgFile}
              userName="ユーザー名"
              size="large"
              onDelete={() => {
                handleImgSrcChange(null);
                handleImgFileChange(null);
              }}
            />
          ) : type === 'idea' ? (
            <IdeaImgIons
              img_src={imgSrc || ''}
              imgFile={imgFile || undefined}
              onDelete={() => {
                handleImgSrcChange(null);
                handleImgFileChange(null);
              }}
            />
          ) : type === 'banner' ? (
            <UserBannerImgIons
              img_src={imgSrc || ''}
              imgFile={imgFile}
              onDelete={() => {
                handleImgSrcChange(null);
                handleImgFileChange(null);
              }}
            />
          ) : (
            <DefaultImgIons imgFile={imgFile} img_src={imgSrc || ''} />
          )
        ) : (
          <ImageUploadForm
            onImageUpload={handleImgFileChange}
            error={error}
            loading={loading}
            imgFile={imgFile}
            imgSrc={imgSrc}
          />
        )}
      </div>
    </section>
  );
};

export default ImageForm;
