import { mailLoginAPI } from '../../../services/api/auth/login/mail/mailLoginAPI';
import { mailLoginAPIInputType } from '../../../services/api/auth/login/mail/mailLoginAPI.type';
import { AuthErrorType } from '../../../types/api/auth/AuthError.type';
import { AppDispatch } from '../../store';
import { updateAuthThunk } from './updateAuthThunk';

export const loginWithMail =
  (input: mailLoginAPIInputType) =>
  async (dispatch: AppDispatch): Promise<AuthErrorType | null> => {
    try {
      const result = await mailLoginAPI(input);
      // ログイン成功時にユーザー情報を取得して Redux ストアを更新
      if (result === null) await dispatch(updateAuthThunk() as any);
      return result; // ログイン成功
    } catch (error) {
      return {
        code: 'UNKNOWN_ERROR',
        message: 'ログインに失敗しました',
      }; // ログイン失敗
    }
  };
