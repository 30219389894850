import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getIdeaActivitiesAPI } from '../../../services/api/idea/activity/getIdeaActivitiesAPI';
import { getIdeaActivitiesType } from '../../../services/api/idea/activity/getIdeaActivitiesAPI.type';
import { IdeaActivityType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeaActivitiesResult = UseQueryResult<IdeaActivityType[]> & {
  queryKey: string[];
};

export const useGetIdeaActivities = (
  query: getIdeaActivitiesType & { enabled: boolean },
): UseGetIdeaActivitiesResult => {
  const queryKey = ['idea-activities', query];

  const result = useQuery<IdeaActivityType[]>({
    queryKey, // 定義した queryKey を使用
    enabled: !!query.enabled,
    queryFn: async () => {
      if (!query.ideaId) throw new Error('ideaId is required');
      return getIdeaActivitiesAPI(query);
    },
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeaActivitiesResult;
};
