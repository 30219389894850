// メール認証API
// 認証できたらok(true)を返す
export const emailVerifyAPI = async (token: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/verify/${token}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  );
  if (!response.ok) {
    throw new Error(`Login failed: ${response.statusText}`);
  }
  return response.ok;
};
