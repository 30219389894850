import { CHAT_DISPLAY_LIMIT } from '../../../../constants/max';
import { GetChatsQueryType } from './getChatsAPI.type';

export const getChatsAPI = async (query: GetChatsQueryType) => {
  try {
    const params = new URLSearchParams();

    if (query?.limit) params.set('limit', String(query.limit));
    else params.set('limit', String(CHAT_DISPLAY_LIMIT));

    if (query?.page) params.set('page', String(query.page));

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/rooms/${query.roomId}/chat?${params.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
