import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './UserInfoGrid.css';

interface TagsCheckboxGridProps {
  userId: string;
  isCorp: boolean;
  numOfRequests: number;
  numOfIdeas: number;
  numOfFollowedUsers: number;
  handleClickFollowedUsers: () => void;
  numOfFollowers: number;
  handleClickFollowers: () => void;
}

const UserInfoGrid: React.FC<TagsCheckboxGridProps> = (props) => {
  const { goToOthersUserPage } = usePageNavigation();
  return (
    <div className="user-info-grid">
      {[
        {
          num: props.isCorp ? props.numOfRequests : props.numOfIdeas,
          text: props.isCorp ? 'お題投稿' : 'アイデア投稿',
          onClick: () => {
            if (props.isCorp)
              goToOthersUserPage(props.userId, { mode: 'requests' });
            else goToOthersUserPage(props.userId, { mode: 'ideas' });
          },
        },
        {
          num: props.numOfFollowedUsers.toString(),
          text: 'フォロー',
          onClick: () => props.handleClickFollowedUsers(),
        },
        {
          num: props.numOfFollowers.toString(),
          text: 'フォロワー',
          onClick: () => props.handleClickFollowers(),
        },
      ].map((v) => (
        <div className="user-info-grid-cell" onClick={v.onClick}>
          <BodyTextIons
            text={v.num.toString()}
            size="normal"
            className="user-info-grid-text"
          />
          <BodySubTextIons
            text={v.text}
            size="small"
            className="user-info-grid-text"
          />
        </div>
      ))}
    </div>
  );
};

export default UserInfoGrid;
