import React from 'react';
import useSetQuery from '../../../../hooks/useQuery';
import { RequestCardType } from '../../../../types/api/request/RequestTypes';
import { SelectorOption } from '../../../atoms/selector/Selector.type';
import DefaultSelectorIons from '../../../ions/selector/DefaultSelector.Ions';

interface ThemeSelectorProps {
  isSP: boolean;
  isPublic?: boolean;
  themes: RequestCardType[];
  requestId: string | null;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = (props) => {
  const { setThemeIdQuery } = useSetQuery();
  return (
    <DefaultSelectorIons
      minWidth={props.isSP ? '140px' : '240px'}
      placeholder="お題を選択"
      options={[
        { label: 'お題未設定', value: 'none' },
        ...props.themes.map((theme: RequestCardType) => {
          if (theme.requestId === null)
            return { label: 'お題未設定', value: 'none' };
          return { label: theme.title, value: theme.requestId };
        }),
      ]}
      value={props.requestId || 'none'}
      onChange={(option: SelectorOption) => {
        if (option.value === 'none') {
          setThemeIdQuery(null);
          return;
        } else {
          setThemeIdQuery(option.value);
          return;
        }
      }}
    />
  );
};

export default ThemeSelector;
