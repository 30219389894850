import React from 'react';
import {
  IdeaTaskStatusType,
  IdeaTaskType,
} from '../../../../types/api/idea/IdeaTypes';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultSpinnerIons from '../../../ions/spinner/DefaultSpinner.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './TaskCard.Molecules.css';

type PurchaseCardMoleculesProps = {
  isSP: boolean;
  task: IdeaTaskType;
  handleConfirmTask: (taskId: string, ideaId: string | null) => void;
};

export const TaskCardMolecules: React.FC<PurchaseCardMoleculesProps> =
  React.memo((props) => {
    const { task } = props;
    const status = task.status as IdeaTaskStatusType;
    const { title, ideaGeneTaskId, ideaId } = task;
    return (
      <div className={`task-card-container `}>
        <div className="task-card-container-left">
          {(status === 'processing' || status === 'pending') && (
            <DefaultSpinnerIons size="small" />
          )}
          <div className="task-card-container-left-texts">
            <BodyTextIons
              color="white"
              size="small"
              text={
                status === 'pending'
                  ? `『${title.length > 6 ? title.slice(0, 6) + '...' : title}』の生成待機中`
                  : status === 'processing'
                    ? `『${title.length > 6 ? title.slice(0, 6) + '...' : title}』を生成中`
                    : status === 'done'
                      ? `『${title.length > 6 ? title.slice(0, 6) + '...' : title}』が生成完了`
                      : `『${title.length > 6 ? title.slice(0, 6) + '...' : title}』が生成失敗`
              }
            />

            <BodyTextIons
              color="white"
              size="extra-small"
              text={
                status === 'pending'
                  ? `現在 ?? 人待機中`
                  : status === 'processing'
                    ? `しばらくお待ちください`
                    : status === 'done'
                      ? `※ まだ投稿されていません`
                      : '※ 生成に失敗しました'
              }
            />
          </div>
        </div>

        {status === 'done' && (
          <RoundWhiteButtonIons
            onClick={() =>
              ideaId && props.handleConfirmTask(ideaGeneTaskId, ideaId)
            }
          >
            <BodyTextBoldIons
              text="確認する"
              className="task-card-confirm-button-text"
            />
          </RoundWhiteButtonIons>
        )}

        {status === 'failed' && (
          <BodyTextBoldIons
            size="medium"
            color="white"
            text="×"
            onClick={() => props.handleConfirmTask(ideaGeneTaskId, null)}
          />
        )}
      </div>
    );
  });

export default TaskCardMolecules;
