import React from 'react';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import './RequestNullCard.Molecules.css';

type PurchaseCardMoleculesProps = {
  isSP: boolean;
  width: number | string;
};

export const RequestNullCardMolecules: React.FC<PurchaseCardMoleculesProps> =
  React.memo((props) => {
    return (
      <div className={`request-card-container `} style={{ width: props.width }}>
        <div className="request-card-null-wrapper">
          <BodySubTextIons
            text={'お題がセットされていません'}
            className="request-card-null-text"
          />
          <BodySubTextIons
            text={'左右にスワイプして、お題をセット'}
            className="request-card-null-description"
          />
        </div>
      </div>
    );
  });

export default RequestNullCardMolecules;
