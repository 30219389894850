import React from 'react';
import { CONTACT_DETAIL_MAX_LENGTH } from '../../../constants/max';
import useTextfield from '../../../hooks/useTextfield.hooks';
import { ContactType } from '../../../types/api/contact/ContactTypes';
import { validateMail } from '../../../utils/validate/validateMail';
import useTextfieldMax from '../../organisms/textfield/useTextfieldMax.hooks';

interface ContactFormState {
  userName: string;
  email: string;
  detail: string;
  type: ContactType;
  isCorp: boolean;
}

interface ContactFormErrors {
  userName: string | null;
  email: string | null;
  detail: string | null;
}

interface ContactFormHandlers {
  handleUserNameChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleEmailChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleDetailChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleTypeChange: (type: ContactType) => void;
  handleIsCorpChange: (isCorp: boolean) => void;
}

export const useContactForm = (
  init?: ContactFormState,
): {
  values: ContactFormState;
  errors: ContactFormErrors;
  handlers: ContactFormHandlers;
  reset: () => void;
} => {
  // 氏名の値
  const {
    value: userName,
    error: userName_error,
    handleChange: handleUserNameChange,
  } = useTextfield(init?.userName ?? '');

  // メールアドレスの値
  const {
    value: email,
    error: email_error,
    handleChange: handleEmailChange,
  } = useTextfield(init?.email ?? '', validateMail);

  // typeの値
  const [type, setType] = React.useState<ContactType>('ideas');

  // ユーザー種別の値(法人)
  const [isCorp, setIsCorp] = React.useState<boolean>(false);

  // 詳細の値
  const {
    value: detail,
    error: detail_error,
    handleChange: handleDetailChange,
  } = useTextfieldMax(init?.detail ?? '', CONTACT_DETAIL_MAX_LENGTH);

  const values: ContactFormState = {
    userName,
    email,
    detail,
    type,
    isCorp,
  };

  const errors: ContactFormErrors = {
    userName: userName_error,
    email: email_error,
    detail: detail_error,
  };

  const reset = () => {
    handleUserNameChange({ target: { value: '' } } as any);
    handleEmailChange({ target: { value: '' } } as any);
    handleDetailChange({ target: { value: '' } } as any);
    setType('ideas');
    setIsCorp(false);
  };

  const handlers = {
    handleUserNameChange,
    handleEmailChange,
    handleDetailChange,
    handleTypeChange: (type: ContactType) => setType(type),
    handleIsCorpChange: (isCorp: boolean) => setIsCorp(isCorp),
  };

  return { values, errors, handlers, reset };
};
