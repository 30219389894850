import React from 'react';
import { PopoverPlacement } from '../../atoms/popover/Popover.type';
import { TextSizeType } from '../../atoms/text/Text.type';
import SettingButtonIons from '../../ions/button/setting/SettingButton.Ions';
import DefaultPopoverIons from '../../ions/popover/DefaultPopover.Ions';
import { BodySubTextIons } from '../../ions/text/body/BodySubText.Ions';
import './SettingButtonPopover.css';

interface SettingButtonPopoverProps {
  textSize: TextSizeType;
  options: { label: string; onClick: () => void }[];
  placement?: PopoverPlacement;
}

const SettingButtonPopover: React.FC<SettingButtonPopoverProps> = React.memo(
  (props) => {
    return (
      <DefaultPopoverIons
        children={<SettingButtonIons />}
        content={
          <div className="setting-button-popover-labels-container">
            {props.options.map((option, index) => (
              <BodySubTextIons
                key={index}
                size={props.textSize}
                text={option.label}
                onClick={option.onClick}
              />
            ))}
          </div>
        }
        trigger="click"
        placement={props.placement || 'bottom-right'}
      />
    );
  },
);

export default SettingButtonPopover;
