import { useSearchParams } from 'react-router-dom';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import {
  ideasSortQueryMap,
  parseIdeasSortQuery,
} from '../../../types/query/ideasQuery';
import NavTextsMolecules from '../../molecules/text/nav/NavTexts.Molecules';

const IdeaSortNavTexts = () => {
  const { goToSearchPage } = usePageNavigation();
  const [searchParams] = useSearchParams();
  const sort = searchParams.get('sort');

  // ideasSortQueryMapから特定の要素たちを取得して、それをNavTextsMoleculesに渡す
  const navOptions = Object.entries(ideasSortQueryMap)
    .map((entry) => {
      const [sort, label] = entry;
      if (!['new', 'popular', 'like', 'adopted'].includes(sort)) return null;
      return {
        label: `${label}アイデア`,
        value: sort,
      };
    })
    .filter((option) => option !== null);

  return (
    <NavTextsMolecules
      textOptions={navOptions.map((option) => ({
        label: option?.label || '',
        value: option?.value || '',
      }))}
      onClick={(value: string | number) =>
        goToSearchPage({ sort: parseIdeasSortQuery(value.toString(), 'new') })
      }
      value={sort || ''}
    />
  );
};

export default IdeaSortNavTexts;
