import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CHAT_DISPLAY_LIMIT } from '../../../../constants/max';
import { useBodyScrollLock } from '../../../../hooks/useBodyScrollLock';
import useSetQuery from '../../../../hooks/useQuery';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { updateNotifyThunk } from '../../../../redux/notify/thunk/notifyThunk';
import { AdoptIdeaAPIProps } from '../../../../services/api/idea/adopt/adoptIdeaAPI.type';
import { createChatAPIProps } from '../../../../services/api/room/chat/createChatAPI.type';
import { ChatDetailType } from '../../../../types/api/chat/ChatTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { TextSizeType } from '../../../atoms/text/Text.type';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import DefaultCommentTextFieldIons from '../../../ions/textfield/DefaultCommentTextField';
import { ChatMolecules } from '../../../molecules/chat/Chat.Molecules';
import './ChatGrid.css';

interface ChatGridProps {
  roomId: string;
  otherUsersViewedAt: (Date | null)[];
  title: string;
  login_user_id: string;
  chats: ChatDetailType[];
  numOfAllChat: number;
  page?: number;
  size?: TextSizeType;
  only?: boolean;
  handleCreateChat: (input: createChatAPIProps) => void;
  handleAdoptIdea?: (input: AdoptIdeaAPIProps) => void;
}

const ChatGrid: React.FC<ChatGridProps> = (props) => {
  const dispatch = useDispatch();
  const { value, handleChange, handleReset } = useTextfield();
  const { setPageQuery } = useSetQuery();

  // 通知状態の更新(reduxに保持)
  useEffect(() => {
    dispatch(updateNotifyThunk() as any);
  }, [dispatch]);

  useBodyScrollLock(true);

  // チャットが追加されたら一番下にスクロール
  useEffect(() => {
    const chatGrid = document.querySelector('.chat-grid');
    if (chatGrid) {
      chatGrid.scrollTop = chatGrid.scrollHeight;
    }
  }, [props.chats]);

  return (
    <div
      data-testid="chat-grid"
      className={`chat-grid-container ${props.only ? 'only' : ''}`}
    >
      <section className={`chat-grid-and-input ${props.only ? 'only' : ''}`}>
        <header className="chat-grid-header">
          <div className="chat-grid-header-back-and-user">
            <ArrowBackIosIcon
              sx={{ color: 'grey' }}
              onClick={PageNavigation.goBack}
            />
            <BodySubTextIons text={props.title} size="small" />
          </div>
        </header>

        <div className={`chat-grid ${props.only ? 'only' : ''}`}>
          {props.numOfAllChat > CHAT_DISPLAY_LIMIT * (props.page || 1) && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '15px',
              }}
            >
              <RoundWhiteButtonIons
                width={'50%'}
                onClick={() => setPageQuery((props.page ?? 1) + 1)}
              >
                さらに表示
              </RoundWhiteButtonIons>
            </div>
          )}
          {props.chats.map((chat) => (
            <ChatMolecules
              key={chat.chatId}
              userId={chat.user?.userId || ''}
              text={chat.text}
              imgSrc={chat.imgSrc}
              userImgSrc={chat.user?.imgSrc || ''}
              userName={chat.user?.userName || ''}
              left={chat.user?.userId !== props.login_user_id}
              createdAt={chat.createdAt}
              size={props.size}
              isRead={props.otherUsersViewedAt.every(
                (viewedAt) => viewedAt && viewedAt >= new Date(chat.createdAt),
              )}
            />
          ))}
        </div>

        <div className={`chat-grid-input ${props.only ? 'only' : ''}`}>
          {/* <ImageIcon className="chat-grid-image-icon" sx={{ fontSize: 28 }} /> */}
          <DefaultCommentTextFieldIons
            value={value}
            mainColor="primary"
            onChange={handleChange}
            error={null}
            placeholder={'メッセージを入力'}
            isSP={props.only}
            width={'100%'}
            onKeyDown={() =>
              props.handleCreateChat({
                chat: {
                  text: value,
                  roomId: props.roomId,
                  imgSrc: null,
                  toChatId: null,
                },
                onSuccess: () => {
                  handleReset();
                },
              })
            }
          />
        </div>
      </section>
    </div>
  );
};

export default ChatGrid;
