import { createCorpAPI } from '../../../services/api/auth/verify/corp/createCorpAPI';
import { CreateCorpType } from '../../../services/api/auth/verify/corp/createCorpAPI.type';
import { AuthErrorType } from '../../../types/api/auth/AuthError.type';
import { AppDispatch } from '../../store';
import { updateAuthThunk } from './updateAuthThunk';

// 企業アカウントの作成と認証を行って自動ログイン
export const createAndVerifyCorpThunk =
  (input: CreateCorpType) =>
  async (dispatch: AppDispatch): Promise<AuthErrorType | null> => {
    try {
      // 企業アカウントの作成と認証のAPIを呼び出す
      const result = await createCorpAPI(input);
      // 認証成功時、自動ログインされているはずなので Redux ストアを更新
      if (result === null) {
        await dispatch(updateAuthThunk() as any);
        return null;
      }
      return result; // ログイン成功
    } catch (error) {
      return {
        code: 'UNKNOWN_ERROR',
        message: '予期せぬエラーが発生しました。',
      }; // ログイン失敗
    }
  };
