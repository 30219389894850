import React from 'react';
import { NotifyCardType } from '../../../types/api/notifications/NotificationType';
import { NotificationsFilterQuery } from '../../../types/query/notificationsQuery';
import HeaderTitle from '../../molecules/text/headerTitle/HeaderTitle';
import NotifyCardGrid from '../../organisms/grid/notifyCardGrid/NotifyCardGrid';
import './NotificationsTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  notifications: NotifyCardType[];
  page: number;
  maxPage: number;
  filter: NotificationsFilterQuery;
  handleReadAllNotifications: () => void;
}

const NotificationsTemplate: React.FC<HomeTemplateProps> = (props) => {
  const { size } = props;
  let width: string;

  switch (size) {
    case 'sp':
      width = '100%';
      break;
    case 'small':
      width = '60%';
      break;
    case 'medium':
      width = '45%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  return (
    <div className="notifications-template">
      <main className="notifications-template-main" style={{ width: width }}>
        <HeaderTitle
          title="Notifications"
          subTitle="通知一覧"
          titleSize={'large'}
          className="notifications-template-header"
        />
        <NotifyCardGrid
          filter={props.filter}
          isSP={size === 'sp'}
          notifications={props.notifications}
          handleReadAllNotifications={props.handleReadAllNotifications}
          page={props.page}
          maxPage={props.maxPage}
        />
      </main>
    </div>
  );
};

export default NotificationsTemplate;
