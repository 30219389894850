export type NotificationsFilterQuery = null | 'unread';

// 配列とラベルを連携させるオブジェクト
export const notificationsFileterQueryMap = {
  unread: '未読の',
};

// パース関数：filterを適切な値にパースする
export const parseNotificationsFilterQuery = (
  filter: string | null | undefined,
  init: NotificationsFilterQuery,
): NotificationsFilterQuery => {
  if (filter && Object.keys(notificationsFileterQueryMap).includes(filter)) {
    return filter as NotificationsFilterQuery;
  } else {
    return init;
  }
};
