// パスワードトークンの認証API
// 認証ができたらtrueを返す
export const getIsVerifiedPasswordTokenAPI = async (
  token: string,
): Promise<boolean> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/tokens/password/${token}/is-valid`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return response.ok;
};
