import { GetUserQueryType } from './getMeAPI.type';

export const getMeAPI = async (query?: GetUserQueryType) => {
  try {
    const params = new URLSearchParams();
    // デフォルト値とクエリパラメータの設定
    if (query?.idea !== undefined)
      params.set('idea', query.idea ? 'true' : 'false');
    if (query?.theme !== undefined)
      params.set('theme', query.theme ? 'true' : 'false');
    if (query?.follow !== undefined)
      params.set('follow', query.theme ? 'true' : 'false');

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/me?${params.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // レスポンスデータを JSON としてパース
    const data = await response.json();

    return data; // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
