import React from 'react';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import EnTitleSection from '../../../organisms/section/EnTitleSection';
import './IdeaInvestCompleteTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaInvestDeleteCompleteTemplate: React.FC<IdeaTemplateProps> = (
  props,
) => {
  const { size, idea } = props;
  let width: string;
  const isSP = size === 'sp';

  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '70%';
      break;
    case 'medium':
      width = '55%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  return (
    <div className="idea-invest-confirm-template">
      <main
        className="idea-invest-confirm-template-main"
        style={{ width: width }}
      >
        <HeaderTitle
          title="Cancel Complete"
          titleSize="large"
          subTitle="出資のキャンセルが完了しました"
        />

        <section className="idea-invest-confirm-template-main">
          <EnTitleSection
            title="Mail Send"
            subTitle="確認メールを送信しました"
            isSP={isSP}
          >
            <ul className="idea-invest-complete-template-main-content-list">
              {[
                '決済のキャンセル処理が正常に完了しました。このお取引に関する今後の請求は発生いたしません。',
                'キャンセル完了のメールを送信いたしましたので、ご確認をお願いいたします。',
                'メールが届かない場合や、ご不明な点がございましたら、support@example.comまでお気軽にご連絡ください。',
              ].map((item) => (
                <li>
                  <BodyTextIons text={item} bold="light" size="extra-small" />
                </li>
              ))}
            </ul>
          </EnTitleSection>
        </section>

        <section className="idea-invest-confirm-template-buttons">
          <RoundPurpleButtonIons
            onClick={() => PageNavigation.goToIdeaPage(idea.ideaId)}
            size="medium"
          >
            アイデアページへ
          </RoundPurpleButtonIons>
        </section>
      </main>
    </div>
  );
};

export default IdeaInvestDeleteCompleteTemplate;
