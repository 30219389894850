import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import DefaultDividerIons from '../../ions/divider/DefaultDivider.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import './AppFooter.css';

const AppFooterSP = React.memo(() => {
  // ログイン状態・ユーザー情報・画面サイズ・通知情報を取得
  const { isXS } = useSelector((state: RootState) => state.screenSize);
  const { categories } = useSelector((state: RootState) => state.category);

  return (
    <footer className={`app-footer-container`}>
      <section className={`app-footer-sp-main`}>
        <BodyTextIons text="RE-IDEA" className="app-footer-logo-text" />

        <DefaultDividerIons width={'100%'} className="app-footer-divider" />

        <div className="app-footer-categories-wrapper">
          <BodyTextIons
            text={'カテゴリ一覧'}
            bold="bold"
            className="app-footer-category-text"
          />
          <div className="app-footer-categories">
            {categories.map((category) => (
              <BodyTextIons
                text={category.name}
                className="app-footer-category-text"
                onClick={() =>
                  PageNavigation.goToSearchPage({
                    categoryId: String(category.categoryId),
                  })
                }
              />
            ))}
          </div>
        </div>

        <DefaultDividerIons width={'100%'} className="app-footer-divider" />

        <div className="app-footer-links">
          <BodyTextIons
            text="> プライバシーポリシー"
            className="app-footer-link-text"
            onClick={() => PageNavigation.goToPrivacyPolicyPage()}
          />
          <BodyTextIons
            text="> 特定商取引法に関する記載/運営会社"
            className="app-footer-link-text"
            onClick={() => PageNavigation.goToSpecifiedCommercialTransaction()}
          />
          <BodyTextIons
            text="> 利用規約"
            className="app-footer-link-text"
            onClick={() => PageNavigation.goToUserAgreementPage()}
          />
        </div>
      </section>
    </footer>
  );
});

export default AppFooterSP;
