import { unlikeIdeaCommentAPIProps } from './like/likeIdeaCommentAPI.type';

// アイデアのコメントを取り消すAPI
export const deleteIdeaCommentAPI = async (
  input: unlikeIdeaCommentAPIProps,
): Promise<void> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${input.info.ideaId}/comments/${input.info.commentId}`,
    {
      method: 'DELETE',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  if (input.onSuccess) input.onSuccess();
};
