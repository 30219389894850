import React, { useEffect } from 'react';
import { IDEA_CATEGORIES_MAX_LENGTH } from '../../../constants/max';
import { useIdeaForm } from '../../../hooks/idea/useIdeaForm.hooks';
import useOpen from '../../../hooks/useOpen';
import { useScroll } from '../../../hooks/useScroll';
import { editIdeaAPIProps } from '../../../services/api/idea/edit/editIdeaAPI.type';
import { IdeaErrorType } from '../../../types/api/idea/IdeaError.type';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import { CircleNumTextIons } from '../../ions/text/circle/CircleNumText.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import ErrorCardMolecules from '../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../molecules/spinner/LoadingOverlaySpinner';
import IdeaDisplaySelectedButtons from '../../organisms/button/selected/IdeaDisplaySelectedButtons';
import CategoriesCheckboxGrid from '../../organisms/grid/tagCheckboxGrid/CategoriesCheckboxGrid';
import IdeaDeleteConfirmPopup from '../../organisms/popup/idea/IdeaDeleteConfirmPopup';
import IdeaSaveConfirmPopup from '../../organisms/popup/idea/IdeaSaveConfirmPopup';
import ImageForm from '../../organisms/textfield/form/radioForm/ImageForm';
import IdeaStoryTextfield from '../../organisms/textfield/story/IdeaStoryTextfield';
import IdeaApealPointTextfield from '../../organisms/textfield/title/IdeaApealPointTextfield';
import IdeaSubTitleTextfield from '../../organisms/textfield/title/IdeaSubTitleTextfield';
import IdeaTitleTextfield from '../../organisms/textfield/title/IdeaTitleTextfield';
import './IdeaEditTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  apiError: IdeaErrorType | null;
  apiLoading: boolean;
  handleEditIdea: (input: editIdeaAPIProps) => void;
  handleDeleteIdea: () => void;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaEditTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, size } = props;
  const isSP = size === 'sp';
  const {
    open: openDeleteConfirmPopup,
    handleClose: handleCloseDeleteConfirmPopup,
    handleChangeOpen: handleChangeOpenDeleteConfirmPopup,
  } = useOpen(false);

  const {
    open: openSaveConfirmPopup,
    handleClose: handleCloseSaveConfirmPopup,
    handleChangeOpen: handleChangeOpenSaveConfirmPopup,
  } = useOpen(false);

  const mainRef = React.useRef<HTMLDivElement>(null);
  const { windowScrollToTop } = useScroll(
    {
      delay: 300,
    },
    mainRef,
  );

  // ラッピングされたフォーム値
  const { loadings, values, errors, handlers } = useIdeaForm({
    init: {
      isPublic: idea.isPublic,
      requestId: idea.request?.requestId || null,
      title: idea.title,
      subTitle: idea.subTitle,
      story: idea.description,
      categoryIds: idea.categories.map((category) => category.categoryId),
      generateImg: false,
      generateSubTitle: false,
      generateAppealPoints: false,
      imgSrc: idea.imgSrc,
      img: null,
      appealPoint1: idea.appealPoints.length > 0 ? idea.appealPoints[0] : null,
      appealPoint2: idea.appealPoints.length > 1 ? idea.appealPoints[1] : null,
      appealPoint3: idea.appealPoints.length > 2 ? idea.appealPoints[2] : null,
    },
  });

  useEffect(() => {
    if (props.apiError !== null) windowScrollToTop();
  }, [props.apiError]);

  // 保存ボタンを押した時の処理
  const handleEditIdea = () => {
    props.handleEditIdea({
      idea: {
        ideaId: idea.ideaId,
        title: values.title,
        subTitle: values.subTitle,
        categoryIds: values.categoryIds,
        description: values.story,
        appealPoints: [
          values.appealPoint1,
          values.appealPoint2,
          values.appealPoint3,
        ].filter((point): point is string => point !== null && point !== ''),
        imgSrc: values.imgSrc,
        img: values.img,
        isPublic: values.isPublic,
      },
      onError: () => handleCloseSaveConfirmPopup(),
    });
  };

  let width: string;
  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '70%';
      break;
    case 'medium':
      width = '55%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  return (
    <div className="idea-edit-template" ref={mainRef}>
      {props.apiLoading && <LoadingOverlaySpinner />}
      <main className="idea-edit-template-main" style={{ width: width }}>
        <header
          className={`idea-edit-template-main-header ${isSP ? 'sp' : ''}`}
        >
          <header className={`idea-edit-template-header `}>
            <BodyTextBoldIons
              text="Idea Edit"
              color="purple"
              className={`idea-edit-template-header-title ${isSP ? 'sp' : ''} `}
            />
            <BodyTextBoldIons text="アイデアの編集" size="normal" />
          </header>
          <div className="idea-edit-template-main-header-buttons">
            <RoundWhiteButtonIons onClick={PageNavigation.goBack}>
              戻る
            </RoundWhiteButtonIons>
            <RoundWhiteButtonIons onClick={handleChangeOpenDeleteConfirmPopup}>
              削除する
            </RoundWhiteButtonIons>
            <RoundWhiteButtonIons
              onClick={() => {
                handleChangeOpenSaveConfirmPopup();
              }}
            >
              保存する
            </RoundWhiteButtonIons>
          </div>
        </header>

        <section className="idea-edit-template-main-details">
          <div className="idea-edit-template-main-columns">
            <CircleNumTextIons num={1} size="medium" />
            <BlackTitleTextIons text="公開設定" size="normal" />
          </div>
          <div className="idea-edit-template-main-contents">
            <IdeaDisplaySelectedButtons
              isPublic={values.isPublic}
              handleChangeIsPublic={handlers.handleIsPublicChange}
            />
          </div>
        </section>

        <section className="idea-edit-template-main-details">
          <div className="idea-edit-template-main-columns">
            <CircleNumTextIons num={2} size="medium" />
            <BlackTitleTextIons text="アイデア詳細設定" size="normal" />
          </div>
          <div className="idea-edit-template-main-contents">
            {props.apiError?.message && (
              <ErrorCardMolecules
                text={props.apiError?.message}
                isSP={isSP}
                padding="5px 10px"
                margin="10px 0"
                bold="light"
              />
            )}
            <IdeaTitleTextfield
              require
              title={values.title}
              error={errors.title}
              handleChange={handlers.handleTitleChange}
              tooltipPlacement={isSP ? 'top-left-center' : 'top-left'}
            />
            <IdeaSubTitleTextfield
              subtitle={values.subTitle}
              error={errors.subTitle}
              handleChange={handlers.handleSubTitleChange}
              tooltipPlacement={isSP ? 'top-left-center' : 'top-left'}
            />
            <IdeaStoryTextfield
              require
              story={values.story}
              error={errors.story}
              handleChange={handlers.handleStoryChange}
              tooltipPlacement={isSP ? 'top-right-center' : 'top-left'}
            />
            <CategoriesCheckboxGrid
              require
              columns={4}
              max={IDEA_CATEGORIES_MAX_LENGTH}
              categoryIds={values.categoryIds}
              error={errors.categories}
              handleChange={handlers.handleCategoriesChange}
            />
            <ImageForm
              require={true}
              type="idea"
              imgSrc={values.imgSrc}
              imgFile={values.img}
              loading={loadings.img}
              title="アイデアのイメージ画像"
              hint="アイデアの魅力が全面に出るような\n画像でアピールしましょう"
              hint_placement={isSP ? 'top' : 'top-left-center'}
              error={null}
              handleImgSrcChange={handlers.handleImgSrcChange}
              handleImgFileChange={handlers.handleImgFileChange}
            />
            <div className={`idea-edit-template-main-contents-appealpoints wa`}>
              <IdeaApealPointTextfield
                index={1}
                appealpoint={values.appealPoint1}
                error={errors.appealPoint1}
                handleChange={handlers.handleAppealPoint1Change}
                isSP={isSP}
              />

              <IdeaApealPointTextfield
                index={2}
                appealpoint={values.appealPoint2}
                error={errors.appealPoint2}
                handleChange={handlers.handleAppealPoint2Change}
                isSP={isSP}
              />

              <IdeaApealPointTextfield
                index={3}
                appealpoint={values.appealPoint3}
                error={errors.appealPoint3}
                handleChange={handlers.handleAppealPoint3Change}
                isSP={isSP}
              />
            </div>
            <section className="idea-edit-template-main-buttons">
              <RoundPurpleButtonIons
                width={'70%'}
                padding={'10px 0'}
                onClick={() => {
                  handleChangeOpenSaveConfirmPopup();
                }}
              >
                保存する
              </RoundPurpleButtonIons>
              <RoundWhiteButtonIons
                width={'70%'}
                padding={'10px 0'}
                onClick={() => PageNavigation.goBack()}
              >
                戻る
              </RoundWhiteButtonIons>
            </section>
          </div>
        </section>
      </main>

      <IdeaDeleteConfirmPopup
        open={openDeleteConfirmPopup}
        onClose={handleCloseDeleteConfirmPopup}
        handleDelete={props.handleDeleteIdea}
      />

      <IdeaSaveConfirmPopup
        open={openSaveConfirmPopup}
        onClose={handleCloseSaveConfirmPopup}
        handleSave={handleEditIdea}
      />
    </div>
  );
};

export default IdeaEditTemplate;
