import { createRoomAPIProps } from './createRoomAPI.type';

// ルームが存在しなければ新規作成するAPI
// 存在する時は取得したルーム情報を用いてonSuccessを呼び出す
export const createRoomAPI = async (input: createRoomAPIProps) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/rooms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input.room),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const room = await response.json();

    if (input.onSuccess) {
      input.onSuccess(room);
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
