import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCardType } from '../../types/api/user/UserTypes';

export interface AuthState {
  isLoggedIn: boolean;
  user: UserCardType | null;
  expiresAt: number | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  user: null,
  expiresAt: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (state, action: PayloadAction<UserCardType>) => {
      if (!state.isLoggedIn) {
        state.isLoggedIn = true;
        state.user = action.payload;
        const maxAge =
          Number(process.env.REACT_APP_MAXAGE) || 12 * 60 * 60 * 1000;
        state.expiresAt = Date.now() + maxAge;
      }
    },
    signout: (state) => {
      if (state.isLoggedIn) {
        state.isLoggedIn = false;
        state.user = null;
        state.expiresAt = null;
      }
    },
    checkExpiration: (state) => {
      if (state.expiresAt && Date.now() > state.expiresAt) {
        state.isLoggedIn = false;
        state.user = null;
        state.expiresAt = null;
      }
    },
  },
});

export const { signin, signout, checkExpiration } = authSlice.actions;
export default authSlice.reducer;
