import React, { useMemo } from 'react';
import useTextfieldMax from '../../component/organisms/textfield/useTextfieldMax.hooks';
import {
  IDEA_APEALPOINT_MAX_LENGTH,
  IDEA_CATEGORIES_MAX_LENGTH,
  IDEA_STORY_MAX_LENGTH,
  IDEA_SUBTITLE_MAX_LENGTH,
  IDEA_TITLE_MAX_LENGTH,
} from '../../constants/max';
import { compressImage } from '../../utils/compressImage';
import useLoading from '../useLoading';
import useNumValuesMax from '../useNumValuesMinMax';

export interface IdeaFormLoadings {
  img: boolean;
}
export interface IdeaFormState {
  isPublic: boolean;
  title: string;
  subTitle: string | null;
  story: string;
  requestId: string | null;
  categoryIds: number[];
  appealPoint1: string | null;
  appealPoint2: string | null;
  appealPoint3: string | null;
  generateImg: boolean;
  generateSubTitle: boolean;
  generateAppealPoints: boolean;
  imgSrc: string | null;
  img: File | null;
}

interface IdeaFormErrors {
  title: string | null;
  subTitle: string | null;
  story: string | null;
  categories: string | null;
  appealPoint1: string | null;
  appealPoint2: string | null;
  appealPoint3: string | null;
}

interface IdeaFormHandlers {
  handleIsPublicChange: (isPublic: boolean) => void;
  handleRequestIdChange: (requestId: string | null) => void;
  handleTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleSubTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleStoryChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleCategoriesChange: (value: number) => void;
  handleAppealPoint1Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleAppealPoint2Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleAppealPoint3Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleChangeGenerateImg: () => void;
  handleChangeGenerateSubTitle: () => void;
  handleChangeGenerateAppealPoints: () => void;
  handleImgSrcChange: (src: string | null) => void;
  handleImgFileChange: (file: File | null) => void;
  clearStorage: () => void;
}

const STORAGE_KEY_PREFIX = 'idea_form_';

export const useIdeaForm = (input: {
  init?: IdeaFormState;
  storageId?: string | null;
}): {
  loadings: IdeaFormLoadings;
  values: IdeaFormState;
  errors: IdeaFormErrors;
  handlers: IdeaFormHandlers;
} => {
  // ローカルストレージのキーを生成
  const storageKey = `${STORAGE_KEY_PREFIX}${input.storageId}`;

  // ローカルストレージから状態を復元する関数
  const getStoredState = (): Partial<IdeaFormState> | null => {
    if (!input.storageId) return null;
    const stored = localStorage.getItem(storageKey);
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        console.error('Failed to parse stored form state:', e);
        return null;
      }
    }
    return null;
  };

  // 初期状態の設定（ローカルストレージ → input.init → デフォルト値の優先順位）
  const storedState = React.useMemo(() => getStoredState(), [storageKey]);

  // 公開設定
  const [isPublic, setIsPublic] = React.useState<boolean>(
    storedState?.isPublic ?? input.init?.isPublic ?? true,
  );

  // リクエストID
  const [requestId, setRequestId] = React.useState<string | null>(
    storedState?.requestId ?? input.init?.requestId ?? null,
  );

  // タイトルの値
  const {
    value: title,
    error: title_error,
    handleChange: handleTitleChange,
  } = useTextfieldMax(
    storedState?.title ?? input.init?.title ?? '',
    IDEA_TITLE_MAX_LENGTH,
  );

  // サブタイトルの値
  const {
    value: subTitle,
    error: subTitle_error,
    handleChange: handleSubTitleChange,
  } = useTextfieldMax(
    storedState?.subTitle ?? input.init?.subTitle ?? '',
    IDEA_SUBTITLE_MAX_LENGTH,
  );

  // ストーリーの値
  const {
    value: story,
    error: story_error,
    handleChange: handleStoryChange,
  } = useTextfieldMax(
    storedState?.story ?? input.init?.story ?? '',
    IDEA_STORY_MAX_LENGTH,
  );

  // カテゴリーの値
  const memoizedInit = useMemo(
    () => storedState?.categoryIds ?? input.init?.categoryIds,
    [JSON.stringify(storedState?.categoryIds ?? input.init?.categoryIds)],
  );
  const {
    values: categoryIds,
    error: categoryIds_error,
    handleChange: handleCategoriesChange,
    handleAllChange: handleAllChangeCategories,
  } = useNumValuesMax(memoizedInit, IDEA_CATEGORIES_MAX_LENGTH);

  // アピールポイント1~3の値
  const {
    value: appealPoint1,
    error: appealPoint1_error,
    handleChange: handleAppealPoint1Change,
  } = useTextfieldMax(
    storedState?.appealPoint1 ?? input.init?.appealPoint1 ?? '',
    IDEA_APEALPOINT_MAX_LENGTH,
  );
  const {
    value: appealPoint2,
    error: appealPoint2_error,
    handleChange: handleAppealPoint2Change,
  } = useTextfieldMax(
    storedState?.appealPoint2 ?? input.init?.appealPoint2 ?? '',
    IDEA_APEALPOINT_MAX_LENGTH,
  );
  const {
    value: appealPoint3,
    error: appealPoint3_error,
    handleChange: handleAppealPoint3Change,
  } = useTextfieldMax(
    storedState?.appealPoint3 ?? input.init?.appealPoint3 ?? '',
    IDEA_APEALPOINT_MAX_LENGTH,
  );

  // 画像生成の値
  const [generateImg, setGenerateImg] = React.useState<boolean>(
    storedState?.generateImg || false,
  );
  const handleChangeGenerateImg = () => setGenerateImg(!generateImg);

  // サブタイトル生成の値
  const [generateSubTitle, setGenerateSubTitle] = React.useState<boolean>(
    storedState?.generateSubTitle ?? true,
  );
  const handleChangeGenerateSubTitle = () =>
    setGenerateSubTitle(!generateSubTitle);

  // アピールポイント生成の値
  const [generateAppealPoints, setGenerateAppealPoints] =
    React.useState<boolean>(storedState?.generateAppealPoints ?? true);
  const handleChangeGenerateAppealPoints = () =>
    setGenerateAppealPoints(!generateAppealPoints);

  // 画像ソースの値
  const [imgSrc, setImgSrc] = React.useState<string | null>(
    storedState?.imgSrc ?? input.init?.imgSrc ?? null,
  );

  const handleImgSrcChange = (src: string | null) => {
    setImgSrc(src);
  };

  // 画像ファイルの値
  const [img, setImg] = React.useState<File | null>(
    storedState?.img ?? input.init?.img ?? null,
  );
  const {
    loading: loadingImage,
    handleStart: handleImageLoadingStart,
    handleEnd: handleImageLoadingEnd,
  } = useLoading();

  // FileInputの変更時に画像をセット
  const handleImgFileChange = async (file: File | null) => {
    handleImageLoadingStart();
    if (!file) {
      handleImageLoadingEnd();
      setImg(null);
      return;
    }
    const compressedFile = await compressImage(file);
    if (compressedFile) {
      setImg(compressedFile);
    }
    handleImageLoadingEnd();
  };

  const loadings: IdeaFormLoadings = {
    img: loadingImage,
  };

  const values: IdeaFormState = {
    isPublic,
    requestId,
    title,
    story,
    subTitle,
    categoryIds: categoryIds,
    appealPoint1: appealPoint1,
    appealPoint2: appealPoint2,
    appealPoint3: appealPoint3,
    generateImg,
    generateSubTitle,
    generateAppealPoints,
    imgSrc,
    img,
  };
  // 値が変更されたときにローカルストレージに保存
  React.useEffect(() => {
    if (input.storageId) {
      // 画像ファイルデータを除外したvaluesを保存
      const { img: _, ...valuesWithoutImg } = values; // imgを取り除いた新しいオブジェクトを作成
      localStorage.setItem(storageKey, JSON.stringify(valuesWithoutImg));
    }
  }, [storageKey, values]);

  const clearStorage = () => {
    if (storageKey) {
      localStorage.removeItem(storageKey);
      // 全ての値をinitまたはデフォルト値にリセット
      setIsPublic(input.init?.isPublic ?? true);
      setRequestId(input.init?.requestId ?? null);
      handleTitleChange({
        target: { value: input.init?.title ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      handleSubTitleChange({
        target: { value: input.init?.subTitle ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      handleStoryChange({
        target: { value: input.init?.story ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      // カテゴリーの初期化
      handleAllChangeCategories(input.init?.categoryIds ?? []);
      handleAppealPoint1Change({
        target: { value: input.init?.appealPoint1 ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      handleAppealPoint2Change({
        target: { value: input.init?.appealPoint2 ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      handleAppealPoint3Change({
        target: { value: input.init?.appealPoint3 ?? '' },
      } as React.ChangeEvent<HTMLInputElement>);
      setGenerateImg(input.init?.generateImg ?? true);
      setGenerateSubTitle(input.init?.generateSubTitle ?? true);
      setGenerateAppealPoints(input.init?.generateAppealPoints ?? true);
      setImgSrc(input.init?.imgSrc ?? null);
      setImg(input.init?.img ?? null);
    }
  };

  const errors: IdeaFormErrors = {
    title: title_error,
    subTitle: subTitle_error,
    story: story_error,
    categories: categoryIds_error,
    appealPoint1: appealPoint1_error,
    appealPoint2: appealPoint2_error,
    appealPoint3: appealPoint3_error,
  };

  const handlers = {
    handleIsPublicChange: (isPublic: boolean) => setIsPublic(isPublic),
    handleRequestIdChange: (requestId: string | null) =>
      setRequestId(requestId),
    handleTitleChange,
    handleSubTitleChange,
    handleStoryChange,
    handleCategoriesChange,
    handleAppealPoint1Change,
    handleAppealPoint2Change,
    handleAppealPoint3Change,
    handleChangeGenerateImg,
    handleChangeGenerateSubTitle,
    handleChangeGenerateAppealPoints,
    handleImgFileChange,
    handleImgSrcChange,
    clearStorage,
  };

  return { loadings, values, errors, handlers };
};
