import { likeIdeaCommentAPIProps } from './likeIdeaCommentAPI.type';

// アイデアのコメントへいいねするAPI
export const likeIdeaCommentAPI = async (
  input: likeIdeaCommentAPIProps,
): Promise<void> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${input.info.ideaId}/comments/${input.info.commentId}/like`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  if (input.onSuccess) input.onSuccess();
};
