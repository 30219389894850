import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useGetIdea } from '../../../../hooks/api/idea/useGetIdea';
import { useGetInvestment } from '../../../../hooks/api/useGetInvestment';
import { useAuthGuard } from '../../../../hooks/auth/useAuthGuard';
import { RootState } from '../../../../redux/store';
import IdeaInvestDeleteCompleteTemplate from '../../../templates/ideaInvest/complete/IdeaInvestDeleteCompleteTemplate';

const IdeaInvestDeleteCompletePage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  // 自動リダイレクト付きの認証
  const { isLoggedIn } = useAuthGuard(window.location.href);
  const { id } = useParams();
  const { investId } = useParams();
  const { data: idea } = useGetIdea(id ?? '');

  // 出資対象の商品を取得する
  const { data: investment } = useGetInvestment(investId);

  // アイデアの所有者はアイデア出資画面にアクセスできない
  if (idea?.isOwner === true) {
    return <Navigate to="/not-found" replace />;
  }

  if (!idea || !investment || !isLoggedIn) return null;

  return (
    <IdeaInvestDeleteCompleteTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
    />
  );
};

export default IdeaInvestDeleteCompletePage;
