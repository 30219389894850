import { GetRoomsQueryType } from './getRoomsAPI.type';

export const getRoomsAPI = async (query?: GetRoomsQueryType) => {
  try {
    const params = new URLSearchParams();
    if (query?.page) params.set('page', String(query.page));
    if (query?.limit) params.set('limit', String(query.limit));
    const response = await fetch(`${process.env.REACT_APP_API_URL}/rooms`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
