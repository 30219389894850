import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import GoogleAccountNotFoundTemplate from '../../templates/auth/AccountError/GoogleAccountNotFoundTemplate';

const GoogleAccountNotFoundPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  return <GoogleAccountNotFoundTemplate isSP={isXS} />;
};

export default GoogleAccountNotFoundPage;
