import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React from 'react';
import { useSelector } from 'react-redux';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { RootState } from '../../../../redux/store';
import { createIdeaCommentAPIProps } from '../../../../services/api/idea/comment/createIdeaCommentAPI.type';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import './CommentCardKubo.Molecules.css';

type IdeaCardProps = {
  ideaId: string;
  commentId: string;
  isOwner?: boolean;
  mentionUserName: string | null;
  mentionUserId: string | null;
  onSendComment: (input: createIdeaCommentAPIProps) => void;
  onCancelReply: () => void;
};

export const CommentCardKuboMoleculesReplyTextfield: React.FC<IdeaCardProps> =
  React.memo((props) => {
    const { ideaId, commentId } = props;
    const { user } = useSelector((state: RootState) => state.auth);
    const { value, handleChange, handleReset } = useTextfield();

    if (user === null) return null;

    return (
      <div className="comment-card-kubo-molecules-full">
        <div className="comment-card-kubo-molecules-container">
          <div className="comment-card-kubo-molecules-header">
            <div className="comment-card-kubo-molecules-user-info">
              <UserIconImgIons
                img_src={user.imgSrc}
                userName={user.userName}
                alt="user-icon"
                size="large"
              />

              <BodyTextIons
                text={user.userName + `${props.isOwner ? ' (投稿者)' : ''}`}
                className={`comment-card-kubo-molecules-username ${props.isOwner ? 'owner' : ''}`}
              />
            </div>

            <div className="comment-card-kubo-molecules-like-wrapper">
              <FavoriteBorderIcon
                className="comment-card-kubo-molecules-like-icon animate-bounce"
                sx={{
                  fontSize: 32,
                }}
              />
            </div>
          </div>
          {props.mentionUserName && props.mentionUserId && (
            <BodyTextIons
              text={'@' + props.mentionUserName}
              className="comment-card-kubo-molecules-button-text"
            />
          )}
          <DefaultTextFieldIons
            value={value}
            label={null}
            placeholder="返信を入力"
            error={null}
            onChange={handleChange}
            design_type="underline"
            width={'100%'}
            componentType="textarea"
            input_class_name="comment-card-kubo-molecules-textfield-input-text"
          />
        </div>

        <div className="comment-card-kubo-molecules-buttons">
          <BodyTextIons
            text="やめる"
            className="comment-card-kubo-molecules-button-text"
            onClick={() => props.onCancelReply()}
          />
          <BodyTextIons
            text="投稿する"
            className="comment-card-kubo-molecules-button-text"
            onClick={() =>
              props.onSendComment({
                comment: {
                  ideaId: ideaId,
                  toCommentId: commentId,
                  text:
                    `${props.mentionUserName ? '@' + props.mentionUserName + ' ' : ''}` +
                    value,
                  mentionUserId: props.mentionUserId,
                },
                onSuccess: () => {
                  handleReset();
                  props.onCancelReply();
                },
              })
            }
          />
        </div>
      </div>
    );
  });
