import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import useOpen from '../../../hooks/useOpen';
import { useScrollIntoViewWithOffset } from '../../../hooks/useScrollIntoViewWithOffset';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import LoadingFullSpinner from '../../molecules/spinner/LoadingFullSpinner';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import SakairiIdeaCarousel from '../../organisms/carousel/SakairiIdeaCarousel';
import IdeaFooter from '../../organisms/footer/IdeaFooter';
import IdeaDetailAndCfSP from '../../organisms/idea/DetailAndCF/SP/IdeaDetailAndCfSP';
import IdeaUserAndReturns from '../../organisms/idea/useAndReturns/IdeaUserAndReturns';
import RewardConfirmPopup from '../../organisms/popup/RewardConfirmPopup';
import IdeaActivityTemplate from './acitivity/IdeaActivityTemplate';
import IdeaCommentTemplate from './comment/IdeaCommentTemplate';
import './IdeaTemplate.css';
import { IdeaTemplateProps } from './IdeaTemplate.type';
import './IdeaTemplateSP.css';
import IdeaStoryTemplate from './story/IdeaStoryTemplate';

const IdeaTemplateSP: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, apiLoadings, apiErrors, apiHandlers } = props;
  const { isXS, isSM, isMD } = useSelector(
    (root: RootState) => root.screenSize,
  );
  const { open, handleClose, handleOpen } = useOpen();
  const { user, isLoggedIn } = useSelector((state: RootState) => state.auth);
  const bottomRef = useRef<HTMLElement>(null);
  useScrollIntoViewWithOffset(
    bottomRef,
    props.view === 'comment' || props.view === 'activity',
    {
      offset: -140,
    },
    props.view,
  );

  const width = '95%';

  if (!idea) return <LoadingFullSpinner />;

  return (
    <div className={`idea-template`}>
      <main
        className={`idea-template-main ${isXS ? 'sp' : isSM ? 'sm' : isMD ? 'md' : ''}`}
      >
        <section className={`idea-template-top`}>
          <IdeaDetailAndCfSP
            view={props.view}
            idea={idea}
            width={width}
            numOfActivities={props.idea_activities.length}
            numOfComments={props.numOfAllComments}
            handleIdeaPublish={apiHandlers.handleIdeaPublish}
            handleIdeaUnPublish={apiHandlers.handleIdeaUnPublish}
            handleIdeaDelete={apiHandlers.handleDeleteIdea}
          />
        </section>

        <section
          style={{ width: width }}
          className={`idea-template-bottom ${isXS ? 'sp' : isSM ? 'sm' : isMD ? 'md' : ''}`}
          ref={bottomRef}
        >
          {props.view === 'home' ? (
            <IdeaStoryTemplate idea={idea} title_size="normal" size="sp" />
          ) : props.view === 'comment' ? (
            <IdeaCommentTemplate
              numOfAllComments={props.numOfAllComments}
              idea={idea}
              isSP
              page={props.page}
              idea_comments={props.idea_comments}
              title_size="normal"
              sendIdeaComment={apiHandlers.handleSendIdeaComment}
              likeIdeaComment={apiHandlers.handleLikeIdeaComment}
              unlikeIdeaComment={apiHandlers.handleUnlikeIdeaComment}
              deleteIdeaComment={apiHandlers.handleDeleteIdeaComment}
            />
          ) : (
            <IdeaActivityTemplate
              idea={idea}
              idea_activities={props.idea_activities}
              title_size="small"
              sendLoading={apiLoadings.sendIdeaActivitiy}
              sendApiError={apiErrors.sendIdeaActivitiy}
              deleteLoading={apiLoadings.deleteIdeaActivitiy}
              deleteApiError={apiErrors.deleteIdeaActivitiy}
              sendIdeaActivity={apiHandlers.handleSendIdeaActivity}
              deleteIdeaActivity={apiHandlers.handleDeleteIdeaActivity}
            />
          )}
          <IdeaUserAndReturns
            idea={idea}
            title_size="normal"
            investments={props.investments}
          />
        </section>
        <div
          className="idea-template-suggest-carousel"
          style={{ width: width }}
        >
          <HeaderTitleSakairi
            title="FEATURED"
            titleSize={'large'}
            subTitle="おすすめのアイデア"
          />
          <SakairiIdeaCarousel
            isSP={true}
            title_size={'large'}
            slide_min_width={280}
            card_width={260}
            ideas={props.suggest_ideas}
            moreOnClick={() =>
              PageNavigation.goToSearchPage({ sort: 'featured' })
            }
          />
        </div>
      </main>

      <IdeaFooter
        text={apiHandlers.getMainButtonText({
          idea,
          user: isLoggedIn ? user : null,
        })}
        onClickMainButton={() =>
          apiHandlers.handleClickMainButton({
            idea,
            user: isLoggedIn ? user : null,
            investment: props.investments[0],
          })
        }
        ideaId={idea.ideaId}
        likeIdea={apiHandlers.handleLike}
        unlikeIdea={apiHandlers.handleUnlike}
        onClick={handleOpen}
        numOfLikes={idea.numOfLikes}
        isLiked={idea.isLiked}
        isAdopted={idea.isAdopted}
      />
      <RewardConfirmPopup open={open} onClose={handleClose} />
    </div>
  );
};

export default IdeaTemplateSP;
