import { useSelector } from 'react-redux';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import RegisterTemplate from '../../templates/auth/RegisterTemplate';

const RegisterPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  const { values, handlers: formHandlers, errors: formErrors } = useAuthForm();
  // ログイン、Google新規会員登録、Line新規会員登録、会員登録の処理
  const { handlers, errors: apiErrros } = useAuthActions({
    values: values,
  });

  return (
    <RegisterTemplate
      isSP={isXS}
      apiError={apiErrros.register}
      values={values}
      formHandlers={formHandlers}
      formErrors={formErrors}
      handleGoogleSignup={handlers.handleGoogleRegister}
      handleRegister={handlers.handleRegister}
    />
  );
};

export default RegisterPage;
