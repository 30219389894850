import React, { ChangeEvent } from 'react';
import { IDEA_TITLE_MAX_LENGTH } from '../../../../constants/max';
import { PopoverPlacement } from '../../../atoms/popover/Popover.type';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface IdeaTitleTextfieldProps {
  require: boolean | null;
  title: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  num?: number;
  isSP?: boolean;
  tooltipPlacement?: PopoverPlacement;
}

const IdeaTitleTextfield: React.FC<IdeaTitleTextfieldProps> = React.memo(
  (props) => {
    return (
      <DefaultTextFieldIons
        value={props.title}
        error={props.error}
        size={props.isSP ? 'small' : 'medium'}
        width={'100%'}
        onChange={props.handleChange}
        label={
          props.num ? (
            <NumAndTitle
              num={props.num}
              title="タイトルを記入"
              num_size="small"
              title_size="small"
            />
          ) : props.require === null ? (
            <BodyTextBoldIons size="small" text="タイトルを記入" />
          ) : (
            <RequireAndTitle
              require={true}
              title="タイトルを記入"
              hint={
                'アイデアの魅力が全面に出るような\nタイトルでアピールしましょう'
              }
              hint_placement={
                props.tooltipPlacement ||
                (props.isSP ? 'top' : 'top-left-center')
              }
            />
          )
        }
        placeholder="アイデアのタイトルを入力してください"
        max={IDEA_TITLE_MAX_LENGTH}
      />
    );
  },
);

export default IdeaTitleTextfield;
