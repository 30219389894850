import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetUserByToken } from '../../../hooks/api/user/useGetUserByToken';
import useLoading from '../../../hooks/useLoading';
import { emailVerifyThunk } from '../../../redux/auth/thunk/emailVerifyThunk';
import { RootState } from '../../../redux/store';
import LoadingOverlaySpinner from '../../molecules/spinner/LoadingOverlaySpinner';
import HowToUseCorpTemplate from '../../templates/howToUse/HowToUseCorpTemplate';
import HowToUseTemplate from '../../templates/howToUse/HowToUseTemplate';
import NotFoundTemplate from '../../templates/not-found/NotFoundTemplate';

const AccountVerifyPage = () => {
  const { token } = useParams();
  const { loading, handleEnd } = useLoading(true);

  // メール認証の結果
  const [result, setResult] = React.useState<boolean | null>(null);
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  const { data: user, isLoading } = useGetUserByToken({
    token,
    enabled: !!result,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const verifyEmail = async () => {
      const _result = await dispatch(emailVerifyThunk(token) as any);
      setResult(_result);
      handleEnd();
    };
    verifyEmail();
  }, [dispatch]);

  // メール認証の最中であれば、全画面ローディングを実行
  if (loading) return <LoadingOverlaySpinner />;
  // 次は、ユーザー情報を取得。取得中であれば、全画面ローディングを実行
  else if (isLoading) return <LoadingOverlaySpinner />;
  // ユーザー情報を取得完了後、ユーザーが取得できなければ
  else if (!user) return <NotFoundTemplate />;
  else
    return user.isCorp ? (
      <HowToUseCorpTemplate
        isSP={isXS}
        message="アカウントの認証が完了しました"
      />
    ) : (
      <HowToUseTemplate isSP={isXS} message="アカウントの認証が完了しました" />
    );
};

export default AccountVerifyPage;
