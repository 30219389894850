import React, { useCallback, useMemo } from 'react';
import useTextfieldMax from '../../component/organisms/textfield/useTextfieldMax.hooks';
import {
  ADDRESS_MAX_LENGTH,
  CORP_NAME_MAX_LENGTH,
  IDEA_CATEGORIES_MAX_LENGTH,
  MAIL_ADDRESS_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  USER_INTRODUCTION_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
  ZIPCODE_MAX_LENGTH,
} from '../../constants/max';
import { AuthErrorType } from '../../types/api/auth/AuthError.type';
import { compressImage } from '../../utils/compressImage';
import { validateMail } from '../../utils/validate/validateMail';
import { validatePassword } from '../../utils/validate/validatePassword';
import { validatePhoneNumber } from '../../utils/validate/validatePhoneNumber';
import { validateZipcode } from '../../utils/validate/validateZipcode';
import useLoading from '../useLoading';
import useNumValuesMax from '../useNumValuesMinMax';
import useTextfield from '../useTextfield.hooks';

export interface AuthFormLoadings {
  img: boolean;
  bannerImg: boolean;
}

export interface AuthFormState {
  userName: string;
  userNameOrEmail?: string;
  corpName?: string;
  zipCode?: string;
  address?: string;
  phoneNumber?: string;
  introduction?: string;
  categoryIds?: number[];
  img?: File | null;
  imgSrc?: string | null;
  bannerImg?: File | null;
  bannerImgSrc?: string | null;
  websiteUrl?: string | null;
  snsUrl1?: string | null;
  snsUrl2?: string | null;
  snsUrl3?: string | null;
  email: string;
  password: string;
}

export interface AuthFormErrors {
  userName: string | null;
  corpName: string | null;
  zipCode: string | null;
  address: string | null;
  phoneNumber: string | null;
  introduction: string | null;
  categoryIds: string | null;
  email: string | null;
  password: string | null;
  signupAPI: AuthErrorType | null | undefined;
}

type ChangeInputType = (
  e:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>,
) => void;

export interface AuthFormHandlers {
  handleUserNameChange: ChangeInputType;
  handleUserNameOrEmailChange: ChangeInputType;
  handleCorpNameChange: ChangeInputType;
  handleEmailChange: ChangeInputType;
  handlePasswordChange: ChangeInputType;
  handleZipCodeChange: ChangeInputType;
  handleAddressChange: ChangeInputType;
  handlePhoneNumberChange: ChangeInputType;
  handleIntroductionChange: ChangeInputType;
  handleCategoriesChange: (value: number) => void;

  handleImgSrcChange: (src: string | null) => void;
  handleImgFileChange: (file: File | null) => void;
  handleBannerImgSrcChange: (src: string | null) => void;
  handleBannerImgFileChange: (file: File | null) => void;

  handleWebsiteUrlChange: ChangeInputType;
  handleSnsUrl1Change: ChangeInputType;
  handleSnsUrl2Change: ChangeInputType;
  handleSnsUrl3Change: ChangeInputType;

  // 会員登録のAPIエラーをセットする
  setSignupAPIError: React.Dispatch<
    React.SetStateAction<AuthErrorType | null | undefined>
  >;

  // 入力値をリセットする
  handleReset: () => void;
}

export const useAuthForm = (
  init?: AuthFormState,
): {
  loadings: AuthFormLoadings;
  values: AuthFormState;
  errors: AuthFormErrors;
  handlers: AuthFormHandlers;
} => {
  // 会員登録のAPIエラー
  const [signupAPIError, setSignupAPIError] = React.useState<
    AuthErrorType | null | undefined
  >(undefined);

  // ユーザー名の値
  const {
    value: userName,
    error: error_userName,
    handleChange: handleUserNameChange,
  } = useTextfieldMax(init?.userName || '', USER_NAME_MAX_LENGTH);

  // ユーザー名またはemailの値
  const { value: userNameOrEmail, handleChange: handleUserNameOrEmailChange } =
    useTextfield(init?.userNameOrEmail || '');

  // 会社名の値
  const {
    value: corpName,
    error: error_corpName,
    handleChange: handleCorpNameChange,
  } = useTextfieldMax(init?.corpName || '', CORP_NAME_MAX_LENGTH);

  // 郵便番号
  const {
    value: zipCode,
    error: error_zipCode,
    handleChange: handleZipCodeChange,
  } = useTextfieldMax(init?.zipCode || '', ZIPCODE_MAX_LENGTH, validateZipcode);

  // 電話番号
  const {
    value: phoneNumber,
    error: error_phoneNumber,
    handleChange: handlePhoneNumberChange,
  } = useTextfieldMax(
    init?.phoneNumber || '',
    PHONE_NUMBER_MAX_LENGTH,
    validatePhoneNumber,
  );

  // 住所の値
  const {
    value: address,
    error: error_address,
    handleChange: handleAddressChange,
  } = useTextfieldMax(init?.address || '', ADDRESS_MAX_LENGTH);

  // 自己紹介の値
  const {
    value: introduction,
    error: error_introduction,
    handleChange: handleIntroductionChange,
  } = useTextfieldMax(init?.introduction || '', USER_INTRODUCTION_MAX_LENGTH);

  // カテゴリーの値(配列の場合はメモ化しておかないとhooksの内部で無限ループする)
  const memoizedInit = useMemo(
    () => init?.categoryIds,
    [JSON.stringify(init?.categoryIds)],
  );
  const {
    values: categoryIds,
    error: categoryIds_error,
    handleChange: handleCategoriesChange,
  } = useNumValuesMax(memoizedInit, IDEA_CATEGORIES_MAX_LENGTH);

  // アイコン画像ソースの値
  const [imgSrc, setImgSrc] = React.useState<string | null>(
    init?.imgSrc ?? null,
  );
  React.useEffect(() => {
    if (init?.imgSrc) {
      setImgSrc(init.imgSrc);
    }
  }, [init?.imgSrc]);
  const handleImgSrcChange = (src: string | null) => {
    setImgSrc(src);
  };

  // アイコン画像の値
  const [img, setImg] = React.useState<File | null>(null);
  const {
    loading: imgLoading,
    handleStart: handleImageLoadingStart,
    handleEnd: handleImageLoadingEnd,
  } = useLoading();
  const handleImgFileChange = useCallback(async (file: File | null) => {
    handleImageLoadingStart();
    if (!file) {
      setImg(null);
      handleImageLoadingEnd();
      return;
    }
    const compressedFile = await compressImage(file);
    if (compressedFile) {
      setImg(compressedFile);
    }
    handleImageLoadingEnd();
  }, []);

  // バナー画像ソースの値
  const [bannerImgSrc, setBannerImgSrc] = React.useState<string | null>(
    init?.bannerImgSrc || null,
  );
  React.useEffect(() => {
    if (init?.bannerImgSrc) {
      setBannerImgSrc(init.bannerImgSrc);
    }
  }, [init?.bannerImgSrc]);
  const handleBannerImgSrcChange = useCallback((src: string | null) => {
    setBannerImgSrc(src);
  }, []);

  // バナー画像の値
  const {
    loading: bannerImgLoading,
    handleStart: handleBannerImageLoadingStart,
    handleEnd: handleBannerImageLoadingEnd,
  } = useLoading();
  const [bannerImg, setBannerImg] = React.useState<File | null>(null);
  const handleBannerImgFileChange = async (file: File | null) => {
    handleBannerImageLoadingStart();
    if (!file) {
      handleBannerImageLoadingEnd();
      setBannerImg(null);
      return;
    }
    const compressedFile = await compressImage(file);
    if (compressedFile) {
      setBannerImg(compressedFile);
    }
    handleBannerImageLoadingEnd();
  };

  // メールアドレスの値
  const {
    value: email,
    error: error_email,
    handleChange: handleEmailChange,
  } = useTextfieldMax(init?.email || '', MAIL_ADDRESS_MAX_LENGTH, validateMail);

  // パスワードの値
  const {
    value: password,
    error: error_password,
    handleChange: handlePasswordChange,
  } = useTextfield(init?.password || '', validatePassword);

  // ウェブサイトの値
  const { value: websiteUrl, handleChange: handleWebsiteUrlChange } =
    useTextfield(init?.websiteUrl ?? '');

  // SNS1の値
  const { value: snsUrl1, handleChange: handleSnsUrl1Change } = useTextfield(
    init?.snsUrl1 ?? '',
  );

  // SNS2の値
  const { value: snsUrl2, handleChange: handleSnsUrl2Change } = useTextfield(
    init?.snsUrl2 ?? '',
  );

  // SNS3の値
  const { value: snsUrl3, handleChange: handleSnsUrl3Change } = useTextfield(
    init?.snsUrl3 ?? '',
  );

  const handleReset = () => {
    handleUserNameChange({ target: { value: '' } } as any);
    handleUserNameOrEmailChange({ target: { value: '' } } as any);
    handleCorpNameChange({ target: { value: '' } } as any);
    handleZipCodeChange({ target: { value: '' } } as any);
    handleAddressChange({ target: { value: '' } } as any);
    handlePhoneNumberChange({ target: { value: '' } } as any);
    handleEmailChange({ target: { value: '' } } as any);
    handlePasswordChange({ target: { value: '' } } as any);
    handleIntroductionChange({ target: { value: '' } } as any);
    handleCategoriesChange(0);
    handleImgSrcChange(null);
    handleImgFileChange(null);
    handleBannerImgSrcChange(null);
    handleBannerImgFileChange(null);
    handleWebsiteUrlChange({ target: { value: '' } } as any);
    handleSnsUrl1Change({ target: { value: '' } } as any);
    handleSnsUrl2Change({ target: { value: '' } } as any);
    handleSnsUrl3Change({ target: { value: '' } } as any);
  };

  const loadings: AuthFormLoadings = {
    img: imgLoading,
    bannerImg: bannerImgLoading,
  };

  const values: AuthFormState = {
    userName,
    userNameOrEmail,
    corpName,
    zipCode,
    address,
    introduction,
    phoneNumber,
    email,
    img,
    imgSrc,
    bannerImgSrc,
    bannerImg,
    password,
    categoryIds,
    websiteUrl,
    snsUrl1,
    snsUrl2,
    snsUrl3,
  };

  const errors: AuthFormErrors = {
    userName: error_userName,
    corpName: error_corpName,
    zipCode: error_zipCode,
    address: error_address,
    phoneNumber: error_phoneNumber,
    email: error_email,
    introduction: error_introduction,
    password: error_password,
    categoryIds: categoryIds_error,
    signupAPI: signupAPIError,
  };

  const handlers = {
    handleUserNameChange,
    handleUserNameOrEmailChange,
    handleCorpNameChange,
    handleZipCodeChange,
    handleAddressChange,
    handlePhoneNumberChange,
    handleEmailChange,
    handlePasswordChange,
    handleIntroductionChange,
    setSignupAPIError,
    handleCategoriesChange,
    handleImgSrcChange,
    handleImgFileChange,
    handleBannerImgSrcChange,
    handleBannerImgFileChange,
    handleWebsiteUrlChange,
    handleSnsUrl1Change,
    handleSnsUrl2Change,
    handleSnsUrl3Change,
    handleReset,
  };

  return { loadings, values, errors, handlers };
};
