import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { createChatAPIProps } from '../../../../services/api/room/chat/createChatAPI.type';
import { ChatDetailType } from '../../../../types/api/chat/ChatTypes';
import { RoomCardType } from '../../../../types/api/room/RoomTypes';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import ChatGrid from '../../grid/chatGrid/ChatGrid';
import './ChatGridPopup.css';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  page?: number;
  room: RoomCardType;
  chats: ChatDetailType[];
  handleCreateChat: (input: createChatAPIProps) => void;
}

const ChatGridPopup: React.FC<PopupProps> = React.memo((props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <DefaultPopupIons open={props.open} onClose={props.onClose} size="medium">
      <div className="chat-grid-popup">
        <ChatGrid
          roomId={props.room.roomId}
          numOfAllChat={props.room.numOfAllChat || 0}
          page={props.page}
          otherUsersViewedAt={
            props.room.toUsers?.map((user) =>
              user.viewedAt ? new Date(user.viewedAt) : null,
            ) || []
          }
          title={
            props.room.toUsers?.map((user) => user.userName).join('・') || ''
          }
          login_user_id={user?.userId || ''}
          chats={props.chats || []}
          size="extra-small"
          handleCreateChat={props.handleCreateChat}
        />
      </div>
    </DefaultPopupIons>
  );
});

export default ChatGridPopup;
