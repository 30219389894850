import React from 'react';
import useOpen from '../../../../hooks/useOpen';
import { createIdeaCommentAPIProps } from '../../../../services/api/idea/comment/createIdeaCommentAPI.type';
import { deleteIdeaCommentAPIProps } from '../../../../services/api/idea/comment/deleteIdeaCommentAPI.type';
import {
  likeIdeaCommentAPIProps,
  unlikeIdeaCommentAPIProps,
} from '../../../../services/api/idea/comment/like/likeIdeaCommentAPI.type';
import { IdeaCommentType } from '../../../../types/api/idea/IdeaTypes';
import DefaultButtonIons from '../../../ions/button/default/DefaultButtonIons';
import { CommentCardKuboMolecules } from './CommentCardKubo.Molecules';
import { CommentCardKuboMoleculesReply } from './CommentCardKuboReply.Molecules';
import './CommentCardKuboWithReplies.Molecules.css';

type IdeaCardProps = {
  comment: IdeaCommentType;
  ideaUserId: string;
  onSendComment: (input: createIdeaCommentAPIProps) => void;
  onLikeComment: (input: likeIdeaCommentAPIProps) => void;
  onDeleteComment: (input: deleteIdeaCommentAPIProps) => void;
  onUnlikeComment: (input: unlikeIdeaCommentAPIProps) => void;
};

export const CommentCardKuboMoleculesWithReplies: React.FC<IdeaCardProps> =
  React.memo((props) => {
    const { comment } = props;
    const { open, handleOpen, handleClose } = useOpen();

    return (
      <div className="comment-card-kubo-molecules-with-reply">
        <CommentCardKuboMolecules
          comment={comment}
          ideaUserId={props.ideaUserId}
          role={
            comment.user.userId === props.ideaUserId
              ? '投稿者'
              : comment.user.isCorp
                ? '企業'
                : '一般'
          }
          isOwner={comment.user.userId === props.ideaUserId}
          onSendComment={props.onSendComment}
          onLikeComment={props.onLikeComment}
          onUnlikeComment={props.onUnlikeComment}
          onDeleteComment={props.onDeleteComment}
        />
        {comment.replies.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              width: '100%',
              gap: '40px',
            }}
          >
            {comment.replies.slice(0, open ? undefined : 2).map((reply) => (
              <div style={{ width: '85%' }}>
                <CommentCardKuboMoleculesReply
                  key={reply.commentId}
                  parentCommentId={comment.commentId}
                  ideaUserId={props.ideaUserId}
                  comment={reply}
                  isOwner={comment.user.userId === props.ideaUserId}
                  role={
                    reply.user.userId === props.ideaUserId
                      ? '投稿者'
                      : comment.user.isCorp
                        ? '企業'
                        : '一般'
                  }
                  onSendComment={props.onSendComment}
                  onLikeComment={props.onLikeComment}
                  onUnlikeComment={props.onUnlikeComment}
                  onDeleteComment={props.onDeleteComment}
                />
              </div>
            ))}
          </div>
        )}
        {!open && comment.replies.length > 2 && (
          <div style={{ width: '85%' }}>
            <DefaultButtonIons
              width={'100%'}
              className="comment-card-more-button"
              onClick={handleOpen}
              padding={'10px 0px'}
            >
              もっと見る
            </DefaultButtonIons>
          </div>
        )}
      </div>
    );
  });
