import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getFollowedUsersAPI } from '../../../services/api/user/follow/getFollowedUsersAPI';
import { UserCardType } from '../../../types/api/user/UserTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetFollowedUsersResult = UseQueryResult<UserCardType[]> & {
  queryKey: string[];
};

// フォローしているユーザー一覧情報を取得するためのカスタムフック
export const useGetFollowedUsers = (
  userId?: string | null,
): UseGetFollowedUsersResult => {
  const queryKey = ['followed-users', userId];

  const result = useQuery<UserCardType[]>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!userId,
    queryFn: async () => {
      if (!userId) throw new Error('userId is required');
      return getFollowedUsersAPI(userId);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetFollowedUsersResult;
};
