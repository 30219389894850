import React from 'react';
import { UserLimitedType } from '../../../types/api/user/UserTypes';
import DefaultImgIons from '../../ions/img/DefaultImg.Ions';
import { BodySubTextIons } from '../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import TextTags from '../../molecules/text/tags/TextTags';
import './UserPageProfileCard.css';

interface UserPageProfileCardProps {
  user: UserLimitedType;
}

const UserPageProfileCard: React.FC<UserPageProfileCardProps> = (props) => {
  return (
    <div className="userpage-profile-card-wrapper">
      <div className="userpage-profile-card-top-container">
        <DefaultImgIons
          img_src={props.user.imgSrc || ''}
          className="userpage-profile-card-user-icon"
        />
        <div className="userpage-profile-card-user-info">
          <BodyTextBoldIons text={props.user.userName} size="normal" />
          <TextTags tags={[]} size="extra-small" />
          <BodySubTextIons text={'仕事名・役職名'} size="small" />
        </div>
      </div>

      <div className="userpage-profile-card-bottom-container">
        <div className="userpage-profile-card-column-cell">
          <BodyTextBoldIons
            text="自己紹介"
            size="normal"
            className="userpage-profile-card-column-title"
          />
          <BodySubTextIons text={props.user.introduction || ''} />
        </div>

        <div className="userpage-profile-card-column-cell">
          <BodyTextBoldIons
            text="好きな食べ物は？"
            size="normal"
            className="userpage-profile-card-column-title"
          />
          <BodySubTextIons text={props.user.introduction || ''} />
        </div>

        <div className="userpage-profile-card-column-cell">
          <BodyTextBoldIons
            text="趣味は？"
            size="normal"
            className="userpage-profile-card-column-title"
          />
          <BodySubTextIons text={props.user.introduction || ''} />
        </div>

        <div className="userpage-profile-card-column-cell">
          <BodyTextBoldIons
            text="特技は？"
            size="normal"
            className="userpage-profile-card-column-title"
          />
          <BodySubTextIons text={props.user.introduction || ''} />
        </div>

        <div className="userpage-profile-card-column-cell">
          <BodyTextBoldIons
            text="一言メッセージ"
            size="normal"
            className="userpage-profile-card-column-title"
          />
          <BodySubTextIons text={props.user.introduction || ''} />
        </div>
      </div>
    </div>
  );
};

export default UserPageProfileCard;
