import { GetNotificationsQueryType } from './getNotificationsAPI.type';

export const getNotificationsAPI = async (
  query?: GetNotificationsQueryType,
) => {
  try {
    const params = new URLSearchParams();

    // デフォルト値とクエリパラメータの設定
    params.set('limit', String(query?.limit || 25));
    // オプショナルなパラメータを追加
    if (query?.page) params.set('page', String(query.page));
    if (query?.filter) params.set('filter', query.filter);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/notifications?${params.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
