import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getIdeaAPI } from '../../../services/api/idea/get/getIdeaAPI';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeaResult = UseQueryResult<IdeaDetailType> & {
  queryKey: string[];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetIdea = (ideaId?: string): UseGetIdeaResult => {
  const queryKey = ['idea', ideaId];

  const result = useQuery<IdeaDetailType>({
    queryKey: ['idea', ideaId], // sortの値も含めてキャッシュを管理
    enabled: !!ideaId,
    queryFn: async () => {
      if (!ideaId) throw new Error('ideaId is required');
      return getIdeaAPI(ideaId);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeaResult;
};
