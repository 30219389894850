import React from 'react';
import DefaultImgIons from '../../../ions/img/DefaultImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './AboutCard.Molecules.css';

type IdeaCardProps = {
  number: number;
  imgSrc: string;
  title: string;
  description: string;
  width: number | string;
  height: number | string;
};

export const AboutCardMolecules: React.FC<IdeaCardProps> = React.memo(
  (props) => {
    return (
      <div
        className={`about-card-molecules-container `}
        style={{
          width: props.width,
          height: props.height,
        }}
      >
        <div className="rotating-text-container">
          <div className="about-card-molecules-number">
            {String(props.number)}
          </div>
          <div className="rotating-text">
            <div className="circle">
              <svg viewBox="0 0 35 35">
                <path
                  className="rotating-path"
                  id="circle-path"
                  d="M17.5,0 A17.5,17.5 0 1,1 17.4,0"
                />
                <text className="about-card-molecules-text">
                  <textPath href="#circle-path" startOffset="0%">
                    RE-IDEA RE-IDEA RE-IDEA RE-IDEA
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        </div>

        <main className="about-card-molecules-main">
          <DefaultImgIons
            img_src={props.imgSrc}
            alt={props.title}
            className="about-card-molecules-img"
          />
          <BodyTextIons
            text={props.title}
            className="about-card-molecules-title"
          />
          <BodyTextIons
            text={props.description}
            className="about-card-molecules-description"
          />
        </main>
      </div>
    );
  },
);
