import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openLoginPopup } from '../../../../../redux/slice/loginPopupSlice';
import { createChatAPI } from '../../../../../services/api/room/chat/createChatAPI';
import { createChatAPIProps } from '../../../../../services/api/room/chat/createChatAPI.type';
import { userFollowAPI } from '../../../../../services/api/user/follow/userFollowAPI';
import {
  FollowUserAPIProps,
  UnFollowUserAPIProps,
} from '../../../../../services/api/user/follow/userFollowAPI.type';
import { userUnFollowAPI } from '../../../../../services/api/user/follow/userUnFollowAPI';
import { RootState } from './../../../../../redux/store';

export const useMyPageActions = (input: { userQueryKey: any }) => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  // チャットを送信する
  const handleCreateChat = useCallback((input: createChatAPIProps) => {
    createChatAPI(input.chat, async () => {
      if (input.onSuccess) input.onSuccess();
    });
  }, []);
  const queryClient = useQueryClient();

  // ユーザーをフォローする
  const handleFollowUser = useCallback(
    async (props: FollowUserAPIProps) => {
      if (!isLoggedIn) {
        dispatch(openLoginPopup() as any);
      } else {
        await userFollowAPI(props.follow, async () => {
          queryClient.invalidateQueries(input.userQueryKey);
          if (props.onSuccess) props.onSuccess();
        });
      }
    },
    [isLoggedIn],
  );

  // ユーザーのフォローを解除する
  const handleUnFollowUser = useCallback(
    (props: UnFollowUserAPIProps) => {
      if (!isLoggedIn) dispatch(openLoginPopup() as any);
      else {
        userUnFollowAPI(props.follow, async () => {
          queryClient.invalidateQueries(input.userQueryKey);
          if (props.onSuccess) props.onSuccess();
        });
      }
    },
    [isLoggedIn],
  );

  return {
    handleCreateChat,
    handleFollowUser,
    handleUnFollowUser,
  };
};
