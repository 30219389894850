import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getScreenSize } from '../../utils/getScreenSize';

export interface ScreenSizeState {
  isXS: boolean; // スマホ画面
  isSM: boolean; // タブレット画面
  isNO: boolean; // タブレット画面(ipadPro最大値)
  isMD: boolean; // PC画面
  isLG: boolean; // PC画面
}

const initialState: ScreenSizeState = getScreenSize(window.innerWidth);

const screenSizeSlice = createSlice({
  name: 'screenSize',
  initialState,
  reducers: {
    updateScreenSize: (state, action: PayloadAction<ScreenSizeState>) => {
      state.isXS = action.payload.isXS;
      state.isSM = action.payload.isSM;
      state.isNO = action.payload.isNO;
      state.isMD = action.payload.isMD;
      state.isLG = action.payload.isLG;
    },
  },
});

export const { updateScreenSize } = screenSizeSlice.actions;
export default screenSizeSlice.reducer;
