import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getNotificationAPI } from '../../../services/api/notifications/getNotificationAPI';
import { FullNotifyType } from '../../../types/api/notifications/NotificationType';

// 戻り値の型を拡張して queryKey を含める
type UseGetNotificationsResult = UseQueryResult<FullNotifyType> & {
  queryKey: string[];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetNotification = (
  notifyId?: string | null,
): UseGetNotificationsResult => {
  const queryKey = ['notification', notifyId];

  const result = useQuery<FullNotifyType>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!notifyId,
    queryFn: async () => {
      if (!notifyId) return null;
      return getNotificationAPI(notifyId);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetNotificationsResult;
};
