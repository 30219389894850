import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PeopleIcon from '@mui/icons-material/People';
import React from 'react';
import { IDEA_MAX_POINT } from '../../../../constants/max';
import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import IdeaImgIons from '../../../ions/img/IdeaImg.Ions';
import DefaultProgressBarIons from '../../../ions/progress/DefaultProgressBar.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './IdeaCard.Molecules.css';

type IdeaCardProps = {
  idea: IdeaCardType;
  width: number | string;
  text_size?: 'extra-small' | 'small';
  hover_effect?: boolean;
  display_description?: boolean;
};

export const IdeaCardMolecules: React.FC<IdeaCardProps> = React.memo(
  (props) => {
    const { idea } = props;
    const icon_size = props.text_size === 'small' ? '20px' : '16px';

    return (
      <div
        className={`idea-card-molecules-container ${props.hover_effect ? 'hover-effect' : ''}`}
        style={{
          width: props.width,
        }}
        onClick={() => {
          PageNavigation.goToIdeaPage(idea.ideaId);
        }}
      >
        <IdeaImgIons
          img_src={idea.imgSrc}
          alt={idea.title}
          className="idea-card-molecules-img"
          isAdopted={idea.isAdopted}
        />
        <main
          className="idea-card-molecules-main"
          style={{
            gap: props.text_size === 'small' ? '5px' : '10px',
          }}
        >
          {/* <header className="idea-card-molecules-header">
            <UserIconImgIons
              userName={idea.user.userName}
              img_src={idea.user.imgSrc}
              size={props.text_size}
            />
            <section className="idea-card-molecules-tags">
              {idea.categories.map((category: CategoryType) => (
                <TagTextIons
                  key={category.categoryId}
                  text={category.name}
                  size={props.text_size}
                />
              ))}
            </section>
          </header> */}

          <section
            className="idea-card-molecules-texts"
            style={{
              gap: props.text_size === 'small' ? '10px' : '5px',
            }}
          >
            <BodyTextIons
              bold="light"
              rows={2}
              size={props.text_size}
              text={props.idea.title}
            />
            {props.display_description && idea.description && (
              <BodySubTextIons
                rows={3}
                bold="light"
                size={props.text_size}
                text={props.idea.description}
              />
            )}
          </section>

          <section className="idea-card-molecules-progress">
            <DefaultProgressBarIons
              width={'60%'}
              progress={(idea.evalPoint / IDEA_MAX_POINT) * 100}
              size="small"
            />
            <BodySubTextIons
              text={`${idea.evalPoint} pt`}
              size={props.text_size}
              bold="light"
            />
          </section>

          <section className="idea-card-molecules-progress">
            <div className="idea-card-molecules-cell">
              <FavoriteBorderIcon sx={{ color: 'grey', fontSize: icon_size }} />
              <BodySubTextIons
                text={idea.numOfLikes.toString()}
                size={props.text_size}
                bold="light"
              />
            </div>
            <div className="idea-card-molecules-cell">
              <PeopleIcon sx={{ color: 'grey', fontSize: icon_size }} />
              <BodySubTextIons
                text={`閲覧 ${idea.numOfViews} 人`}
                size={props.text_size}
                bold="light"
              />
            </div>
          </section>
        </main>
      </div>
    );
  },
);
