import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getRoomsAPI } from '../../../services/api/room/get/getRoomsAPI';
import { GetRoomsQueryType } from '../../../services/api/room/get/getRoomsAPI.type';
import { RoomCardType } from '../../../types/api/room/RoomTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetRoomsResult = UseQueryResult<RoomCardType[]> & {
  queryKey: readonly ['rooms'];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetRooms = (
  query?: GetRoomsQueryType & {
    enabled?: boolean;
  },
): UseGetRoomsResult => {
  const queryKey = ['rooms'] as const;

  const result = useQuery<RoomCardType[]>({
    queryKey,
    enabled: !!query?.enabled,
    queryFn: () => getRoomsAPI(query),
  });

  return {
    ...result,
    queryKey,
  } as UseGetRoomsResult;
};
