import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useGetIdea } from '../../../../hooks/api/idea/useGetIdea';
import { useGetInvestment } from '../../../../hooks/api/useGetInvestment';
import { useAuthGuard } from '../../../../hooks/auth/useAuthGuard';
import useLoading from '../../../../hooks/useLoading';
import { RootState } from '../../../../redux/store';
import { purchaseInvestmentAPI } from '../../../../services/api/investment/purchase/purchaseAPI';
import { purchaseAPIProps } from '../../../../services/api/investment/purchase/purchaseAPI.type';
import { PurchaseError } from '../../../../types/api/investment/purchase/PurchaseError.type';
import { PageNavigation } from '../../../../utils/pageNavigation';
import IdeaInvestConfirmTemplate from '../../../templates/ideaInvest/confirm/IdeaInvestConfirmTemplate';

const IdeaInvestConfirmPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  // 自動リダイレクト付きの認証
  const { isLoggedIn } = useAuthGuard(window.location.href);
  const { loading, handleStart, handleEnd } = useLoading(false);
  const [purchaseAPIError, setPurchaseAPIError] =
    useState<PurchaseError | null>(null);
  const { id } = useParams();
  const { investId } = useParams();
  const { data: idea } = useGetIdea(id ?? '');

  // 出資対象の商品を取得する
  const { data: investment } = useGetInvestment(investId);

  // 出資記録を送信するAPI
  const handlePurchaseInvestment = useCallback(
    async (input: purchaseAPIProps) => {
      // 処理開始
      handleStart();
      const apiResult = await purchaseInvestmentAPI(input.purchase, () => {
        // 処理終了(成功時)
        handleEnd();
        if (input.onSuccess) input.onSuccess();
        if (idea && investment)
          PageNavigation.goToIdeaInvestCompletePage(
            idea.ideaId,
            investment.investmentId,
          );
      });
      // 処理終了・結果をセット
      handleEnd();
      setPurchaseAPIError(apiResult);
    },
    [idea, investment], // 依存配列が空でも良い場合。関数自体が変更されない場合
  );

  // アイデアの所有者はアイデア出資画面にアクセスできない
  if (!idea || !investment || !isLoggedIn) return null;
  if (idea?.isOwner === true) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <IdeaInvestConfirmTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
      investment={investment}
      handlePurchaseInvestment={handlePurchaseInvestment}
      loading={loading}
      purchaseAPIError={purchaseAPIError}
    />
  );
};

export default IdeaInvestConfirmPage;
