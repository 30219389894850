import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { PurchaseInvestementType } from '../../../../types/api/investment/InvestmentTypes';
import { formatDateToJapanese } from '../../../../utils/timeCalculator';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import PurchaseCardMolecules from '../../../molecules/card/purchase/PurchaseCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import './PurchaseCardGrid.css';

interface PurchaseCardGridProps {
  isSP: boolean;
  page: number;
  maxPage: number;
  purchases: PurchaseInvestementType[];
  title: string;
  num: number;
  isPublic?: boolean;
}

const PurchaseCardGridWithHeader: React.FC<PurchaseCardGridProps> = (props) => {
  const { goToIdeaInvestDeleteConfirmPage } = usePageNavigation();
  return (
    <section className="card-grid-section">
      <div className={`card-grid-header ${props.isSP ? '' : 'pc'}`}>
        <div className="card-grid-header-title">
          <BodyTextBoldIons
            text={props.title}
            size={props.isSP ? 'normal' : 'medium'}
          />
          <BodySubTextIons text={`(${props.num}件)`} size="small" />
        </div>
      </div>

      {props.purchases.length > 0 ? (
        props.purchases.map((purchase) => (
          <PurchaseCardMolecules
            isSP={props.isSP}
            title={purchase.idea.title}
            date={formatDateToJapanese(purchase.createdAt, 'yyyy年M月d日 HH:m')}
            amount={purchase.numOfItems * purchase.investment.price}
            onClick={() =>
              goToIdeaInvestDeleteConfirmPage(
                purchase.idea.ideaId,
                purchase.investment.investmentId,
              )
            }
          />
        ))
      ) : (
        <div className="card-grid-no-item">
          <h4 className={`card-grid-no-item-text ${props.isSP ? '' : 'pc'}`}>
            アイデアが見つかりませんでした
          </h4>
        </div>
      )}
      <PageNationButtons num={props.page} max_num={props.maxPage} />
    </section>
  );
};

export default PurchaseCardGridWithHeader;
