import { IdeaActivityType } from '../../../../types/api/idea/IdeaTypes';
import { getIdeaActivitiesType } from './getIdeaActivitiesAPI.type';

// アイデアの活動記録を取得するAPI
export const getIdeaActivitiesAPI = async (
  input: getIdeaActivitiesType,
): Promise<IdeaActivityType[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${input.ideaId}/activities`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
