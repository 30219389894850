import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getRequestAPI } from '../../../services/api/request/get/getRequestAPI';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetRequestResult = UseQueryResult<RequestDetailType> & {
  queryKey: string[];
};

// お題一覧情報を取得するためのカスタムフック
export const useGetRequest = (
  requestId?: string | null,
): UseGetRequestResult => {
  const queryKey = ['request', requestId];

  const result = useQuery<RequestDetailType>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!requestId,
    queryFn: async () => {
      if (requestId) return getRequestAPI(requestId);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetRequestResult;
};
