import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuthGuard } from '../../../hooks/auth/useAuthGuard';
import useLoading from '../../../hooks/useLoading';
import useOpen from '../../../hooks/useOpen';
import { RootState } from '../../../redux/store';
import { createContactThemeAPIProps } from '../../../services/api/contact/createContactAPI.type';
import { createContactThemeAPI } from '../../../services/api/contact/createContactThemeAPI';
import { ContactError } from '../../../types/api/contact/ContactError.type';
import ContactThemeTemplate from '../../templates/contact/theme/ContactThemeTemplate';
import NotFoundTemplate from '../../templates/not-found/NotFoundTemplate';

const ContactThemePage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // ログインしていなければ自動リダイレクト
  const { user } = useAuthGuard(window.location.href);

  // お問い合わせ送信中のローディング
  const { loading, handleStart, handleEnd } = useLoading(false);

  // APIエラー
  const [contactAPIError, setContactAPIError] = useState<ContactError | null>(
    null,
  );

  // お問い合わせ送信成功・失敗ポップアップ
  const {
    open: openSuccessPopup,
    handleOpen: handleSuccessPopupOpen,
    handleClose: handleSuccessPopupClose,
  } = useOpen();

  const {
    open: openFailedPopup,
    handleOpen: handleFailedPopupOpen,
    handleClose: handleFailedPopupClose,
  } = useOpen();

  // お問い合わせ送信API
  const sendContactAPI = async (input: createContactThemeAPIProps) => {
    handleStart();
    const result = await createContactThemeAPI({
      ...input,
      onError: () => {
        handleEnd();
        handleFailedPopupOpen();
        input.onError && input.onError();
      },
      onSuccess: () => {
        handleEnd();
        handleSuccessPopupOpen();
        input.onSuccess && input.onSuccess();
      },
    });
    setContactAPIError(result);
  };

  // 企業ユーザーかつ、ログインしている場合のみ表示
  if (!user?.isCorp) {
    return <NotFoundTemplate />;
  }

  return (
    <ContactThemeTemplate
      loading={loading}
      openSuccessPopup={openSuccessPopup}
      handleSuccessPopupClose={handleSuccessPopupClose}
      openFailedPopup={openFailedPopup}
      handleFailedPopupClose={handleFailedPopupClose}
      isSP={isXS}
      createContactRequest={sendContactAPI}
      contactAPIError={contactAPIError}
    />
  );
};

export default ContactThemePage;
