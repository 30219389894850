import React from 'react';
import './Text.Atoms.css';
import { TextAtomsProps } from './Text.type';

export const TextAtoms: React.FC<TextAtomsProps> = React.memo(
  ({
    text,
    className,
    size,
    rows,
    onClick,
    color,
    bold,
    textAlign,
    width,
  }) => (
    <span
      onClick={onClick}
      className={`text-atoms ${size ? size : ''} ${color ? color : ''} weight-${bold} ${className}`}
      style={{
        textAlign: textAlign,
        cursor: onClick ? 'pointer' : undefined,
        height: rows
          ? `calc(var(--font-size) * var(--line-height) * ${rows})`
          : undefined,
        width: width,
        display: rows ? '-webkit-box' : undefined,
        WebkitBoxOrient: rows ? 'vertical' : undefined,
        overflow: rows ? 'hidden' : undefined,
        WebkitLineClamp: rows ? rows : undefined,
      }}
    >
      {text}
    </span>
  ),
);
