import React, { useEffect } from 'react';
import {
  IDEA_CATEGORIES_MAX_LENGTH,
  USER_INTRODUCTION_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
} from '../../../../constants/max';
import { useScroll } from '../../../../hooks/useScroll';
import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import CategoriesCheckboxGrid from '../../../organisms/grid/tagCheckboxGrid/CategoriesCheckboxGrid';
import ImageForm from '../../../organisms/textfield/form/radioForm/ImageForm';
import RequireTextfield from '../../../organisms/textfield/RequireTextfield';
import './MyEditTemplate.css';
import { MyEditTemplateProps } from './MyEditTemplate.type';

// 事業者のメール認証と、詳細情報設定
const MyEditTemplate: React.FC<MyEditTemplateProps> = React.memo((props) => {
  const {
    isSP,
    apiErrors,
    values,
    formLoadings,
    formErrors,
    formHandlers,
    apiLoadings,
    apiHandlers,
  } = props;
  const apiError: AuthErrorType | null = apiErrors.updateMe;
  const { windowScrollToTop } = useScroll();

  useEffect(() => {
    if (apiError !== null) windowScrollToTop();
  }, [apiError]);

  return (
    <div className={`my-edit-template-container ${isSP ? 'sp' : ''}`}>
      {apiLoadings.updateMe && <LoadingOverlaySpinner />}
      <header className="my-edit-template-header">
        <HeaderTitle
          title="Edit Account"
          subTitle="ユーザー情報を変更する"
          titleSize="large"
        />
      </header>
      <main className={`my-edit-template-main ${isSP ? 'sp' : ''}`}>
        <form
          className={`my-edit-template-form`}
          onSubmit={(e) => e.preventDefault()}
        >
          {apiError?.message && (
            <ErrorCardMolecules
              text={apiError?.message}
              isSP={isSP}
              padding="5px 10px"
              margin="10px 0"
              bold="light"
            />
          )}
          <RequireTextfield
            label="ユーザーネーム"
            placeholder="〇〇〇〇"
            isSP={isSP}
            text={values.userName}
            handleChange={formHandlers.handleUserNameChange}
            error={formErrors.userName}
            require={true}
            max={USER_NAME_MAX_LENGTH}
          />
          <div className="my-edit-template-secrets">
            <RequireTextfield
              isSP={isSP}
              label="メールアドレス(認証済み)"
              placeholder="example@sample.com"
              text={values.email || ''}
              handleChange={formHandlers.handleEmailChange}
              error={formErrors.email}
              require={true}
              disabled
            />
            <BodyTextIonsClickable
              onClick={PageNavigation.goToSettingEmailPage}
              size="small"
              text="メールアドレスの変更はこちら"
            />
          </div>
          <div className="my-edit-template-secrets">
            <RequireTextfield
              isSP={isSP}
              label="パスワード"
              placeholder="***********"
              text={values.password}
              handleChange={formHandlers.handlePasswordChange}
              error={null}
              require={true}
              disabled
            />
            <BodyTextIonsClickable
              onClick={PageNavigation.goToSettingPasswordPage}
              size="small"
              text="パスワードの変更はこちら"
            />
          </div>
          <RequireTextfield
            isSP={isSP}
            label="プロフィール文"
            placeholder="事業内容や特徴を記入\n(例: 〇〇事業を展開しています)"
            text={values.introduction || ''}
            handleChange={formHandlers.handleIntroductionChange}
            error={formErrors.introduction}
            require={true}
            componentType="textarea"
            rows={6}
            max={USER_INTRODUCTION_MAX_LENGTH}
          />
          <CategoriesCheckboxGrid
            categoryIds={values.categoryIds || []}
            error={formErrors.categoryIds}
            handleChange={(categoryId) =>
              formHandlers.handleCategoriesChange(categoryId)
            }
            columns={props.isSP ? 3 : 4}
            require={false}
            max={IDEA_CATEGORIES_MAX_LENGTH}
          />

          <ImageForm
            require={false}
            imgSrc={values.imgSrc || null}
            imgFile={values.img || null}
            title="ユーザーアイコン画像"
            loading={formLoadings.img}
            handleImgFileChange={formHandlers.handleImgFileChange}
            handleImgSrcChange={formHandlers.handleImgSrcChange}
            error={null}
            type="icon"
          />
          <ImageForm
            require={false}
            imgSrc={values.bannerImgSrc || null}
            imgFile={values.bannerImg || null}
            title="マイページバナー画像"
            loading={formLoadings.bannerImg}
            handleImgFileChange={formHandlers.handleBannerImgFileChange}
            handleImgSrcChange={formHandlers.handleBannerImgSrcChange}
            error={null}
            type="banner"
          />
        </form>

        <div className="my-edit-template-submit-button">
          <RoundPurpleButtonIons
            width={'60%'}
            size={'normal'}
            onClick={apiHandlers.handleUpdateMe}
          >
            アカウントを更新する
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            width={'60%'}
            size={'normal'}
            onClick={PageNavigation.goBack}
          >
            戻る
          </RoundWhiteButtonIons>
        </div>
      </main>
    </div>
  );
});

export default MyEditTemplate;
