import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { formatTimeAgo } from '../../../../utils/timeCalculator';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { CircleNumTextIons } from '../../../ions/text/circle/CircleNumText.Ions';
import './RoomCard.Molecules.css';

type RoomCardProps = {
  room_id: string;
  userName: string;
  imgSrc: string | null;
  lastMessage: string;
  lastCreatedAt: string;
  unread_count: number;
  iconSize?: 'small' | 'medium' | 'large';
};

export const RoomCardMolecules: React.FC<RoomCardProps> = React.memo(
  (props) => {
    const { goToMyPage } = usePageNavigation();
    return (
      <div
        className="room-card-molecules-container"
        onClick={() => goToMyPage({ mode: 'chat', roomId: props.room_id })}
      >
        <div className="room-card-icons-and-name">
          <UserIconImgIons
            userName={props.userName}
            img_src={props.imgSrc}
            size={props.iconSize || 'medium'}
          />
          <div className="room-card-user-name-message">
            <BodyTextBoldIons text={props.userName} size="small" />
            <BodySubTextIons
              text={props.lastMessage}
              rows={1}
              size="extra-small"
            />
          </div>
        </div>
        <div className="room-card-time-unread">
          <BodySubTextIons
            text={formatTimeAgo(props.lastCreatedAt)}
            size="extra-small"
          />
          {props.unread_count > 0 && (
            <CircleNumTextIons num={props.unread_count} size="small" />
          )}
        </div>
      </div>
    );
  },
);
