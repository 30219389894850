import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GET_IDEA_MAX_LIMIT } from '../../../../constants/max';
import { useGetMe } from '../../../../hooks/api/user/useGetMe';
import { RootState } from '../../../../redux/store';
import {
  MyCorpPageModeQuery,
  MyPageModeQuery,
  parseMyCorpPageModeQuery,
  parseMyPageModeQuery,
} from '../../../../types/query/UserPageQuery';
import { UserPageTemplateProps } from '../../../templates/userpage/userpage/UserPageTemplate.type';
import UserPageTemplatePC from '../../../templates/userpage/userpage/UserPageTemplatePC';
import UserPageTemplateSP from '../../../templates/userpage/userpage/UserPageTemplateSP';
import { useMyPageActions } from './hooks/useMyPageActions.hooks';
import { useMyPageData } from './hooks/useMyPageData.hooks';
import { useWebSocket } from './hooks/useWebSocket.hooks';

const MyPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  // ユーザーのアイデアの個数限界
  const [searchParams] = useSearchParams();
  // ルームIDを取得
  const roomId: string | null = searchParams.get('roomId');
  // isPublicかどうかを取得
  const isPublic: boolean = (searchParams.get('isPublic') || 'true') === 'true';
  // ページ番号を取得
  const page = parseInt(searchParams.get('page') || '1', 10);

  // 対象のユーザー情報を取得
  const { data: user, queryKey: userQueryKey } = useGetMe({
    idea: true,
    theme: true,
    follow: true,
  });

  // 対象のユーザー情報を用いて、ページのモードを取得
  const mode: MyPageModeQuery | MyCorpPageModeQuery = !user?.isCorp
    ? parseMyPageModeQuery(searchParams.get('mode'))
    : parseMyCorpPageModeQuery(searchParams.get('mode'));

  // ユーザー関連情報を取得
  const { items, numOfItems, queryKey } = useMyPageData({
    user,
    mode,
    roomId,
    requestId: searchParams.get('requestId') || undefined,
    isPublic,
    page,
  });

  // WebSocket接続のeffectを管理
  useWebSocket({
    mode,
    user,
    roomId,
    chatsQueryKey: queryKey.chats,
    roomQueryKey: queryKey.room,
    roomsQueryKey: queryKey.rooms,
  });

  const { handleCreateChat, handleFollowUser, handleUnFollowUser } =
    useMyPageActions({
      userQueryKey,
    });

  const props: UserPageTemplateProps = {
    my: true,
    isCorp: user?.type === 'corp',
    mode: mode,
    user: user,
    page: page,
    requestId: searchParams.get('requestId') || undefined,
    items: items,
    numOfItems: numOfItems,
    maxPage: Math.ceil(numOfItems.userIdeas / GET_IDEA_MAX_LIMIT),
    handleCreateChat: handleCreateChat,
    handleFollowUser: handleFollowUser,
    handleUnFollowUser: handleUnFollowUser,
    isPublic: isPublic,
  };

  return isXS ? (
    <UserPageTemplateSP {...props} />
  ) : (
    <UserPageTemplatePC
      {...props}
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
    />
  );
};

export default MyPage;
