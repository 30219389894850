export const convertLinks = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) =>
    urlRegex.test(part) ? (
      <a
        key={index}
        href={part}
        style={{
          color: '#0066c0',
          textDecoration: 'none',
          display: 'inline-block',
        }}
      >
        {part}
      </a>
    ) : (
      part
    ),
  );
};
