import React, { useRef } from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import {
  MyCorpPageModeQuery,
  myCorpPageModeQueryMap,
  MyPageModeQuery,
  myPageModeQueryMap,
  OthersCorpPageModeQuery,
  othersCorpPageModeQueryMap,
  OthersPageModeQuery,
  othersPageModeQueryMap,
} from '../../../types/query/UserPageQuery';
import SelectedTabButtonIons from '../../ions/button/selected/SelectedTabButton.Ions';
import './UserPageModeTabButtons.css';

interface UserPageModeTabButtonsProps {
  userId: string;
  mode:
    | MyPageModeQuery
    | OthersPageModeQuery
    | OthersCorpPageModeQuery
    | MyCorpPageModeQuery;
  my: boolean;
  isCorp: boolean;
}

const UserPageModeTabButtons: React.FC<UserPageModeTabButtonsProps> = (
  props,
) => {
  const { goToMyPage, goToOthersUserPage } = usePageNavigation();
  const sectionRef = useRef<HTMLElement>(null);

  // タブボタンのラベルとクエリのマップ
  const ModeMapQueries: { [key: string]: string } =
    !props.my && !props.isCorp
      ? othersPageModeQueryMap
      : props.my && !props.isCorp
        ? myPageModeQueryMap
        : !props.my && props.isCorp
          ? othersCorpPageModeQueryMap
          : myCorpPageModeQueryMap;

  // イベントの型を明示的に定義
  const handleTabClick = (mode: string) => {
    const buttons = sectionRef.current?.querySelectorAll(
      '.userpage-mode-tab-button',
    );
    buttons?.forEach((button) => {
      if (button.textContent === ModeMapQueries[mode]) {
        button.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    });

    if (props.my) goToMyPage({ mode });
    else goToOthersUserPage(props.userId, { mode });
  };

  return (
    <section className="userpage-mode-tab-buttons" ref={sectionRef}>
      {Object.entries(ModeMapQueries).map(([mode, label]) => (
        <SelectedTabButtonIons
          key={mode}
          selected={props.mode === mode}
          onClick={() => handleTabClick(mode)}
          className="userpage-mode-tab-button"
          padding={'8px 10px'}
        >
          {String(label)}
        </SelectedTabButtonIons>
      ))}
    </section>
  );
};

export default UserPageModeTabButtons;
