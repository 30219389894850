import { IdeaErrorType } from '../../../../types/api/idea/IdeaError.type';
import { deleteIdeaActivityType } from './deleteIdeaActivityAPI.type';

// アイデアの活動記録を削除するAPI
export const deleteIdeaActivityAPI = async (
  input: deleteIdeaActivityType,
  onSuccess?: () => void,
  onError?: () => void,
): Promise<IdeaErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/ideas/${input.ideaId}/activities/${input.activityId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    if (!response.ok) {
      if (onError) onError();
      const errorData: IdeaErrorType = await response.json();
      return errorData;
    } else {
      if (onSuccess) onSuccess();
      return null;
    }
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
