import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import VerifyEmailSendTemplate from '../../templates/auth/verify/VerifyEmailSendTemplate';

const VerifyEmailSendPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  return <VerifyEmailSendTemplate isSP={isXS} />;
};

export default VerifyEmailSendPage;
