import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCommentsAPI } from '../../../services/api/idea/comment/getCommentsAPI';
import { getCommentsType } from '../../../services/api/idea/comment/getCommentsAPI.type';
import { IdeaCommentType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeaCommentsResult = UseQueryResult<{
  comments: IdeaCommentType[];
  numOfAllComments: number;
}> & {
  queryKey: string[];
};

export const useGetComments = (
  query: getCommentsType & { enabled: boolean },
): UseGetIdeaCommentsResult => {
  const queryKey = ['comments', query];

  const result = useQuery<{
    comments: IdeaCommentType[];
    numOfAllComments: number;
  }>({
    queryKey, // 定義した queryKey を使用
    enabled: !!query.enabled,
    queryFn: async () => {
      return getCommentsAPI(query);
    },
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeaCommentsResult;
};
