import React from 'react';
import { RequestCardType } from '../../../types/api/request/RequestTypes';
import DefaultCarouselIons from '../../ions/carousel/DefaultCarousel.Ions';
import RequestCard2Molecules from '../../molecules/card/request/RequestCard2.Molecules';
import RequestNullCardMolecules from '../../molecules/card/request/RequestNullCard.Molecules';
import './RequestCarousel.css';

interface RequestCarouselProps {
  slide_min_width: number;
  card_width: number;
  requests: RequestCardType[];
  display_link?: boolean;
  setRequestID?: (value: string | null) => void;
  isSP?: boolean;

  otherTransparent?: boolean;
  playOnInit?: boolean;
  isBanner?: boolean;
  prevAndNextButton?: boolean;
}

const RequestCarousel2: React.FC<RequestCarouselProps> = (props) => {
  return (
    <section className="request-calousel-wrapper" style={{ maxWidth: '100%' }}>
      <DefaultCarouselIons
        slide_min_width={props.slide_min_width}
        isTwoRows={false}
        setSelectedID={props.setRequestID}
        IDs={props.requests.map((request) => request.requestId)}
        items={props.requests.map((request, index: number) =>
          request.requestId === null ? (
            <RequestNullCardMolecules
              key={index}
              width={props.requests.length === 1 ? '100%' : props.card_width}
              isSP={false}
            />
          ) : (
            <RequestCard2Molecules
              key={index}
              request={request}
              width={props.requests.length === 1 ? '100%' : props.card_width}
              isSP={false}
              hover_effect={!props.isSP}
            />
          ),
        )}
        displayButton={true}
        playOnInit={props.playOnInit ?? true}
        otherTransparent={props.otherTransparent}
        isBanner={props.isBanner}
        nextButton={
          props.prevAndNextButton ? (
            <button className="request-carousel-button next">
              <label className="request-carousel-button-text">→</label>
            </button>
          ) : undefined
        }
        prevButton={
          props.prevAndNextButton ? (
            <button className="request-carousel-button prev">
              <label className="request-carousel-button-text">←</label>
            </button>
          ) : undefined
        }
      />
    </section>
  );
};

export default RequestCarousel2;
