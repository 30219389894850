import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getMeAPI } from '../../../services/api/user/get/getMeAPI';
import { GetUserQueryType } from '../../../services/api/user/get/getMeAPI.type';
import { UserDetailType } from '../../../types/api/user/UserTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetMeResult = UseQueryResult<UserDetailType> & {
  queryKey: string[];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetMe = (query?: GetUserQueryType): UseGetMeResult => {
  const queryKey = ['user-me'];

  const result = useQuery<UserDetailType>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    queryFn: async () => {
      return getMeAPI(query);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetMeResult;
};
