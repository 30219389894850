export type RequestPageModeQuery = null | 'detail' | 'ideas' | 'result';

// 配列とタイトルを連携させるオブジェクト
export const requestPageModeQueryMap = {
  detail: 'お題詳細',
  ideas: 'アイデア一覧',
  result: '結果発表',
};

// パース関数
export const parseRequestPageModeQuery = (
  mode?: string | null,
): RequestPageModeQuery => {
  return mode && Object.keys(requestPageModeQueryMap).includes(mode)
    ? (mode as RequestPageModeQuery)
    : 'detail';
};
