import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getIdeasAPI } from '../../../services/api/idea/get/getIdeasAPI';
import { GetIdeasQueryType } from '../../../services/api/idea/get/getIdeasAPI.type';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeasResult = UseQueryResult<{
  ideas: IdeaCardType[];
  numOfAllIdeas: number;
}> & {
  queryKey: any;
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetIdeas = (
  query?: GetIdeasQueryType & {
    enabled?: boolean;
  },
): UseGetIdeasResult => {
  const queryKey = ['ideas', query];
  const result = useQuery<{ ideas: IdeaCardType[]; numOfAllIdeas: number }>({
    queryKey: queryKey,
    enabled: query?.enabled,
    queryFn: () => getIdeasAPI(query), // 関数を返すように修正
    // staleTime: 1000 * 60 * 60, // 1時間
    // gcTime: 1000 * 60 * 60, // 1時間
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeasResult;
};
