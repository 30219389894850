import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../redux/store';
import HowToUseCorpTemplate from '../templates/howToUse/HowToUseCorpTemplate';
import HowToUseTemplate from '../templates/howToUse/HowToUseTemplate';

const HowToUsePage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);
  // クエリパラメーターを取得
  const [searchParams] = useSearchParams();
  const isCorp = searchParams.get('corp') === 'true';

  return isCorp ? (
    <HowToUseCorpTemplate message={undefined} isSP={isXS} />
  ) : (
    <HowToUseTemplate message={undefined} isSP={isXS} />
  );
};

export default HowToUsePage;
