import React, { useEffect } from 'react';
import {
  CONTACT_DETAIL_MAX_LENGTH,
  CONTACT_USERNAME_MAX_LENGTH,
} from '../../../../constants/max';
import { createContactThemeAPIProps } from '../../../../services/api/contact/createContactAPI.type';
import { ContactError } from '../../../../types/api/contact/ContactError.type';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../../ions/divider/DefaultDivider.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';
import StepNavigation from '../../../organisms/navTexts/StepNavigation';
import './../ContactTemplate.css';
import { useContactThemeForm } from './useContactThemeForm.hooks';
import { useContactThemeFormValidator } from './useContactThemeFormValidator.hooks';

interface ContactThemeTemplateProps {
  isSP: boolean;
  loading: boolean;
  openSuccessPopup: boolean;
  handleSuccessPopupClose: () => void;
  openFailedPopup: boolean;
  handleFailedPopupClose: () => void;
  createContactRequest: (input: createContactThemeAPIProps) => void;
  contactAPIError: ContactError | null;
}

const ContactThemeTemplate: React.FC<ContactThemeTemplateProps> = React.memo(
  (props) => {
    const { isSP } = props;

    // お問い合わせフォームの値とエラー
    const {
      values: values,
      errors: errors,
      handlers: handlers,
      reset: reset,
    } = useContactThemeForm();

    // お問い合わせフォームのバリデーション
    const { validators, validateErrors } = useContactThemeFormValidator();

    // タイトルと本文が変更された際にエラーメッセージを消す
    useEffect(() => {
      validators.reset();
    }, [values.userName, values.detail]);

    return (
      <div
        className={`contact-template-container `}
        style={{ minHeight: isSP ? '130vh' : '150vh' }}
      >
        <header className={`contact-template-header `}>
          <BodyTextBoldIons
            text="Theme Form"
            color="purple"
            size="large"
            className="contact-template-header-title"
          />
          <BodyTextBoldIons text="お題の投稿フォーム" size="small" />
        </header>

        <main className={`contact-template-main ${isSP ? '' : 'pc'}`}>
          <section className={`contact-template-main-top `}>
            <StepNavigation
              steps={['内容送信', 'オンライン相談\n(zoom等)', 'お題の投稿']}
              currentStep={1}
            />
            <BodyTextIons
              text={
                '内容を確認次第、ご登録のメールアドレスにてご連絡させていただきます。\n※通常1-3営業日以内に返信いたします'
              }
              bold="light"
              className="contact-template-main-top-description"
            />
          </section>

          <form className={`contact-template-main-form`}>
            {props.contactAPIError && (
              <ErrorCardMolecules
                isSP={isSP}
                text={props.contactAPIError.message || ''}
              />
            )}
            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="会社名(ユーザーネーム)" require={true} />
              <DefaultTextFieldIons
                label={null}
                size={isSP ? 'small' : 'medium'}
                value={values.userName}
                placeholder="会社名(ユーザーネーム)を入力してください。"
                onChange={handlers.handleUserNameChange}
                width={'100%'}
                max={CONTACT_USERNAME_MAX_LENGTH}
                error={validateErrors.userName || errors.userName}
              />
            </div>
            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="お題詳細" require={true} />
              <DefaultTextFieldIons
                label={null}
                width={'100%'}
                size={isSP ? 'small' : 'medium'}
                value={values.detail}
                placeholder="どのようなアイデアを募集したいのか、お題の詳細を入力してください。"
                onChange={handlers.handleDetailChange}
                rows={7}
                max={CONTACT_DETAIL_MAX_LENGTH}
                error={validateErrors.detail || errors.detail}
              />
            </div>
          </form>

          <RoundPurpleButtonIons
            width={'300px'}
            size={'normal'}
            disabled={props.loading}
            onClick={() =>
              validators.validateRequestContactForm(values) &&
              props.createContactRequest({
                contact: { userName: values.userName, detail: values.detail },
                onSuccess: () => {
                  reset();
                },
              })
            }
          >
            お題の相談をする
          </RoundPurpleButtonIons>
        </main>

        <DefaultPopupIons
          open={props.openSuccessPopup}
          onClose={() => {
            props.handleSuccessPopupClose();
          }}
          size="normal"
        >
          <div className="contact-template-popup">
            <HeaderTitle
              title="Contact Complete"
              titleSize="large"
              subTitle="お題の相談を受け付けました"
            />
            <BodyTextIons
              text="確認のため、ご登録のメールアドレスにメールをお送りしております。内容をご確認のうえ、返信をお待ちください。"
              size="small"
              bold="light"
              width={'90%'}
            />
            <RoundWhiteButtonIons
              width={'200px'}
              onClick={props.handleSuccessPopupClose}
            >
              閉じる
            </RoundWhiteButtonIons>
          </div>
        </DefaultPopupIons>

        <DefaultPopupIons
          open={props.openFailedPopup}
          onClose={() => {
            props.handleFailedPopupClose();
          }}
          size="normal"
        >
          <div className="contact-template-popup">
            <HeaderTitle
              title="Contact Failed"
              titleSize="large"
              subTitle="お題の相談に失敗しました"
            />
            <BodyTextIons
              text="お手数ですが、時間をおいて再度お試しください。"
              size="small"
              bold="light"
            />
            <RoundWhiteButtonIons
              width={'200px'}
              onClick={props.handleFailedPopupClose}
            >
              閉じる
            </RoundWhiteButtonIons>
          </div>
        </DefaultPopupIons>

        {props.loading && <LoadingOverlaySpinner size="large" />}
      </div>
    );
  },
);

export default ContactThemeTemplate;
