import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import PriavcyPolicyTemplate from '../../templates/documents/PrivacyPolicyTemplate';

const PriavcyPolicyPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  return (
    <PriavcyPolicyTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
    />
  );
};

export default PriavcyPolicyPage;
