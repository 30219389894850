import React from 'react';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import { TextSizeType } from '../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../ions/divider/DefaultDivider.Ions';
import IdeaImgIons from '../../ions/img/IdeaImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import LoadingFullSpinner from '../../molecules/spinner/LoadingFullSpinner';
import UserIconAndName from '../../molecules/user/UserIconAndName';
import StepNavigation from '../../organisms/navTexts/StepNavigation';
import './IdeaAdoptRequestTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType | undefined;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaAdoptRequestTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, size } = props;
  const isSP = size === 'sp';
  let width: string;
  let title_size: TextSizeType;

  switch (size) {
    case 'sp':
      width = '95%';
      title_size = 'normal';
      break;
    case 'small':
      width = '60%';
      title_size = 'normal';
      break;
    case 'medium':
      width = '45%';
      title_size = 'normal';
      break;
    case 'large':
      width = '45%';
      title_size = 'medium';
      break;
    default:
      width = '45%';
      title_size = 'medium';
  }

  if (!idea) return <LoadingFullSpinner />;

  return (
    <div className="idea-adopt-request-template">
      <main
        className="idea-adopt-request-template-main"
        style={{ width: width }}
      >
        <header className={`idea-adopt-request-template-header `}>
          <BodyTextBoldIons
            text="Adopt Idea"
            color="purple"
            className={`idea-adopt-request-template-header-title ${isSP ? 'sp' : ''} `}
          />
          <BodyTextBoldIons text="アイデアの商品化を申請する" size="normal" />
        </header>

        <StepNavigation
          steps={['アイデアの確認', '注意事項を確認', '商品化を申請']}
          currentStep={1}
        />

        <section className="idea-adopt-request-template-main">
          <section
            className={`idea-adopt-request-template-main-content ${size === 'sp' ? 'sp' : ''}`}
          >
            <header className="idea-adopt-request-template-main-heading">
              <BodyTextIons
                text="Confirm "
                className="idea-adopt-request-template-main-heading-title"
              />
              <BodyTextBoldIons
                text="アイデアを確認する"
                color="purple"
                size={title_size}
              />
            </header>

            <IdeaImgIons
              img_src={idea.imgSrc}
              className={`idea-adopt-request-template-img ${size === 'sp' ? 'sp' : ''}`}
            />

            <div
              className={`idea-adopt-request-template-main-content-texts ${size === 'sp' ? 'sp' : ''}`}
            >
              <BodyTextIons
                color="black"
                bold="bold"
                text={idea.title}
                size={'small'}
              />
              <BodyTextIons
                color="black"
                text={idea.description}
                rows={2}
                size={'small'}
              />

              <UserIconAndName
                userName={idea.user.userName}
                user_img_src={idea.user.imgSrc}
                icon_size="small"
                userId={idea.user.userId}
              />
            </div>

            <DefaultDividerIons width={'90%'} />

            <div
              className={`idea-adopt-request-template-main-content-texts ${size === 'sp' ? 'sp' : ''}`}
            >
              {[
                { label: '出資総額', value: `${idea.totalAmount} 円` },
                { label: '出資人数', value: `${idea.numOfInvestors} 人` },
                { label: '総閲覧数', value: `${idea.numOfViews} pv` },
                { label: '総いいね数', value: `${idea.numOfLikes} いいね` },
              ].map((item) => (
                <div className="idea-adopt-request-template-main-content-texts-money">
                  <BodyTextIons
                    color="black"
                    bold="light"
                    text={`${item.label}: `}
                  />
                  <BodyTextIons color="black" bold="light" text={item.value} />
                </div>
              ))}
            </div>
          </section>
        </section>

        <section
          className={`idea-adopt-request-template-main-content ${size === 'sp' ? 'sp' : ''}`}
        >
          <header className="idea-adopt-request-template-main-heading">
            <BodyTextIons
              text="Pay Attention "
              className="idea-adopt-request-template-main-heading-title"
            />
            <BodyTextBoldIons
              text="注意事項を確認する"
              color="purple"
              size={title_size}
            />
          </header>
          <div
            className={`idea-adopt-request-template-main-content-texts ${size === 'sp' ? 'sp' : ''}`}
          >
            <ul className="idea-adopt-request-template-main-content-list">
              {[
                '【商品化申請に関するご案内】\n' +
                  '商品化の申請を受け付けたアイデアにつきましては、運営チームが審査を行い、実現可能性や市場ニーズなどを総合的に検討した上で、商品化の可否を決定いたします。\n\n' +
                  '審査結果につきましては、1～3営業日以内にオンライン会議の詳細を含めたご案内を、本メールアドレス宛にお送りいたします。\n\n' +
                  'また、商品化が決定した場合の具体的な進め方につきましては、担当者より改めてご連絡いたします。\n\n',
                '【商品化後の取り扱いについて】\n' +
                  '当社にて商品化が決定された案件につきましては、クラウドファンディングプラットフォームに正式に掲載させていただきます。\n\n' +
                  '本プラットフォームにて集まりました出資総額につきましては、その金額の90%を貴社が受領できるものとします。\n\n' +
                  '商品化後の売上に対する投稿者様への還元率は、取り決めにより売上高の2%とさせていただいております。\n\n' +
                  '商品化の決定後、貴社は当該アイデアの実現に向けて責任を持って取り組む法的義務を負うものとします。\n\n' +
                  '出資者様へのリターンに関しましては、当社とのオンライン面談の場にてご相談させていただきますようお願い申し上げます。\n\n' +
                  '商品化決定後におきましても、製品開発の品質向上を目的として、投稿者様とのチャットルームは継続させていただきます。\n\n' +
                  '商品化の決定につきましては、一度決定した後の取り消しは一切できかねますため、慎重なご判断をお願い申し上げます。\n\n',
                '【お問い合わせ】\n' +
                  '商品化申請に関するご不明点やご質問がございましたら、お気軽にお問い合わせください。',
              ].map((item) => (
                <li>
                  <BodyTextIons text={item} bold="light" size="extra-small" />
                </li>
              ))}
            </ul>
          </div>
        </section>

        <RoundPurpleButtonIons
          onClick={() => PageNavigation.goToIdeaAdoptConfirmPage(idea.ideaId)}
          size="medium"
          width={'60%'}
        >
          確認へ進む
        </RoundPurpleButtonIons>
        <RoundWhiteButtonIons
          onClick={PageNavigation.goBack}
          size="medium"
          width={'60%'}
        >
          戻る
        </RoundWhiteButtonIons>
      </main>
    </div>
  );
};

export default IdeaAdoptRequestTemplate;
