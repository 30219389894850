import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import DefaultDividerIons from '../../ions/divider/DefaultDivider.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import './AppFooter.css';

const AppFooterPC = React.memo(() => {
  // ログイン状態・ユーザー情報・画面サイズ・通知情報を取得
  const { isXS } = useSelector((state: RootState) => state.screenSize);
  const { categories } = useSelector((state: RootState) => state.category);

  return (
    <footer className={`app-footer-container ${isXS ? 'sp' : ''}`}>
      <section className={`app-footer-pc-main ${isXS ? 'sp' : ''}`}>
        <div className="app-footer-categories-wrapper pc">
          <>
            <BodyTextIons
              text={'カテゴリ一覧'}
              bold="bold"
              className="app-footer-category-text"
            />
            <DefaultDividerIons
              width={'100%'}
              margin={1}
              className="app-footer-divider"
            />
          </>
          <div className="app-footer-categories">
            {categories.map((category) => (
              <BodyTextIons
                text={category.name}
                className="app-footer-category-text"
                onClick={() =>
                  PageNavigation.goToSearchPage({
                    categoryId: String(category.categoryId),
                  })
                }
              />
            ))}
          </div>
        </div>

        <div className="app-footer-pc-right">
          <BodyTextIons text="RE-IDEA" className="app-footer-logo-text" />

          <div className="app-footer-links">
            <BodyTextIons
              text="> プライバシーポリシー"
              className="app-footer-link-text"
              onClick={() => PageNavigation.goToPrivacyPolicyPage()}
            />
            <BodyTextIons
              text="> 特定商取引法に関する記載/運営会社"
              className="app-footer-link-text"
              onClick={() =>
                PageNavigation.goToSpecifiedCommercialTransaction()
              }
            />
            <BodyTextIons
              text="> 利用規約"
              className="app-footer-link-text"
              onClick={() => PageNavigation.goToUserAgreementPage()}
            />
          </div>
        </div>
      </section>
    </footer>
  );
});

export default AppFooterPC;
