import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { UserCardType } from '../types/api/user/UserTypes';
import authReducer, { AuthState } from './auth/authSlice';
import categoryReducer, { CategoryState } from './category/categorySlice';
import notifyReducer, { NotifyState } from './notify/notifySlice';
import ideaPostPopupReducer, {
  IdeaPostPopupState,
} from './slice/ideaPostPopupSlice';
import loginPopupReducer, { LoginPopupState } from './slice/loginPopupSlice';
import registerPopupReducer, {
  RegisterPopupState,
} from './slice/registerPopupSlice';
import screenSizeReducer, { ScreenSizeState } from './slice/screenSizeSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isLoggedIn', 'user', 'expiresAt'],
};

const categoryPersistConfig = {
  key: 'category',
  storage,
  whitelist: ['categories', 'expiresAt'],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedCategoryReducer = persistReducer(
  categoryPersistConfig,
  categoryReducer,
);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    notify: notifyReducer,
    category: persistedCategoryReducer,
    loginPopup: loginPopupReducer,
    registerPopup: registerPopupReducer,
    ideaPostPopup: ideaPostPopupReducer,
    screenSize: screenSizeReducer,
  },
});

export type RootState = {
  auth: AuthState;
  notify: NotifyState;
  user: UserCardType;
  loginPopup: LoginPopupState;
  registerPopup: RegisterPopupState;
  ideaPostPopup: IdeaPostPopupState;
  screenSize: ScreenSizeState;
  category: CategoryState;
};
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
