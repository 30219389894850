export const validateZipcode = (value: string): string | null => {
  if (value === '') return null;

  // ハイフンの有無に関わらず7桁の数字であることを確認
  const postalRegex = /^\d{3}-?\d{4}$/;

  // ハイフンなしの7桁の数字
  const digitsOnly = /^\d{7}$/;

  return postalRegex.test(value) || digitsOnly.test(value)
    ? null
    : '郵便番号は7桁の半角数字（例：123-4567）で入力してください';
};
