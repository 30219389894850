import React from 'react';
import IdeaImgIons from '../../../ions/img/IdeaImg.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './LikeCard.Molecules.css';

type PurchaseCardMoleculesProps = {
  isSP: boolean;
  imgSrc: string;
  title: string;
  description: string;
  onClick?: () => void;
};

export const LikeCardMolecules: React.FC<PurchaseCardMoleculesProps> =
  React.memo((props) => {
    const { isSP } = props;
    return (
      <div
        className={`like-card-container ${isSP ? '' : 'pc'}`}
        onClick={props.onClick}
      >
        <div className={`like-card-img ${isSP ? '' : 'pc'}`}>
          <IdeaImgIons img_src={props.imgSrc} alt={props.title} />
        </div>

        <div className={`like-card-texts ${isSP ? '' : 'pc'}`}>
          <BodyTextBoldIons
            text={props.title}
            rows={1}
            size={isSP ? 'extra-small' : 'normal'}
          />
          <BodySubTextIons
            size={isSP ? 'extra-small' : 'small'}
            rows={2}
            text={`${props.description}`}
          />
        </div>
      </div>
    );
  });

export default LikeCardMolecules;
