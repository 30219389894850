import { IdeaErrorType } from '../../../../types/api/idea/IdeaError.type';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { CreateIdeaType } from './createIdeaAPI.type';

// アイデアを作成するAPI（FormData形式）
export const createIdea = async (
  input: CreateIdeaType,
  onSuccess?: () => void,
  onError?: () => void,
): Promise<
  | (IdeaErrorType & { type: 'error' })
  | (IdeaDetailType & { type: 'idea' })
  | null
> => {
  const formData = new FormData();

  // 各プロパティを FormData に追加
  Object.entries(input).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      // Fileオブジェクトの場合はそのまま追加、それ以外は文字列として追加
      if (value instanceof Blob || value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    }
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/ideas/`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });

    if (!response.ok) {
      if (onError) onError();
      const result: IdeaErrorType = await response.json();
      return { ...result, type: 'error' };
    } else {
      // nullを返す
      const text = await response.clone().text();
      if (text.length === 0) {
        return null;
      }
      const result = await response.json().catch(() => null);
      return result;
    }
  } catch (error) {
    console.log('error', error);
    console.error('Error creating idea:', error);
    if (onError) onError();
    return {
      code: 'UNKNOWN_ERROR',
      message: 'エラーが発生しました',
      type: 'error',
    };
  }
};
