import React from 'react';
import './Img.Atoms.css';
import { ImgAtomsProps } from './Img.type';

const ImgAtoms: React.FC<ImgAtomsProps> = React.memo(
  ({ img_src, imgFile, alt, className, onDelete, onClick }) => {
    return (
      <div className="img-atoms-wrapper">
        <img
          src={imgFile ? URL.createObjectURL(imgFile) : img_src}
          alt={alt}
          className={`img-atoms ${className}`}
          onClick={onClick}
        />
        {onDelete && (
          <button
            className="img-atoms-delete-button"
            onClick={onDelete}
            aria-label="Delete image"
          >
            ×
          </button>
        )}
      </div>
    );
  },
);

export default ImgAtoms;
