import React from 'react';
import { IDEA_SUBTITLE_MAX_LENGTH } from '../../../../../constants/max';
import RadioButtonAtoms from '../../../../atoms/radio/RadioButton.Atoms';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../../ions/textfield/DefaultTextField.Ions';
import NumAndTitle from '../../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../../molecules/text/requireTitle/RequireAndTitle';
import './RadioButtonForm.css';

interface SubtitleGeneFormProps {
  num?: number;
  require?: boolean | null;
  subTitle: string | null;
  generate: boolean;
  handleChangeGenerate: (value: boolean) => void;
  handleSubTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  error: string | null;
  isSP?: boolean;
}

const SubTitleGeneForm: React.FC<SubtitleGeneFormProps> = ({
  num,
  generate,
  subTitle,
  require,
  handleChangeGenerate,
  handleSubTitleChange,
  error,
  isSP,
}) => {
  return (
    <section className="radio-button-form-button-wrapper">
      {num && (
        <NumAndTitle
          num={num}
          title="アイデアのサブタイトル"
          num_size="small"
          title_size="small"
        />
      )}
      {(require === true || require === false) && (
        <RequireAndTitle
          title="アイデアのサブタイトル"
          require={require}
          hint={
            'タイトルや説明文からサブタイトルを\nAIで生成することができます。'
          }
          hint_placement={isSP ? 'top-right-center' : 'top-left-center'}
        />
      )}
      <RadioButtonAtoms
        checked={!generate}
        size={'small'}
        onChange={() => {
          handleChangeGenerate(false);
        }}
      >
        <div
          className={`radio-button-form-button-cell ${!generate ? 'selected' : ''} `}
        >
          <BodyTextIons
            text={'アイデアのサブタイトルを自分で入力する'}
            size={'extra-small'}
            className="radio-button-form-button-text"
          />
        </div>
      </RadioButtonAtoms>
      <RadioButtonAtoms
        checked={generate}
        size={'small'}
        onChange={() => {
          handleChangeGenerate(true);
        }}
      >
        <div
          className={`radio-button-form-button-cell ${generate ? 'selected' : ''} `}
        >
          <BodyTextIons
            text={'サブタイトルをAIが自動で生成する'}
            size={'extra-small'}
            className="radio-button-form-button-text"
          />
        </div>
      </RadioButtonAtoms>
      {!generate && (
        <DefaultTextFieldIons
          value={subTitle || ''}
          onChange={handleSubTitleChange}
          placeholder="アイデアのサブタイトルを入力してください"
          label={null}
          error={error}
          size="small"
          componentType="textarea"
          rows={1}
          max={IDEA_SUBTITLE_MAX_LENGTH}
        />
      )}
    </section>
  );
};

export default SubTitleGeneForm;
