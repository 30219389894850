import React from 'react';
import ButtonAtoms from '../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../atoms/button/Button.type';
import './ButtonRound.css';

const RoundButtonIons: React.FC<ButtonAtomsProps> = React.memo((props) => {
  return (
    <ButtonAtoms
      {...props}
      className={`button-round-ions ${props.className}`}
    />
  );
});

export default RoundButtonIons;
