import React from 'react';
import './TextField.Atoms.css';
import { TextFieldAtomsProps } from './TextField.type';

const TextFieldAtoms: React.FC<TextFieldAtomsProps> = React.memo(
  ({
    label,
    value,
    onChange,
    placeholder = '',
    error,
    max,
    componentType,
    type = 'text',
    input_class_name,
    rows,
    design_type = 'default',
    size = 'medium',
    width,
    disabled,
  }) => {
    const InputComponent = componentType || 'input';

    return (
      <div className="textfield-container" style={{ width }}>
        {label && <label className={`textfield-label ${size}`}>{label}</label>}
        <InputComponent
          role="textfield-input"
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`textfield-atoms ${design_type === 'underline' ? 'textfield-input-underline' : 'textfield-input'} ${error ? 'textfield-input-error' : ''} ${size} ${input_class_name ?? ''}`}
          rows={rows}
          disabled={disabled}
          style={{
            maxWidth: '100%',
            padding:
              componentType === 'textarea'
                ? 'calc(var(--font-size) * 0.5) calc(var(--font-size) * 0.5) calc(var(--font-size) * 0.4) calc(var(--font-size) * 0.5)'
                : undefined,
            height: rows
              ? `calc(var(--font-size) * var(--line-height) * ${rows + 1})`
              : undefined,
          }}
        />
        <section className="textfield-error-max-container">
          {error && <div className="textfield-error">{error}</div>}
          {max && (
            <div
              className={`textfield-counter ${value.length >= max ? 'max' : ''}`}
            >
              {value.length} / {max}
            </div>
          )}
        </section>
      </div>
    );
  },
);

export default TextFieldAtoms;
