import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { AdoptIdeaRequestAPIProps } from '../../../../services/api/idea/adopt/adoptIdeaRequestAPI.type';
import { IdeaErrorType } from '../../../../types/api/idea/IdeaError.type';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../../ions/divider/DefaultDivider.Ions';
import TooltipPopoverIons from '../../../ions/popover/TooltipPopover.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingFullSpinner from '../../../molecules/spinner/LoadingFullSpinner';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import StepNavigation from '../../../organisms/navTexts/StepNavigation';
import './IdeaAdoptRequestConfirmTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType | undefined;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  loading: boolean;
  error: IdeaErrorType | null;
  handleAdoptRequestIdea: (input: AdoptIdeaRequestAPIProps) => void;
}

const IdeaAdoptRequestConfirmTemplate: React.FC<IdeaTemplateProps> = (
  props,
) => {
  const { goBack } = usePageNavigation();
  const { size, idea } = props;
  let width: string;
  const isSP = size === 'sp';

  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '70%';
      break;
    case 'medium':
      width = '55%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  if (!idea) return <LoadingFullSpinner />;

  return (
    <div className="idea-adopt-request-confirm-template">
      {props.loading && <LoadingOverlaySpinner />}
      <main
        className="idea-adopt-request-confirm-template-main"
        style={{ width: width }}
      >
        <header className={`idea-adopt-request-confirm-template-header `}>
          <BodyTextBoldIons
            text="Adopt Idea"
            color="purple"
            className={`idea-adopt-request-confirm-template-header-title ${isSP ? 'sp' : ''} `}
          />
          <BodyTextBoldIons text="アイデアの商品化を申請する" size="normal" />
        </header>

        <StepNavigation
          steps={['アイデアの確認', '注意事項を確認', '商品化を申請']}
          currentStep={3}
        />
        {props.error?.message && (
          <ErrorCardMolecules isSP={isSP} text={props.error?.message} />
        )}
        <section className="idea-adopt-request-confirm-template-main">
          <section
            className={`idea-adopt-request-confirm-template-main-content ${size === 'sp' ? 'sp' : ''}`}
          >
            <header className="idea-adopt-request-confirm-template-main-heading">
              <BodyTextIons
                text="Check"
                className="idea-adopt-request-confirm-template-main-heading-title"
              />
              <BodyTextBoldIons
                text="商品化の申請を確認"
                color="purple"
                size={'normal'}
              />
            </header>
            {[
              {
                title: 'アイデア',
                tooltip: '商品化対象のアイデアを確認しましょう',
                value: idea.title,
              },
              {
                title: 'アイデアの詳細',
                tooltip: '商品化対象のアイデアを確認しましょう',
                value: idea.description,
              },
              {
                title: '投稿者',
                tooltip: 'アイデアの投稿者を確認しましょう',
                value: idea.user.userName,
              },
            ].map((item, index) => (
              <>
                <div className="idea-adopt-request-confirm-template-main-content-texts-money">
                  <div
                    className="idea-adopt-request-confirm-template-main-content-texts-money-title"
                    style={{ width: '35%' }}
                  >
                    <BodyTextIons
                      text={item.title}
                      size={'small'}
                      bold="light"
                      className="idea-adopt-request-confirm-template-main-content-texts-money-value"
                    />
                    <TooltipPopoverIons
                      text={item.tooltip}
                      placement="top-left-center"
                    />
                  </div>
                  <BodyTextIons
                    text={`${item.value}`}
                    size={'small'}
                    bold="light"
                    width={'65%'}
                    className="idea-adopt-request-confirm-template-main-content-texts-money-value"
                  />
                </div>
                {index !== 2 && (
                  <DefaultDividerIons width={'100%'} margin={0} />
                )}
              </>
            ))}
          </section>
        </section>

        <section className="idea-adopt-request-confirm-template-buttons">
          <RoundPurpleButtonIons
            onClick={async () => {
              await props.handleAdoptRequestIdea({
                ideaId: idea.ideaId,
                onSuccess: async () => {
                  PageNavigation.goToIdeaAdoptCompletePage(idea.ideaId);
                },
              });
            }}
            size="medium"
            disabled={props.loading}
          >
            申請する
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            onClick={() => {
              goBack();
            }}
            size="medium"
          >
            戻る
          </RoundWhiteButtonIons>
        </section>
      </main>
    </div>
  );
};

export default IdeaAdoptRequestConfirmTemplate;
