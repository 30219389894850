import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getInvestmentAPI } from '../../services/api/investment/getInvestmentAPI';
import { InvestmentType } from '../../types/api/investment/InvestmentTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetInvestmentResult = UseQueryResult<InvestmentType> & {
  queryKey: readonly ['investment', string];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetInvestment = (
  investmentId?: string | null,
): UseGetInvestmentResult => {
  const queryKey = ['investment', investmentId] as const;

  const result = useQuery<InvestmentType>({
    queryKey: ['investment', investmentId], // sortの値も含めてキャッシュを管理
    enabled: !!investmentId,
    queryFn: async () => {
      if (!investmentId) throw new Error('investmentId is required');
      return getInvestmentAPI(investmentId);
    },
    // staleTime: 1000 * 60 * 60, // 1時間
    // gcTime: 1000 * 60 * 60, // 1時間
  });

  return {
    ...result,
    queryKey,
  } as UseGetInvestmentResult;
};
