import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import useOpen from '../../../hooks/useOpen';
import { useScrollIntoViewWithOffset } from '../../../hooks/useScrollIntoViewWithOffset';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import LoadingFullSpinner from '../../molecules/spinner/LoadingFullSpinner';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import SakairiIdeaCarousel from '../../organisms/carousel/SakairiIdeaCarousel';
import IdeaDetailAndCfPC from '../../organisms/idea/DetailAndCF/PC/IdeaDetailAndCfPC';
import IdeaUserAndReturns from '../../organisms/idea/useAndReturns/IdeaUserAndReturns';
import RewardConfirmPopup from '../../organisms/popup/RewardConfirmPopup';
import IdeaActivityTemplate from './acitivity/IdeaActivityTemplate';
import IdeaCommentTemplate from './comment/IdeaCommentTemplate';
import './IdeaTemplate.css';
import { IdeaTemplateProps } from './IdeaTemplate.type';
import IdeaStoryTemplate from './story/IdeaStoryTemplate';

interface IdeaTemplatePCProps extends IdeaTemplateProps {
  size?: 'small' | 'medium' | 'large' | 'none';
}

const IdeaTemplatePC: React.FC<IdeaTemplatePCProps> = (props) => {
  const { idea, apiErrors, apiHandlers, apiLoadings } = props;
  const { open, handleClose } = useOpen();
  const { user, isLoggedIn } = useSelector((state: RootState) => state.auth);

  const bottomLeftRef = useRef<HTMLElement>(null);

  useScrollIntoViewWithOffset(
    bottomLeftRef,
    props.view === 'comment' || props.view === 'activity',
    {
      offset: -160,
    },
    props.view,
  );

  let width: string;
  switch (props.size) {
    case 'small':
      width = '95%';
      break;
    case 'medium':
      width = '850px';
      break;
    case 'large':
      width = '960px';
      break;
    default:
      width = '950px';
  }

  if (!idea) return <LoadingFullSpinner />;

  return (
    <div className={`idea-template`}>
      <main className={`idea-template-main`}>
        <section className={`idea-template-top`}>
          <IdeaDetailAndCfPC
            view={props.view}
            text={apiHandlers.getMainButtonText({
              idea,
              user: isLoggedIn ? user : null,
            })}
            onClick={() =>
              apiHandlers.handleClickMainButton({
                idea,
                user: isLoggedIn ? user : null,
                investment: props.investments[0],
              })
            }
            handleIdeaPublish={apiHandlers.handleIdeaPublish}
            handleIdeaUnPublish={apiHandlers.handleIdeaUnPublish}
            handleIdeaDelete={apiHandlers.handleDeleteIdea}
            idea={idea}
            width={width}
            likeIdea={apiHandlers.handleLike}
            unlikeIdea={apiHandlers.handleUnlike}
            numOfActivities={props.idea_activities.length}
            numOfComments={props.numOfAllComments}
          />
        </section>

        <section style={{ width: width }} className={`idea-template-bottom`}>
          <section className={`idea-template-bottom-left`} ref={bottomLeftRef}>
            {props.view === 'home' ? (
              <IdeaStoryTemplate idea={idea} title_size="normal" isSP={false} />
            ) : props.view === 'comment' ? (
              <IdeaCommentTemplate
                numOfAllComments={props.numOfAllComments}
                page={props.page}
                idea={idea}
                idea_comments={props.idea_comments}
                title_size="normal"
                sendIdeaComment={apiHandlers.handleSendIdeaComment}
                likeIdeaComment={apiHandlers.handleLikeIdeaComment}
                unlikeIdeaComment={apiHandlers.handleUnlikeIdeaComment}
                deleteIdeaComment={apiHandlers.handleDeleteIdeaComment}
              />
            ) : (
              <IdeaActivityTemplate
                idea={idea}
                idea_activities={props.idea_activities}
                title_size="normal"
                sendLoading={apiLoadings.sendIdeaActivitiy}
                sendApiError={apiErrors.sendIdeaActivitiy}
                deleteLoading={apiLoadings.deleteIdeaActivitiy}
                deleteApiError={apiErrors.deleteIdeaActivitiy}
                sendIdeaActivity={apiHandlers.handleSendIdeaActivity}
                deleteIdeaActivity={apiHandlers.handleDeleteIdeaActivity}
              />
            )}
          </section>

          <section className={`idea-template-bottom-right `}>
            <IdeaUserAndReturns
              idea={idea}
              title_size="normal"
              investments={props.investments}
            />
          </section>
        </section>
        <div
          className="idea-template-suggest-carousel"
          style={{ width: width }}
        >
          <HeaderTitleSakairi
            title="FEATURED"
            titleSize={'large'}
            subTitle="おすすめのアイデア"
          />
          <SakairiIdeaCarousel
            isSP={true}
            title_size={'large'}
            slide_min_width={280}
            card_width={260}
            ideas={props.suggest_ideas}
            moreOnClick={() =>
              PageNavigation.goToSearchPage({ sort: 'featured' })
            }
          />
        </div>
      </main>

      <RewardConfirmPopup open={open} onClose={handleClose} />
    </div>
  );
};

export default IdeaTemplatePC;
