import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { IdeasSortQuery } from '../types/query/ideasQuery';

export const usePageNavigation = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // 特定のアイデア詳細ページに遷移
  const goToIdeaPage = useCallback(
    (ideaId: string, params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/ideas/' + ideaId);
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/ideas/${ideaId}${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  // お題投稿相談ページに遷移
  const goToContactRequestPage = useCallback(() => {
    navigate(`/contact/theme`);
  }, []);

  // 特定のアイデア編集ページに遷移
  const goToIdeaEditPage = useCallback((ideaId: string) => {
    navigate(`/ideas/${ideaId}/edit`);
  }, []);

  // ログインページに遷移
  const goToLoginPage = useCallback((redirectUrl: string) => {
    navigate(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}`);
  }, []);

  // 新規登録ページに遷移
  const goToRegisterPage = useCallback((redirectUrl: string) => {
    navigate(`/register?redirectUrl=${encodeURIComponent(redirectUrl)}`);
  }, []);

  // 事業者用の新規登録ページに遷移
  const goToRegisterCorpPage = useCallback(() => {
    navigate(`/corp/register`);
  }, []);

  // 認証メール送信完了
  const goToVerifyEmailSendPage = useCallback(() => {
    navigate(`/auth/verify/email/send`);
  }, []);

  // 特定のアイデア出資ページに遷移
  const goToIdeaInvestPage = useCallback(
    (ideaId: string, investmentId: string) => {
      navigate(`/ideas/${ideaId}/invest/${investmentId}`);
    },
    [],
  );

  // 特定のアイデア出資確認ページに遷移
  const goToIdeaInvestConfirmPage = useCallback(
    (ideaId: string, investmentId: string) => {
      navigate(`/ideas/${ideaId}/invest/${investmentId}/confirm`);
    },
    [],
  );

  // 特定のアイデア出資削除確認ページに遷移
  const goToIdeaInvestDeleteConfirmPage = useCallback(
    (ideaId: string, investmentId: string) => {
      navigate(`/ideas/${ideaId}/invest/${investmentId}/delete/confirm`);
    },
    [],
  );

  // ホームページに遷移
  const goToHomePage = useCallback(() => {
    navigate(`/`);
  }, []);

  // 検索ページに遷移
  const goToSearchPage = useCallback(
    (
      params?:
        | Record<'sort', IdeasSortQuery>
        | Record<'keyword', string>
        | Record<'categoryId', number>
        | Record<'user_id', string>,
    ) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/search');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/search${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToMyPage = useCallback(
    (params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/users/my');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/users/my${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToOthersUserPage = useCallback(
    (userId: string, params?: Record<string, string>) => {
      // ユーザーがログイン済みの場合は、自分のページに遷移
      if (user && user.userId === userId) {
        if (!user.isCorp) navigate('/users/my?mode=ideas');
        else navigate('/users/my?mode=requests');
        return;
      }
      if (!params || Object.keys(params).length === 0) {
        navigate('/users/' + userId);
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/users/${userId}${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToIntroPage = useCallback(
    (params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/introduce');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/introduce${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToRequestPage = useCallback(
    (requestId: string, params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate(`/requests/${requestId}`);
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/requests/${requestId}${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  return {
    goBack,
    goToIdeaPage,
    goToHomePage,
    goToIdeaInvestPage,
    goToIdeaInvestConfirmPage,
    goToIdeaInvestDeleteConfirmPage,
    goToIdeaEditPage,
    goToSearchPage,
    goToMyPage,
    goToIntroPage,
    goToRequestPage,
    goToRegisterCorpPage,
    goToLoginPage,
    goToRegisterPage,
    goToOthersUserPage,
    goToContactRequestPage,
    goToVerifyEmailSendPage,
  };
};
