import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetIsVerifiedPassToken } from '../../../hooks/api/verify/useGetIsVerifiedPassToken';
import { RootState } from '../../../redux/store';
import UpdatePasswordSuccessTemplate from '../../templates/auth/verify/UpdatePasswordSuccessTemplate';
import NotFoundTemplate from '../../templates/not-found/NotFoundTemplate';

const UpdatePasswordSuccessPage = () => {
  const { token } = useParams();
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // トークンを用いて認証を行う
  const { data: isVerified } = useGetIsVerifiedPassToken(token);

  // 認証が完了していない場合はNotFoundTemplateを表示
  if (isVerified === undefined || !token) return null;
  if (!isVerified) return <NotFoundTemplate />;

  return <UpdatePasswordSuccessTemplate isSP={isXS} />;
};

export default UpdatePasswordSuccessPage;
