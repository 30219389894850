import React, { ChangeEvent } from 'react';
import { PopoverPlacement } from '../../atoms/popover/Popover.type';
import DefaultTextFieldIons from '../../ions/textfield/DefaultTextField.Ions';
import RequireAndTitle from '../../molecules/text/requireTitle/RequireAndTitle';

interface IdeaTitleTextfieldProps {
  require: boolean;
  label: string;
  text: string;
  placeholder?: string;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;

  error: string | null;
  hint?: string;
  hint_placement?: PopoverPlacement;
  componentType?: 'input' | 'textarea';
  isSP?: boolean;
  max?: number;
  rows?: number;
  disabled?: boolean;
}

const RequireTextfield: React.FC<IdeaTitleTextfieldProps> = React.memo(
  (props) => {
    return (
      <DefaultTextFieldIons
        value={props.text}
        error={props.error}
        placeholder={props.placeholder}
        componentType={props.componentType}
        size={props.isSP ? 'small' : 'medium'}
        width={'100%'}
        onChange={props.handleChange}
        disabled={props.disabled}
        rows={props.rows}
        label={
          <RequireAndTitle
            require={props.require}
            title={props.label}
            hint={props.hint}
            hint_placement={props.hint_placement}
          />
        }
        max={props.max}
      />
    );
  },
);

export default RequireTextfield;
