import {
  CHAT_DISPLAY_LIMIT,
  GET_IDEA_MAX_LIMIT,
} from '../../../../../constants/max';
import { useGetChats } from '../../../../../hooks/api/chat/useGetChats';
import { useGetIdeas } from '../../../../../hooks/api/idea/useGetIdeas';
import { useGetRequests } from '../../../../../hooks/api/request/useGetRequests';
import { useGetRoom } from '../../../../../hooks/api/room/useGetRoom';
import { useGetRooms } from '../../../../../hooks/api/room/useGetRooms';
import { useGetPurchases } from '../../../../../hooks/api/useGetPurchases';
import { useGetFollowedUsers } from '../../../../../hooks/api/user/useGetFollowedUsers';
import { useGetFollowers } from '../../../../../hooks/api/user/useGetFollowers';
import { ChatDetailType } from '../../../../../types/api/chat/ChatTypes';
import { IdeaCardType } from '../../../../../types/api/idea/IdeaTypes';
import { PurchaseInvestementType } from '../../../../../types/api/investment/InvestmentTypes';
import { RequestCardType } from '../../../../../types/api/request/RequestTypes';
import { RoomCardType } from '../../../../../types/api/room/RoomTypes';
import {
  UserCardType,
  UserDetailType,
} from '../../../../../types/api/user/UserTypes';
import { IdeasSortQuery } from '../../../../../types/query/ideasQuery';
import {
  MyCorpPageModeQuery,
  MyPageModeQuery,
} from '../../../../../types/query/UserPageQuery';

export interface MyPageItemsInterface {
  followers: UserCardType[];
  followedUsers: UserCardType[];
  userIdeas: IdeaCardType[];
  userRequests: RequestCardType[];
  userPurchases: PurchaseInvestementType[];
  rooms: RoomCardType[];
  room: RoomCardType | null;
  chats: ChatDetailType[];
}

export interface MyPageNumOfItemsInterface {
  followers: number;
  followedUsers: number;
  userIdeas: number;
  userRequests: number;
  userPurchases: number;
  rooms: number;
  chats: number;
}

export interface MyPageQueryKeyInterface {
  followers: any;
  followedUsers: any;
  userIdeas: any;
  userRequests: any;
  userPurchases: any;
  room: any;
  rooms: any;
  chats: any;
}

export const useMyPageData = (input: {
  user: UserDetailType | undefined;
  mode: MyPageModeQuery | MyCorpPageModeQuery;
  roomId: string | null;
  requestId?: string;
  isPublic: boolean;
  page: number;
}): {
  items: MyPageItemsInterface;
  numOfItems: MyPageNumOfItemsInterface;
  queryKey: MyPageQueryKeyInterface;
} => {
  // ユーザーのフォロワー情報を取得
  const { data: followers, queryKey: followersQueryKey } = useGetFollowers(
    input.user?.userId,
  );
  const { data: followedUsers, queryKey: followedUsersQueryKey } =
    useGetFollowedUsers(input.user?.userId);

  // ユーザーのアイデアを取得
  const { data: userIdeasResult, queryKey: userIdeasQueryKey } = useGetIdeas({
    enabled:
      input.user !== undefined &&
      (input.mode === 'ideas' ||
        input.mode === 'liked' ||
        input.mode === 'viewed' ||
        input.mode === 'received'),
    sort:
      input.mode === 'ideas'
        ? 'posted'
        : input.mode === 'liked' ||
            input.mode === 'viewed' ||
            input.mode === 'received'
          ? (input.mode as IdeasSortQuery)
          : 'posted',
    isPublic: input.isPublic,
    requestId: input.requestId,
    limit: GET_IDEA_MAX_LIMIT,
    page: input.page,
    comment: input.mode === 'ideas' || input.mode === 'received' ? true : false,
    like: input.mode === 'ideas' || input.mode === 'received' ? true : false,
    view: input.mode === 'ideas' || input.mode === 'received' ? true : false,
  });

  // ユーザーのお題を取得
  const { data: userRequests, queryKey: userRequestsQueryKey } = useGetRequests(
    {
      enabled:
        input.user !== undefined &&
        (input.mode === 'requests' || input.mode === 'received'),
      userId: input.user?.userId || undefined,
      limit: GET_IDEA_MAX_LIMIT,
      page: input.page,
    },
  );

  // ユーザーの出資履歴を取得
  const { data: userPurchasesResult, queryKey: userPurchasesQueryKey } =
    useGetPurchases({
      enabled: input.mode === 'invested',
      limit: GET_IDEA_MAX_LIMIT,
      page: input.page,
    });

  // チャットルーム一覧情報を取得
  const { data: rooms, queryKey: roomsQueryKey } = useGetRooms({
    enabled:
      input.user !== undefined &&
      input.mode === 'chat' &&
      input.roomId === null,
  });

  const { data: room, queryKey: roomQueryKey } = useGetRoom(input.roomId);

  // 指定されたチャットルーム情報、チャット情報を取得
  const { data: chats, queryKey: chatsQueryKey } = useGetChats({
    enabled: !!input.roomId,
    roomId: input.roomId,
    limit: CHAT_DISPLAY_LIMIT * (input.page || 1),
    page: 1,
  });

  const items: MyPageItemsInterface = {
    followers: followers || [],
    followedUsers: followedUsers || [],
    userIdeas: userIdeasResult?.ideas || [],
    userRequests: userRequests || [],
    userPurchases: userPurchasesResult?.items || [],
    rooms: rooms || [],
    room: room || null,
    chats: chats || [],
  };

  const numOfItems: MyPageNumOfItemsInterface = {
    followers: followers?.length || 0,
    followedUsers: followedUsers?.length || 0,
    userIdeas: userIdeasResult?.numOfAllIdeas || 0,
    userRequests: userRequests?.length || 0,
    userPurchases: userPurchasesResult?.numOfItems || 0,
    rooms: rooms?.length || 0,
    chats: chats?.length || 0,
  };

  const queryKey: MyPageQueryKeyInterface = {
    followers: followersQueryKey,
    followedUsers: followedUsersQueryKey,
    userIdeas: userIdeasQueryKey,
    userRequests: userRequestsQueryKey,
    userPurchases: userPurchasesQueryKey,
    rooms: roomsQueryKey,
    room: roomQueryKey,
    chats: chatsQueryKey,
  };

  return { items, numOfItems, queryKey };
};
