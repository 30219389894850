import { likeIdeaAPIProps } from './likeIdeaAPI.type';

// アイデアのコメントを送信するAPI
export const likeIdeaAPI = async (input: likeIdeaAPIProps) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${input.ideaId}/like`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    // throw new Error(`Failed to send comment: ${response.statusText}`);
    alert('Input:\n' + String(JSON.stringify(input)));
  }

  if (input.onSuccess) input.onSuccess();
};
