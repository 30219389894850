import React from 'react';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './IdeaSaveConfirmPopup.css';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  handleSave: () => void;
}

const IdeaSaveConfirmPopup: React.FC<PopupProps> = React.memo(
  ({ open, onClose, handleSave }) => {
    return (
      <DefaultPopupIons open={open} onClose={onClose} size="normal">
        <div className="idea-save-confirm-popup">
          <header className={`idea-save-confirm-popup-header `}>
            <BodyTextBoldIons
              text="Idea Save"
              color="purple"
              size="large"
              className="idea-save-confirm-popup-heade"
            />
            <BodyTextBoldIons text="アイデアの保存" size="small" />
          </header>

          <BodyTextIons
            size="small"
            bold="light"
            text="入力された内容で保存してもよろしいですか？"
          />

          <div className="idea-save-confirm-popup-buttons">
            <RoundWhiteButtonIons
              width={'140px'}
              size="normal"
              onClick={onClose}
            >
              キャンセル
            </RoundWhiteButtonIons>
            <RoundPurpleButtonIons
              width={'140px'}
              size="normal"
              onClick={handleSave}
            >
              保存する
            </RoundPurpleButtonIons>
          </div>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default IdeaSaveConfirmPopup;
