import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getUsersAPI } from '../../../services/api/user/get/getUsersAPI';
import { GetUsersQueryType } from '../../../services/api/user/get/getUsersAPI.type';
import { UserCardType } from '../../../types/api/user/UserTypes';

// アイデア一覧情報を取得するためのカスタムフック
export const useGetUsers = (
  query?: GetUsersQueryType,
): UseQueryResult<UserCardType[]> => {
  return useQuery<UserCardType[]>({
    queryKey: ['users', query], // sortの値も含めてキャッシュを管理
    queryFn: () => getUsersAPI(query), // 関数を返すように修正
    // staleTime: 1000 * 60 * 60, // 1時間
    // gcTime: 1000 * 60 * 60, // 1時間
  });
};
