import React from 'react';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import EnTitleSection from '../../../organisms/section/EnTitleSection';
import './VerifyEmailTemplate.css';

interface PopupProps {
  isSP: boolean;
}

// パスワード変更完了後の遷移ページ
const UpdatePasswordSuccessTemplate: React.FC<PopupProps> = React.memo(
  (props) => {
    const { isSP } = props;
    return (
      <div className={`verify-email-template`}>
        <HeaderTitle
          title="Update Complete"
          subTitle="パスワードの変更が完了しました"
          titleSize="large"
        />

        <section className={`verify-email-template-main ${isSP ? 'sp' : ''}`}>
          <EnTitleSection
            title="Updated Your Password"
            subTitle="パスワードが正常に更新されました"
            isSP={isSP}
          >
            <ul className="verify-email-template-list">
              {[
                'パスワード変更手続きが正常に完了いたしました。',
                'セキュリティ保護のため、先ほどお送りした認証メールに記載されたURLはまもなく無効となりますので、ご了承ください。',
                `その他ご不明な点がございましたら、${process.env.REACT_APP_SUPPORTER_EMAIL} までお気軽にご連絡ください。`,
              ].map((item) => (
                <li>
                  <BodyTextIons text={item} bold="light" size="extra-small" />
                </li>
              ))}
            </ul>
            <RoundPurpleButtonIons
              width={'200px'}
              onClick={PageNavigation.goToHomePage}
            >
              トップページへ
            </RoundPurpleButtonIons>
          </EnTitleSection>
        </section>
      </div>
    );
  },
);

export default UpdatePasswordSuccessTemplate;
