import { GET_IDEA_MAX_LIMIT } from '../../../../../constants/max';
import { useGetIdeas } from '../../../../../hooks/api/idea/useGetIdeas';
import { useGetRequests } from '../../../../../hooks/api/request/useGetRequests';
import { useGetFollowedUsers } from '../../../../../hooks/api/user/useGetFollowedUsers';
import { useGetFollowers } from '../../../../../hooks/api/user/useGetFollowers';
import { UserLimitedType } from '../../../../../types/api/user/UserTypes';
import {
  OthersCorpPageModeQuery,
  OthersPageModeQuery,
} from '../../../../../types/query/UserPageQuery';
import {
  MyPageItemsInterface,
  MyPageNumOfItemsInterface,
  MyPageQueryKeyInterface,
} from '../../my/hooks/useMyPageData.hooks';

export const useOthersPageData = (input: {
  user?: UserLimitedType;
  userId: string | null;
  mode: OthersPageModeQuery | OthersCorpPageModeQuery;
  page: number;
}): {
  items: MyPageItemsInterface;
  numOfItems: MyPageNumOfItemsInterface;
  queryKey: MyPageQueryKeyInterface;
} => {
  // ユーザーのアイデアを取得
  const { data: userIdeasResult, queryKey: usersQueryKey } = useGetIdeas({
    enabled: input.user !== undefined && input.mode === 'ideas',
    userId: input.userId || undefined,
    comment: true,
    view: true,
    like: true,
  });

  // ユーザーのお題を取得
  const { data: userRequests, queryKey: userRequestsQueryKey } = useGetRequests(
    {
      enabled: input.user !== undefined && input.mode === 'requests',
      userId: input.user?.userId || undefined,
      limit: GET_IDEA_MAX_LIMIT,
    },
  );

  // ユーザーのフォロワー情報を取得
  const { data: followers, queryKey: followersQueryKey } = useGetFollowers(
    input.user?.userId,
  );
  const { data: followedUsers, queryKey: followedUsersQueryKey } =
    useGetFollowedUsers(input.user?.userId);

  const items: MyPageItemsInterface = {
    followers: followers || [],
    followedUsers: followedUsers || [],
    userIdeas: userIdeasResult?.ideas || [],
    userRequests: userRequests || [],
    userPurchases: [],
    rooms: [],
    room: null,
    chats: [],
  };

  const numOfItems: MyPageNumOfItemsInterface = {
    followers: followers?.length || 0,
    followedUsers: followedUsers?.length || 0,
    userIdeas: userIdeasResult?.numOfAllIdeas || 0,
    userRequests: userRequests?.length || 0,
    userPurchases: 0,
    rooms: 0,
    chats: 0,
  };

  const queryKey: MyPageQueryKeyInterface = {
    followers: followersQueryKey,
    followedUsers: followedUsersQueryKey,
    userIdeas: usersQueryKey,
    userRequests: userRequestsQueryKey,
    userPurchases: '',
    room: '',
    rooms: '',
    chats: '',
  };

  return { items, numOfItems, queryKey };
};
