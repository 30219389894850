import React from 'react';
import './Button.Atoms.css';
import { ButtonAtomsProps } from './Button.type';

// 引数の値が変わらない限り再レンダリングされない
// ただし、再レンダリングの度に関数(onClick)は新しく生成されてしまう。そのため、必ずuseCallbackでラップすること
const ButtonAtoms: React.FC<ButtonAtomsProps> = React.memo(
  ({
    children,
    onClick,
    className,
    disabled = false,
    size,
    width,
    color,
    height,
    padding,
  }) => {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`button-atoms ${size} ${color} ${className}`}
        style={{ width, padding, height }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}
        >
          {children}
        </div>
      </button>
    );
  },
);

export default ButtonAtoms;
