import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getIdeaTasksAPI } from '../../../services/api/idea/task/getIdeaTasksAPI';
import { GetIdeasTasksQueryType } from '../../../services/api/idea/task/getIdeaTasksAPI.type';
import { IdeaTaskType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeaTasksResult = UseQueryResult<IdeaTaskType[]> & {
  queryKey: any;
};
export const useGetIdeaTasks = (
  query?: GetIdeasTasksQueryType & {
    enabled?: boolean;
  },
): UseGetIdeaTasksResult => {
  const queryKey = ['idea-tasks', query];
  const result = useQuery<IdeaTaskType[]>({
    queryKey: queryKey,
    enabled: query?.enabled,
    queryFn: () => getIdeaTasksAPI(query),
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeaTasksResult;
};
