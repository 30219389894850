import './SpecifiedCommercialTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const SpecifiedCommercialTemplate: React.FC<HomeTemplateProps> = (props) => {
  return (
    <main className="commercial-transaction-container">
      <section
        className={`commercial-transaction-content ${props.size === 'sp' ? 'sp' : ''}`}
      >
        <h1 className="commercial-transaction-title">
          特定商法取引に基づく記載/運営会社
        </h1>

        <div className="commercial-transaction-table">
          <table>
            <tbody>
              <tr>
                <th className="table-header">事業者名</th>
                <td className="table-data">株式会社RE-IDEA</td>
              </tr>

              <tr>
                <th className="table-header">運営統括責任者</th>
                <td className="table-data">代表取締役 中井涼祐</td>
              </tr>

              <tr>
                <th className="table-header">住所</th>
                <td className="table-data">
                  〒151-0051 東京都渋谷区千駄ヶ谷1-30-10-4F
                </td>
              </tr>

              <tr>
                <th className="table-header">お問い合わせ先</th>
                <td className="table-data">
                  TEL：070-1436-0803
                  <br />
                  Mail：info@re-idea.jp
                </td>
              </tr>

              <tr>
                <th className="table-header">設立</th>
                <td className="table-data">2024年5月31日</td>
              </tr>

              <tr>
                <th className="table-header">資本金</th>
                <td className="table-data">3,000,000円</td>
              </tr>

              <tr>
                <th className="table-header">ご利用料金</th>
                <td className="table-data">
                  各種IDEA詳細ページをご覧ください。
                </td>
              </tr>

              <tr>
                <th className="table-header">引き渡し</th>
                <td className="table-data">募集期間終了日以降</td>
              </tr>

              <tr>
                <th className="table-header">その他お客様の負担する費用</th>
                <td className="table-data">
                  ・インターネット利用のために必要となる通信料及び接続料（金額は、お客様が契約した各事業者にお問い合わせください。）
                  <br />
                  ・購入時の決済手数料 0 %〜10 %<br />
                  <br />
                  返品・キャンセルについて：製品の性質上、原則、商品の返品・キャンセルはお受けしておりません。
                </td>
              </tr>

              <tr>
                <th className="table-header">事業内容</th>
                <td className="table-data">
                  ・「IDEA実現→テストマーケティング→販売」までを一括で行うクラウドファンディングサイト「RE-IDEA」を中心とした各種支援サービスの運営
                  <br />
                  ・クラウドファンディング実行における各種コンサルティング業務
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </main>
  );
};

export default SpecifiedCommercialTemplate;
