import React from 'react';
import useSetQuery from '../../../../hooks/useQuery';
import { SelectorOption } from '../../../atoms/selector/Selector.type';
import DefaultSelectorIons from '../../../ions/selector/DefaultSelector.Ions';

interface PublicSelectorProps {
  isPublic?: boolean;
}

const PublicSelector: React.FC<PublicSelectorProps> = (props) => {
  const { setIsPublicQuery } = useSetQuery();
  return (
    <DefaultSelectorIons
      minWidth="100px"
      options={[
        { label: '公開中', value: 'public' },
        { label: '非公開', value: 'private' },
      ]}
      value={
        props.isPublic === undefined
          ? 'public'
          : props.isPublic
            ? 'public'
            : 'private'
      }
      onChange={(option: SelectorOption) => {
        if (option.value === 'public') {
          setIsPublicQuery(true);
          return;
        } else {
          setIsPublicQuery(false);
          return;
        }
      }}
    />
  );
};

export default PublicSelector;
