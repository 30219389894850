import React, { useRef } from 'react';
import IdeaImgIons from '../../ions/img/IdeaImg.Ions';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import { BodySubTextIons } from '../../ions/text/body/BodySubText.Ions';
import './ImageUploadForm.css';
import { useImageUpload } from './ImageUploadForm.hooks';

interface ImageUploadFormProps {
  imgFile: File | null;
  imgSrc: string | null;
  error: string | null;
  loading?: boolean;
  onImageUpload: (file: File | null) => void;
}

// 画像がfileかstringで渡されることを想定
// 画像がnullでない場合は、画像を表示する。nullの時は、ドラッグ&ドロップエリアを表示する
export const ImageUploadForm: React.FC<ImageUploadFormProps> = (props) => {
  const {
    isDragging,
    onDragOver,
    onDragLeave,
    onDrop,
    onFileSelect,
    onDelete,
  } = useImageUpload(props.onImageUpload);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  return (
    <div className="upload-container">
      <div className="upload-form">
        {!props.imgSrc && !props.imgFile ? (
          <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            className={`drop-zone ${isDragging ? 'dragging' : ''} ${props.error ? 'error' : ''}`}
          >
            {props.loading === true ? (
              <>
                <BodySubTextIons
                  text={'画像を圧縮中'}
                  size="extra-small"
                  className="drop-text"
                />
                <DefaultSpinnerIons color="black" />
              </>
            ) : (
              <>
                <BodySubTextIons
                  text={'ドラッグ&ドロップで画像をアップロード'}
                  size="extra-small"
                  className="drop-text"
                />

                <BodySubTextIons
                  text={'または'}
                  size="extra-small"
                  className="drop-text"
                />

                <input
                  ref={fileInputRef}
                  type="file"
                  className="file-input"
                  accept="image/*"
                  onChange={onFileSelect}
                />
                <button onClick={handleButtonClick}>ファイルを選択</button>
              </>
            )}
          </div>
        ) : (
          <IdeaImgIons
            img_src={props.imgSrc || ''}
            imgFile={props.imgFile || undefined}
            alt="upload-image"
            onDelete={onDelete}
          />
        )}
        {props.error && (
          <div className="upload-form-error">画像を選択してください</div>
        )}
      </div>
    </div>
  );
};
