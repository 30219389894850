import React from 'react';
import useTextfieldMax from '../../../component/organisms/textfield/useTextfieldMax.hooks';
import { IDEA_COMMENT_MAX_LENGTH } from '../../../constants/max';

export interface IdeaCommentFormState {
  text: string;
}

interface IdeaCommentFormErrors {
  text: string | null;
}

interface IdeaCommentFormHandlers {
  handleTextChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleReset: () => void;
  clearStorage: () => void;
}

const STORAGE_KEY_PREFIX = 'idea_comment_form_';

export const useIdeaCommentForm = (input: {
  init?: IdeaCommentFormState;
  storageId?: string | null;
}): {
  values: IdeaCommentFormState;
  errors: IdeaCommentFormErrors;
  handlers: IdeaCommentFormHandlers;
} => {
  // ローカルストレージのキーを生成
  const storageKey = `${STORAGE_KEY_PREFIX}${input.storageId}`;

  // ローカルストレージから状態を復元する関数
  const getStoredState = (): Partial<IdeaCommentFormState> | null => {
    if (!input.storageId) return null;
    const stored = localStorage.getItem(storageKey);
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        console.error('Failed to parse stored form state:', e);
        return null;
      }
    }
    return null;
  };

  // 初期状態の設定（ローカルストレージ → input.init → デフォルト値の優先順位）
  const storedState = React.useMemo(() => getStoredState(), [storageKey]);

  // タイトルの値
  const {
    value: text,
    error: text_error,
    handleChange: handleTextChange,
  } = useTextfieldMax(storedState?.text ?? '', IDEA_COMMENT_MAX_LENGTH);

  const values: IdeaCommentFormState = {
    text,
  };

  const errors: IdeaCommentFormErrors = {
    text: text_error,
  };

  const handleReset = () => {
    handleTextChange({ target: { value: '' } } as any);
  };

  const clearStorage = () => {
    if (storageKey) {
      localStorage.removeItem(storageKey);
      // 全ての値をinitまたはデフォルト値にリセット
      handleTextChange({ target: { value: '' } } as any);
    }
  };

  // 値が変更されたときにローカルストレージに保存
  React.useEffect(() => {
    if (input.storageId) {
      // 画像ファイルデータを除外したvaluesを保存
      localStorage.setItem(storageKey, JSON.stringify(values));
    }
  }, [storageKey, values]);

  const handlers = {
    handleTextChange,
    handleReset,
    clearStorage,
  };

  return { values, errors, handlers };
};
