import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getIdeaCommentsAPI } from '../../../services/api/idea/comment/getIdeaCommentsAPI';
import { getIdeaCommentsType } from '../../../services/api/idea/comment/getIdeaCommentsAPI.type';
import { IdeaCommentType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeaCommentsResult = UseQueryResult<{
  comments: IdeaCommentType[];
  numOfAllComments: number;
}> & {
  queryKey: string[];
};

export const useGetIdeaComments = (
  query: getIdeaCommentsType & { enabled: boolean },
): UseGetIdeaCommentsResult => {
  const queryKey = ['idea-comments', query];

  const result = useQuery<{
    comments: IdeaCommentType[];
    numOfAllComments: number;
  }>({
    queryKey, // 定義した queryKey を使用
    enabled: !!query.enabled,
    queryFn: async () => {
      if (!query.ideaId) throw new Error('ideaId is required');
      return getIdeaCommentsAPI(query);
    },
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeaCommentsResult;
};
