import React from 'react';
import { useBodyScrollLock } from '../../../hooks/useBodyScrollLock';
import { useVisibilityController } from '../../../hooks/useVisibilityController';
import './Popup.Atoms.css';
import { PopupAtomsProps } from './Popup.type';

const PopupAtoms: React.FC<PopupAtomsProps> = React.memo(
  ({ open, onClose, children, size = 'medium', zIndex, className }) => {
    // アニメーションを制御するhooks
    const { isVisible, isClosing } = useVisibilityController(open, 200);
    // bodyのスクロールを制御するhooks
    useBodyScrollLock(isVisible);

    if (!isVisible) return null;

    return (
      <div
        role="overlay"
        className={`popup-atoms-overlay ${isClosing ? 'hidden' : ''}`}
        style={{ zIndex: zIndex || 100 }}
        onClick={onClose}
      >
        <div
          role="dialog"
          className={`popup-atoms ${size} ${isClosing ? 'closing' : ''} ${className}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ zIndex: zIndex || 100 }}
        >
          <button className="popup-atoms-close" onClick={onClose}>
            &times;
          </button>
          {children}
        </div>
      </div>
    );
  },
);

export default PopupAtoms;
