import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { NotificationsFilterQuery } from '../../types/query/notificationsQuery';
import { useGetNotification } from '../api/notifications/useGetNotification';
import { useGetNotifications } from '../api/notifications/useGetNotifications';

export const useNotifications = (input?: {
  notifyId?: string | null;
  filter?: NotificationsFilterQuery;
  limit?: number;
  page?: number;
}) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const { data: result } = useGetNotifications({
    enabled: isLoggedIn,
    filter: input?.filter,
    limit: input?.limit,
    page: input?.page,
  });

  const { data: notify } = useGetNotification(input?.notifyId);

  return {
    notify,
    notifications: result?.notifications || [],
  };
};
