import { useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useIdeaActions } from '../../../hooks/idea/useIdeaActions.hooks';
import { RootState } from '../../../redux/store';
import {
  IdeaPageViewQuery,
  parseIdeaViewQuery,
} from '../../../types/query/ideasQuery';
import { IdeaTemplateProps } from '../../templates/idea/IdeaTemplate.type';
import IdeaTemplateIPad from '../../templates/idea/IdeaTemplateIpad';
import IdeaTemplatePC from '../../templates/idea/IdeaTemplatePC';
import IdeaTemplateSP from '../../templates/idea/IdeaTemplateSP';
import { useIdeaData } from './hooks/useIdeaData.hooks';

const IdeaPage = () => {
  const { isXS, isSM, isNO, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const [searchParams] = useSearchParams();
  const view: IdeaPageViewQuery = parseIdeaViewQuery(searchParams.get('view'));
  const page = parseInt(searchParams.get('page') || '1', 10);

  const { id } = useParams();

  // アイデアに関する全てのデータを取得するhooks
  const {
    idea,
    ideaComments,
    numOfAllComments,
    ideaActivities,
    investments,
    suggestIdeas,
    queryKeys: { ideaKey, ideaCommentsKey, ideaActivitiesKey },
  } = useIdeaData({ ideaId: id, view: view, page: page });

  // アイデアに対する全ての動作のhooks(いいね・コメント・活動記録の作成・削除)
  const { apiLoadings, apiErrors, apiHandlers } = useIdeaActions({
    isLoggedIn,
    ideaKey,
    ideaCommentsKey,
    ideaActivitiesKey,
  });

  const commonProps: IdeaTemplateProps = {
    view,
    page,
    idea: idea || null,
    numOfAllComments,
    idea_comments: ideaComments || [],
    suggest_ideas: suggestIdeas || [],
    idea_activities: ideaActivities || [],
    investments: investments || [],
    apiHandlers,
    apiLoadings,
    apiErrors,
  };

  if (idea?.isPublic === false && idea?.isOwner === false) {
    return <Navigate to="/not-found" replace />;
  }

  if (isXS) {
    return <IdeaTemplateSP {...commonProps} />;
  } else if (isSM || isNO) {
    return (
      <IdeaTemplateIPad
        {...commonProps}
        size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
      />
    );
  } else {
    return (
      <IdeaTemplatePC
        size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
        {...commonProps}
      />
    );
  }
};

export default IdeaPage;
