import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NULL_REQUEST_DETAIL_DATA } from '../../../../constants/dummy';
import { IDEA_CATEGORIES_MAX_LENGTH } from '../../../../constants/max';
import { useGetRequest } from '../../../../hooks/api/request/useGetRequest';
import { useGetRequests } from '../../../../hooks/api/request/useGetRequests';
import { useIdeaActions } from '../../../../hooks/idea/useIdeaActions.hooks';
import { useIdeaForm } from '../../../../hooks/idea/useIdeaForm.hooks';
import { useScroll } from '../../../../hooks/useScroll';
import { useVisibilityController } from '../../../../hooks/useVisibilityController';
import { closeIdeaPostPopup } from '../../../../redux/slice/ideaPostPopupSlice';
import { RootState } from '../../../../redux/store';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import RequestCard2Molecules from '../../../molecules/card/request/RequestCard2.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';
import RequestCarousel2 from '../../../organisms/carousel/RequestCarousel2';
import CategoriesCheckboxGrid from '../../../organisms/grid/tagCheckboxGrid/CategoriesCheckboxGrid';
import AppealPointsGeneForm from '../../../organisms/textfield/form/radioForm/AppealPointsGeneForm';
import ImageGeneForm from '../../../organisms/textfield/form/radioForm/ImageGeneForm';
import SubTitleGeneForm from '../../../organisms/textfield/form/radioForm/SubTitleGeneForm';
import IdeaStoryTextfield from '../../../organisms/textfield/story/IdeaStoryTextfield';
import IdeaTitleTextfield from '../../../organisms/textfield/title/IdeaTitleTextfield';
import './IdeaPostPopup.css';

interface PopupProps {
  temp?: string;
}

const IdeaPostPopup: React.FC<PopupProps> = React.memo(() => {
  const dispatch = useDispatch();
  const { isOpen: open } = useSelector(
    (state: RootState) => state.ideaPostPopup,
  );
  const onClose = useCallback(() => {
    dispatch(closeIdeaPostPopup());
  }, [dispatch]);

  // ログイン状態の取得
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { isXS: isSP } = useSelector((state: RootState) => state.screenSize);

  // アニメーションを制御するhooks
  const { isVisible } = useVisibilityController(open, 200);
  const popupMainRef = React.useRef<HTMLDivElement>(null);
  const { scrollToTop } = useScroll({}, popupMainRef);

  // お題一覧取得
  const { data: requests } = useGetRequests({
    enabled: isVisible,
    limit: 7,
  });

  // 指定中のお題取得
  const { requestId } = useParams();
  const { data: request } = useGetRequest(requestId || null);

  // フォーム値(ローカルストレージに保存)とアイデア生成関数を取得
  const { loadings, values, errors, handlers } = useIdeaForm({
    storageId: `post${requestId ? `-${requestId}` : ''}`,
  });

  const { apiHandlers, apiErrors, apiLoadings } = useIdeaActions({
    isLoggedIn: isLoggedIn,
  });

  useEffect(() => {
    if (apiErrors.createIdea !== null) scrollToTop();
  }, [apiErrors.createIdea]);

  return (
    <>
      {apiLoadings.createIdea && <LoadingOverlaySpinner />}
      <DefaultPopupIons
        open={open}
        onClose={onClose}
        size={isSP ? 'large' : 'medium'}
        className="idea-popup-container"
        zIndex={101}
      >
        <div className="idea-popup" ref={popupMainRef}>
          <HeaderTitle
            title="Idea Form"
            subTitle="アイデアの投稿フォーム"
            titleSize="large"
          />
          <div className="idea-popup-main">
            <section className="idea-popup-main">
              <main className="idea-popup-main-container">
                {apiErrors.createIdea?.message && (
                  <ErrorCardMolecules
                    text={apiErrors.createIdea?.message}
                    isSP={isSP}
                    padding="5px 10px"
                    margin="10px 0"
                    bold="light"
                  />
                )}
                <div className="idea-popup-main-container-request-carousel">
                  <RequireAndTitle
                    require={false}
                    title={request ? '選択中のお題' : 'お題を選ぶ (未選択も可)'}
                    title_size="small"
                    hint={
                      'お題を選ぶと、主催した企業に\n直接アイデアが届きます'
                    }
                    hint_placement={
                      request
                        ? 'top-left-center'
                        : isSP
                          ? 'top-right-center'
                          : 'top-left-center'
                    }
                  />
                  {requestId && request ? (
                    <div style={{ width: isSP ? '90%' : '75%' }}>
                      <RequestCard2Molecules
                        width={'100%'}
                        isSP={isSP}
                        request={request}
                      />
                    </div>
                  ) : (
                    <RequestCarousel2
                      setRequestID={handlers.handleRequestIdChange}
                      display_link={true}
                      card_width={300}
                      slide_min_width={320}
                      requests={[NULL_REQUEST_DETAIL_DATA, ...(requests || [])]}
                      otherTransparent={true}
                      playOnInit={false}
                      isBanner={isSP}
                      prevAndNextButton={!isSP}
                    />
                  )}
                </div>

                <IdeaTitleTextfield
                  require={true}
                  title={values.title}
                  error={errors.title}
                  handleChange={handlers.handleTitleChange}
                  isSP={isSP}
                />
                <IdeaStoryTextfield
                  require={true}
                  story={values.story}
                  error={errors.story}
                  handleChange={handlers.handleStoryChange}
                  isSP={isSP}
                />

                <CategoriesCheckboxGrid
                  require={true}
                  max={IDEA_CATEGORIES_MAX_LENGTH}
                  columns={isSP ? 3 : 4}
                  categoryIds={values.categoryIds}
                  error={errors.categories}
                  handleChange={handlers.handleCategoriesChange}
                  isSP={isSP}
                />
                <ImageGeneForm
                  require={true}
                  text_size={isSP ? 'extra-small' : 'extra-small'}
                  generate={values.generateImg}
                  handleChangeGenerate={handlers.handleChangeGenerateImg}
                  handleImgSrcChange={handlers.handleImgSrcChange}
                  imgSrc={values.imgSrc}
                  imgFile={values.img}
                  loading={loadings.img}
                  error={null}
                  handleImgFileChange={handlers.handleImgFileChange}
                  isSP={isSP}
                />

                <SubTitleGeneForm
                  generate={values.generateSubTitle}
                  subTitle={values.subTitle}
                  require={false}
                  handleChangeGenerate={handlers.handleChangeGenerateSubTitle}
                  handleSubTitleChange={handlers.handleSubTitleChange}
                  error={errors.subTitle}
                  isSP={isSP}
                />

                <AppealPointsGeneForm
                  generate={values.generateAppealPoints}
                  require={false}
                  handleChangeGenerate={
                    handlers.handleChangeGenerateAppealPoints
                  }
                  appealPoint1={values.appealPoint1}
                  appealPoint2={values.appealPoint2}
                  appealPoint3={values.appealPoint3}
                  handleAppealPoint1Change={handlers.handleAppealPoint1Change}
                  handleAppealPoint2Change={handlers.handleAppealPoint2Change}
                  handleAppealPoint3Change={handlers.handleAppealPoint3Change}
                  error1={errors.appealPoint1}
                  error2={errors.appealPoint2}
                  error3={errors.appealPoint3}
                  isSP={isSP}
                />
              </main>
            </section>

            <div className="form-submit-button">
              <RoundPurpleButtonIons
                width="75%"
                onClick={() => {
                  apiHandlers.handleCreateIdea({
                    task: {
                      requestId: requestId || values.requestId,
                      title: values.title,
                      description: values.story,
                      subTitle: values.subTitle || undefined,
                      appealPoints: [
                        values.appealPoint1,
                        values.appealPoint2,
                        values.appealPoint3,
                      ].filter(
                        (point): point is string =>
                          point !== null && point !== '',
                      ),
                      img: values.img,
                      generateImg: values.generateImg,
                      generateSubTitle: values.generateSubTitle,
                      generateAppealPoints: values.generateAppealPoints,
                      categoryIds: values.categoryIds,
                    },
                    onSuccess: () => {
                      handlers.clearStorage();
                      window.location.reload();
                    },
                  });
                }}
                size="normal"
              >
                投稿する
              </RoundPurpleButtonIons>
            </div>
          </div>
        </div>
      </DefaultPopupIons>
    </>
  );
});

export default IdeaPostPopup;
