import React from 'react';
import { NotifyType } from '../../../../types/api/notifications/NotificationType';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './MiniNotifyCard.Molecules.css';

type NotifyCardMoleculesProps = {
  isSP: boolean;
  notifyId: string;
  type: NotifyType;
  title: string;
  description: string;
  isRead: boolean;
  onClick?: () => void;
};

export const MiniNotifyCardMolecules: React.FC<NotifyCardMoleculesProps> =
  React.memo((props) => {
    return (
      <div
        className={`mini-notify-card-container ${props.isRead ? 'read' : ''}`}
        onClick={() => PageNavigation.goToNotificationPage(props.notifyId)}
      >
        <div className={`mini-notify-card-titles ${props.isSP ? '' : 'pc'}`}>
          <BodyTextIons
            text={
              props.type === 'chat.unread'
                ? '新着メッセージ'
                : props.type === 'idea.comment'
                  ? 'アイデアに新着コメント'
                  : props.type === 'idea.comment-reply'
                    ? 'コメントへの新しい返信'
                    : props.type === 'idea.apply-request'
                      ? '商品化申請'
                      : props.type === 'idea.applied'
                        ? '商品化確定'
                        : '通知'
            }
            rows={1}
            size={'extra-small'}
            className={`mini-notify-card-title ${props.type}`}
            bold="light"
          />
          <BodySubTextIons
            text={`${props.description}`}
            rows={2}
            size={'extra-small'}
            className="mini-notify-card-text"
          />
        </div>
      </div>
    );
  });

export default MiniNotifyCardMolecules;
