import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import { TextBoldType } from '../../../atoms/text/Text.type';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './ErrorCard.Molecules.css';

type NotifyCardMoleculesProps = {
  isSP: boolean;
  text: string;
  padding?: string;
  margin?: string;
  bold?: TextBoldType;
};

export const ErrorCardMolecules: React.FC<NotifyCardMoleculesProps> =
  React.memo((props) => {
    return (
      <div
        className={`error-card-container`}
        style={{ padding: props.padding, margin: props.margin }}
      >
        <ErrorIcon className="error-card-icon" />
        <BodyTextIons
          text={props.text}
          color="red"
          size={'extra-small'}
          bold={props.bold || 'bold'}
        />
      </div>
    );
  });

export default ErrorCardMolecules;
