import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import IdeaCardGrid from '../grid/IdeaCardGrid/IdeaCardGrid';
import './RequestResult.css';

interface TagsCheckboxGridProps {
  isSP: boolean;
  ideas: IdeaCardType[];
  page?: number;
  maxPage?: number;
}

const RequestResult: React.FC<TagsCheckboxGridProps> = (props) => {
  const { ideas, isSP } = props;
  return (
    <div className="request-result">
      {ideas.length === 0 ? (
        <div className="request-result-no-item">
          <h4 className="request-result-no-item-text">
            まだアイデアが採用されていません
          </h4>
        </div>
      ) : (
        // <div className={`request-result-idea ${props.isSP ? 'sp' : ''}`}>
        //   <IdeaImgIons img_src={idea.imgSrc} alt={idea.title} isAdopted />
        //   <BodyTextBoldIons text={idea.title} size="normal" />
        //   <BodyTextIons text={idea.description} size="small" bold="light" />
        // </div>
        <div style={{ width: '100%', padding: isSP ? '10px 5px' : '20px' }}>
          <IdeaCardGrid
            isSP={isSP}
            card_text_size={isSP ? 'extra-small' : 'small'}
            ideas={props.ideas}
            columns={isSP ? 2 : 3}
            page={props.page || 1}
            maxPage={props.maxPage || 1}
          />
        </div>
      )}
    </div>
  );
};

export default RequestResult;
