import { InvestmentType } from '../../../types/api/investment/InvestmentTypes';
import { getInvestmentsType } from './getInvestmentsAPI.type';

// アイデアの出資商品を取得するAPI
export const getInvestmentsAPI = async (
  input: getInvestmentsType,
): Promise<InvestmentType[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/investments?ideaId=${input.ideaId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
