import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { InvestmentType } from '../../../../types/api/investment/InvestmentTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { TextSizeType } from '../../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import { ReturnCardMolecules } from '../../../molecules/card/return/ReturnCard.Molecules';
import UserIconAndName from '../../../molecules/user/UserIconAndName';
import './IdeaUserAndReturns.css';

interface TagsCheckboxGridProps {
  idea: IdeaDetailType;
  title_size?: TextSizeType;
  investments: InvestmentType[];
}

const IdeaUserAndReturns: React.FC<TagsCheckboxGridProps> = (props) => {
  const { idea } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <section className="idea-user-and-returns-wrapper">
      <div className="idea-user-and-returns-cell">
        <BlackTitleTextIons
          text={
            idea.request ? 'アイデアの採用について' : 'アイデアの商品化について'
          }
          size={props.title_size}
        />
        <div className="idea-user-cell">
          <BodyTextIons
            text={
              (idea.request ? 'アイデアの採用' : 'アイデアの商品化') +
              'を決定するとクラウドファンディングで支援者を募ることができます。'
            }
            size="small"
            bold="light"
          />
          <BodyTextIons
            text={
              '共感を生むストーリーと独自の価値を武器に、新しい市場を切り拓きましょう。'
            }
            size="small"
            bold="light"
          />
          <RoundPurpleButtonIons
            onClick={() => PageNavigation.goToIdeaAdoptPage(idea.ideaId)}
            size="medium"
            disabled={
              user?.isCorp !== true ||
              idea.isAdopted === true ||
              (idea?.request
                ? idea?.request.user.userId !== user?.userId
                : false)
            }
          >
            {idea.isAdopted
              ? '商品化が決定しています'
              : idea.request !== undefined
                ? 'アイデアを採用'
                : '商品化を申請'}
          </RoundPurpleButtonIons>
        </div>
      </div>

      <div className="idea-user-and-returns-cell">
        <BlackTitleTextIons text="アイデアの投稿者" size={props.title_size} />
        <div className="idea-user-cell">
          <UserIconAndName
            userName={idea.user.userName}
            user_img_src={idea.user.imgSrc}
            icon_size="medium"
            userId={idea.user.userId}
          />
          <BodyTextIons text={idea.user.introduction || ''} size="small" />
          <RoundWhiteButtonIons
            onClick={() => {
              idea.user.userId === user?.userId
                ? PageNavigation.goToMyPage()
                : PageNavigation.goToOtherUserPage(idea.user.userId);
            }}
            size="medium"
          >
            詳細を見る
          </RoundWhiteButtonIons>
        </div>
      </div>

      <div className="idea-user-and-returns-cell">
        <BlackTitleTextIons text="リターン" size={props.title_size} />
        {props.investments.map((investment, index) => (
          <ReturnCardMolecules
            key={index + 1}
            stage={index + 1}
            investment={investment}
            ideaId={props.idea.ideaId}
            isAdopted={props.idea.isAdopted}
          />
        ))}
      </div>
    </section>
  );
};

export default IdeaUserAndReturns;
