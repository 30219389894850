import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import PopoverAtoms from '../../atoms/popover/Popover.Atoms';
import { PopoverAtomsProps } from '../../atoms/popover/Popover.type';
import './TooltipPopover.Ions.css';

interface TooltipIonsProps
  extends Omit<PopoverAtomsProps, 'children' | 'content'> {
  text: string;
  title?: string;
  iconSize?: 'small' | 'medium' | 'large';
}

const TooltipPopoverIons: React.FC<TooltipIonsProps> = React.memo(
  ({ text, title, iconSize = 'medium', ...props }) => {
    const iconSizes = {
      small: '16px',
      medium: '20px',
      large: '24px',
    };

    return (
      <PopoverAtoms
        {...props}
        trigger="hover"
        children={
          <HelpOutlineIcon
            className="tooltip-icon"
            style={{ fontSize: iconSizes[iconSize] }}
          />
        }
        content={
          <div className="tooltip-content">
            {title && <h4 className="tooltip-title">{title}</h4>}
            <p className="tooltip-text">{text}</p>
          </div>
        }
      />
    );
  },
);

export default TooltipPopoverIons;
