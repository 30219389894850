// アイデア関連情報
export const IDEA_TITLE_MAX_LENGTH = 30;
export const IDEA_STORY_MAX_LENGTH = 1000;
export const IDEA_CATEGORIES_MAX_LENGTH = 3;
export const IDEA_SUBTITLE_MAX_LENGTH = 50;
export const IDEA_APEALPOINT_MAX_LENGTH = 30;

export const IDEA_COMMENT_MAX_LENGTH = 1000;
export const GET_IDEA_COMMENT_MAX_LIMIT = 10; // アイデアコメントの最大取得個数(親)
export const IDEA_MAX_POINT = 50;
export const IDEA_CALCULATION_FORMULA_TEXT =
  'PV数 + コメント数 × 3 + いいね数 × 5';

export const IDEA_ACTIVITY_TITLE_MAX_LENGTH = 50;
export const IDEA_ACTIVITY_DESCRIPTION_MAX_LENGTH = 500;

// ユーザー関連情報
export const USER_INTRODUCTION_MAX_LENGTH = 500;
export const USER_NAME_MAX_LENGTH = 50;
export const MAIL_ADDRESS_MAX_LENGTH = 254;
export const CORP_NAME_MAX_LENGTH = 150;
export const ZIPCODE_MAX_LENGTH = 8;
export const PHONE_NUMBER_MAX_LENGTH = 14;
export const ADDRESS_MAX_LENGTH = 50;

// お問い合わせ関連情報
export const CONTACT_USERNAME_MAX_LENGTH = 50;
export const CONTACT_DETAIL_MAX_LENGTH = 500;

export const NOTIFICATIONS_DISPLAY_LENGTH = 8;

// アイデアの最大取得個数(limit)
export const GET_IDEA_MAX_LIMIT = 18;

// 投稿者への売り上げ還元率
export const REWARD_RATIO = 0.02;
// 出資総額の受領金額の割合
export const RECEIVED_INVEST_RATIO = 0.9;

// チャット表示数
export const CHAT_DISPLAY_LIMIT = 50;
