import React from 'react';
import { NotifyCardType } from '../../../types/api/notifications/NotificationType';
import { formatDateToJapanese } from '../../../utils/timeCalculator';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import NotifyDetailCardMolecules from '../../molecules/card/notify/NotifyDetailCard.Molecules';
import HeaderTitle from '../../molecules/text/headerTitle/HeaderTitle';
import './NotifyTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  notify: NotifyCardType | undefined;
}

const NotifyTemplate: React.FC<HomeTemplateProps> = (props) => {
  const { size, notify } = props;
  let width: string;

  switch (size) {
    case 'sp':
      width = '100%';
      break;
    case 'small':
      width = '60%';
      break;
    case 'medium':
      width = '45%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  if (!notify)
    return (
      <div className={`notify-template-null`}>
        <DefaultSpinnerIons color="grey" />
      </div>
    );

  return (
    <div className="notify-template">
      <main className="notify-template-main" style={{ width: width }}>
        <HeaderTitle
          title="Notification"
          subTitle="通知詳細"
          titleSize={'large'}
          className="notify-template-header"
        />
        <NotifyDetailCardMolecules
          isSP={size === 'sp'}
          notifyId={notify.notifyId}
          isRead={notify.isRead}
          type={notify.type}
          title={notify.title}
          description={notify.description}
          date={formatDateToJapanese(notify.notifyAt, 'yyyy年M月d日 HH時mm分')}
        />
      </main>
    </div>
  );
};

export default NotifyTemplate;
