import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetIsVerifiedPassToken } from '../../../hooks/api/verify/useGetIsVerifiedPassToken';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import SettingNewPasswordTemplate from '../../templates/auth/setting/SettingNewPasswordTemplate';
import NotFoundTemplate from '../../templates/not-found/NotFoundTemplate';

const UpdatePasswordByTokenPage = () => {
  const { token } = useParams();
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // トークンを用いて認証を行う
  const { data: isVerified } = useGetIsVerifiedPassToken(token);
  const { values, handlers, errors } = useAuthForm();
  const {
    values: values2,
    handlers: handlers2,
    errors: errors2,
  } = useAuthForm();

  const {
    handlers: apiHandlers,
    errors: apiErrors,
    loadings: apiLoadings,
  } = useAuthActions({ values: values2 });

  // 認証が完了していない場合はNotFoundTemplateを表示
  if (isVerified === undefined || !token) return null;
  if (!isVerified) return <NotFoundTemplate />;

  return (
    <SettingNewPasswordTemplate
      isSP={isXS}
      result={false}
      newPassword={values.password}
      newPasswordConfirm={values2.password}
      handleChangeNewPassword={handlers.handlePasswordChange}
      handleChangeNewPasswordConfirm={handlers2.handlePasswordChange}
      newPasswordFormError={errors.password}
      newPasswordConfirmFormError={errors2.password}
      handleUpdatePassword={() =>
        apiHandlers.handleUpdatePassword({
          token,
          onSuccess: () => {
            PageNavigation.goToUpdatePasswordSuccessPage(token);
            // handlers.handleReset();
            // handlers2.handleReset();
          },
        })
      }
      apiLoading={apiLoadings.updatePassword}
      apiError={apiErrors.updatePassword}
    />
  );
};

export default UpdatePasswordByTokenPage;
