export const readAllNotificationsAPI = async (): Promise<void> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/notifications/read-all`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};
