import React, { ChangeEvent } from 'react';
import { IDEA_SUBTITLE_MAX_LENGTH } from '../../../../constants/max';
import { PopoverPlacement } from '../../../atoms/popover/Popover.type';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface IdeaSubTitleTextfieldProps {
  subtitle: string | null;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  isSP?: boolean;
  tooltipPlacement?: PopoverPlacement;
}

const IdeaSubTitleTextfield: React.FC<IdeaSubTitleTextfieldProps> = React.memo(
  (props) => {
    return (
      <DefaultTextFieldIons
        value={props.subtitle || ''}
        error={props.error}
        onChange={props.handleChange}
        width={'100%'}
        label={
          <RequireAndTitle
            require={false}
            title="サブタイトル"
            hint="アイデアをわかりやすく伝える、魅力的なサブタイトルを考えましょう"
            hint_placement={props.tooltipPlacement || 'top-left-center'}
          />
        }
        placeholder="アイデアのサブタイトルを入力してください"
        max={IDEA_SUBTITLE_MAX_LENGTH}
      />
    );
  },
);

export default IdeaSubTitleTextfield;
