import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getPurchasesAPI } from '../../services/api/investment/purchase/getPurchasesAPI';
import { PurchaseInvestementType } from '../../types/api/investment/InvestmentTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetInvestmentsResult = UseQueryResult<{
  items: PurchaseInvestementType[];
  numOfItems: number;
}> & {
  queryKey: readonly ['purchases-user'];
};

export const useGetPurchases = (
  query?: { limit?: number; page?: number } & {
    enabled?: boolean;
  },
): UseGetInvestmentsResult => {
  const queryKey = ['purchases-user'] as const;

  const result = useQuery<{
    items: PurchaseInvestementType[];
    numOfItems: number;
  }>({
    queryKey, // 定義した queryKey を使用
    enabled: !!query?.enabled,
    queryFn: () => getPurchasesAPI(query),
  });

  return {
    ...result,
    queryKey,
  } as UseGetInvestmentsResult;
};
