import { getNotificationsAPI } from '../../../services/api/notifications/getNotificationsAPI';
import { countUnreadChatAPI } from '../../../services/api/room/get/countUnreadChatAPI';
import { AppDispatch, RootState } from '../../store';
import { setNotifications, setUnreadChatCount } from '../notifySlice';

// ユーザー認証情報(セッション)を用いて、Redux ストアを更新する
export const updateNotifyThunk =
  () =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const { isLoggedIn } = getState().auth;
      if (isLoggedIn) {
        const result = await getNotificationsAPI({
          limit: 3,
          filter: 'unread',
          page: 1,
        });
        const unreadChatCount = await countUnreadChatAPI();
        await dispatch(setNotifications(result.notifications));
        await dispatch(setUnreadChatCount(unreadChatCount));
      }
      return;
    } catch (error) {
      return;
    }
  };
