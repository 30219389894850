import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ContactSuccessTemplate from '../../templates/contact/ContactSuccessTemplate';

const ContactSuccessPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  return <ContactSuccessTemplate isSP={isXS} />;
};

export default ContactSuccessPage;
