import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import SpecifiedCommercialTemplate from '../../templates/documents/SpecifiedCommercialTemplate';

const SpecifiedCommercialPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  return (
    <SpecifiedCommercialTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
    />
  );
};

export default SpecifiedCommercialPage;
