import React from 'react';
import ButtonAtoms from '../../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../../atoms/button/Button.type';
import '../ButtonFullRound.css';

const FullRoundWhiteButtonIons: React.FC<ButtonAtomsProps> = React.memo(
  (props) => {
    return (
      <ButtonAtoms
        {...props}
        color="white"
        className={'button-full-round-ions'}
      />
    );
  },
);

export default FullRoundWhiteButtonIons;
