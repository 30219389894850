import React from 'react';
import {
  AuthFormErrors,
  AuthFormHandlers,
  AuthFormState,
} from '../../../hooks/auth/useAuthForm.hooks';
import { AuthErrorType } from '../../../types/api/auth/AuthError.type';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { SNSRoundButton } from '../../ions/sns/round/SNSRoundButton';
import { BodyTextIonsClickable } from '../../ions/text/body/BodyTextClickable.Ions';
import DefaultTextFieldIons from '../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../molecules/card/error/ErrorCard.Molecules';
import HeaderTitle from '../../molecules/text/headerTitle/HeaderTitle';
import './AuthTemplate.css';

interface PopupProps {
  isSP: boolean;
  apiError: AuthErrorType | null;
  values: AuthFormState;
  formErrors: AuthFormErrors;
  formHandlers: AuthFormHandlers;
  handleRegister: () => void;
  handleGoogleSignup: () => void;
}

const RegisterTemplate: React.FC<PopupProps> = React.memo((props) => {
  const {
    isSP,
    apiError,
    handleGoogleSignup,
    handleRegister,
    values,
    formHandlers,
    formErrors,
  } = props;

  return (
    <div className={`auth-template-container ${isSP ? 'sp' : ''}`}>
      <div className={`auth-template-card ${isSP ? 'sp' : ''}`}>
        <header className={`auth-template-header `}>
          <HeaderTitle
            title="Sign up"
            subTitle="新規会員登録"
            titleSize="large"
          />
        </header>

        <main className={`auth-template-main `}>
          <form className={`auth-template-form `}>
            <DefaultTextFieldIons
              label="ユーザーネーム"
              size={isSP ? 'small' : 'medium'}
              value={values.userName}
              onChange={formHandlers.handleUserNameChange}
              error={formErrors.userName}
            />
            <DefaultTextFieldIons
              label="メールアドレス"
              size={isSP ? 'small' : 'medium'}
              value={values.email}
              type="email"
              onChange={formHandlers.handleEmailChange}
              error={formErrors.email}
            />
            <DefaultTextFieldIons
              label="パスワード"
              size={isSP ? 'small' : 'medium'}
              value={values.password}
              type="password"
              onChange={formHandlers.handlePasswordChange}
              error={formErrors.password}
            />
            {apiError?.message && (
              <ErrorCardMolecules
                text={apiError?.message}
                isSP={isSP}
                padding="5px 10px"
                margin="10px 0"
                bold="light"
              />
            )}
          </form>

          <nav className="auth-template-nav-links">
            <BodyTextIonsClickable
              text="既にアカウントをお持ちの方はこちら"
              size="extra-small"
              onClick={PageNavigation.goToSignInPage}
            />
            <BodyTextIonsClickable
              text="事業者の方はこちら"
              size="extra-small"
              onClick={PageNavigation.goToCorpSignUp}
            />
          </nav>

          <div className="auth-template-submit-button">
            <RoundPurpleButtonIons
              width={'100%'}
              size={'normal'}
              onClick={handleRegister}
            >
              新規会員登録
            </RoundPurpleButtonIons>
          </div>
        </main>

        <nav className={`auth-template-sns-buttons `}>
          {['Google'].map((type) => (
            <SNSRoundButton
              key={type}
              text="会員登録"
              type={type as any}
              size="large"
              onClick={() => {
                if (type === 'Google') {
                  handleGoogleSignup();
                }
              }}
            />
          ))}
        </nav>
      </div>
    </div>
  );
});

export default RegisterTemplate;
