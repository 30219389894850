import React from 'react';
import {
  FacebookRoundButtonAtoms,
  GoogleRoundButtonAtoms,
  InstagramRoundButtonAtoms,
  LineRoundButtonAtoms,
  XRoundButtonAtoms,
} from '../../../atoms/sns/roundButton/SNSRoundButtonAtoms';
import { SNSButtonSizeTypeAtoms } from '../../../atoms/sns/SNSButton.type';

interface SNSButtonProps {
  type: 'Facebook' | 'Google' | 'X' | 'Instagram' | 'Line';
  url?: string;
  title?: string;
  onClick: () => void;
  text?: string;
  size?: SNSButtonSizeTypeAtoms;
}

export const SNSRoundButton: React.FC<SNSButtonProps> = React.memo(
  ({ type, onClick, text, size = 'medium', title, url }) => {
    switch (type) {
      case 'Facebook':
        return (
          <FacebookRoundButtonAtoms
            url={url}
            title={title}
            onClick={onClick}
            text={type + ' で' + text}
            size={size}
          />
        );
      case 'Google':
        return (
          <GoogleRoundButtonAtoms
            url={url}
            title={title}
            onClick={onClick}
            text={type + ' で' + text}
            size={size}
          />
        );
      case 'X':
        return (
          <XRoundButtonAtoms
            url={url}
            title={title}
            onClick={onClick}
            text={type + ' で' + text}
            size={size}
          />
        );
      case 'Instagram':
        return (
          <InstagramRoundButtonAtoms
            url={url}
            title={title}
            onClick={onClick}
            text={type + ' で' + text}
            size={size}
          />
        );
      case 'Line':
        return (
          <LineRoundButtonAtoms
            url={url}
            title={title}
            onClick={onClick}
            text={'LINEで' + text}
            size={size}
          />
        );
      default:
        return null;
    }
  },
);
