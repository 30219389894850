import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { cancelPurchaseAPIProps } from '../../../../services/api/investment/purchase/cancelPurchaseAPI.type';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { InvestmentType } from '../../../../types/api/investment/InvestmentTypes';
import { PurchaseError } from '../../../../types/api/investment/purchase/PurchaseError.type';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundRedButtonIons from '../../../ions/button/round/Red/RoundRedButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../../ions/divider/DefaultDivider.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import EnTitleSection from '../../../organisms/section/EnTitleSection';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  investment: InvestmentType;
  handleDeletePurchaseInvestment: (input: cancelPurchaseAPIProps) => void;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  loading: boolean;
  cancelAPIError: PurchaseError | null;
}

const IdeaInvestDeleteConfirmTemplate: React.FC<IdeaTemplateProps> = (
  props,
) => {
  const { goBack } = usePageNavigation();
  const isSP = props.size === 'sp';
  const { size, idea, investment } = props;
  let width: string;

  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '70%';
      break;
    case 'medium':
      width = '55%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  return (
    <div className="idea-invest-confirm-template">
      {props.loading && <LoadingOverlaySpinner />}
      <main
        className="idea-invest-confirm-template-main"
        style={{ width: width }}
      >
        <HeaderTitle
          title="Invest Cancel"
          titleSize="large"
          subTitle="出資の取り消し"
        />

        {props.cancelAPIError && (
          <ErrorCardMolecules
            isSP={size === 'sp'}
            text={props.cancelAPIError.message}
          />
        )}

        <section className="idea-invest-confirm-template-main">
          <EnTitleSection
            title="Check"
            subTitle="キャンセルする出資の確認"
            isSP={isSP}
          >
            <section
              className={`idea-invest-confirm-template-main-content ${size === 'sp' ? 'sp' : ''}`}
            >
              {[
                { title: 'アイデア', value: idea.title },
                {
                  title: '出資対象',
                  value: investment.name,
                },
                { title: '出資金額', value: `${investment.price}円` },
                {
                  title: '決済情報',
                  value: `カード種別: VISA\n名義人: TOTORO TOTORO\nカード有効期限: 01/23\nカード番号: ****-****-****-1234`,
                },
              ].map((item, index) => (
                <>
                  <div className="idea-invest-confirm-template-main-content-texts-money">
                    <BodyTextIons
                      text={item.title}
                      size={'small'}
                      bold="light"
                      width={'20%'}
                    />
                    <BodyTextIons
                      text={`${item.value}`}
                      size={'small'}
                      bold="light"
                      width={'60%'}
                      className="idea-invest-confirm-template-main-content-texts-money-value"
                    />
                  </div>
                  {index !== 3 && (
                    <DefaultDividerIons width={'100%'} margin={0} />
                  )}
                </>
              ))}
            </section>
          </EnTitleSection>
        </section>

        <section className="idea-invest-confirm-template-buttons">
          <RoundRedButtonIons
            onClick={() =>
              props.handleDeletePurchaseInvestment({
                purchase: {
                  investmentId: investment.investmentId,
                },
                onSuccess: () => {
                  PageNavigation.goToIdeaPage(idea.ideaId);
                },
              })
            }
            size="medium"
            disabled={props.loading}
          >
            出資を取り消す
          </RoundRedButtonIons>
          <RoundWhiteButtonIons onClick={() => goBack()} size="medium">
            戻る
          </RoundWhiteButtonIons>
        </section>
      </main>
    </div>
  );
};

export default IdeaInvestDeleteConfirmTemplate;
