import React from 'react';
import ImgAtoms from '../../../atoms/img/Img.Atoms';
import './IconImg.Ions.css';

interface ImgProps {
  userName: string;
  img_src: string | null;
  imgFile?: File | null;
  alt?: string;
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
  className?: string;
  onClick?: () => void;
  onDelete?: () => void;
}

const UserIconImgIons: React.FC<ImgProps> = React.memo(
  ({
    userName,
    img_src,
    imgFile,
    alt,
    size = 'medium',
    className,
    onClick,
    onDelete,
  }) => {
    return img_src || imgFile ? (
      <ImgAtoms
        img_src={process.env.REACT_APP_IMAGES_URL + (img_src || '')}
        imgFile={imgFile}
        alt={alt}
        className={`icon-img-ions ${size} ${className}`}
        onClick={onClick}
        onDelete={onDelete}
      />
    ) : (
      <div
        className={`icon-img-ions ${size} user-icon-text-ions ${className || ''}`}
        onClick={onClick}
      >
        {userName ? userName[0] : ''}
      </div>
    );
  },
);

export default UserIconImgIons;
