import React from 'react';
import {
  AuthAPIErrors,
  AuthAPILoadingState,
} from '../../../../hooks/auth/useAuthActions.hooks';
import {
  AuthFormErrors,
  AuthFormHandlers,
  AuthFormState,
} from '../../../../hooks/auth/useAuthForm.hooks';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import './SettingTemplate.css';

interface PopupProps {
  isSP: boolean;
  result: boolean;
  values: AuthFormState;
  formHandlers: AuthFormHandlers;
  formErrors: AuthFormErrors;
  apiErrors: AuthAPIErrors;
  apiLoadings: AuthAPILoadingState;
  handleUpdatePassword: () => void;
}

const SettingPasswordTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP, values, formHandlers, formErrors } = props;
  const apiError = props.apiErrors.resetPassword;
  const apiLoading = props.apiLoadings.resetPassword;

  return (
    <div className={`setting-template-container `}>
      {apiLoading && <LoadingOverlaySpinner />}
      <header className={`setting-template-header `}>
        <HeaderTitle
          title="Setting Password"
          subTitle="パスワードの再設定"
          titleSize="large"
        />
      </header>

      <main className={`setting-template-main ${isSP ? 'sp' : ''}`}>
        <form className={`setting-template-form `}>
          {props.result && (
            <div className="setting-template-success">
              {
                '※ ご入力頂いたメールアドレスにパスワード変更用のURLを送りました。メール内のリンクをクリックして、パスワードの変更を完了してください。'
              }
            </div>
          )}
          <DefaultTextFieldIons
            label="アカウントのメールアドレス"
            size={isSP ? 'small' : 'medium'}
            value={values.email}
            onChange={formHandlers.handleEmailChange}
            error={formErrors.email}
            width={'100%'}
          />
          <BodyTextIons
            text="※ パスワード再設定用のメールをお送りするため、アカウントに登録されているメールアドレスを入力してください。"
            bold="light"
            size="extra-small"
          />
          {apiError?.message && (
            <ErrorCardMolecules
              text={apiError?.message}
              isSP={isSP}
              padding="5px 10px"
              margin="10px 0"
              bold="light"
            />
          )}
        </form>

        <div className="setting-template-submit-buttons">
          <RoundPurpleButtonIons
            width={'60%'}
            size={'normal'}
            onClick={props.handleUpdatePassword}
          >
            送信
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            width={'60%'}
            size={'normal'}
            onClick={PageNavigation.goBack}
          >
            戻る
          </RoundWhiteButtonIons>
        </div>
      </main>
    </div>
  );
});

export default SettingPasswordTemplate;
