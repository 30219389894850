import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAdoptedIdeaInReqAPI } from '../../../services/api/idea/get/getAdoptedIdeaInReqAPI';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetIdeaResult = UseQueryResult<IdeaCardType[]> & {
  queryKey: string[];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetAdoptedIdeaInReq = (
  requestId?: string,
): UseGetIdeaResult => {
  const queryKey = ['idea-adopted-in-req', requestId];

  const result = useQuery<IdeaCardType[]>({
    queryKey: ['idea', requestId], // sortの値も含めてキャッシュを管理
    enabled: !!requestId,
    queryFn: async () => {
      if (!requestId) throw new Error('requestId is required');
      return getAdoptedIdeaInReqAPI(requestId);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetIdeaResult;
};
