import { PurchaseInvestementType } from '../../../../types/api/investment/InvestmentTypes';

// アイデアの出資商品を取得するAPI
export const getPurchasesAPI = async (query?: {
  limit?: number;
  page?: number;
}): Promise<{
  items: PurchaseInvestementType[];
  numOfItems: number;
}> => {
  const params = new URLSearchParams();

  // デフォルト値とクエリパラメータの設定
  params.set('limit', String(query?.limit || 25));

  // オプショナルなパラメータを追加
  if (query?.page) params.set('page', String(query.page));

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/purchases?${params.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
