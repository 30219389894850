import { useCallback, useEffect, useState } from 'react';

interface UseTextValuesProps {
  values: number[];
  error: string | null;
  handleChange: (newValue: number) => void;
  handleAllChange: (values: number[]) => void;
}

const useNumValuesMax = (init?: number[], max?: number): UseTextValuesProps => {
  const [values, setValues] = useState<number[]>(init || []);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(init || [])) {
      setValues(init || []);
    }
  }, [init]);

  const handleChange = useCallback(
    (newValue: number) => {
      setValues((prevValues) => {
        // prevValuesを使って判定を行う
        if (prevValues.includes(newValue)) {
          setError(null);
          return prevValues.filter((v: number) => newValue !== v);
        } else {
          if (max && prevValues.length >= max) {
            setError('既に最大数に達しています');
            return prevValues; // 変更なし
          } else {
            setError(null);
            return [...prevValues, newValue];
          }
        }
      });
    },
    [max],
  ); // maxだけを依存配列に入れる

  // 全て上書きする関数を定義
  const handleAllChange = useCallback((values: number[]) => {
    setValues(values);
    setError(null);
  }, []);

  return { values, error, handleChange, handleAllChange };
};

export default useNumValuesMax;
