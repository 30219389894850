import React from 'react';
import { RequestCardType } from '../../../../types/api/request/RequestTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import CircleRainbowButtonIons from '../../../ions/button/circle/rainbow/CircleRainbowButton.Ions';
import RequestImgIons from '../../../ions/img/RequestImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './RequestCard.Molecules.css';

type PurchaseCardMoleculesProps = {
  isSP: boolean;
  request: RequestCardType;
  width: number | string;
  hover_effect?: boolean;
  banner?: boolean;
};

export const RequestCardMolecules: React.FC<PurchaseCardMoleculesProps> =
  React.memo((props) => {
    const { request } = props;
    return (
      <div
        className={`request-card-container  ${props.hover_effect ? 'hover-effect' : ''}`}
        style={{ width: props.width }}
        onClick={() =>
          request.requestId && PageNavigation.goToRequestPage(request.requestId)
        }
      >
        <RequestImgIons img_src={request.imgSrc} alt={request.title} />
        {request.requestId && props.banner !== false && (
          <>
            <div
              className={`request-card-bottom-texts ${props.isSP ? 'sp' : ''}`}
            >
              <BodyTextIons
                text={request.title}
                color="white"
                size={props.isSP ? 'extra-small' : 'small'}
                bold="bold"
                rows={2}
              />
              <BodyTextIons
                text={request.user.userName}
                color="white"
                size="extra-small"
                rows={1}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
              }}
            >
              <CircleRainbowButtonIons
                onClick={() =>
                  props.request.requestId &&
                  PageNavigation.goToRequestPage(props.request.requestId)
                }
              >
                <BodyTextIons
                  text={'→'}
                  color="grey"
                  size="medium"
                  bold="bold"
                  rows={1}
                  className="request-card-button-text"
                />
              </CircleRainbowButtonIons>
            </div>
          </>
        )}
      </div>
    );
  });

export default RequestCardMolecules;
