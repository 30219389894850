import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import SettingPasswordTemplate from '../../templates/auth/setting/SettingPasswordTemplate';

const SettingPasswordPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);
  const [result, setResult] = useState<boolean>(false);

  const { values, handlers: formHandlers, errors: formErrors } = useAuthForm();
  const {
    handlers: apiHandlers,
    errors: apiErrors,
    loadings: apiLoadings,
  } = useAuthActions({
    values,
  });

  return (
    <SettingPasswordTemplate
      isSP={isXS}
      result={result}
      values={values}
      formHandlers={formHandlers}
      apiErrors={apiErrors}
      apiLoadings={apiLoadings}
      formErrors={formErrors}
      handleUpdatePassword={() =>
        apiHandlers.handleResetPassword({
          onSuccess: () => setResult(true),
          onError: () => setResult(false),
        })
      }
    />
  );
};

export default SettingPasswordPage;
