import React from 'react';
import ImgAtoms from '../../atoms/img/Img.Atoms';
import { ImgAtomsProps } from '../../atoms/img/Img.type';
import './Img.Ions.css';

interface UserBannerImgIonsProps extends ImgAtomsProps {
  onDelete?: () => void;
  isAdopted?: boolean;
}

const UserBannerImgIons: React.FC<UserBannerImgIonsProps> = React.memo(
  (props) => {
    return (
      <ImgAtoms
        {...props}
        img_src={process.env.REACT_APP_IMAGES_URL + props.img_src}
        className={`user-banner-img-ions ${props.className}`}
      />
    );
  },
);

export default UserBannerImgIons;
