import React from 'react';
import { useDispatch } from 'react-redux';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import RoundButtonIons from '../../ions/button/round/RoundButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import StepNavigation from '../../organisms/navTexts/StepNavigation';
import EnTitleSection from '../../organisms/section/EnTitleSection';
import './HowToUseTemplate.css';

interface PopupProps {
  message?: string;
  isSP: boolean;
}

// メール認証完了後の遷移ページ
const HowToUseTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP } = props;
  const dispatch = useDispatch();
  const { goToHomePage } = usePageNavigation();
  return (
    <div className={`how-to-use-template-container ${isSP ? 'sp' : ''}`}>
      <div className={`how-to-use-template ${isSP ? 'sp' : ''}`}>
        {props.message && (
          <BodyTextIons
            size="small"
            text={props.message}
            className="how-to-use-template-title"
          />
        )}
        <header className="how-to-use-template-header">
          <HeaderTitleSakairi
            title="How To Use"
            subTitle="RE-IDEAの使い方"
            titleSize="medium"
          />
        </header>
        <StepNavigation
          steps={['アイデアを投稿', '商品化が決定', '収益をゲット']}
          currentStep={3}
        />

        <section className="how-to-use-template-main">
          <EnTitleSection
            title="Post Your Idea"
            subTitle="① アイデアを投稿してみよう"
            isSP={isSP}
          >
            <section className="how-to-use-template-main-content">
              <ul className="how-to-use-template-list">
                {[
                  '下記のボタンからアイデアを投稿！あなたのアイデアをシェアしてみましょう。',
                  'あなたの思い描いているアイデアを画像化すると、アイデアにいいねや出資がされやすくなり、実現への可能性が高まります。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>

              <RoundButtonIons
                onClick={() => {
                  goToHomePage();
                  dispatch(openIdeaPostPopup());
                }}
                padding={'10px 20px'}
                width={'90%'}
                className="how-to-use-template-button"
              >
                <BodyTextIons
                  text="アイデアを投稿してみる"
                  size="small"
                  bold="bold"
                  color="white"
                />
              </RoundButtonIons>
            </section>
          </EnTitleSection>

          <EnTitleSection
            title="Idea Adopted"
            subTitle="② 商品化が決定する"
            isSP={isSP}
          >
            <section className="how-to-use-template-main-content">
              <ul className="how-to-use-template-list">
                {[
                  'アイデアが事業者の目に留まり、興味を持たれると、事業者と協力してあなたのアイデアを実際に商品化することができます。',
                  '事業者と公平なやり取りができるよう、IDEA工房運営メンバーと、三者で話し合いながら商品化をしていきます。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>
            </section>
          </EnTitleSection>

          <EnTitleSection
            title="Get Your Fund"
            subTitle="③ 収益をゲットする"
            isSP={isSP}
          >
            <section className="how-to-use-template-main-content">
              <ul className="how-to-use-template-list">
                {[
                  '商品化されたアイデアが、IDEAファンドで販売されると、売上の2%があなたの収益に！',
                  '自分のアイデアが形になるワクワク感だけでなく、金銭的な報酬も獲得できます。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>
            </section>
          </EnTitleSection>
        </section>
      </div>
    </div>
  );
});

export default HowToUseTemplate;
