import React, { useEffect } from 'react';
import {
  CONTACT_DETAIL_MAX_LENGTH,
  CONTACT_USERNAME_MAX_LENGTH,
} from '../../../constants/max';
import { useScroll } from '../../../hooks/useScroll';
import { createContactAPIProps } from '../../../services/api/contact/createContactAPI.type';
import { ContactError } from '../../../types/api/contact/ContactError.type';
import {
  ContactType,
  ContactTypeValues,
  getContactTypeLabel,
} from '../../../types/api/contact/ContactTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../ions/divider/DefaultDivider.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import DefaultTextFieldIons from '../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../molecules/card/error/ErrorCard.Molecules';
import RadioButtonGrid from '../../molecules/radio/grid/RadioButtonGrid';
import LoadingOverlaySpinner from '../../molecules/spinner/LoadingOverlaySpinner';
import RequireAndTitle from '../../molecules/text/requireTitle/RequireAndTitle';
import StepNavigation from '../../organisms/navTexts/StepNavigation';
import './ContactTemplate.css';
import { useContactForm } from './useContactForm.hooks';

interface ContactThemeTemplateProps {
  isSP: boolean;
  loading: boolean;
  createContact: (input: createContactAPIProps) => void;
  contactAPIError: ContactError | null;
}

const ContactTemplate: React.FC<ContactThemeTemplateProps> = React.memo(
  (props) => {
    const { isSP, contactAPIError } = props;
    const { windowScrollToTop } = useScroll();

    // お問い合わせフォームの値とエラー
    const {
      values: values,
      errors: errors,
      handlers: handlers,
      reset: reset,
    } = useContactForm();

    useEffect(() => {
      if (contactAPIError !== null) windowScrollToTop();
    }, [contactAPIError]);

    return (
      <div
        className={`contact-template-container `}
        style={{ minHeight: isSP ? '170vh' : '200vh' }}
      >
        <header className={`contact-template-header `}>
          <BodyTextBoldIons
            text="Contact Form"
            color="purple"
            size="large"
            className="contact-template-header-title"
          />
          <BodyTextBoldIons text="お問い合わせフォーム" size="small" />
        </header>

        <main className={`contact-template-main ${isSP ? '' : 'pc'}`}>
          <section className={`contact-template-main-top `}>
            <StepNavigation
              steps={['必要事項を入力', '内容を送信', 'メールにて返信']}
              currentStep={1}
            />

            <BodyTextIons
              text={
                '内容を確認次第、ご登録のメールアドレスにてご連絡させていただきます。\n※通常1-3営業日以内に返信いたします'
              }
              bold="light"
              className="contact-template-main-top-description"
            />
          </section>

          <form className={`contact-template-main-form`}>
            {props.contactAPIError && (
              <ErrorCardMolecules
                isSP={isSP}
                text={props.contactAPIError.message || ''}
              />
            )}

            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="氏名" require={true} />
              <DefaultTextFieldIons
                label={null}
                size={isSP ? 'small' : 'medium'}
                value={values.userName}
                placeholder="お名前を入力してください。"
                onChange={handlers.handleUserNameChange}
                width={'100%'}
                max={CONTACT_USERNAME_MAX_LENGTH}
                error={errors.userName}
              />
            </div>

            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="メールアドレス" require={true} />
              <DefaultTextFieldIons
                label={null}
                width={'100%'}
                size={isSP ? 'small' : 'medium'}
                value={values.email}
                placeholder="メールアドレスを入力してください。"
                onChange={handlers.handleEmailChange}
                rows={1}
                error={errors.email}
              />
            </div>

            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="ユーザー種別" require={true} />
              <RadioButtonGrid
                columns={2}
                options={[false, true].map((isCorp: boolean) => ({
                  checked: isCorp === values.isCorp,
                  size: 'small',
                  onChange: () => handlers.handleIsCorpChange(isCorp),
                  children: (
                    <div
                      className={`idea-type-radio-button-cell ${isCorp === values.isCorp ? 'selected' : ''} `}
                    >
                      <BodyTextIons
                        text={isCorp ? '法人' : '一般ユーザー'}
                        size={'extra-small'}
                        onClick={() => handlers.handleIsCorpChange(isCorp)}
                      />
                    </div>
                  ),
                }))}
              />
            </div>

            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="お問い合わせ種別" require={true} />
              <RadioButtonGrid
                columns={2}
                options={ContactTypeValues.map((v: ContactType) => ({
                  checked: v === values.type,
                  size: 'small',
                  onChange: () => handlers.handleTypeChange(v),
                  children: (
                    <div
                      className={`idea-type-radio-button-cell ${v === values.type ? 'selected' : ''} `}
                    >
                      <BodyTextIons
                        text={getContactTypeLabel(v) || ''}
                        size={'extra-small'}
                        onClick={() => handlers.handleTypeChange(v)}
                      />
                    </div>
                  ),
                }))}
              />
            </div>

            <DefaultDividerIons width={'100%'} />
            <div className="contact-template-main-form-cell">
              <RequireAndTitle title="問い合わせ内容" require={true} />
              <DefaultTextFieldIons
                label={null}
                width={'100%'}
                size={isSP ? 'small' : 'medium'}
                value={values.detail}
                placeholder={'お問い合わせの内容を入力してください。'}
                onChange={handlers.handleDetailChange}
                rows={7}
                max={CONTACT_DETAIL_MAX_LENGTH}
                error={errors.detail}
              />
            </div>
          </form>

          <div className="contact-template-buttons">
            <RoundPurpleButtonIons
              width={'300px'}
              size={'normal'}
              disabled={props.loading}
              onClick={() =>
                props.createContact({
                  contact: {
                    isCorp: values.isCorp,
                    userName: values.userName,
                    email: values.email,
                    type: values.type,
                    detail: values.detail,
                  },
                  onSuccess: () => {
                    reset();
                  },
                })
              }
            >
              内容を送信する
            </RoundPurpleButtonIons>
            <RoundWhiteButtonIons
              width={'300px'}
              size={'normal'}
              disabled={props.loading}
              onClick={() => PageNavigation.goBack()}
            >
              戻る
            </RoundWhiteButtonIons>
          </div>
        </main>

        {props.loading && <LoadingOverlaySpinner size="large" />}
      </div>
    );
  },
);

export default ContactTemplate;
