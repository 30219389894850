import { deleteIdeaActivityAPIProps } from '../../../../services/api/idea/activity/deleteIdeaActivityAPI.type';
import { IdeaErrorType } from '../../../../types/api/idea/IdeaError.type';
import { IdeaActivityType } from '../../../../types/api/idea/IdeaTypes';
import { ActivityCardMolecules } from '../../../molecules/card/activity/ActivityCard.Molecules';
import './IdeaActivities.css';

interface IdeaActivitiesProps {
  idea_activities: IdeaActivityType[];
  deleteIdeaActivity: (input: deleteIdeaActivityAPIProps) => void;
  loading: boolean;
  apiError: IdeaErrorType | null;
}

const IdeaActivities: React.FC<IdeaActivitiesProps> = (props) => {
  return (
    <div className="idea-activities-wrapper">
      <div className="idea-activities-cell">
        {props.idea_activities.map((activity) => (
          <ActivityCardMolecules
            key={activity.activityId}
            activity={activity}
            deleteIdeaActivity={props.deleteIdeaActivity}
          />
        ))}
      </div>
    </div>
  );
};

export default IdeaActivities;
