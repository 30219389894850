import { GetRequestsQueryType } from './getRequestsAPI.type';

export const getRequestsAPI = async (query?: GetRequestsQueryType) => {
  const params = new URLSearchParams();

  // デフォルト値とクエリパラメータの設定
  params.set('limit', String(query?.limit || 25));

  // オプショナルなパラメータを追加
  if (query?.page) params.set('page', String(query.page));
  if (query?.userId) params.set('userId', String(query.userId));
  if (query?.categoryId) params.set('categoryId', String(query.categoryId));
  if (query?.keyword) params.set('keyword', query.keyword);

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/requests?${params.toString()}`,
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
