import React from 'react';
import '../ButtonCircle.css';
import './CircleRainbowButton.Ions.css';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const CircleRainbowButtonIons: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <div
      className={`rainbow-button-wrapper-ions ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
});

export default CircleRainbowButtonIons;
