import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getRequestsAPI } from '../../../services/api/request/get/getRequestsAPI';
import { GetRequestsQueryType } from '../../../services/api/request/get/getRequestsAPI.type';
import { RequestCardType } from '../../../types/api/request/RequestTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetRequestsResult = UseQueryResult<RequestCardType[]> & {
  queryKey: string[];
};

// お題一覧情報を取得するためのカスタムフック
export const useGetRequests = (
  query?: GetRequestsQueryType & { enabled: boolean },
): UseGetRequestsResult => {
  const queryKey = ['requests', query];

  const result = useQuery<RequestCardType[]>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!query?.enabled,
    queryFn: async () => {
      return getRequestsAPI(query);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetRequestsResult;
};
