import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetUser } from '../../../../hooks/api/user/useGetUser';
import { RootState } from '../../../../redux/store';
import {
  OthersCorpPageModeQuery,
  OthersPageModeQuery,
  parseOtherCorpPageModeQuery,
  parseOthersPageModeQuery,
} from '../../../../types/query/UserPageQuery';
import { UserPageTemplateProps } from '../../../templates/userpage/userpage/UserPageTemplate.type';
import UserPageTemplatePC from '../../../templates/userpage/userpage/UserPageTemplatePC';
import UserPageTemplateSP from '../../../templates/userpage/userpage/UserPageTemplateSP';
import { useOthersPageData } from './hooks/useOthersData.hooks';
import { useOthersPageActions } from './hooks/useOthersPageActions.hooks';
const OthersPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  // アイデアの個数限界
  const limit = 18;
  const [searchParams] = useSearchParams();
  // ルームIDを取得
  const { userId } = useParams();
  // ページ番号を取得
  const page = parseInt(searchParams.get('page') || '1', 10);

  // 対象のユーザー情報を取得
  const { data: user, queryKey: userQueryKey } = useGetUser(userId, {
    idea: true,
    theme: true,
    follow: true,
  });

  // 対象のユーザー情報を用いてページのモードを取得
  const mode: OthersPageModeQuery | OthersCorpPageModeQuery = !user?.isCorp
    ? parseOthersPageModeQuery(searchParams.get('mode'))
    : parseOtherCorpPageModeQuery(searchParams.get('mode'));

  // ユーザー情報を取得
  const { items, numOfItems } = useOthersPageData({
    user,
    userId: userId || null,
    mode,
    page,
  });

  const { handleFollowUser, handleUnFollowUser } = useOthersPageActions({
    userQueryKey,
  });

  const props: UserPageTemplateProps = {
    my: false,
    isCorp: user?.isCorp === true,
    user: user,
    items,
    numOfItems,
    mode: mode,
    page: page,
    maxPage: Math.ceil(numOfItems.userIdeas / limit),
    handleFollowUser,
    handleUnFollowUser,
  };

  return isXS ? (
    <UserPageTemplateSP {...props} />
  ) : (
    <UserPageTemplatePC
      {...props}
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
    />
  );
};

export default OthersPage;
