import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetIdea } from '../../../hooks/api/idea/useGetIdea';
import { useAuthGuard } from '../../../hooks/auth/useAuthGuard';
import { useIdeaActions } from '../../../hooks/idea/useIdeaActions.hooks';
import { RootState } from '../../../redux/store';
import { editIdeaAPIProps } from '../../../services/api/idea/edit/editIdeaAPI.type';
import { PageNavigation } from '../../../utils/pageNavigation';
import IdeaEditTemplate from '../../templates/ideaEdit/IdeaEditTemplate';
import NotFoundTemplate from '../../templates/not-found/NotFoundTemplate';

const IdeaEditPage = () => {
  // 自動リダイレクト付きの認証
  const { isLoggedIn } = useAuthGuard(window.location.href);

  // アイデアを取得する
  const { id } = useParams();
  const { data: idea, queryKey: idea_key } = useGetIdea(id ?? '');

  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  // アイデアを編集する
  const { apiHandlers, apiErrors, apiLoadings } = useIdeaActions({
    isLoggedIn,
    ideaKey: idea_key,
  });

  // TODO: アイデアの所有者でなければリダイレクトする
  if (!idea) {
    return null;
  }
  if (idea.isOwner !== true) {
    return <NotFoundTemplate />;
  }

  // アイデアの投稿者としてログインできていればアイデア編集画面を表示する
  return (
    <IdeaEditTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
      apiError={apiErrors.editIdea}
      apiLoading={apiLoadings.editIdea}
      handleEditIdea={(props: editIdeaAPIProps) =>
        apiHandlers.handleEditIdea({
          ...props,
          onSuccess: () => {
            PageNavigation.goToIdeaPage(idea.ideaId);
          },
        })
      }
      handleDeleteIdea={() => apiHandlers.handleDeleteIdea(idea.ideaId)}
    />
  );
};

export default IdeaEditPage;
