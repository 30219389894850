import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthActions } from '../../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../../hooks/auth/useAuthForm.hooks';
import { useVisibilityController } from '../../../../hooks/useVisibilityController';
import { closeRegisterPopup } from '../../../../redux/slice/registerPopupSlice';
import { RootState } from '../../../../redux/store';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { SNSRoundButton } from '../../../ions/sns/round/SNSRoundButton';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import './RegisterPopup.css';

const RegisterPopup = React.memo(() => {
  const dispatch = useDispatch();
  const { isOpen: open } = useSelector(
    (state: RootState) => state.registerPopup,
  );
  const onClose = useCallback(() => {
    dispatch(closeRegisterPopup());
  }, [dispatch]);
  const { isVisible } = useVisibilityController(open, 0);
  const { isXS: isSP } = useSelector((state: RootState) => state.screenSize);

  // ユーザー名、メールアドレス、パスワードの値とエラー
  const { values, errors: formErrors, handlers: formHandlers } = useAuthForm();

  // ログイン、Google新規会員登録、Line新規会員登録、会員登録の処理
  const { handlers: authHandlers, errors: apiErrors } = useAuthActions({
    values: values,
  });

  return (
    <DefaultPopupIons
      open={isVisible}
      onClose={onClose}
      size={isSP ? 'large' : 'medium'}
      zIndex={1002}
    >
      <div className={`register-popup ${isSP ? 'sp' : ''}`}>
        <HeaderTitle
          title="Sign up"
          subTitle="新規会員登録"
          titleSize="large"
        />

        <div className={`register-popup-content ${isSP ? 'sp' : ''}`}>
          <main className={`register-popup-auth-container ${isSP ? 'sp' : ''}`}>
            <form
              className={`register-popup-auth-textfields ${isSP ? 'sp' : ''}`}
            >
              <DefaultTextFieldIons
                label="ユーザーネーム"
                size={isSP ? 'small' : 'medium'}
                value={values.userName}
                onChange={formHandlers.handleUserNameChange}
                error={formErrors.userName}
              />
              <DefaultTextFieldIons
                label="メールアドレス"
                size={isSP ? 'small' : 'medium'}
                value={values.email}
                onChange={formHandlers.handleEmailChange}
                error={formErrors.email}
              />
              <DefaultTextFieldIons
                label="パスワード"
                size={isSP ? 'small' : 'medium'}
                value={values.password}
                onChange={formHandlers.handlePasswordChange}
                error={formErrors.password}
              />
              {apiErrors.register?.message && (
                <ErrorCardMolecules
                  text={apiErrors.register?.message}
                  isSP={isSP}
                  bold="light"
                  padding="5px 10px"
                />
              )}
            </form>

            <aside className="register-popup-auth-links">
              <BodyTextIonsClickable
                text="既にアカウントをお持ちの方はこちら"
                size="extra-small"
                onClick={authHandlers.handleOpenLogin}
              />
              <BodyTextIonsClickable
                text="事業者の方はこちら"
                size="extra-small"
                onClick={() => {
                  window.location.href = '/corp/register';
                  onClose();
                }}
              />
            </aside>

            <div className="register-popup-form-submit-button">
              <RoundPurpleButtonIons
                width="100%"
                size="normal"
                onClick={authHandlers.handleRegister}
              >
                登録する
              </RoundPurpleButtonIons>
            </div>
          </main>

          <nav
            className={`register-popup-sns-buttons-container ${isSP ? 'sp' : ''}`}
          >
            {['Google'].map((type) => (
              <SNSRoundButton
                key={type}
                text="登録"
                type={type as any}
                size="large"
                onClick={
                  type === 'Google'
                    ? authHandlers.handleGoogleRegister
                    : authHandlers.handleLineRegister
                }
              />
            ))}
          </nav>
        </div>
      </div>
    </DefaultPopupIons>
  );
});

export default RegisterPopup;
