import { GetIdeasQueryType } from './getIdeasAPI.type';

export const getIdeasAPI = async (query?: GetIdeasQueryType) => {
  const params = new URLSearchParams();

  // デフォルト値とクエリパラメータの設定
  params.set('limit', String(query?.limit || 25));

  // オプショナルなパラメータを追加
  if (query?.page) params.set('page', String(query.page));
  if (query?.requestId) params.set('requestId', String(query.requestId));
  if (query?.userId) params.set('userId', String(query.userId));
  if (query?.sort) params.set('sort', query.sort);
  if (query?.categoryId) params.set('categoryId', String(query.categoryId));
  if (query?.keyword) params.set('keyword', query.keyword);
  if (query?.isPublic !== undefined)
    params.set('isPublic', query.isPublic ? 'true' : 'false');
  if (query?.view !== undefined)
    params.set('view', query.view ? 'true' : 'false');
  if (query?.like !== undefined)
    params.set('like', query.like ? 'true' : 'false');
  if (query?.comment !== undefined)
    params.set('comment', query.comment ? 'true' : 'false');

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas?${params.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
