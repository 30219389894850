import React from 'react';
import { NotifyCardType } from '../../../../types/api/notifications/NotificationType';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../../ions/divider/DefaultDivider.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import MiniNotifyCardGrid from '../../../organisms/grid/notifyCardGrid/MiniNotifyCardGrid';
import './NotifyPopover.css';

interface NotifyPopoverProps {
  isSP: boolean;
  notifications: NotifyCardType[];
}

const NotifyPopover: React.FC<NotifyPopoverProps> = React.memo((props) => {
  return (
    <section className="notify-popover-wrapper">
      {props.notifications.length === 0 ? (
        <div className="notify-popover-empty">
          <BodyTextIons
            text="未読の通知はありません"
            bold="light"
            size="small"
          />
          <DefaultDividerIons margin={0} />
          <RoundWhiteButtonIons
            width={'100%'}
            onClick={() =>
              PageNavigation.goToNotificationsPage({ filter: null })
            }
          >
            <BodyTextIons text="通知一覧へ" bold="light" size="small" />
          </RoundWhiteButtonIons>
        </div>
      ) : (
        <MiniNotifyCardGrid
          isSP={props.isSP}
          notifications={props.notifications}
        />
      )}
    </section>
  );
});

export default NotifyPopover;
