import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useGetIdeaTasks } from '../../../../hooks/api/idea/useGetIdeaTasks';
import { RootState } from '../../../../redux/store';
import { confirmIdeaTasksAPI } from '../../../../services/api/idea/task/confirmIdeaTasksAPI';
import { PageNavigation } from '../../../../utils/pageNavigation';
import TaskCardMolecules from '../../../molecules/card/task/TaskCard.Molecules';
import './TaskCardGrid.css';

interface TaskCardGridProps {
  temp?: string;
}

const TaskCardGrid: React.FC<TaskCardGridProps> = () => {
  const isIdeasPath = location.pathname.includes('/ideas');
  const { isXS: isSP } = useSelector(
    (state: RootState) => state.screenSize,
    shallowEqual,
  );
  const queryClient = useQueryClient();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  // アプリケーション単位でユーザーのタスクを管理
  const { data: tasks, queryKey: tasksQueryKey } = useGetIdeaTasks({
    enabled: isLoggedIn,
  });

  // 進行中のタスクが存在した時は、5秒ごとにタスクの再取得を実行する。
  useEffect(() => {
    if (!tasks) return; // データがない場合は処理しない

    // 進行中のタスクがあるかチェック
    const hasInProgressTask = tasks.some(
      (task) => task.status !== 'failed' && task.status !== 'done',
    );

    // 進行中のタスクがあるなら、5秒ごとに再取得
    if (hasInProgressTask) {
      const interval = setInterval(() => {
        queryClient.refetchQueries({ queryKey: tasksQueryKey });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [tasks, queryClient, tasksQueryKey]);

  const confirmTask = useCallback((taskId: string, ideaId: string | null) => {
    confirmIdeaTasksAPI(taskId, () => {
      queryClient.invalidateQueries(tasksQueryKey);
      if (ideaId) PageNavigation.goToIdeaPage(ideaId);
    });
  }, []);

  return (
    <div
      data-testid="task-card-grid"
      className={`task-card-grid-container ${isSP ? 'sp' : ''}`}
      style={{ bottom: isIdeasPath && isSP ? '75px' : undefined }}
    >
      {(tasks || []).map((task) => (
        <TaskCardMolecules
          isSP={isSP}
          key={task.ideaGeneTaskId}
          task={task}
          handleConfirmTask={confirmTask}
        />
      ))}
    </div>
  );
};

export default TaskCardGrid;
