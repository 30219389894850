import {
  IDEA_CALCULATION_FORMULA_TEXT,
  IDEA_MAX_POINT,
} from '../../../../../constants/max';
import { usePageNavigation } from '../../../../../hooks/usePageNavigation';
import {
  likeIdeaAPIProps,
  unlikeIdeaAPIProps
} from '../../../../../services/api/idea/like/likeIdeaAPI.type';
import { CategoryType } from '../../../../../types/api/category/CategoryTypes';
import { IdeaDetailType } from '../../../../../types/api/idea/IdeaTypes';
import { ideaPageViewQueryMap } from '../../../../../types/query/ideasQuery';
import RoundPurpleButtonIons from '../../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundLikeButtonIons from '../../../../ions/button/round/White/RoundLikeButton.Ions';
import RoundWhiteButtonIons from '../../../../ions/button/round/White/RoundWhiteButton.Ions';
import SelectedTabButtonIons from '../../../../ions/button/selected/SelectedTabButton.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import TooltipPopoverIons from '../../../../ions/popover/TooltipPopover.Ions';
import DefaultProgressBarIons from '../../../../ions/progress/DefaultProgressBar.Ions';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../../ions/text/body/BodyTextBold.Ions';
import { BlackTitleTextIons } from '../../../../ions/text/title/BlackTitleText.Ions';
import SNSCircleButtons from '../../../../molecules/sns/circle/SNSCircleButtons';
import TextTags from '../../../../molecules/text/tags/TextTags';
import './IdeaDetailAndCfPC.css';

interface IdeaDetailAndCfPCProps {
  width?: string | number;
  view: string;
  idea: IdeaDetailType;
  numOfActivities: number;
  numOfComments: number;
  text?: string;
  onClick?: () => void;
  likeIdea: (props: likeIdeaAPIProps) => void;
  unlikeIdea: (props: unlikeIdeaAPIProps) => void;
  handleIdeaPublish: (ideaId: string) => void;
  handleIdeaUnPublish: (ideaId: string) => void;
  handleIdeaDelete: (ideaId: string) => void;
}

const IdeaDetailAndCfPC: React.FC<IdeaDetailAndCfPCProps> = (props) => {
  const { goToIdeaPage, goToIdeaEditPage } = usePageNavigation();
  const { idea } = props;

  return (
    <section className="idea-detail-and-cf-wrapper-pc">
      {idea.isOwner && !idea.isPublic && (
        <div
          style={{ width: props.width }}
          className="idea-detail-and-cf-wrapper-pc-private"
        >
          <BodyTextIons
            text={`公開状況: ${idea.isPublic ? '公開済み' : '非公開 (下書き保存済み)'}`}
            className="idea-detail-and-cf-wrapper-pc-private-text"
          />
          <div className="idea-detail-and-cf-wrapper-pc-private-buttons">
            <RoundWhiteButtonIons
              size="medium"
              onClick={() => props.handleIdeaDelete(idea.ideaId)}
              className=""
            >
              削除する
            </RoundWhiteButtonIons>
            <RoundPurpleButtonIons
              size="medium"
              onClick={() => props.handleIdeaPublish(idea.ideaId)}
              className=""
            >
              公開する
            </RoundPurpleButtonIons>
          </div>
        </div>
      )}

      <section style={{ width: props.width }} className="idea-wrapper-pc-top">
        <section className="idea-wrapper-pc-top-left">
          <IdeaImgIons isAdopted={idea.isAdopted} img_src={idea.imgSrc} />
          <BodyTextIons
            rows={2}
            text={idea.description}
            size="normal"
            className=""
          />

          <TextTags
            tags={idea.categories.map((category: CategoryType) => ({
              label: category.name,
              value: String(category.categoryId),
            }))}
            size="small"
            className=""
          />

          <SNSCircleButtons size="small" title={idea.title} />
        </section>

        <section className="idea-wrapper-pc-top-right">
          <section className="idea-wrapper-pc-top-right-subtitle">
            <BlackTitleTextIons text={idea.title} size="large" className="" />

            <BodyTextIons text={idea.subTitle} size="normal" className="" />
          </section>

          <section className="idea-wrapper-pc-top-right-progress">
            <section className="idea-wrapper-pc-top-right-progress-info-backer">
              <BodyTextIons
                text={`${idea.numOfViews} PV`}
                size="normal"
                className=""
              />
              <BodyTextIons
                text={`${props.numOfComments}件のコメント`}
                size="normal"
                className=""
              />
            </section>

            <DefaultProgressBarIons
              progress={(idea.evalPoint / IDEA_MAX_POINT) * 100}
              size="large"
            />

            <section className="idea-wrapper-pc-top-right-progress-info-days">
              <BodyTextIons
                text={`${idea.numOfLikes} いいね`}
                size="normal"
                className=""
              />
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <BodyTextBoldIons
                  text={`${idea.evalPoint} pt`}
                  size="normal"
                  className=""
                />
                <TooltipPopoverIons
                  placement={'top-right'}
                  text={IDEA_CALCULATION_FORMULA_TEXT}
                />
              </div>
            </section>
          </section>

          <section className="idea-wrapper-pc-top-right-buttons">
            <RoundPurpleButtonIons
              size="large"
              onClick={
                idea.isOwner
                  ? () => goToIdeaEditPage(idea.ideaId)
                  : props.onClick
              }
              className=""
            >
              {idea.isOwner ? '編集する' : props.text}
            </RoundPurpleButtonIons>
            <RoundLikeButtonIons
              size="large"
              numOfLikes={idea.numOfLikes}
              onClick={() =>
                idea.isLiked
                  ? props.unlikeIdea({ ideaId: idea.ideaId })
                  : props.likeIdea({ ideaId: idea.ideaId })
              }
              liked={idea.isLiked}
            />
          </section>
        </section>
      </section>

      <section
        style={{ width: props.width }}
        className="idea-wrapper-pc-bottom-tabs"
      >
        {Object.entries(ideaPageViewQueryMap).map(([key, value]) => {
          return (
            <SelectedTabButtonIons
              selected={props.view === key}
              onClick={() => {
                goToIdeaPage(idea.ideaId, { view: key });
              }}
            >
              {value +
                ` ${key === 'comment' ? `(${props.numOfComments})` : key === 'activity' ? `(${props.numOfActivities})` : ''}`}
            </SelectedTabButtonIons>
          );
        })}
      </section>
    </section>
  );
};

export default IdeaDetailAndCfPC;
