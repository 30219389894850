import { ChangeEvent, useCallback, useEffect, useState } from 'react';

type ValidateFunction = (value: string) => string | null;

interface UseTextfieldProps {
  value: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleReset: () => void;
}

const useTextfield = (
  init?: string,
  validate?: ValidateFunction,
): UseTextfieldProps => {
  const [value, setValue] = useState(init || '');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setValue(init ?? '');
    setError(null);
  }, [init]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      setValue(newValue);

      if (validate) {
        const validationError = validate(newValue);
        setError(validationError);
      } else {
        setError(null);
      }
    },
    [validate],
  );

  const handleReset = () => {
    setValue('');
  };

  return { value, error, handleChange, handleReset };
};

export default useTextfield;
