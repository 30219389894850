import { NOTIFICATIONS_DISPLAY_LENGTH } from '../../../../constants/max';
import { NotifyCardType } from '../../../../types/api/notifications/NotificationType';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import MiniNotifyCardMolecules from '../../../molecules/card/notify/MiniNotifyCard.Molecules';
import './MiniNotifyCardGrid.css';

interface NotifyCardGridProps {
  isSP: boolean;
  notifications: NotifyCardType[];
}

const MiniNotifyCardGrid: React.FC<NotifyCardGridProps> = (props) => {
  return (
    <div className={`mini-notify-card-grid-container `}>
      <div className={`mini-notify-card-grid `}>
        {props.notifications
          .slice(0, NOTIFICATIONS_DISPLAY_LENGTH)
          .map((notify) => (
            <MiniNotifyCardMolecules
              notifyId={notify.notifyId}
              isRead={notify.isRead}
              isSP={props.isSP}
              type={notify.type}
              title={notify.title}
              description={notify.description}
            />
          ))}
      </div>
      <RoundWhiteButtonIons
        width={'100%'}
        onClick={() =>
          PageNavigation.goToNotificationsPage({ filter: 'unread' })
        }
      >
        {props.notifications.length - NOTIFICATIONS_DISPLAY_LENGTH > 0 ? (
          <BodyTextIons
            text={`他${props.notifications.length - NOTIFICATIONS_DISPLAY_LENGTH}件の通知も表示`}
            bold="light"
            size="small"
            color="grey"
          />
        ) : (
          <BodyTextIons text="通知一覧へ" bold="light" size="small" />
        )}
      </RoundWhiteButtonIons>
    </div>
  );
};

export default MiniNotifyCardGrid;
