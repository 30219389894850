import React from 'react';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import EnTitleSection from '../../../organisms/section/EnTitleSection';
import './VerifyEmailTemplate.css';

interface PopupProps {
  isSP: boolean;
}

// 認証メール送信完了後の遷移ページ
const VerifyEmailSendTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP } = props;
  return (
    <div className={`verify-email-template`}>
      <HeaderTitle
        title="Email Send"
        subTitle="認証メールを送信しました"
        titleSize="large"
      />

      <section className={`verify-email-template-main ${isSP ? 'sp' : ''}`}>
        <EnTitleSection
          title="Click the link"
          subTitle="メール内のリンクをクリック"
          isSP={isSP}
        >
          <ul className="verify-email-template-list">
            {[
              'ご入力いただいたメールアドレスに認証メールを送信しました。メール内のリンクをクリックして、登録を完了してください。',
              'メールが届かない場合は、迷惑メールフォルダをご確認ください。',
              `ご不明な点がございましたら、${process.env.REACT_APP_SUPPORTER_EMAIL} までお気軽にご連絡ください。`,
            ].map((item) => (
              <li>
                <BodyTextIons text={item} bold="light" size="extra-small" />
              </li>
            ))}
          </ul>
          <RoundPurpleButtonIons
            width={'200px'}
            onClick={PageNavigation.goToHomePage}
          >
            トップページへ
          </RoundPurpleButtonIons>
        </EnTitleSection>
      </section>
    </div>
  );
});

export default VerifyEmailSendTemplate;
