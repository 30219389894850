import React from 'react';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { ScreenSizeType } from '../../../../types/screenSizeType';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import RequestCard2Molecules from '../../../molecules/card/request/RequestCard2.Molecules';
import IdeaApealAndStory from '../../../organisms/idea/apealAndStory/IdeaApealAndStory';
import './../IdeaTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  title_size?: TextSizeType;
  size?: ScreenSizeType;
  isSP?: boolean;
}

const IdeaStoryTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, size } = props;
  return (
    <div className={`idea-story-template ${size}`}>
      {props.isSP === false && (
        <BlackTitleTextIons
          text="ストーリー"
          size={props.title_size}
          className=""
        />
      )}
      <IdeaApealAndStory idea={idea} size={props.size} />

      <BlackTitleTextIons text="お題" size={props.title_size} className="" />
      {idea.request ? (
        <RequestCard2Molecules
          width={'85%'}
          isSP={false}
          request={idea.request}
        />
      ) : (
        <BodyTextIons text={'お題が設定されていません'} />
      )}
    </div>
  );
};

export default IdeaStoryTemplate;
