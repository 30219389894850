import React from 'react';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './HeaderTitleSakairi.css';

interface HomeTitleAndSubtitleProps {
  title: string;
  titleSize: TextSizeType;
  subTitle: string;
  className?: string;
}

const HeaderTitleSakairi: React.FC<HomeTitleAndSubtitleProps> = React.memo(
  (props) => {
    return (
      <div className={`header-title-sakairi-molecules ${props.className}`}>
        <BodyTextIons
          text={props.title}
          className={`header-title-sakairi-molecules-title ${props.titleSize}`}
        />
        <BodyTextBoldIons
          text={props.subTitle}
          className="header-title-sakairi-molecules-subtitle"
        />
      </div>
    );
  },
);

export default HeaderTitleSakairi;
