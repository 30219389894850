import React from 'react';
import {
  AuthAPIErrors,
  AuthAPILoadingState,
} from '../../../../hooks/auth/useAuthActions.hooks';
import {
  AuthFormErrors,
  AuthFormHandlers,
  AuthFormState,
} from '../../../../hooks/auth/useAuthForm.hooks';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import './SettingTemplate.css';

interface PopupProps {
  isSP: boolean;
  result: boolean;
  email: string;
  values: AuthFormState;
  formHandlers: AuthFormHandlers;
  formErrors: AuthFormErrors;
  apiErrors: AuthAPIErrors;
  apiLoadings: AuthAPILoadingState;
  handleUpdateEmail: () => void;
}

const SettingEmailTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP, values, formHandlers, formErrors } = props;
  const apiError = props.apiErrors.resetEmail;
  const apiLoading = props.apiLoadings.resetEmail;

  return (
    <div className={`setting-template-container `}>
      {apiLoading && <LoadingOverlaySpinner />}
      <header className={`setting-template-header `}>
        <HeaderTitle
          title="Setting Email"
          subTitle="メールアドレスの再設定"
          titleSize="large"
        />
      </header>

      <main className={`setting-template-main ${isSP ? 'sp' : ''}`}>
        <form className={`setting-template-form `}>
          {props.result && (
            <div className="setting-template-success">
              {
                '※ 変更後のメールアドレスに認証メールを送りました。メール内のリンクをクリックして、メールアドレスの変更を完了してください。'
              }
            </div>
          )}
          <div className="setting-template-now">
            <label className="textfield-label small">
              現在のメールアドレス
            </label>
            <BodyTextIons text={props.email} size={'small'} bold="light" />
          </div>
          <DefaultTextFieldIons
            label="変更後のメールアドレス"
            size={isSP ? 'small' : 'medium'}
            value={values.email}
            onChange={formHandlers.handleEmailChange}
            error={formErrors.email}
            width={'100%'}
          />
          <DefaultTextFieldIons
            label="確認のためパスワードを入力してください"
            size={isSP ? 'small' : 'medium'}
            value={values.password}
            onChange={formHandlers.handlePasswordChange}
            error={formErrors.password}
            width={'100%'}
            type="password"
          />
          {apiError?.message && (
            <ErrorCardMolecules
              text={apiError?.message}
              isSP={isSP}
              padding="5px 10px"
              margin="10px 0"
              bold="light"
            />
          )}
        </form>

        <div className="setting-template-submit-buttons">
          <RoundPurpleButtonIons
            width={'60%'}
            size={'normal'}
            onClick={props.handleUpdateEmail}
          >
            認証メールを送信する
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            width={'60%'}
            size={'normal'}
            onClick={PageNavigation.goBack}
          >
            戻る
          </RoundWhiteButtonIons>
        </div>
      </main>
    </div>
  );
});

export default SettingEmailTemplate;
