import { useEffect, useState } from 'react';
import { GET_IDEA_COMMENT_MAX_LIMIT } from '../../../../constants/max';
import { useIdeaCommentForm } from '../../../../hooks/idea/comment/useIdeaCommentForm.hooks';
import useSetQuery from '../../../../hooks/useQuery';
import { createIdeaCommentAPIProps } from '../../../../services/api/idea/comment/createIdeaCommentAPI.type';
import { deleteIdeaCommentAPIProps } from '../../../../services/api/idea/comment/deleteIdeaCommentAPI.type';
import {
  likeIdeaCommentAPIProps,
  unlikeIdeaCommentAPIProps,
} from '../../../../services/api/idea/comment/like/likeIdeaCommentAPI.type';
import {
  IdeaCommentType,
  IdeaDetailType,
} from '../../../../types/api/idea/IdeaTypes';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import DefaultCommentTextFieldIons from '../../../ions/textfield/DefaultCommentTextField';
import { CommentCardKuboMoleculesWithReplies } from '../../../molecules/card/comment/CommentCardKuboWithReplies.Molecules';
import './IdeaComments.css';

interface IdeaCommentsProps {
  ideaId: string;
  idea: IdeaDetailType;
  page: number;
  isSP?: boolean;
  idea_comments: IdeaCommentType[];
  numOfAllComments: number;
  login_user_img_src: string;
  login_userName: string;
  onSendComment: (input: createIdeaCommentAPIProps) => void;
  onLikeComment: (input: likeIdeaCommentAPIProps) => void;
  onDeleteComment: (input: deleteIdeaCommentAPIProps) => void;
  onUnlikeComment: (input: unlikeIdeaCommentAPIProps) => void;
  isLoggedIn: boolean;
}

const IdeaComments: React.FC<IdeaCommentsProps> = (props) => {
  const { isLoggedIn } = props;
  const { values, errors, handlers } = useIdeaCommentForm({
    init: { text: '' },
    storageId: props.ideaId,
  });
  const { setPageQuery } = useSetQuery();
  const [validateError, setValidateError] = useState<string | null>(null);

  const validate = () => {
    if (!isLoggedIn) {
      return true;
    } else if (values.text.length === 0) {
      setValidateError('コメントを入力してください');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (values.text.length > 0) {
      setValidateError(null);
    }
  }, [values.text]);

  return (
    <div className="idea-comments-wrapper">
      <form className="idea-comments-form">
        <div className={`idea-comments-form-top ${props.isSP ? 'sp' : ''}`}>
          <UserIconImgIons
            img_src={props.login_user_img_src}
            userName={props.login_userName}
            alt="user-icon"
            size="medium"
          />
          <DefaultCommentTextFieldIons
            value={values.text}
            mainColor="secondary"
            onChange={handlers.handleTextChange}
            placeholder={
              isLoggedIn ? 'コメントを入力する' : 'ログインしてください'
            }
            isSP={props.isSP}
            error={validateError || errors.text}
            disabled={!isLoggedIn}
            onKeyDown={() =>
              validate() &&
              props.onSendComment({
                comment: {
                  ideaId: props.ideaId,
                  toCommentId: null,
                  text: values.text,
                  mentionUserId: null,
                },
                onSuccess: () => {
                  handlers.handleReset();
                  handlers.clearStorage();
                },
              })
            }
          />
        </div>
      </form>
      <div className="idea-comments-cards-container">
        {props.idea_comments.map((comment, i) => (
          <CommentCardKuboMoleculesWithReplies
            key={i}
            comment={comment}
            ideaUserId={props.idea.user.userId}
            onSendComment={props.onSendComment}
            onLikeComment={props.onLikeComment}
            onUnlikeComment={props.onUnlikeComment}
            onDeleteComment={props.onDeleteComment}
          />
        ))}

        {GET_IDEA_COMMENT_MAX_LIMIT * props.page < props.numOfAllComments && (
          <RoundWhiteButtonIons
            size="normal"
            width={'100%'}
            onClick={() => setPageQuery(props.page + 1)}
          >
            コメントをさらに表示
          </RoundWhiteButtonIons>
        )}
      </div>
    </div>
  );
};

export default IdeaComments;
