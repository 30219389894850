import React from 'react';
import { SpinnerProps } from '../../atoms/spinner/Spinner.type';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import './LoadingFullSpinner.css';

export const LoadingFullSpinner: React.FC<SpinnerProps> = React.memo(
  (props) => {
    return (
      <div className={`loading-full-spinner`}>
        <DefaultSpinnerIons color="grey" />
      </div>
    );
  },
);

export default LoadingFullSpinner;
