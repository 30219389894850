import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { GET_IDEA_MAX_LIMIT } from '../../constants/max';
import { useGetAdoptedIdeaInReq } from '../../hooks/api/idea/useGetAdoptedIdeaInReq';
import { useGetIdeas } from '../../hooks/api/idea/useGetIdeas';
import { useGetRequest } from '../../hooks/api/request/useGetRequest';
import { useGetRequests } from '../../hooks/api/request/useGetRequests';
import { RootState } from '../../redux/store';
import { parseRequestPageModeQuery } from '../../types/query/RequestPageQuery';
import RequestTemplate from '../templates/request/RequestTemplate';

const RequestPage = () => {
  const [searchParams] = useSearchParams();
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const mode = parseRequestPageModeQuery(searchParams.get('mode'));
  const page = parseInt(searchParams.get('page') || '1', 10);
  const { requestId } = useParams();
  const { data: request } = useGetRequest(requestId || null);
  const { data: ideasResult } = useGetIdeas({
    requestId: request?.requestId,
    enabled: !!request?.requestId,
    like: true,
    comment: true,
    view: true,
    page,
    limit: GET_IDEA_MAX_LIMIT,
  });
  const { data: adoptedIdeas } = useGetAdoptedIdeaInReq(requestId || undefined);
  const { data: other_requests } = useGetRequests({
    enabled: true,
  });

  return (
    <RequestTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      page={page}
      maxPage={Math.ceil(
        (ideasResult?.numOfAllIdeas || 0) / GET_IDEA_MAX_LIMIT,
      )}
      mode={mode}
      request={request}
      ideas={ideasResult?.ideas || []}
      adoptedIdeas={adoptedIdeas || []}
      other_requests={other_requests || []}
    />
  );
};

export default RequestPage;
