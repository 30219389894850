import React, { useEffect } from 'react';
import {
  ADDRESS_MAX_LENGTH,
  CORP_NAME_MAX_LENGTH,
  IDEA_CATEGORIES_MAX_LENGTH,
  MAIL_ADDRESS_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  USER_INTRODUCTION_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
  ZIPCODE_MAX_LENGTH,
} from '../../../../constants/max';
import { AuthAPIHandlers } from '../../../../hooks/auth/useAuthActions.hooks';
import {
  AuthFormErrors,
  AuthFormHandlers,
  AuthFormState,
} from '../../../../hooks/auth/useAuthForm.hooks';
import { useScroll } from '../../../../hooks/useScroll';
import { CreateCorpType } from '../../../../services/api/auth/verify/corp/createCorpAPI.type';
import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';
import { UserDetailType } from '../../../../types/api/user/UserTypes';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingFullSpinner from '../../../molecules/spinner/LoadingFullSpinner';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import CategoriesCheckboxGrid from '../../../organisms/grid/tagCheckboxGrid/CategoriesCheckboxGrid';
import ImageForm from '../../../organisms/textfield/form/radioForm/ImageForm';
import RequireTextfield from '../../../organisms/textfield/RequireTextfield';
import './CreateCorpTemplate.css';

interface EmailVerifyCorpTemplateProps {
  isSP: boolean;
  isTokenVerified: boolean | null;
  tokenId: string;
  user: UserDetailType | undefined;
  handleCreateCorp: (input: CreateCorpType) => void;
  values: AuthFormState;
  formHandlers: AuthFormHandlers;
  formErrors: AuthFormErrors;
  apihandlers: AuthAPIHandlers;
  apiError: AuthErrorType | null;
  apiLoading: boolean;
}

// 事業者のメール認証と、詳細情報設定
const CreateCorpTemplate: React.FC<EmailVerifyCorpTemplateProps> = React.memo(
  (props) => {
    const { isSP, apiError, values, formErrors, formHandlers, apiLoading } =
      props;
    const { windowScrollToTop } = useScroll();

    useEffect(() => {
      if (apiError !== null) windowScrollToTop();
    }, [apiError]);

    if (props.isTokenVerified === null) return <LoadingFullSpinner />;
    if (props.isTokenVerified === false)
      return (
        <div className={`create-corp-template-container ${isSP ? 'sp' : ''}`}>
          <div
            className={`create-corp-template-main ${isSP ? 'sp' : ''}`}
            style={{ marginTop: '100px' }}
          >
            <header className="create-corp-template-header">
              <HeaderTitle
                title="Token Invalid"
                subTitle="トークンが無効です"
                titleSize="large"
              />
            </header>
            <ErrorCardMolecules
              text="申し訳ございません。お使いのリンクの有効期限が切れているようです。お手数ですが、先ほど受信したメールに記載されている URL から再度アクセスしてください。もし問題が解決しない場合は、info@re-idea.jp までご連絡ください。ご不便をおかけして申し訳ございません。"
              isSP={isSP}
              padding="5px 10px"
              margin="10px 0"
              bold="light"
            />
          </div>
        </div>
      );
    if (props.user === undefined) return <LoadingFullSpinner />;

    return (
      <div className={`create-corp-template-container ${isSP ? 'sp' : ''}`}>
        {apiLoading && <LoadingOverlaySpinner />}
        <header className="create-corp-template-header">
          <HeaderTitle
            title="Sign up"
            subTitle="事業者情報を入力してアカウントを登録"
            titleSize="large"
          />
        </header>
        <main className={`create-corp-template-main ${isSP ? 'sp' : ''}`}>
          <form
            className={`create-corp-template-form`}
            onSubmit={(e) => e.preventDefault()}
          >
            {apiError?.message && (
              <ErrorCardMolecules
                text={apiError?.message}
                isSP={isSP}
                padding="5px 10px"
                margin="10px 0"
                bold="light"
              />
            )}
            <RequireTextfield
              label="会社名(ユーザーネーム)"
              placeholder="株式会社〇〇"
              isSP={isSP}
              text={values.userName}
              handleChange={formHandlers.handleUserNameChange}
              error={formErrors.userName}
              require={true}
              max={USER_NAME_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="会社名(正式名称)"
              placeholder="株式会社〇〇"
              text={values.corpName || ''}
              handleChange={formHandlers.handleCorpNameChange}
              error={formErrors.corpName}
              require={true}
              max={CORP_NAME_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="郵便番号"
              placeholder="000-0000"
              text={values.zipCode || ''}
              handleChange={formHandlers.handleZipCodeChange}
              error={formErrors.zipCode}
              require={true}
              max={ZIPCODE_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="所在地"
              placeholder="〇〇県〇〇区〇〇1-1-1 〇〇ビル1F"
              text={values.address || ''}
              handleChange={formHandlers.handleAddressChange}
              error={formErrors.address}
              require={true}
              max={ADDRESS_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="電話番号"
              placeholder="000-0000-0000"
              text={values.phoneNumber || ''}
              handleChange={formHandlers.handlePhoneNumberChange}
              error={formErrors.phoneNumber}
              require={true}
              max={PHONE_NUMBER_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="メールアドレス(認証済み)"
              placeholder="example@sample.com"
              text={values.email || ''}
              handleChange={formHandlers.handleEmailChange}
              error={formErrors.email}
              require={true}
              max={MAIL_ADDRESS_MAX_LENGTH}
              disabled
            />
            <RequireTextfield
              isSP={isSP}
              label="プロフィール文"
              placeholder="事業内容や特徴を記入\n(例: 〇〇事業を展開しています)"
              text={values.introduction || ''}
              handleChange={formHandlers.handleIntroductionChange}
              error={formErrors.introduction}
              componentType="textarea"
              require={true}
              rows={6}
              max={USER_INTRODUCTION_MAX_LENGTH}
            />
            <CategoriesCheckboxGrid
              categoryIds={values.categoryIds || []}
              error={formErrors.categoryIds}
              handleChange={(categoryId) =>
                formHandlers.handleCategoriesChange(categoryId)
              }
              columns={props.isSP ? 3 : 4}
              require={true}
              max={IDEA_CATEGORIES_MAX_LENGTH}
            />

            <ImageForm
              require={false}
              imgSrc={values.imgSrc || null}
              imgFile={values.img || null}
              title="ユーザーアイコン画像"
              handleImgFileChange={formHandlers.handleImgFileChange}
              handleImgSrcChange={formHandlers.handleImgSrcChange}
              error={null}
              type="icon"
            />

            <ImageForm
              require={false}
              imgSrc={values.bannerImgSrc || null}
              imgFile={values.bannerImg || null}
              title="マイページバナー画像"
              handleImgFileChange={formHandlers.handleBannerImgFileChange}
              handleImgSrcChange={formHandlers.handleBannerImgSrcChange}
              error={null}
              type="banner"
            />

            <RequireTextfield
              isSP={isSP}
              label="公式webサイトURL"
              placeholder="https://example.com"
              text={values.websiteUrl || ''}
              handleChange={formHandlers.handleWebsiteUrlChange}
              error={null}
              require={false}
            />

            <RequireTextfield
              isSP={isSP}
              label="SNSリンクURL1"
              placeholder="https://example.com"
              text={values.snsUrl1 || ''}
              handleChange={formHandlers.handleSnsUrl1Change}
              error={null}
              require={false}
            />
            <RequireTextfield
              isSP={isSP}
              label="SNSリンクURL2"
              placeholder="https://example.com"
              text={values.snsUrl2 || ''}
              handleChange={formHandlers.handleSnsUrl2Change}
              error={null}
              require={false}
            />
            <RequireTextfield
              isSP={isSP}
              label="SNSリンクURL3"
              placeholder="https://example.com"
              text={values.snsUrl3 || ''}
              handleChange={formHandlers.handleSnsUrl3Change}
              error={null}
              require={false}
            />
          </form>

          <div className="create-corp-template-form-submit-button">
            <RoundPurpleButtonIons
              width={'60%'}
              size={'normal'}
              onClick={() =>
                props.handleCreateCorp({
                  tokenId: props.tokenId,
                  corpName: values.corpName || '',
                  zipCode: values.zipCode || '',
                  address: values.address || '',
                  phoneNumber: values.phoneNumber || '',
                  userName: values.userName || '',
                  email: values.email || '',
                  categoryIds: values.categoryIds || [],
                  introduction: values.introduction || null,
                  img: values.img || null,
                  imgSrc: values.imgSrc || null,
                  bannerImg: values.bannerImg || null,
                  bannerImgSrc: values.bannerImgSrc || null,
                  websiteUrl: values.websiteUrl || null,
                  snsUrl1: values.snsUrl1 || null,
                  snsUrl2: values.snsUrl2 || null,
                  snsUrl3: values.snsUrl3 || null,
                })
              }
            >
              アカウントを登録する
            </RoundPurpleButtonIons>
          </div>
        </main>
      </div>
    );
  },
);

export default CreateCorpTemplate;
