import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';
import { InvestmentType } from '../../../types/api/investment/InvestmentTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultDividerIons from '../../ions/divider/DefaultDivider.Ions';
import IdeaImgIons from '../../ions/img/IdeaImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import StepNavigation from '../../organisms/navTexts/StepNavigation';
import EnTitleSection from '../../organisms/section/EnTitleSection';
import CreditCardForm from '../../organisms/textfield/form/CreditCardForm';
import './IdeaInvestTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  investment: InvestmentType;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaInvestTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, investment, size } = props;
  const isSP = size === 'sp';
  const { goToIdeaInvestConfirmPage } = usePageNavigation();
  let width: string;

  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '60%';
      break;
    case 'medium':
      width = '45%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  return (
    <div className="idea-invest-template">
      <main className="idea-invest-template-main" style={{ width: width }}>
        <header className={`idea-invest-template-header `}>
          <BodyTextBoldIons
            text="Idea Invest"
            color="purple"
            className={`idea-invest-template-header-title ${isSP ? 'sp' : ''} `}
          />
          <BodyTextBoldIons text="アイデアへの出資" size="normal" />
        </header>

        <StepNavigation
          steps={[
            '出資内容の確認',
            'クレジットカード\n情報を登録',
            '出資を確定',
          ]}
          currentStep={1}
        />

        <section className="idea-invest-template-main">
          <EnTitleSection
            title="Confirm "
            subTitle="出資内容を確認する"
            isSP={size === 'sp'}
          >
            <div
              className={`idea-invest-template-main-content ${size === 'sp' ? 'sp' : ''}`}
            >
              <IdeaImgIons
                img_src={idea.imgSrc}
                className={`idea-invest-template-img ${size === 'sp' ? 'sp' : ''}`}
              />

              <div
                className={`idea-invest-template-main-content-texts ${size === 'sp' ? 'sp' : ''}`}
              >
                <BodyTextIons bold="bold" text={idea.title} size={'small'} />
                <BodyTextIons text={idea.description} rows={2} size={'small'} />
              </div>

              <DefaultDividerIons width={'90%'} />

              <div
                className={`idea-invest-template-main-content-texts ${size === 'sp' ? 'sp' : ''}`}
              >
                <div className="idea-invest-template-main-content-texts-money">
                  <BodyTextIons color="black" text={'出資金額: '} />
                  <BodyTextIons color="black" text={`${investment.price}円`} />
                </div>
                <div className="idea-invest-template-main-content-texts-money">
                  <BodyTextIons color="black" text={'システム利用料: '} />
                  <BodyTextIons color="black" text={'0円'} />
                </div>
                <div
                  className="idea-invest-template-main-content-texts-money"
                  style={{ marginTop: '10px' }}
                >
                  <BodyTextIons color="black" text={'支払い総額(税込): '} />
                  <BodyTextBoldIons
                    color="black"
                    size="normal"
                    text={`${investment.price}円`}
                  />
                </div>
              </div>
            </div>
          </EnTitleSection>

          <EnTitleSection
            title="Credit Card "
            subTitle="クレジットカード情報を登録"
            isSP={size === 'sp'}
          >
            <div
              className={`idea-invest-template-main-content ${size === 'sp' ? 'sp' : ''}`}
            >
              <CreditCardForm />
            </div>
          </EnTitleSection>

          <EnTitleSection
            title="Pay Attention "
            subTitle="注意事項を確認する"
            isSP={isSP}
          >
            <div
              className={`idea-invest-template-main-content ${size === 'sp' ? 'sp' : ''}`}
            >
              <ul className="idea-invest-template-main-content-list">
                {[
                  'アイデアが実現した時点で初めて決済（引き落とし）が行われます。実現前の段階では請求は発生しません。',
                  '決済完了後は、ご自身の都合による出資のキャンセルはできません。慎重にご判断の上、出資をお願いいたします。',
                  '進行状況により、リターンの内容が変更される可能性があります。変更が生じた場合は速やかにご連絡いたします。',
                  'アイデアの実現時期は、開発状況や外部要因により変動する可能性があります。予定より遅延する場合もございますので、あらかじめご了承ください。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>
            </div>
          </EnTitleSection>
        </section>

        <section className="idea-invest-template-buttons">
          <RoundPurpleButtonIons
            onClick={() => {
              goToIdeaInvestConfirmPage(
                props.idea.ideaId,
                props.investment.investmentId,
              );
            }}
            size="medium"
            width={'60%'}
          >
            支払い確認へ進む
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            onClick={PageNavigation.goBack}
            size="medium"
            width={'60%'}
          >
            戻る
          </RoundWhiteButtonIons>
        </section>
      </main>
    </div>
  );
};

export default IdeaInvestTemplate;
