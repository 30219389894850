export const validatePhoneNumber = (value: string): string | null => {
  if (value === '') return null;

  // ハイフンを取り除いた数字だけの文字列を作成
  const digitsOnly = value.replace(/-/g, '');

  // 電話番号は市外局番（0で始まる）+残りの番号で10桁または11桁
  const digitRegex = /^0\d{9,10}$/;

  // ハイフン入りの形式（0X-XXXX-XXXX や0XX-XXX-XXXX など）
  const phoneNumberRegex = /^0\d{1,4}-\d{1,4}-\d{4}$/;

  return phoneNumberRegex.test(value) || digitRegex.test(digitsOnly)
    ? null
    : '電話番号は半角数字で入力してください（例：03-1234-5678）';
};
