import { useSelector } from 'react-redux';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { RootState } from '../../../redux/store';
import { CategoryType } from '../../../types/api/category/CategoryTypes';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import NavTextsMolecules from '../../molecules/text/nav/NavTexts.Molecules';
import './NavTexts.css';

interface CategoryNavTextsProps {
  categoryId: number;
}

const CategoryNavTexts: React.FC<CategoryNavTextsProps> = (props) => {
  const { goToSearchPage } = usePageNavigation();
  const categories = useSelector(
    (state: RootState) => state.category.categories,
  );
  if (!categories) return null;

  return (
    <section className="with-title-nav-texts">
      <BodyTextBoldIons text="カテゴリ一覧" size="small" />
      <NavTextsMolecules
        textOptions={(categories || []).map((category: CategoryType) => ({
          label: category.name,
          value: category.categoryId,
        }))}
        onClick={(value: string | number) =>
          goToSearchPage({ categoryId: Number(value) })
        }
        value={props.categoryId}
      />
    </section>
  );
};

export default CategoryNavTexts;
