import React from 'react';
import { useSelector } from 'react-redux';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { RootState } from '../../../../redux/store';
import { InvestmentType } from '../../../../types/api/investment/InvestmentTypes';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import { PurpleTitleTextIons } from '../../../ions/text/title/PurpleTitleText.Ions';
import './ReturnCard.Molecules.css';

type ReturnCardMoleculesProps = {
  ideaId: string;
  stage: number;
  investment: InvestmentType;
  isAdopted: boolean;
};

export const ReturnCardMolecules: React.FC<ReturnCardMoleculesProps> =
  React.memo((props) => {
    const { goToIdeaInvestPage, goToIdeaInvestDeleteConfirmPage } =
      usePageNavigation();
    const { investment } = props;
    const { user } = useSelector((state: RootState) => state.auth);

    return (
      <div className={`return-card-molecules-container`}>
        <div className="return-card-molecules-header">
          <PurpleTitleTextIons text={'STAGE' + props.stage} size="small" />
          <BodyTextIons text={investment.price + ' 円'} size="medium" />
        </div>
        <main className="return-card-molecules-main">
          <BodyTextBoldIons text={investment.name} size="small" />
          <BodyTextBoldIons text={investment.description} size="small" />
          <div className="return-card-molecules-main-buttons">
            <RoundPurpleButtonIons
              size="medium"
              width={'100%'}
              onClick={() =>
                goToIdeaInvestPage(props.ideaId, investment.investmentId)
              }
              disabled={
                investment.isInvested ||
                investment.user.userId === user?.userId ||
                props.isAdopted ||
                true
              }
            >
              {/* {investment.isInvested
                ? '出資済み'
                : props.isAdopted
                  ? '商品化が決定しています'
                  : investment.user.userId === user?.userId
                    ? '投稿者は出資できません'
                    : '出資の機能は準備中です'} */}
              {'出資の機能は準備中です'}
            </RoundPurpleButtonIons>
            {investment.isInvested && (
              <BodyTextIonsClickable
                size="extra-small"
                text="出資の取り消しはこちら"
                onClick={() =>
                  goToIdeaInvestDeleteConfirmPage(
                    props.ideaId,
                    investment.investmentId,
                  )
                }
              />
            )}
          </div>
        </main>
      </div>
    );
  });
