import React, { useCallback } from 'react';
import SelectedWhiteRoundButtonIons from '../../../ions/button/selected/SelectedWhiteRoundButton.Ions';
import './IdeaDisplaySelectedButtons.css';

interface IdeaDisplaySelectedButtonsProps {
  isPublic: boolean;
  handleChangeIsPublic: (isPublic: boolean) => void;
}

const IdeaDisplaySelectedButtons: React.FC<IdeaDisplaySelectedButtonsProps> =
  React.memo((props) => {
    // ボタンに渡す用に、空の関数へ変形している。
    const handleChangeDisplay = useCallback(() => {
      props.handleChangeIsPublic(true);
    }, []);
    const handleChangeNotDisplay = useCallback(() => {
      props.handleChangeIsPublic(false);
    }, []);

    return (
      <section className="idea-display-selected-buttons-wrapper">
        <SelectedWhiteRoundButtonIons
          onClick={handleChangeDisplay}
          size="small"
          selected={props.isPublic}
          width={'70px'}
        >
          公開
        </SelectedWhiteRoundButtonIons>
        <SelectedWhiteRoundButtonIons
          selected={!props.isPublic}
          size="small"
          onClick={handleChangeNotDisplay}
          width={'70px'}
        >
          非公開
        </SelectedWhiteRoundButtonIons>
      </section>
    );
  });

export default IdeaDisplaySelectedButtons;
