import React from 'react';
import { useSelector } from 'react-redux';
import useOpen from '../../../../hooks/useOpen';
import { RootState } from '../../../../redux/store';
import { createIdeaActivityAPIProps } from '../../../../services/api/idea/activity/createIdeaActivityAPI.type';
import { deleteIdeaActivityAPIProps } from '../../../../services/api/idea/activity/deleteIdeaActivityAPI.type';
import { IdeaErrorType } from '../../../../types/api/idea/IdeaError.type';
import {
  IdeaActivityType,
  IdeaDetailType,
} from '../../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import IdeaActivities from '../../../organisms/idea/activity/IdeaActivities';
import IdeaActivityPostPopup from '../../../organisms/popup/idea/activity/IdeaActivityPostPopup';
import './../IdeaTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  idea_activities: IdeaActivityType[];
  title_size?: TextSizeType;
  sendLoading: boolean;
  sendApiError: IdeaErrorType | null;
  deleteLoading: boolean;
  deleteApiError: IdeaErrorType | null;
  sendIdeaActivity: (input: createIdeaActivityAPIProps) => void;
  deleteIdeaActivity: (input: deleteIdeaActivityAPIProps) => void;
}

const IdeaActivityTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const {
    open: activity_post_open,
    handleClose: handleCloseActivityPost,
    handleOpen: handleOpenActivityPost,
  } = useOpen();

  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className="idea-activity-template">
      <div className="idea-activity-template-titles">
        <BlackTitleTextIons text="活動報告" size="normal" />
        {props.idea.user.userId === user?.userId && (
          <RoundWhiteButtonIons onClick={handleOpenActivityPost}>
            投稿する
          </RoundWhiteButtonIons>
        )}
      </div>

      <IdeaActivities
        idea_activities={props.idea_activities}
        deleteIdeaActivity={props.deleteIdeaActivity}
        loading={props.deleteLoading}
        apiError={props.deleteApiError}
      />

      <IdeaActivityPostPopup
        open={activity_post_open}
        onClose={handleCloseActivityPost}
        ideaId={props.idea.ideaId}
        sendIdeaActivity={props.sendIdeaActivity}
        loading={props.sendLoading}
        apiError={props.sendApiError}
      />

      {props.sendLoading || (props.deleteLoading && <LoadingOverlaySpinner />)}
    </div>
  );
};

export default IdeaActivityTemplate;
