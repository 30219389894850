import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getFollowersAPI } from '../../../services/api/user/follow/getFollowersAPI';
import { UserCardType } from '../../../types/api/user/UserTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetFollowersResult = UseQueryResult<UserCardType[]> & {
  queryKey: string[];
};

// フォロワー一覧情報を取得するためのカスタムフック
export const useGetFollowers = (
  userId?: string | null,
): UseGetFollowersResult => {
  const queryKey = ['followers', userId];

  const result = useQuery<UserCardType[]>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!userId,
    queryFn: async () => {
      if (!userId) throw new Error('userId is required');
      return getFollowersAPI(userId);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetFollowersResult;
};
