import React from 'react';
import { CategoryType } from '../../../../types/api/category/CategoryTypes';
import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import IdeaImgIons from '../../../ions/img/IdeaImg.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { TagTextIons } from '../../../ions/text/tag/TagText.Ions';
import './IdeaCard.Molecules.css';
import './SakairiIdeaCard.Molecules.css';

type IdeaCardProps = {
  idea: IdeaCardType;
  width: number | string;
  text_size?: 'extra-small' | 'small';
  hover_effect?: boolean;
  display_description?: boolean;
};

export const SakairiIdeaCardMolecules: React.FC<IdeaCardProps> = React.memo(
  (props) => {
    const { idea } = props;

    return (
      <div
        className={`sakairi-idea-card-molecules-container ${props.hover_effect ? 'hover-effect' : ''}`}
        style={{
          width: props.width,
        }}
        onClick={() => {
          PageNavigation.goToIdeaPage(idea.ideaId);
        }}
      >
        <IdeaImgIons
          img_src={idea.imgSrc}
          alt={idea.title}
          className="idea-card-molecules-img"
          isAdopted={idea.isAdopted}
        />
        <main className="sakairi-idea-card-molecules-main">
          <header className="idea-card-molecules-header">
            <UserIconImgIons
              userName={idea.user?.userName}
              img_src={idea.user?.imgSrc}
              size={'small'}
            />
            <section className="idea-card-molecules-tags">
              {idea.categories.map((category: CategoryType) => (
                <TagTextIons
                  key={category.categoryId}
                  text={category.name}
                  size={props.text_size}
                />
              ))}
            </section>
          </header>

          <section className="sakairi-idea-card-molecules-texts">
            <BodyTextBoldIons
              // rows={2}
              size={props.text_size}
              text={props.idea.title}
            />
            <div className="sakairi-idea-card-molecules-descripiton">
              <span className="sakairi-idea-card-molecules-circle">⚫︎</span>
              <BodySubTextIons
                rows={2}
                size={props.text_size}
                text={props.idea.description}
              />
            </div>
          </section>
          {/* 
          <section className="idea-card-molecules-progress">
            <DefaultProgressBarIons width={'70%'} progress={10} size="small" />
            <BodySubTextIons
              text={`${idea.numOfViews}PV`}
              size={props.text_size}
            />
          </section>

          <section className="idea-card-molecules-progress">
            <div className="idea-card-molecules-cell">
              <FavoriteBorderIcon sx={{ color: 'grey', fontSize: icon_size }} />
              <BodySubTextIons
                text={idea.numOfLikes.toString()}
                size={props.text_size}
              />
            </div>
            <div className="idea-card-molecules-cell">
              <PeopleIcon sx={{ color: 'grey', fontSize: icon_size }} />
              <BodySubTextIons
                text={`出資 ${idea.numOfInvestors} 人`}
                size={props.text_size}
              />
            </div>
          </section> */}
        </main>
      </div>
    );
  },
);
