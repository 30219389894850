import './StepNavigation.css';

interface StepNavigationProps {
  steps: string[];
  currentStep: number;
  activeColor?: string;
  inactiveColor?: string;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  steps = [],
  currentStep = 1,
  activeColor = '#383E86',
  inactiveColor = '#D1D5DB',
}) => {
  return (
    <nav className="step-navigation">
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        const isActive = stepNumber <= currentStep;
        const color = isActive ? activeColor : inactiveColor;

        return (
          <li
            key={stepNumber}
            className={
              `step-container ${index === 0 ? 'first-step' : ''} ` +
              `${index === steps.length - 1 ? 'last-step' : ''} ` +
              `${index > 0 && index < steps.length - 1 ? 'middle-step' : ''} `
            }
          >
            <div
              className={
                `step-circle ${!isActive ? 'disabled' : ''} ` +
                `${index < steps.length - 1 ? 'with-after' : ''} ` +
                `${index > 0 ? 'with-before' : ''} `
              }
              style={{
                borderColor: color,
              }}
            >
              <span className="step-label" style={{ color }}>
                STEP
              </span>
              <span className="step-number" style={{ color }}>
                {String(stepNumber).padStart(2, '0')}
              </span>
            </div>
            <span
              className="step-title"
              style={{
                color,
                textAlign:
                  index > 0 && index < steps.length - 1 ? 'center' : undefined,
              }}
            >
              {step}
            </span>
          </li>
        );
      })}
    </nav>
  );
};

export default StepNavigation;
