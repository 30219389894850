import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { RootState } from '../../../redux/store';
import LoadingOverlaySpinner from '../../molecules/spinner/LoadingOverlaySpinner';
import VerifyEmailSuccessTemplate from '../../templates/auth/verify/VerifyEmailSuccessTemplate';
import NotFoundPage from '../error/NotFoundPage';

// メールアドレス認証をするだけのページ
const UpdateEmailByTokenPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  const { token } = useParams();
  const {
    handlers: apiHandlers,
    errors: apiErrors,
    loadings: apiLoadings,
  } = useAuthActions();

  useEffect(() => {
    if (token) {
      apiHandlers.handleVerifyEmail({
        token,
      });
    }
  }, []);

  // ロード中ならスピナーを表示
  if (apiLoadings.verifyEmail === true) return <LoadingOverlaySpinner />;
  if (apiErrors.verifyEmail !== null) return <NotFoundPage />;

  return <VerifyEmailSuccessTemplate isSP={isXS} />;
};

export default UpdateEmailByTokenPage;
