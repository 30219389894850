import { RoomCardType } from '../../../../types/api/room/RoomTypes';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { RoomCardMolecules } from '../../../molecules/card/room/RoomCard.Molecules';
import './RoomCardGrid.css';

interface TagsCheckboxGridProps {
  isSP: boolean;
  rooms: RoomCardType[];
  title: string;
  num: number;
}

const RoomCardGridWithHeader: React.FC<TagsCheckboxGridProps> = (props) => {
  return (
    <div data-testid="room-card-grid" className={`room-card-grid-container `}>
      <div className={`card-grid-header ${props.isSP ? '' : 'pc'}`}>
        <div className="card-grid-header-title">
          <BodyTextBoldIons
            text={props.title}
            size={props.isSP ? 'normal' : 'medium'}
          />
          <BodySubTextIons text={`(${props.num}件)`} size="small" />
        </div>
      </div>
      {props.rooms.map((room) => (
        <RoomCardMolecules
          room_id={room.roomId}
          userName={room.toUsers?.map((user) => user.userName).join('・') || ''}
          imgSrc={
            room.toUsers && room.toUsers.length > 0
              ? room.toUsers[0].imgSrc
              : null
          }
          lastMessage={room.lastChat?.text || ''}
          lastCreatedAt={room.lastChat?.createdAt || room.createdAt}
          unread_count={room.numOfUnread}
        />
      ))}
    </div>
  );
};

export default RoomCardGridWithHeader;
