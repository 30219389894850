import React from 'react';
import { useGetCategories } from '../../../../hooks/api/useGetCategories';
import { CategoryType } from '../../../../types/api/category/CategoryTypes';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import CheckboxGrid from '../../../molecules/checkbox/grid/CheckboxGrid';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';
import './CategoriesCheckboxGrid.css';

interface CategoriesCheckboxGridProps {
  categoryIds: number[];
  error: string | null;
  handleChange: (categoryId: number) => void;
  columns: number;
  require: boolean | null;
  num?: number;
  max?: number;
  isSP?: boolean;
}

const CategoriesCheckboxGrid: React.FC<CategoriesCheckboxGridProps> =
  React.memo((props) => {
    const { data: allCategories } = useGetCategories();
    return (
      <section className="tag-checkbox-grid-wrapper">
        {props.num ? (
          <NumAndTitle
            num={props.num}
            title="カテゴリーを選択"
            num_size="small"
            title_size="small"
          />
        ) : props.require === null ? (
          <BodyTextBoldIons size="small" text="カテゴリーを選択" />
        ) : (
          <RequireAndTitle
            require={props.require}
            title="カテゴリーを選択"
            hint={'興味のあるユーザーへ優先的に\n表示されるようになります'}
            hint_placement={props.isSP ? 'top' : 'top-left-center'}
          />
        )}
        <CheckboxGrid
          columns={props.columns}
          error={props.error}
          max={props.max}
          num_of_checked={props.categoryIds.length}
          options={(allCategories || []).map((category: CategoryType) => {
            return {
              label: category.name,
              value: category.categoryId.toString(),
              checked: props.categoryIds.includes(category.categoryId),
              onChange: () => {
                props.handleChange(category.categoryId);
              },
              disabled: false,
              size: 'small',
            };
          })}
        />
      </section>
    );
  });

export default CategoriesCheckboxGrid;
