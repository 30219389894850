import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  LineShareButton,
  LinkedinShareButton,
} from 'react-share';
import {
  EmailCircleButtonAtoms,
  FacebookCircleButtonAtoms,
  HatenaCircleButtonAtoms,
  InstaCircleButtonAtoms,
  LineCircleButtonAtoms,
  LinkedinCircleButtonAtoms,
  XCircleButtonAtoms,
} from '../../../atoms/sns/circleButton/SNSCircleButton.Atoms';
import { SNSButtonSizeTypeAtoms } from '../../../atoms/sns/SNSButton.type';
interface SNSCircleButtonProps {
  type: 'Facebook' | 'X' | 'Line' | 'Hatena' | 'Insta' | 'Linkedin' | 'Email';
  url: string;
  title: string;
  size?: SNSButtonSizeTypeAtoms;
}

export const SNSShareButton: React.FC<SNSCircleButtonProps> = React.memo(
  ({ type, size = 'small', url, title }) => {
    switch (type) {
      case 'Facebook':
        return (
          <FacebookShareButton url={url}>
            <FacebookCircleButtonAtoms size={size} url={url} title={title} />
          </FacebookShareButton>
        );
      case 'X':
        return <XCircleButtonAtoms size={size} url={url} title={title} />;
      case 'Line':
        return (
          <LineShareButton url={url} title={url}>
            <LineCircleButtonAtoms size={size} />
          </LineShareButton>
        );
      case 'Hatena':
        return (
          <HatenaShareButton url={url}>
            <HatenaCircleButtonAtoms size={size} url={url} title={title} />
          </HatenaShareButton>
        );
      case 'Insta':
        return <InstaCircleButtonAtoms size={size} url={url} title={title} />;
      case 'Linkedin':
        return (
          <LinkedinShareButton url={url}>
            <LinkedinCircleButtonAtoms size={size} url={url} title={title} />
          </LinkedinShareButton>
        );
      case 'Email':
        return (
          <EmailShareButton url={title}>
            <EmailCircleButtonAtoms size={size} url={url} title={title} />
          </EmailShareButton>
        );
      default:
        return null;
    }
  },
);
