import { NotifyCardType } from '../../../../types/api/notifications/NotificationType';
import { NotificationsFilterQuery } from '../../../../types/query/notificationsQuery';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { formatTimeAgo } from '../../../../utils/timeCalculator';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultCheckboxIons from '../../../ions/checkbox/DefaultCheckbox.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import NotifyCardMolecules from '../../../molecules/card/notify/NotifyCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import './NotifyCardGrid.css';

interface NotifyCardGridProps {
  isSP: boolean;
  filter: NotificationsFilterQuery;
  notifications: NotifyCardType[];
  handleReadAllNotifications: () => void;
  page: number;
  maxPage: number;
}

const NotifyCardGrid: React.FC<NotifyCardGridProps> = (props) => {
  return (
    <div
      data-testid="notify-card-grid"
      className={`notify-card-grid-container `}
    >
      <div className="notify-card-grid-container-header">
        <DefaultCheckboxIons
          label="既読の通知を表示しない"
          checked={props.filter === 'unread'}
          onChange={() =>
            props.filter === 'unread'
              ? PageNavigation.goToNotificationsPage()
              : PageNavigation.goToNotificationsPage({
                  filter: 'unread',
                })
          }
          value="unread"
        />
        <RoundWhiteButtonIons onClick={props.handleReadAllNotifications}>
          <BodyTextIons
            text="全て既読にする"
            size="extra-small"
            className="notify-card-grid-container-header-button-text"
          />
        </RoundWhiteButtonIons>
      </div>

      {props.notifications.length > 0 ? (
        props.notifications.map((notify) => (
          <NotifyCardMolecules
            isSP={props.isSP}
            type={notify.type}
            isRead={notify.isRead}
            title={notify.title}
            date={formatTimeAgo(notify.notifyAt)}
            description={notify.description}
            notifyId={notify.notifyId}
          />
        ))
      ) : (
        <BodyTextIons
          text="通知はありません"
          size="small"
          bold="light"
          className="notify-card-grid-not-exists"
        />
      )}

      <PageNationButtons num={props.page} max_num={props.maxPage} />
    </div>
  );
};

export default NotifyCardGrid;
