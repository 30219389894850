import { RequestCardType } from '../types/api/request/RequestTypes';

export const NULL_REQUEST_DETAIL_DATA: RequestCardType = {
  requestId: null,
  imgSrc: 'REQUEST-NULL.png',
  title: '',
  categories: [],
  description: '',
  deadline: null,
  user: {
    userId: '1',
    userName: '佐藤 一郎',
    bannerImgSrc: 'REIDEA-BANNER.jpg',
    imgSrc: 'REIDEA-BANNER.jpg',
    introduction: '未来のアイデアを形にすることを目指しています。',
    isCorp: false,
    isFollowed: false,
    isFollower: false,
    isMe: true,
  },
};
