import { ChangeEvent, useCallback, useEffect, useState } from 'react';

interface UseTextfieldProps {
  value: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleReset: () => void;
}

const useTextfieldMax = (
  init: string,
  max: number,
  validate?: (value: string) => string | null,
): UseTextfieldProps => {
  const [value, setValue] = useState(init || '');
  const [error, setError] = useState<string | null>(null);

  // initの値が変更された時にvalueを更新
  useEffect(() => {
    setValue(init);
  }, [init]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      if (max && e.target.value.length > max) {
        setError(`最大${max}文字までです`);
        return;
      } else {
        if (validate) {
          const validationError = validate(e.target.value);
          setError(validationError);
        } else {
          setError(null);
        }
        setValue(e.target.value);
      }
    },
    [],
  );

  const handleReset = useCallback(() => {
    setValue('');
    setError(null);
  }, []);

  return { value, error, handleChange, handleReset };
};

export default useTextfieldMax;
