import { useCallback, useState } from 'react';
import { createContactThemeType } from '../../../../services/api/contact/createContactAPI.type';

interface ValidateRequestContactFormErrors {
  userName: string | null;
  detail: string | null;
}

interface IdeaFormValidators {
  validateRequestContactForm: (props: createContactThemeType) => boolean;
  reset: () => void;
}

export const useContactThemeFormValidator = () => {
  const [userName_error, setUserNameError] = useState<string | null>(null);
  const [detail_error, setDetailError] = useState<string | null>(null);

  const validateRequestContactForm = useCallback(
    (props: createContactThemeType) => {
      let isValid = true;

      if (!props.userName) {
        setUserNameError('会社名を入力してください');
        isValid = false;
      } else {
        setUserNameError(null);
      }

      if (!props.detail) {
        setDetailError('お題詳細を入力してください');
        isValid = false;
      } else {
        setDetailError(null);
      }

      return isValid;
    },
    [],
  );

  const reset = () => {
    setUserNameError(null);
    setDetailError(null);
  };

  const validateErrors: ValidateRequestContactFormErrors = {
    userName: userName_error,
    detail: detail_error,
  };

  const validators: IdeaFormValidators = {
    validateRequestContactForm,
    reset,
  };

  return {
    validateErrors,
    validators,
  };
};
