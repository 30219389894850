import { PurchaseError } from '../../../../types/api/investment/purchase/PurchaseError.type';
import { PurchaseInputType } from './purchaseAPI.type';

// アイデアの出資商品を取得するAPI
export const purchaseInvestmentAPI = async (
  input: PurchaseInputType,
  onSuccess?: () => void,
): Promise<PurchaseError | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/purchases/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(input),
      },
    );

    if (!response.ok) {
      const errorData: PurchaseError = await response.json();
      return errorData;
    }

    if (onSuccess) onSuccess();
    return null;
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
