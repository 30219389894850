import { useCallback, useState } from 'react';

interface UseLoadingReturn {
  loading: boolean;
  handleStart: () => void;
  handleEnd: () => void;
  handleChangeLoading: () => void;
}

// 開閉の状態を管理するカスタムフック
const useLoading = (init?: boolean): UseLoadingReturn => {
  const [loading, setLoading] = useState<boolean>(init ?? false);

  const handleStart = useCallback(() => {
    setLoading(true);
  }, []);

  const handleEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const handleChangeLoading = useCallback(() => {
    setLoading((prevOpen) => !prevOpen);
  }, []);

  return {
    loading,
    handleStart,
    handleEnd,
    handleChangeLoading,
  };
};

export default useLoading;
