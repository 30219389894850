import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAndVerifyCorpThunk } from '../../redux/auth/thunk/createAndVerifyCorpThunk';
import { loginWithMail } from '../../redux/auth/thunk/mailLoginThunk';
import { registerCorpWithMail } from '../../redux/auth/thunk/mailRegisterCorpThunk';
import { registerWithMail } from '../../redux/auth/thunk/mailRegisterThunk';
import { signoutThunk } from '../../redux/auth/thunk/signoutThunk';
import { updateAuthThunk } from '../../redux/auth/thunk/updateAuthThunk';
import {
  closeLoginPopup,
  openLoginPopup,
} from '../../redux/slice/loginPopupSlice';
import {
  closeRegisterPopup,
  openRegisterPopup,
} from '../../redux/slice/registerPopupSlice';
import { googleSigninAPI } from '../../services/api/auth/login/google/googleSigninAPI';
import { googleSignupAPI } from '../../services/api/auth/signup/google/googleRegisterAPI';
import { lineSignupAPI } from '../../services/api/auth/signup/line/lineRegisterAPI';
import { lineSigninAPI } from '../../services/api/auth/signup/line/lineSigninAPI';
import { resetEmailAPI } from '../../services/api/auth/update-email/updateEmailAPI';
import { verifyEmailAPI } from '../../services/api/auth/update-email/verifyEmailAPI';
import { createTokenForPasswordAPI } from '../../services/api/auth/update-password/createTokenForPasswordAPI';
import { updatePasswordByTokenAPI } from '../../services/api/auth/update-password/updatePasswordByTokenAPI';
import { updateMeAPI } from '../../services/api/user/update/updateMeAPI';
import { updateMeCorpAPI } from '../../services/api/user/update/updateMeCorpAPI';
import { AuthErrorType } from '../../types/api/auth/AuthError.type';
import { PageNavigation } from '../../utils/pageNavigation';
import useLoading from '../useLoading';
import { AuthFormState } from './useAuthForm.hooks';

export interface AuthAPILoadingState {
  register: boolean;
  corpRegister: boolean;
  createCorp: boolean;
  login: boolean;
  updateMe: boolean;
  updateMeCorp: boolean;
  resetEmail: boolean;
  verifyEmail: boolean;
  resetPassword: boolean;
  updatePassword: boolean;
}

export interface AuthAPIErrors {
  register: AuthErrorType | null;
  corpRegister: AuthErrorType | null;
  createCorp: AuthErrorType | null;
  login: AuthErrorType | null;
  updateMe: AuthErrorType | null;
  updateMeCorp: AuthErrorType | null;
  resetEmail: AuthErrorType | null;
  verifyEmail: AuthErrorType | null;
  resetPassword: AuthErrorType | null;
  updatePassword: AuthErrorType | null;
}

type FuncPropsType = {
  onSuccess?: () => void;
  onError?: () => void;
};

export interface AuthAPIHandlers {
  handleOpenLogin: () => void;
  handleCloseLogin: () => void;
  handleOpenRegister: () => void;
  handleCloseRegister: () => void;
  handleGoogleRegister: () => void;
  handleLineRegister: () => void;
  handleLogout: () => void;
  handleLogin: (props: FuncPropsType) => void;
  handleGoogleSignin: (redirectUrl?: string) => void;
  handleLineSignin: () => void;
  handleRegister: () => void;
  handleCorpRegister: () => void;
  handleCreateCorpByToken: (tokenId: string) => void;
  handleUpdateMe: () => void;
  handleUpdateMeCorp: () => void;
  handleResetEmail: (props: FuncPropsType) => void;
  handleVerifyEmail: (props: { token: string } & FuncPropsType) => void;
  handleResetPassword: (props: FuncPropsType) => void;
  handleUpdatePassword: (props: { token: string } & FuncPropsType) => void;
}

export const useAuthActions = (input?: {
  redirectUrl?: string | null;
  values?: AuthFormState;
}): {
  loadings: AuthAPILoadingState;
  errors: AuthAPIErrors;
  handlers: AuthAPIHandlers;
} => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(async () => {
    await PageNavigation.goToHomePage();
    await dispatch(signoutThunk() as any);
  }, [dispatch]);

  // ログインをクリックした際の処理
  const handleOpenLogin = useCallback(() => {
    dispatch(closeRegisterPopup());
    dispatch(openLoginPopup());
  }, [dispatch]);

  const handleCloseLogin = useCallback(() => {
    dispatch(closeLoginPopup());
  }, [dispatch]);

  // 新規会員登録をクリックした際の処理
  const handleOpenRegister = useCallback(() => {
    dispatch(closeLoginPopup());
    dispatch(openRegisterPopup());
  }, [dispatch]);

  const handleCloseRegister = useCallback(() => {
    dispatch(closeRegisterPopup());
  }, [dispatch]);

  const handleGoogleSignin = useCallback(async (redirectUrl?: string) => {
    await googleSigninAPI(redirectUrl);
  }, []);

  const handleLineSignin = useCallback(async () => {
    await lineSigninAPI();
  }, []);

  // google新規会員登録をクリックした際の処理
  const handleGoogleRegister = useCallback(async () => {
    googleSignupAPI();
  }, []);

  // line新規会員登録をクリックした際の処理
  const handleLineRegister = useCallback(async () => {
    lineSignupAPI();
  }, []);

  // ログイン処理
  const {
    loading: loginLoading,
    handleStart: handleStartLogin,
    handleEnd: handleEndLogin,
  } = useLoading(false);

  const [loginError, setLoginError] = useState<AuthErrorType | null>(null);

  useEffect(() => {
    setLoginError(null);
  }, [input?.values?.userNameOrEmail, input?.values?.password]);

  const handleLogin = useCallback(
    async (props: FuncPropsType) => {
      if (input?.values?.userNameOrEmail && input?.values?.password) {
        handleStartLogin();
        const result = await dispatch(
          loginWithMail({
            userNameOrEmail: input?.values?.userNameOrEmail,
            password: input?.values?.password,
          }) as any,
        );

        if (result === null) {
          if (props.onSuccess) props.onSuccess();
          handleEndLogin();
          setLoginError(null);
        } else {
          if (props.onError) props.onError();
          handleEndLogin();
          setLoginError(result);
        }
      }
    },
    [dispatch, input?.values?.userNameOrEmail, input?.values?.password],
  );

  // 会員登録処理
  const {
    loading: registerLoading,
    handleStart: handleStartRegister,
    handleEnd: handleEndRegister,
  } = useLoading(false);

  const [registerError, setRegisterError] = useState<AuthErrorType | null>(
    null,
  );

  useEffect(() => {
    setRegisterError(null);
  }, [input?.values?.userName, input?.values?.email, input?.values?.password]);

  const handleRegister = useCallback(async () => {
    if (
      input?.values?.userName &&
      input?.values?.email &&
      input?.values?.password
    ) {
      handleStartRegister();
      const result: AuthErrorType | null = await dispatch(
        registerWithMail({
          info: {
            userName: input?.values?.userName,
            email: input?.values?.email,
            password: input?.values?.password,
          },
        }) as any,
      );
      if (result === null) {
        handleEndRegister();
        PageNavigation.goToVerifyEmailSendPage();
      } else {
        handleEndRegister();
        setRegisterError(result);
      }
    }
  }, [
    dispatch,
    input?.values?.userName,
    input?.values?.email,
    input?.values?.password,
  ]);

  // 会員登録をクリックした際の処理(事業者)
  const {
    loading: corpRegisterLoading,
    handleStart: handleStartCorpRegister,
    handleEnd: handleEndCorpRegister,
  } = useLoading(false);

  const [corpRegisterError, setCorpRegisterError] =
    useState<AuthErrorType | null>(null);

  useEffect(() => {
    setCorpRegisterError(null);
  }, [input?.values?.userName, input?.values?.email, input?.values?.password]);

  const handleCorpRegister = useCallback(async () => {
    if (
      input?.values?.userName &&
      input?.values?.email &&
      input?.values?.password
    ) {
      handleStartCorpRegister();
      const result: AuthErrorType | null = await dispatch(
        registerCorpWithMail({
          info: {
            userName: input?.values?.userName,
            email: input?.values?.email,
            password: input?.values?.password,
          },
        }) as any,
      );
      if (result === null) {
        handleEndCorpRegister();
        setCorpRegisterError(null);
        PageNavigation.goToVerifyEmailSendPage();
      } else {
        handleEndCorpRegister();
        setCorpRegisterError(result);
      }
    }
  }, [
    dispatch,
    input?.values?.userName,
    input?.values?.email,
    input?.values?.password,
  ]);

  // トークンから事業者アカウントを作成
  const {
    loading: createCorpLoading,
    handleStart: handleStartCreateCorp,
    handleEnd: handleEndCreateCorp,
  } = useLoading(false);

  const [createCorpError, setCreateCorpError] = useState<AuthErrorType | null>(
    null,
  );

  // 企業アカウントの作成と認証を行って自動ログイン
  const handleCreateCorpByToken = async (tokenId: string) => {
    handleStartCreateCorp();
    const result = await dispatch(
      createAndVerifyCorpThunk({
        tokenId: tokenId,
        userName: input?.values?.userName || '',
        corpName: input?.values?.corpName || '',
        zipCode: input?.values?.zipCode || '',
        address: input?.values?.address || '',
        phoneNumber: input?.values?.phoneNumber || '',
        categoryIds: input?.values?.categoryIds || [],
        img: input?.values?.img || null,
        imgSrc: input?.values?.imgSrc || null,
        bannerImg: input?.values?.bannerImg || null,
        bannerImgSrc: input?.values?.bannerImgSrc || null,
        introduction: input?.values?.introduction || null,
        email: input?.values?.email || '',
        websiteUrl: input?.values?.websiteUrl || null,
        snsUrl1: input?.values?.snsUrl1 || null,
        snsUrl2: input?.values?.snsUrl2 || null,
        snsUrl3: input?.values?.snsUrl3 || null,
      }) as any,
    );
    if (result === null) {
      handleEndCreateCorp();
      PageNavigation.goToSignUpCompletePage();
      setCreateCorpError(null);
    } else {
      handleEndCreateCorp();
      setCreateCorpError(result);
    }
  };

  // ユーザー情報の更新を行う(一般ユーザー)
  const {
    loading: updateMeLoading,
    handleStart: handleStartUpdateMe,
    handleEnd: handleEndUpdateMe,
  } = useLoading(false);
  const [updateMeError, setUpdateMeError] = useState<AuthErrorType | null>(
    null,
  );
  const handleUpdateMe = async () => {
    handleStartUpdateMe();
    const result = await updateMeAPI({
      user: {
        userName: input?.values?.userName || '',
        img: input?.values?.img || null,
        imgSrc: input?.values?.imgSrc || null,
        bannerImg: input?.values?.bannerImg || null,
        bannerImgSrc: input?.values?.bannerImgSrc || null,
        introduction: input?.values?.introduction || '',
        email: input?.values?.email || '',
        categoryIds: input?.values?.categoryIds || [],
      },
    });
    if (result === null) {
      handleEndUpdateMe();
      // ログイン状態を更新
      await dispatch(updateAuthThunk() as any);
      PageNavigation.goToMyPage();
      setUpdateMeError(null);
    } else {
      handleEndUpdateMe();
      setUpdateMeError(result);
    }
  };

  // ユーザー情報の更新を行う(事業者)
  const {
    loading: updateMeCorpLoading,
    handleStart: handleStartUpdateMeCorp,
    handleEnd: handleEndUpdateMeCorp,
  } = useLoading(false);

  const [updateMeCorpError, setUpdateMeCorpError] =
    useState<AuthErrorType | null>(null);

  const handleUpdateMeCorp = async () => {
    handleStartUpdateMeCorp();
    const result = await updateMeCorpAPI({
      user: {
        userName: input?.values?.userName || '',
        corpName: input?.values?.corpName || '',
        zipCode: input?.values?.zipCode || '',
        address: input?.values?.address || '',
        phoneNumber: input?.values?.phoneNumber || '',
        img: input?.values?.img || null,
        imgSrc: input?.values?.imgSrc || null,
        bannerImg: input?.values?.bannerImg || null,
        bannerImgSrc: input?.values?.bannerImgSrc || null,
        introduction: input?.values?.introduction || '',
        categoryIds: input?.values?.categoryIds || [],
      },
    });
    if (result === null) {
      handleEndUpdateMeCorp();
      // ログイン状態を更新
      await dispatch(updateAuthThunk() as any);
      PageNavigation.goToMyPage();
      setUpdateMeCorpError(null);
    } else {
      handleEndUpdateMeCorp();
      setUpdateMeCorpError(result);
    }
  };

  // メール認証を行う(トークンが発行され、当該アドレスで認証メールを受け取る)
  const {
    loading: resetEmailLoading,
    handleStart: handleStartResetEmail,
    handleEnd: handleEndResetEmail,
  } = useLoading(false);

  const [resetEmailError, setResetEmailError] = useState<AuthErrorType | null>(
    null,
  );

  const handleResetEmail = async (props: FuncPropsType) => {
    if (input?.values?.password && input?.values?.email) {
      // メール認証APIを呼び出す
      handleStartResetEmail();
      const result = await resetEmailAPI({
        info: {
          password: input?.values?.password,
          email: input?.values?.email,
        },
      });
      if (result === null) {
        if (props.onSuccess) props.onSuccess();
        handleEndResetEmail();
        setResetEmailError(null);
      } else {
        if (props.onError) props.onError();
        handleEndResetEmail();
        setResetEmailError(result);
      }
    }
  };

  // メール認証を行う(トークンを用いてGET通信を送る)
  const {
    loading: verifyEmailCorpLoading,
    handleStart: handleStartVerifyEmail,
    handleEnd: handleEndVerifyEmail,
  } = useLoading(false);

  const [verifyEmailError, setVerifyEmailError] =
    useState<AuthErrorType | null>(null);

  const handleVerifyEmail = async (
    props: { token: string } & FuncPropsType,
  ) => {
    // メール認証APIを呼び出す
    handleStartVerifyEmail();
    const result = await verifyEmailAPI(props.token);
    if (result === null) {
      if (props.onSuccess) props.onSuccess();
      handleEndVerifyEmail();
      setVerifyEmailError(null);
    } else {
      if (props.onError) props.onError();
      handleEndVerifyEmail();
      setVerifyEmailError(result);
    }
  };

  // パスワード再設定を始める(トークンが発行され、当該アドレスで再設定用メールを受け取る)
  const {
    loading: resetPasswordLoading,
    handleStart: handleStartResetPassword,
    handleEnd: handleEndResetPassword,
  } = useLoading(false);

  const [resetPasswordError, setResetPasswordError] =
    useState<AuthErrorType | null>(null);

  const handleResetPassword = async (props: FuncPropsType) => {
    if (input?.values?.email) {
      // メール認証APIを呼び出す
      handleStartResetPassword();
      const result = await createTokenForPasswordAPI(input?.values?.email);
      if (result === null) {
        if (props.onSuccess) props.onSuccess();
        handleEndResetPassword();
        setResetPasswordError(null);
      } else {
        if (props.onError) props.onError();
        handleEndResetPassword();
        setResetPasswordError(result);
      }
    }
  };

  // トークンを利用して、パスワードを更新する
  const {
    loading: updatePasswordLoading,
    handleStart: handleStartUpdatePassword,
    handleEnd: handleEndUpdatePassword,
  } = useLoading(false);

  const [updatePasswordError, setUpdatePasswordError] =
    useState<AuthErrorType | null>(null);

  const handleUpdatePassword = async (
    props: { token: string } & FuncPropsType,
  ) => {
    // メール認証APIを呼び出す
    handleStartUpdatePassword();
    const result = await updatePasswordByTokenAPI({
      info: {
        token: props.token,
        newPassword: input?.values?.password || '',
      },
    });
    if (result === null) {
      if (props.onSuccess) props.onSuccess();
      handleEndUpdatePassword();
      setUpdatePasswordError(null);
    } else {
      if (props.onError) props.onError();
      handleEndUpdatePassword();
      setUpdatePasswordError(result);
    }
  };

  const loadings: AuthAPILoadingState = {
    register: registerLoading,
    corpRegister: corpRegisterLoading,
    createCorp: createCorpLoading,
    login: loginLoading,
    updateMe: updateMeLoading,
    updateMeCorp: updateMeCorpLoading,
    resetEmail: resetEmailLoading,
    verifyEmail: verifyEmailCorpLoading,
    resetPassword: resetPasswordLoading,
    updatePassword: updatePasswordLoading,
  };

  const errors: AuthAPIErrors = {
    register: registerError,
    corpRegister: corpRegisterError,
    createCorp: createCorpError,
    login: loginError,
    updateMe: updateMeError,
    updateMeCorp: updateMeCorpError,
    resetEmail: resetEmailError,
    verifyEmail: verifyEmailError,
    resetPassword: resetPasswordError,
    updatePassword: updatePasswordError,
  };

  const handlers: AuthAPIHandlers = {
    handleLogout,
    handleCloseLogin,
    handleOpenLogin,
    handleCloseRegister,
    handleOpenRegister,
    handleGoogleRegister,
    handleLineRegister,
    handleRegister,
    handleCorpRegister,
    handleLogin,
    handleGoogleSignin,
    handleLineSignin,
    handleCreateCorpByToken,
    handleUpdateMe,
    handleUpdateMeCorp,
    handleResetEmail,
    handleVerifyEmail,
    handleResetPassword,
    handleUpdatePassword,
  };

  return { loadings, errors, handlers };
};
