import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotifyCardType } from '../../types/api/notifications/NotificationType';

export interface NotifyState {
  notifications: NotifyCardType[];
  unreadNotifyCount: number;
  unreadChatCount: number;
}

const initialState: NotifyState = {
  notifications: [],
  unreadNotifyCount: 0,
  unreadChatCount: 0,
};

const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    // 通知情報(未読件数も)を更新する
    setNotifications: (state, action: PayloadAction<NotifyCardType[]>) => {
      state.notifications = action.payload;
      state.unreadNotifyCount = (action.payload || []).filter(
        (n) => !n.isRead,
      ).length;
    },

    // 未読件数(通知)を更新する
    setUnreadNotifyCount: (state, action: PayloadAction<number>) => {
      state.unreadNotifyCount = action.payload;
    },

    // 未読件数(チャット)を更新する
    setUnreadChatCount: (state, action: PayloadAction<number>) => {
      state.unreadChatCount = action.payload;
    },
  },
});

export const { setNotifications, setUnreadNotifyCount, setUnreadChatCount } =
  notifySlice.actions;
export default notifySlice.reducer;
