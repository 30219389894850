import {
  IDEA_CALCULATION_FORMULA_TEXT,
  IDEA_MAX_POINT,
} from '../../../../../constants/max';
import { usePageNavigation } from '../../../../../hooks/usePageNavigation';
import { CategoryType } from '../../../../../types/api/category/CategoryTypes';
import { IdeaDetailType } from '../../../../../types/api/idea/IdeaTypes';
import { ideaPageViewQueryMap } from '../../../../../types/query/ideasQuery';
import RoundPurpleButtonIons from '../../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../../ions/button/round/White/RoundWhiteButton.Ions';
import SelectedTabButtonIons from '../../../../ions/button/selected/SelectedTabButton.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import TooltipPopoverIons from '../../../../ions/popover/TooltipPopover.Ions';
import DefaultProgressBarIons from '../../../../ions/progress/DefaultProgressBar.Ions';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../../ions/text/body/BodyTextBold.Ions';
import SNSCircleButtons from '../../../../molecules/sns/circle/SNSCircleButtons';
import TextTags from '../../../../molecules/text/tags/TextTags';
import './IdeaDetailAndCfSP.css';

interface TagsCheckboxGridProps {
  width?: string | number;
  view: string;
  idea: IdeaDetailType;
  numOfActivities: number;
  numOfComments: number;
  handleIdeaPublish: (ideaId: string) => void;
  handleIdeaUnPublish: (ideaId: string) => void;
  handleIdeaDelete: (ideaId: string) => void;
}

const IdeaDetailAndCfSP: React.FC<TagsCheckboxGridProps> = (props) => {
  const { idea } = props;
  const { goToIdeaPage, goToIdeaEditPage } = usePageNavigation();

  return (
    <section className="idea-detail-and-cf-wrapper-sp">
      <section style={{ width: props.width }} className="idea-wrapper-sp-top">
        {props.idea.isOwner && !idea.isPublic && (
          <div className="idea-detail-and-cf-wrapper-sp-private">
            <BodyTextIons
              text={`公開状況: ${idea.isPublic ? '公開済み' : '非公開 (下書き保存済み)'}`}
              className="idea-detail-and-cf-wrapper-pc-private-text"
            />
            <div className="idea-detail-and-cf-wrapper-sp-private-buttons">
              <RoundWhiteButtonIons
                onClick={() => props.handleIdeaDelete(idea.ideaId)}
                className=""
              >
                削除する
              </RoundWhiteButtonIons>
              <RoundPurpleButtonIons
                onClick={() => goToIdeaEditPage(idea.ideaId)}
                className=""
              >
                編集する
              </RoundPurpleButtonIons>
            </div>
          </div>
        )}
        <IdeaImgIons
          isAdopted={idea.isAdopted}
          img_src={idea.imgSrc}
          className=""
        />
        <div className="idea-wrapper-sp-top-title">
        <BodyTextIons text={idea.title} size="normal" className="idea-wrapper-sp-title" />
        <BodyTextIons text={idea.subTitle} size="small" className="idea-wrapper-sp-subtitle" />
        </div>
        <section className="idea-wrapper-sp-top-progress">
          <section className="idea-wrapper-sp-top-progress-info-backer">
            <BodyTextIons
              text={`${idea.numOfViews} PV`}
              size="small"
              className=""
            />
            <BodyTextIons
              text={`${props.numOfComments}件のコメント`}
              size="small"
              className=""
            />
          </section>

          <DefaultProgressBarIons
            progress={(idea.evalPoint / IDEA_MAX_POINT) * 100}
            size="large"
          />

          <section className="idea-wrapper-sp-top-right-progress-info-days">
            <BodyTextIons
              text={`${idea.numOfLikes} いいね`}
              size="small"
              className=""
            />
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <BodyTextBoldIons
                text={`${idea.evalPoint} pt`}
                size="normal"
                className=""
              />
              <TooltipPopoverIons
                placement={'top-right'}
                text={IDEA_CALCULATION_FORMULA_TEXT}
              />
            </div>
          </section>
        </section>

        <TextTags
          tags={idea.categories.map((category: CategoryType) => ({
            label: category.name,
            value: String(category.categoryId),
          }))}
          size="small"
        />

        <SNSCircleButtons size="small" title={idea.title} />
      </section>

      <section
        style={{ width: props.width }}
        className="idea-wrapper-sp-bottom-tabs"
      >
        {Object.entries(ideaPageViewQueryMap).map(([key, value]) => {
          return (
            <SelectedTabButtonIons
              selected={props.view === key}
              onClick={() => {
                goToIdeaPage(idea.ideaId, { view: key });
              }}
              className=""
            >
              {value +
                ` ${key === 'comment' ? `(${props.numOfComments})` : key === 'activity' ? `(${props.numOfActivities})` : ''}`}
            </SelectedTabButtonIons>
          );
        })}
      </section>
    </section>
  );
};

export default IdeaDetailAndCfSP;
