import React from 'react';
import useOpen from '../../../../hooks/useOpen';
import { CategoryType } from '../../../../types/api/category/CategoryTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import FullRoundPurpleButtonIons from '../../../ions/button/fullRound/Purple/FullRoundPurpleButton.Ions';
import FullRoundWhiteButtonIons from '../../../ions/button/fullRound/White/FullRoundWhiteButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import UserBannerImgIons from '../../../ions/img/UserBannerImg.Ions';
import DefaultSpinnerIons from '../../../ions/spinner/DefaultSpinner.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import TextTags from '../../../molecules/text/tags/TextTags';
import ChatGrid from '../../../organisms/grid/chatGrid/ChatGrid';
import HistoryIdeaCardGridWithHeader from '../../../organisms/grid/historyIdeaCardGrid/HistoryIdeaCardGridWithHeader';
import IdeaCardGridWithHeader from '../../../organisms/grid/IdeaCardGrid/IdeaCardGridWithHeader';
import PurchaseCardGridWithHeader from '../../../organisms/grid/purchaseCardGrid/PurchaseCardGridWithHeader';
import RequestCardGridWithHeader from '../../../organisms/grid/requestCardGrid/RequestCardGridWithHeader';
import RoomCardGridWithHeader from '../../../organisms/grid/roomCardGrid/RoomCardGridWithHeader';
import UserInfoGrid from '../../../organisms/grid/userInfoGrid/UserInfoGrid';
import FollowUsersConfirmPopup from '../../../organisms/popup/FollowUsersConfirmPopup';
import UserPageProfileCard from '../../../organisms/profile/UserPageProfileCard';
import UserPageModeTabButtons from '../../../organisms/tabs/UserPageModeTabButtons';
import './../UserPageTemplate.css';
import { UserPageTemplateProps } from './UserPageTemplate.type';

const UserPageTemplateSP: React.FC<UserPageTemplateProps> = (props) => {
  const { items, numOfItems, mode, user } = props;

  const {
    open: openFollowersPopup,
    handleOpen: handleOpenFollowersPopup,
    handleClose: handleCloseFollowersPopup,
  } = useOpen();

  const {
    open: openFollowedUsersPopup,
    handleOpen: handleOpenFollowedUsersPopup,
    handleClose: handleCloseFollowedUsersPopup,
  } = useOpen();

  const commonProps = {
    isSP: true,
    page: props.page || 1,
    maxPage: props.maxPage || 1,
  };

  if (!user)
    return (
      <div className={`userpage-template-null`}>
        <DefaultSpinnerIons color="grey" />
      </div>
    );

  return (
    <div className="userpage-template">
      <UserBannerImgIons
        img_src={user.bannerImgSrc || 'REIDEA-BANNER.jpg'}
        alt={user.userName}
      />
      <div className="userpage-template-top-container">
        <div className="userpage-template-user-icon-wrapper">
          <UserIconImgIons
            img_src={user.imgSrc}
            userName={user.userName}
            size="extra-large"
            className="userpage-template-user-icon"
          />
          {props.my ? (
            <RoundWhiteButtonIons
              onClick={() => PageNavigation.goToMyEditPage(user.isCorp)}
            >
              <BodyTextIons text="プロフィールを編集" bold="light" />
            </RoundWhiteButtonIons>
          ) : user.isFollowed ? (
            <FullRoundWhiteButtonIons
              onClick={() =>
                props.handleUnFollowUser &&
                props.handleUnFollowUser({
                  follow: {
                    userId: user.userId,
                  },
                })
              }
            >
              フォローを解除する
            </FullRoundWhiteButtonIons>
          ) : (
            <FullRoundPurpleButtonIons
              onClick={() =>
                props.handleFollowUser &&
                props.handleFollowUser({
                  follow: {
                    userId: user.userId,
                  },
                })
              }
            >
              フォローする
            </FullRoundPurpleButtonIons>
          )}
        </div>

        <div className="userpage-template-user-info">
          <BlackTitleTextIons text={user.userName} size="medium" />
          <TextTags
            tags={(user?.categories || []).map((category: CategoryType) => ({
              label: category.name,
              value: String(category.categoryId),
            }))}
            size="extra-small"
          />
          <BodyTextIons text={user.introduction || ''} size="small" />
        </div>
        <UserInfoGrid
          userId={user.userId}
          isCorp={props.isCorp}
          numOfRequests={user.numOfRequests || 0}
          numOfIdeas={user.numOfIdeas || 0}
          numOfFollowedUsers={user.numOfFollowedUsers || 0}
          handleClickFollowedUsers={handleOpenFollowedUsersPopup}
          numOfFollowers={user.numOfFollowers || 0}
          handleClickFollowers={handleOpenFollowersPopup}
        />
      </div>

      <UserPageModeTabButtons
        userId={user.userId}
        mode={mode}
        my={props.my}
        isCorp={props.isCorp}
      />

      <div className="userpage-template-bottom-container">
        <div className="userpage-template-bottom-container-main">
          {mode === 'ideas' || mode === 'received' ? (
            <IdeaCardGridWithHeader
              {...commonProps}
              columns={2}
              ideas={items.userIdeas}
              card_text_size="extra-small"
              title={mode === 'ideas' ? '投稿したアイデア' : '届いたアイデア'}
              num={numOfItems.userIdeas || 0}
              isPublic={mode === 'received' ? undefined : props.isPublic}
              requestId={
                mode === 'received' ? props.requestId || null : undefined
              }
              themes={mode === 'received' ? items.userRequests : undefined}
            />
          ) : mode === 'requests' ? (
            <RequestCardGridWithHeader
              {...commonProps}
              columns={1}
              requests={items.userRequests}
              title="お題一覧"
              num={numOfItems.userRequests}
            />
          ) : mode === 'invested' ? (
            <PurchaseCardGridWithHeader
              {...commonProps}
              purchases={items.userPurchases}
              title="出資履歴"
              num={numOfItems.userPurchases || 0}
            />
          ) : mode === 'intro' ? (
            <UserPageProfileCard user={user} />
          ) : mode === 'applied' || mode === 'viewed' || mode === 'liked' ? (
            <HistoryIdeaCardGridWithHeader
              {...commonProps}
              ideas={items.userIdeas}
              title={
                mode === 'applied'
                  ? '採用履歴'
                  : mode === 'liked'
                    ? 'いいね履歴'
                    : '閲覧履歴'
              }
              num={numOfItems.userIdeas || 0}
            />
          ) : mode === 'chat' && props.my ? (
            items.room && props.handleCreateChat ? (
              <ChatGrid
                roomId={items.room?.roomId || ''}
                numOfAllChat={items.room.numOfAllChat || 0}
                otherUsersViewedAt={
                  items.room.toUsers?.map((user) =>
                    user.viewedAt ? new Date(user.viewedAt) : null,
                  ) || []
                }
                page={props.page || 1}
                title={
                  items.room.toUsers?.map((user) => user.userName).join('・') ||
                  ''
                }
                login_user_id={user.userId}
                chats={items.chats || []}
                size="extra-small"
                only
                handleCreateChat={props.handleCreateChat}
              />
            ) : (
              <RoomCardGridWithHeader
                isSP={true}
                rooms={items.rooms}
                title="チャット一覧"
                num={numOfItems.rooms || 0}
              />
            )
          ) : null}
        </div>
      </div>

      <FollowUsersConfirmPopup
        edit={props.my}
        mode="followers"
        users={items.followers}
        open={openFollowersPopup}
        onClose={handleCloseFollowersPopup}
        handleFollowUser={props.handleFollowUser}
        handleUnFollowUser={props.handleUnFollowUser}
      />

      <FollowUsersConfirmPopup
        edit={props.my}
        mode="followedUsers"
        users={items.followedUsers}
        open={openFollowedUsersPopup}
        onClose={handleCloseFollowedUsersPopup}
        handleFollowUser={props.handleFollowUser}
        handleUnFollowUser={props.handleUnFollowUser}
      />
    </div>
  );
};

export default UserPageTemplateSP;
