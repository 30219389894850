import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getChatsAPI } from '../../../services/api/room/chat/getChatsAPI';
import { GetChatsQueryType } from '../../../services/api/room/chat/getChatsAPI.type';
import { ChatDetailType } from '../../../types/api/chat/ChatTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetChatsResult = UseQueryResult<ChatDetailType[]> & {
  queryKey: readonly [
    'chats',
    GetChatsQueryType & {
      enabled?: boolean;
    },
  ];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetChats = (
  query: GetChatsQueryType & {
    enabled?: boolean;
  },
): UseGetChatsResult => {
  const queryKey = ['chats' + query.roomId, query] as const;

  const result = useQuery<ChatDetailType[]>({
    queryKey,
    enabled: !!query.enabled,
    queryFn: () => getChatsAPI(query),
  });

  return {
    ...result,
    queryKey,
  } as UseGetChatsResult;
};
