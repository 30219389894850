import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAuthActions } from '../../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../../hooks/auth/useAuthForm.hooks';
import { useVisibilityController } from '../../../../hooks/useVisibilityController';
import { RootState } from '../../../../redux/store';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { SNSRoundButton } from '../../../ions/sns/round/SNSRoundButton';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import './LoginPopup.css';

const LoginPopup = React.memo(() => {
  const { isOpen: open } = useSelector((state: RootState) => state.loginPopup);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { isXS: isSP } = useSelector((state: RootState) => state.screenSize);

  // フォームの処理
  const { values, handlers: formHandlers } = useAuthForm();

  // ログイン、Google新規会員登録、Line新規会員登録、会員登録の処理
  const { handlers, errors } = useAuthActions({
    values: values,
  });

  // ポップアップ表示の制御
  const { isVisible, isClosing } = useVisibilityController(
    open,
    isLoggedIn ? 1500 : 0,
  );

  return (
    <DefaultPopupIons
      open={isVisible}
      onClose={handlers.handleCloseLogin}
      size={isLoggedIn && isClosing ? 'normal' : isSP ? 'large' : 'medium'}
      zIndex={1001}
    >
      {isLoggedIn && isClosing ? (
        <div className={`success-popup ${isSP ? 'sp' : 'sp'}`}>
          <div className={`check-container`}>
            <CheckIcon sx={{ color: 'white', fontSize: 48 }} />
          </div>
          <BlackTitleTextIons
            text="ログインに成功しました"
            size={isSP ? 'normal' : 'medium'}
            className="success-popup-text"
          />
        </div>
      ) : (
        <div className={`login-popup ${isSP ? 'sp' : ''}`}>
          <HeaderTitle
            title="Log-in Form"
            subTitle="ログイン"
            titleSize="large"
          />

          <div className={`login-popup-content ${isSP ? 'sp' : ''}`}>
            <article className={`login-popup-container ${isSP ? 'sp' : 'sp'}`}>
              <form className={`login-popup-form ${isSP ? 'sp' : 'sp'}`}>
                <DefaultTextFieldIons
                  label="メールアドレスまたはユーザーネーム"
                  size={isSP ? 'small' : 'medium'}
                  value={values.userNameOrEmail || ''}
                  onChange={formHandlers.handleUserNameOrEmailChange}
                  error={null}
                />
                <DefaultTextFieldIons
                  label="パスワード"
                  size={isSP ? 'small' : 'medium'}
                  value={values.password || ''}
                  type="password"
                  onChange={formHandlers.handlePasswordChange}
                  error={null}
                />
                {errors.login?.message && (
                  <ErrorCardMolecules
                    text={errors.login?.message}
                    isSP={isSP}
                    padding="5px 10px"
                    bold="light"
                    margin="10px 0"
                  />
                )}
              </form>

              <nav
                className="login-popup-auth-links"
                aria-label="認証関連リンク"
              >
                <BodyTextIonsClickable
                  text="パスワードを忘れた場合"
                  size="extra-small"
                  onClick={PageNavigation.goToSettingPasswordPage}
                />
                <BodyTextIonsClickable
                  text="新規会員登録はこちら"
                  size="extra-small"
                  onClick={handlers.handleOpenRegister}
                />
              </nav>

              <div className="login-popup-form-submit-button">
                <RoundPurpleButtonIons
                  width={'100%'}
                  size={'normal'}
                  onClick={() =>
                    handlers.handleLogin({
                      onSuccess: () => {
                        handlers.handleCloseLogin();
                      },
                    })
                  }
                >
                  ログイン
                </RoundPurpleButtonIons>
              </div>
            </article>

            <nav className={`login-popup-sns-buttons ${isSP ? 'sp' : 'sp'}`}>
              {['Google'].map((type) => (
                <SNSRoundButton
                  key={type}
                  text="ログイン"
                  size="large"
                  type={type as any}
                  onClick={() => {
                    if (type === 'Google') {
                      handlers.handleGoogleSignin(window.location.href);
                    } else {
                      handlers.handleLineSignin();
                    }
                  }}
                />
              ))}
            </nav>
          </div>
        </div>
      )}
    </DefaultPopupIons>
  );
});

export default LoginPopup;
