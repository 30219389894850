import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../ions/popup/DefaultPopup.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import NumAndTitle from '../../molecules/text/numTitle/NumAndTitle';
import './RewardConfirmPopup.css';

interface PopupProps {
  open: boolean;
  onClose: () => void;
}

const RewardConfirmPopup: React.FC<PopupProps> = React.memo(
  ({ open, onClose }) => {
    const { goToMyPage } = usePageNavigation();

    return (
      <DefaultPopupIons open={open} onClose={onClose} size="large">
        <div className="reward-confirm-popup">
          <BodyTextBoldIons size="normal" text="共同制作について" />
          {/* 共同制作について */}

          <section className="reward-confirm-popup-rewards">
            <BodyTextBoldIons size="small" text="共同制作特典" />
            <div className="reward-confirm-popup-rewards-cells">
              {[
                'アイデアへの出資総額獲得',
                'アイデアのファンを獲得',
                'クラファン時の販売手数料割引',
              ].map((title, index) => (
                <NumAndTitle
                  key={index}
                  num_size="small"
                  title_size="small"
                  num={index + 1}
                  title={title}
                />
              ))}
            </div>
          </section>

          <section className="reward-confirm-popup-attention">
            <BodyTextBoldIons size="small" text="注意事項" />
            <div className="reward-confirm-popup-attention-cells">
              <BodyTextIons
                text={
                  '※ 商品化したアイデアはRE-IDEAクラファンサイト「RE-LAUNCH」にて販売されます。'
                }
              />
              <BodyTextIons
                text={
                  '※ アイデアを商品化をした際、アイデア発案者に売上の2%が還元されます。'
                }
              />
            </div>
          </section>

          <RoundPurpleButtonIons
            width={'60%'}
            size="normal"
            onClick={() => goToMyPage({ mode: 'chat', roomId: '1' })}
          >
            トーク画面へ
          </RoundPurpleButtonIons>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default RewardConfirmPopup;
