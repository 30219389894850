import React, { ChangeEvent } from 'react';
import { PopoverPlacement } from '../../../atoms/popover/Popover.type';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface ButtonProps {
  num?: number;
  require: boolean | null;
  story: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  isSP?: boolean;
  tooltipPlacement?: PopoverPlacement;
}

const IdeaStoryTextfield: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <DefaultTextFieldIons
      value={props.story}
      error={props.error}
      width={'100%'}
      size={props.isSP ? 'small' : 'medium'}
      onChange={props.handleChange}
      componentType="textarea"
      label={
        props.num ? (
          <NumAndTitle
            num={props.num}
            title="アイデアの詳細を記入"
            num_size="small"
            title_size="small"
          />
        ) : props.require === null ? (
          <BodyTextBoldIons size="small" text="アイデアの詳細を記入" />
        ) : (
          <RequireAndTitle
            require={true}
            title="アイデアの詳細を記入"
            hint="アイデアの魅力や、共感を呼ぶ誕生ストーリーを入力しましょう"
            hint_placement={
              props.tooltipPlacement ||
              (props.isSP ? 'top-right-center' : 'top-left-center')
            }
          />
        )
      }
      placeholder="アイデアの詳細説明や、誕生ストーリーを入力してください"
      rows={5}
    />
  );
});

export default IdeaStoryTextfield;
