import React from 'react';
import { RequestCardType } from '../../../../types/api/request/RequestTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RequestImgIons from '../../../ions/img/RequestImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './RequestCard2.Molecules.css';

type PurchaseCardMoleculesProps = {
  isSP: boolean;
  request: RequestCardType;
  width: number | string;
  hover_effect?: boolean;
};

export const RequestCard2Molecules: React.FC<PurchaseCardMoleculesProps> =
  React.memo((props) => {
    const { request } = props;
    return (
      <div
        className={`request-card-container  ${props.hover_effect ? 'hover-effect' : ''}`}
        style={{ width: props.width }}
        onClick={() =>
          request.requestId && PageNavigation.goToRequestPage(request.requestId)
        }
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <div style={{ width: '40%' }}>
            <RequestImgIons
              img_src={request.imgSrc}
              alt={request.title}
              className="request-card2-img"
            />
          </div>

          <div className="request-card2-texts">
            <BodyTextIons
              text={request.user.userName}
              bold="bold"
              size="extra-small"
            />
            <BodyTextIons
              text={request.title}
              bold="light"
              size="extra-small"
            />
          </div>
        </div>
      </div>
    );
  });

export default RequestCard2Molecules;
