import { emailVerifyAPI } from '../../../services/api/auth/verify/emailVerifyAPI';
import { AppDispatch } from '../../store';
import { updateAuthThunk } from './updateAuthThunk';

export const emailVerifyThunk =
  (token?: string) =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    try {
      if (!token) {
        return false;
      }
      const result = await emailVerifyAPI(token);
      // 認証成功時、自動ログインされているはずなので Redux ストアを更新
      if (result) {
        await dispatch(updateAuthThunk() as any);
      }
      return true; // ログイン成功
    } catch (error) {
      return false; // ログイン失敗
    }
  };
