import { InvestmentType } from '../../../types/api/investment/InvestmentTypes';

// アイデアの出資商品を取得するAPI
export const getInvestmentAPI = async (
  investmentId: string,
): Promise<InvestmentType> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/investments/${investmentId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
