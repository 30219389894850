import { AuthErrorType } from '../../../../../types/api/auth/AuthError.type';
import { mailCorpRegisterAPIProps } from './mailCorpRegisterAPI.type';

// 事業者用の新規会員登録API
export const mailCorpRegisterAPI = async (
  input: mailCorpRegisterAPIProps,
): Promise<AuthErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/corp/signup`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input.info),
      },
    );

    if (!response.ok) {
      const errorData: AuthErrorType = await response.json();
      return errorData;
    }

    return null;
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
