import React from 'react';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import LoadingFullSpinner from '../../../molecules/spinner/LoadingFullSpinner';
import './IdeaAdoptRequestCompleteTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType | undefined;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaAdoptRequestCompleteTemplate: React.FC<IdeaTemplateProps> = (
  props,
) => {
  const { size, idea } = props;
  let width: string;
  const isSP = size === 'sp';

  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '70%';
      break;
    case 'medium':
      width = '55%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  if (!idea) return <LoadingFullSpinner />;

  return (
    <div className="idea-adopt-complete-template">
      <main
        className="idea-adopt-complete-template-main"
        style={{ width: width }}
      >
        <header className={`idea-adopt-complete-template-header `}>
          <BodyTextBoldIons
            text="Adopt Complete"
            color="purple"
            className={`idea-adopt-complete-template-header-title ${isSP ? 'sp' : ''} `}
          />
          <BodyTextBoldIons text="商品化の申請が完了しました" size="normal" />
        </header>

        <section className="idea-adopt-complete-template-main">
          <section
            className={`idea-adopt-complete-template-main-content ${size === 'sp' ? 'sp' : ''}`}
          >
            <header className="idea-adopt-complete-template-main-heading">
              <BodyTextIons
                text="Mail Send"
                className="idea-adopt-complete-template-main-heading-title"
              />
              <BodyTextBoldIons
                text="確認メールを送信しました"
                color="purple"
                size={'normal'}
              />
            </header>
            <ul className="idea-adopt-complete-template-main-content-list">
              {[
                '商品化申請に関する各種手続きにつきましては、ご登録いただいたメールアドレス宛に詳細を送付させていただきます。また、諸条件の詳細に関しましては、当社利用規約およびポリシーをご参照くださいますようお願い申し上げます。',
                '商品化申請の内容につきまして、申請確定後の修正は原則として承れません。お手数ではございますが、申請内容に相違がないか今一度ご確認をお願い申し上げます。',
                'ご不明な点やお問い合わせにつきましては、当社サポートセンター（info@re-idea.jp）にて承っております。ご質問・ご相談など、お気軽にご連絡いただけますと幸いです。',
              ].map((item) => (
                <li>
                  <BodyTextIons text={item} bold="light" size="extra-small" />
                </li>
              ))}
            </ul>
          </section>
        </section>

        <section className="idea-adopt-complete-template-buttons">
          <RoundPurpleButtonIons
            onClick={() => {
              PageNavigation.goToIdeaPage(idea.ideaId);
            }}
            size="medium"
          >
            アイデアページへ
          </RoundPurpleButtonIons>
        </section>
      </main>
    </div>
  );
};

export default IdeaAdoptRequestCompleteTemplate;
