import React from 'react';
import SearchTextFieldAtoms from '../../atoms/textfield/search/SearchTextField.Atoms';
import { SearchTextFieldAtomsProps } from '../../atoms/textfield/search/SearchTextField.type';
import './SearchTextFieldSakairi.Ions.css';

const SearchTextFieldSkairiIons: React.FC<SearchTextFieldAtomsProps> =
  React.memo((props) => {
    return (
      <SearchTextFieldAtoms
        {...props}
        className="search-textfield-sakairi-ions"
        borderRadius={'50px'}
        iconColor="#35869e"
        color="#1f6074"
      />
    );
  });

export default SearchTextFieldSkairiIons;
