import React from 'react';
import {
  CONTACT_DETAIL_MAX_LENGTH,
  CONTACT_USERNAME_MAX_LENGTH,
} from '../../../../constants/max';
import useTextfieldMax from '../../../organisms/textfield/useTextfieldMax.hooks';

interface ContactFormState {
  userName: string;
  detail: string;
}

interface ContactFormErrors {
  userName: string | null;
  detail: string | null;
}

interface ContactFormHandlers {
  handleUserNameChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleDetailChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

export const useContactThemeForm = (
  init?: ContactFormState,
): {
  values: ContactFormState;
  errors: ContactFormErrors;
  handlers: ContactFormHandlers;
  reset: () => void;
} => {
  // タイトルの値
  const {
    value: userName,
    error: userName_error,
    handleChange: handleUserNameChange,
  } = useTextfieldMax(init?.userName ?? '', CONTACT_USERNAME_MAX_LENGTH);

  // 本文の値
  const {
    value: detail,
    error: detail_error,
    handleChange: handleDetailChange,
  } = useTextfieldMax(init?.detail ?? '', CONTACT_DETAIL_MAX_LENGTH);

  const values: ContactFormState = {
    userName,
    detail,
  };

  const errors: ContactFormErrors = {
    userName: userName_error,
    detail: detail_error,
  };

  const reset = () => {
    handleUserNameChange({ target: { value: '' } } as any);
    handleDetailChange({ target: { value: '' } } as any);
  };

  const handlers = {
    handleUserNameChange,
    handleDetailChange,
  };

  return { values, errors, handlers, reset };
};
