import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { RequestCardType } from '../../../../types/api/request/RequestTypes';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { IdeaCardMolecules } from '../../../molecules/card/idea/IdeaCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import PublicSelector from '../../selector/PublicSelector/PublicSelector';
import ThemeSelector from '../../selector/ThemeSelector/ThemeSelector';
import './../CardGridHeader.css';
import './IdeaCardGrid.css';

interface TagsCheckboxGridProps {
  page: number;
  maxPage: number;
  columns: number;
  ideas: IdeaCardType[];
  card_text_size?: 'extra-small' | 'small';
  isSP: boolean;
  title: string;
  num: number;
  isPublic?: boolean;
  requestId?: string | null;
  themes?: RequestCardType[];
}

const IdeaCardGridWithHeader: React.FC<TagsCheckboxGridProps> = (props) => {
  return (
    <section className="card-grid-section">
      <div className={`card-grid-header ${props.isSP ? '' : 'pc'}`}>
        <div className="card-grid-header-title">
          <BodyTextBoldIons
            text={props.title}
            size={props.isSP ? 'normal' : 'medium'}
          />
          <BodySubTextIons text={`(${props.num}件)`} size="small" />
        </div>
        {props.isPublic !== undefined && (
          <PublicSelector isPublic={props.isPublic} />
        )}
        {props.requestId !== undefined && props.themes && (
          <ThemeSelector
            isSP={props.isSP}
            isPublic={props.isPublic}
            themes={props.themes}
            requestId={props.requestId}
          />
        )}
      </div>

      {(props.ideas || []).length ? (
        <div
          data-testid="card-grid"
          className={`card-grid-container columns-${props.columns}`}
        >
          {props.ideas.map((idea) => (
            <IdeaCardMolecules
              hover_effect={!props.isSP}
              idea={idea}
              text_size={props.card_text_size}
              width={'100%'}
            />
          ))}
        </div>
      ) : (
        <div className="card-grid-no-item">
          <h4 className={`card-grid-no-item-text ${props.isSP ? '' : 'pc'}`}>
            アイデアが見つかりませんでした
          </h4>
        </div>
      )}
      <PageNationButtons num={props.page} max_num={props.maxPage} />
    </section>
  );
};

export default IdeaCardGridWithHeader;
