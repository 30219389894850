// サインアウトAPI
export const signoutAPI = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/signout`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  if (!response.ok) {
    throw new Error(`Login failed: ${response.statusText}`);
  }
  return response.ok;
};
