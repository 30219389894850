import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GET_IDEA_MAX_LIMIT } from '../../constants/max';
import { useGetIdeas } from '../../hooks/api/idea/useGetIdeas';
import { useGetUser } from '../../hooks/api/user/useGetUser';
import { useGetUsers } from '../../hooks/api/user/useGetUsers';
import { convertCategoryIdToName } from '../../redux/category/categorySlice';
import { RootState } from '../../redux/store';
import {
  getIdeasSortLabel,
  IdeasSortQuery,
  parseIdeasSortQuery,
} from '../../types/query/ideasQuery';
import SearchTemplatePC from '../templates/search/SearchTemplatePC';
import SearchTemplateSP from '../templates/search/SearchTemplateSP';

const SearchPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const { categories } = useSelector((state: RootState) => state.category);
  const [searchParams] = useSearchParams();
  const limit = GET_IDEA_MAX_LIMIT;
  const page = parseInt(searchParams.get('page') || '1', 10);

  // 注目のユーザー
  const { data: users } = useGetUsers({ limit: 3, sort: 'ideas' });

  // ソート
  const sort = parseIdeasSortQuery(searchParams.get('sort'), 'new');

  // ユーザーID
  const userId = searchParams.get('user_id');
  const { data: user } = useGetUser(userId || null);

  // カテゴリID
  const categoryId = searchParams.get('categoryId')
    ? Number(searchParams.get('categoryId'))
    : undefined;

  // キーワード
  const keyword = searchParams.get('keyword');

  const { data: result } = useGetIdeas({
    sort: sort as IdeasSortQuery,
    limit: limit,
    page: page,
    isPublic: true,
    userId: userId || undefined,
    categoryId,
    keyword: keyword || undefined,
    like: true,
    comment: true,
    view: true,
  });

  // 優先順位は sort > keyword > user > categoryId
  const props = {
    title_en: searchParams.get('sort')
      ? sort.toUpperCase()
      : keyword
        ? 'KEYWORD'
        : user
          ? 'USER'
          : categoryId
            ? 'CATEGORY'
            : 'NEW',
    title: searchParams.get('sort')
      ? getIdeasSortLabel(sort) + 'アイデア'
      : keyword
        ? keyword + ' の検索結果 (' + result?.numOfAllIdeas || 0 + '件)'
        : user
          ? user.userName + ' のアイデア'
          : categoryId
            ? convertCategoryIdToName(categories, categoryId) + ' のアイデア'
            : getIdeasSortLabel(sort) + 'アイデア',
    limit: limit,
    page: page,
    categoryId: categoryId,
    userId: userId,
    maxPage: Math.ceil((result?.numOfAllIdeas || 1) / limit),
    ideas: result?.ideas,
    users: users || [],
  };

  return isXS ? (
    <SearchTemplateSP {...props} />
  ) : (
    <SearchTemplatePC
      {...props}
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
    />
  );
};

export default SearchPage;
