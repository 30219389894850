import React, { ChangeEvent } from 'react';
import { IDEA_APEALPOINT_MAX_LENGTH } from '../../../../constants/max';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface ButtonProps {
  index: number;
  appealpoint: string | null;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  isSP?: boolean;
}

const IdeaApealPointTextfield: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <DefaultTextFieldIons
      value={props.appealpoint || ''}
      error={props.error}
      onChange={props.handleChange}
      width={'100%'}
      label={
        <RequireAndTitle
          require={false}
          title={'ポイント' + props.index}
          hint="アイデアの魅力を伝える、アピールポイントを入力しましょう"
          hint_placement={props.isSP ? 'top' : 'top-left-center'}
        />
      }
      placeholder="アピールポイントを入力してください"
      max={IDEA_APEALPOINT_MAX_LENGTH}
    />
  );
});

export default IdeaApealPointTextfield;
