export type MyPageModeQuery = null | 'ideas' | 'chat' | 'invested' | 'liked';
export type OthersPageModeQuery = null | 'ideas' | 'liked';
export type OthersCorpPageModeQuery = null | 'intro' | 'requests';
export type MyCorpPageModeQuery =
  | null
  | 'requests'
  | 'received'
  | 'chat'
  | 'applied'
  | 'viewed'
  | 'liked'
  | 'intro';

// 配列とタイトルを連携させるオブジェクト
export const myPageModeQueryMap = {
  ideas: 'アイデア',
  invested: '出資履歴',
  liked: 'いいね履歴',
  chat: 'チャット',
};
export const othersPageModeQueryMap = {
  ideas: 'アイデア',
  liked: 'いいね履歴',
};
export const othersCorpPageModeQueryMap = {
  intro: '企業紹介',
  requests: 'お題一覧',
};
export const myCorpPageModeQueryMap = {
  requests: 'お題',
  received: '届いたアイデア',
  chat: 'チャット',
  applied: '採用履歴',
  viewed: '閲覧履歴',
  liked: 'いいね履歴',
};
export const myPageTypeQueryMap = {
  posted: '投稿した',
  invested: '出資した',
  liked: 'いいねした',
};

// パース関数
export const parseMyPageModeQuery = (mode?: string | null): MyPageModeQuery => {
  return mode && Object.keys(myPageModeQueryMap).includes(mode)
    ? (mode as MyPageModeQuery)
    : 'ideas';
};
export const parseOthersPageModeQuery = (
  mode?: string | null,
): OthersPageModeQuery => {
  return mode && Object.keys(othersPageModeQueryMap).includes(mode)
    ? (mode as OthersPageModeQuery)
    : 'ideas';
};
export const parseOtherCorpPageModeQuery = (
  mode?: string | null,
): OthersCorpPageModeQuery => {
  return mode && Object.keys(othersCorpPageModeQueryMap).includes(mode)
    ? (mode as OthersCorpPageModeQuery)
    : 'intro';
};
export const parseMyCorpPageModeQuery = (
  mode?: string | null,
): MyCorpPageModeQuery => {
  return mode && Object.keys(myCorpPageModeQueryMap).includes(mode)
    ? (mode as MyCorpPageModeQuery)
    : 'requests';
};
