import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryType } from '../../types/api/category/CategoryTypes';

export interface CategoryState {
  categories: CategoryType[];
  expiresAt: number | null;
}

const initialState: CategoryState = {
  categories: [],
  expiresAt: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<CategoryType[]>) => {
      state.categories = action.payload;
      const maxAge =
        Number(process.env.REACT_APP_MAXAGE) || 12 * 60 * 60 * 1000;
      state.expiresAt = Date.now() + maxAge;
    },
  },
});

export const convertCategoryIdToName = (
  categories: CategoryType[],
  categoryId: number,
): string => {
  return (
    categories.find((category) => category.categoryId === categoryId)?.name ||
    ''
  );
};

export const { setCategories } = categorySlice.actions;
export default categorySlice.reducer;
