import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useGetIdea } from '../../../../hooks/api/idea/useGetIdea';
import { useGetInvestment } from '../../../../hooks/api/useGetInvestment';
import useLoading from '../../../../hooks/useLoading';
import { RootState } from '../../../../redux/store';
import { cancelPurchaseAPI } from '../../../../services/api/investment/purchase/cancelPurchaseAPI';
import { cancelPurchaseAPIProps } from '../../../../services/api/investment/purchase/cancelPurchaseAPI.type';
import { PurchaseError } from '../../../../types/api/investment/purchase/PurchaseError.type';
import { PageNavigation } from '../../../../utils/pageNavigation';
import IdeaInvestDeleteConfirmTemplate from '../../../templates/ideaInvest/confirm/IdeaInvestDeleteConfirmTemplate';

const IdeaInvestDeleteConfirmPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const { loading, handleStart, handleEnd } = useLoading(false);
  const [cancelAPIError, setCancelAPIError] = useState<PurchaseError | null>(
    null,
  );
  const { id } = useParams();
  const { investId } = useParams();
  const { data: idea } = useGetIdea(id ?? '');
  const { data: investment } = useGetInvestment(investId ?? '');

  // 出資記録を送信する
  const handleDeletePurchaseInvestment = useCallback(
    async (input: cancelPurchaseAPIProps) => {
      handleStart();
      const result = await cancelPurchaseAPI(input.purchase, () => {
        handleEnd();
        if (input.onSuccess) input.onSuccess();
        if (idea && investment)
          PageNavigation.goToIdeaInvestDeleteCompletePage(
            idea.ideaId,
            investment.investmentId,
          );
      });
      handleEnd();
      setCancelAPIError(result);
    },
    [idea, investment],
  );

  // アイデアの所有者はアイデア出資削除画面にアクセスできない
  if (!idea || !investment) return null;
  if (idea?.isOwner === true) {
    return <Navigate to={`/ideas/${idea.ideaId}`} replace />;
  }

  return (
    <IdeaInvestDeleteConfirmTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
      investment={investment}
      handleDeletePurchaseInvestment={handleDeletePurchaseInvestment}
      loading={loading}
      cancelAPIError={cancelAPIError}
    />
  );
};

export default IdeaInvestDeleteConfirmPage;
