import { FollowUserType } from './userFollowAPI.type';

export const userFollowAPI = async (
  input: FollowUserType,
  onSuccess?: () => void,
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/me/follow/${input.userId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
    }
    if (onSuccess) onSuccess();
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
