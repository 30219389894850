import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import { RootState } from '../../../redux/store';
import {
  IdeaCardType,
  IdeaCommentType,
} from '../../../types/api/idea/IdeaTypes';
import { RequestCardType } from '../../../types/api/request/RequestTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import { TextSizeType } from '../../atoms/text/Text.type';
import CircleFixedPostButtonIons from '../../ions/button/circle/pink/CircleFixedPostButton.Ions';
import CircleFixedRequestButtonIons from '../../ions/button/circle/pink/CircleFixedRequestButton.Ions';
import { TopCommentCardMolecules } from '../../molecules/card/comment/TopCommentCard.Molecules';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import AboutCarousel from '../../organisms/carousel/AboutCarousel';
import RequestCarousel from '../../organisms/carousel/RequestCarousel';
import SakairiIdeaCarousel from '../../organisms/carousel/SakairiIdeaCarousel';
import IdeaCardGridAtHome from '../../organisms/grid/IdeaCardGrid/IdeaCardGridAtHome';
import './HomeTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  main_ideas1: IdeaCardType[];
  main_ideas2: IdeaCardType[];
  main_ideas3: IdeaCardType[];
  latest_comments: IdeaCommentType[];
  themes: RequestCardType[];
}

const HomeTemplate: React.FC<HomeTemplateProps> = (props) => {
  const dispatch = useDispatch();
  const { size } = props;
  const { user } = useSelector((state: RootState) => state.auth);

  let theme_card_min_width: number;
  let about_card_min_width: number;
  let card_min_width: number;
  let main_width: string;
  let subTitle_size: TextSizeType;

  switch (size) {
    case 'sp':
      about_card_min_width = 280;
      card_min_width = 290;
      theme_card_min_width = 320;
      main_width = '95%';
      subTitle_size = 'normal';
      break;

    case 'small':
      about_card_min_width = 300;
      card_min_width = 350;
      theme_card_min_width = 400;
      main_width = '95%';
      subTitle_size = 'medium';
      break;

    case 'medium':
      about_card_min_width = 320;
      card_min_width = 370;
      theme_card_min_width = 400;
      main_width = '92%';
      subTitle_size = 'large';
      break;

    case 'large':
      about_card_min_width = 350;
      card_min_width = 370;
      theme_card_min_width = 400;
      main_width = '85%';
      subTitle_size = 'large';
      break;

    default:
      about_card_min_width = 280;
      card_min_width = 290;
      theme_card_min_width = 400;
      main_width = '85%';
      subTitle_size = 'large';
  }

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      // 再生速度の調整
      videoRef.current.playbackRate = 0.75;
    }
  }, []);

  if (size === 'none') {
    return null;
  }

  return (
    <div className="home-template">
      {/* 背景動画 */}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="home-background-video"
      >
        <source
          src={
            size === 'sp'
              ? 'system-videos/HOME-BG-SP.mp4'
              : 'system-videos/HOME-BG-PC.mp4'
          }
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <main className="home-template-main">
        <section
          className="home-template-main-bottom"
          style={{ width: main_width }}
        >
          <div className="home-template-main-cell">
            <HeaderTitleSakairi
              title="IDEA工房"
              titleSize={size === 'sp' ? 'large' : 'extra-large'}
              subTitle={
                '企業のお題に投稿しよう！\nあなたのアイデアを実現します。'
              }
            />
            <RequestCarousel
              display_link={true}
              slide_min_width={theme_card_min_width * 1.05}
              card_width={theme_card_min_width}
              requests={props.themes}
              playOnInit={true}
              isBanner={false}
              prevAndNextButton={true}
              autoScroll
            />
          </div>

          <div className="home-template-main-cell">
            <HeaderTitleSakairi
              title="How to Use"
              titleSize={size === 'sp' ? 'large' : 'extra-large'}
              subTitle="あなたのひらめきが商品に"
            />
            <AboutCarousel
              isSP={size === 'sp'}
              title=""
              type="more"
              title_size={subTitle_size}
              slide_min_width={about_card_min_width * 1.05}
              card_width={about_card_min_width}
              ideas={props.main_ideas1}
              moreOnClick={() => PageNavigation.goToSearchPage({ sort: 'new' })}
            />
          </div>

          <div className="home-template-main-cell">
            <HeaderTitleSakairi
              title="NEW"
              titleSize={size === 'sp' ? 'large' : 'extra-large'}
              subTitle="新着のアイデア"
            />
            <SakairiIdeaCarousel
              isSP={size === 'sp'}
              title_size={subTitle_size}
              slide_min_width={card_min_width * 1.05}
              card_width={card_min_width}
              ideas={props.main_ideas1}
              moreOnClick={() => PageNavigation.goToSearchPage({ sort: 'new' })}
            />
          </div>

          <div className="home-template-main-cell">
            <HeaderTitleSakairi
              title="PICKUP"
              titleSize={size === 'sp' ? 'large' : 'extra-large'}
              subTitle="日用品特集"
            />
            <SakairiIdeaCarousel
              isSP={size === 'sp'}
              title_size={subTitle_size}
              slide_min_width={card_min_width * 1.05}
              card_width={card_min_width}
              ideas={props.main_ideas3}
              moreOnClick={() =>
                PageNavigation.goToSearchPage({ categoryId: '1' })
              }
            />
          </div>

          {size === 'sp' && (
            <div className="home-template-main-cell">
              <HeaderTitleSakairi
                title="COMMENTS"
                titleSize={size === 'sp' ? 'large' : 'extra-large'}
                subTitle="新着のコメント"
              />
              {props.latest_comments.map((comment: IdeaCommentType) => (
                <TopCommentCardMolecules comment={comment} />
              ))}
            </div>
          )}

          <div className="home-template-main-cell">
            <HeaderTitleSakairi
              title="RANKING"
              titleSize={size === 'sp' ? 'large' : 'extra-large'}
              subTitle="いいねの多いアイデア"
            />
            {size !== 'sp' ? (
              <SakairiIdeaCarousel
                isSP={false}
                title_size={subTitle_size}
                slide_min_width={card_min_width * 1.05}
                card_width={card_min_width}
                ideas={props.main_ideas2}
                moreOnClick={() =>
                  PageNavigation.goToSearchPage({ sort: 'like' })
                }
              />
            ) : (
              <IdeaCardGridAtHome
                isSP
                card_text_size="extra-small"
                columns={2}
                ideas={props.main_ideas2}
                moreOnClick={() =>
                  PageNavigation.goToSearchPage({ sort: 'like' })
                }
              />
            )}
          </div>
        </section>
      </main>

      {user?.isCorp ? (
        <CircleFixedRequestButtonIons
          onClick={PageNavigation.goToContactRequestPage}
          size={size === 'sp' ? 'medium' : 'large'}
          isMobile={size === 'sp'}
        />
      ) : (
        <CircleFixedPostButtonIons
          onClick={() => {
            dispatch(openIdeaPostPopup());
          }}
          size={size === 'sp' ? 'medium' : 'large'}
          isMobile={size === 'sp'}
        />
      )}
    </div>
  );
};

export default HomeTemplate;
