import { useState } from 'react';
import { useSelector } from 'react-redux';
import useLoading from '../../../hooks/useLoading';
import { RootState } from '../../../redux/store';
import { createContactAPI } from '../../../services/api/contact/createContactAPI';
import { createContactAPIProps } from '../../../services/api/contact/createContactAPI.type';
import { ContactError } from '../../../types/api/contact/ContactError.type';
import { PageNavigation } from '../../../utils/pageNavigation';
import ContactTemplate from '../../templates/contact/ContactTemplate';

const ContactPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // お問い合わせ送信中のローディング
  const { loading, handleStart, handleEnd } = useLoading(false);

  // APIエラー
  const [contactAPIError, setContactAPIError] = useState<ContactError | null>(
    null,
  );

  // お問い合わせ送信API
  const sendContactAPI = async (input: createContactAPIProps) => {
    handleStart();
    const result = await createContactAPI({
      ...input,
      onError: () => {
        handleEnd();
        input.onError && input.onError();
      },
      onSuccess: () => {
        handleEnd();
        input.onSuccess && input.onSuccess();
        PageNavigation.goToContactSuccessPage();
      },
    });
    setContactAPIError(result);
  };

  return (
    <ContactTemplate
      loading={loading}
      isSP={isXS}
      createContact={sendContactAPI}
      contactAPIError={contactAPIError}
    />
  );
};

export default ContactPage;
