import React, { useCallback } from 'react';
import {
  IDEA_ACTIVITY_DESCRIPTION_MAX_LENGTH,
  IDEA_ACTIVITY_TITLE_MAX_LENGTH,
} from '../../../../../constants/max';
import { useIdeaActivityForm } from '../../../../../hooks/idea/activitiy/useIdeaActivityForm.hooks';
import { createIdeaActivityAPIProps } from '../../../../../services/api/idea/activity/createIdeaActivityAPI.type';
import { IdeaErrorType } from '../../../../../types/api/idea/IdeaError.type';
import { PageNavigation } from '../../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../../../ions/popup/DefaultPopup.Ions';
import DefaultTextFieldIons from '../../../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../../../molecules/card/error/ErrorCard.Molecules';
import HeaderTitle from '../../../../molecules/text/headerTitle/HeaderTitle';
import './IdeaActivityPostPopup.css';

interface PopupProps {
  ideaId: string;
  open: boolean;
  onClose: () => void;
  sendIdeaActivity: (input: createIdeaActivityAPIProps) => void;
  loading: boolean;
  apiError: IdeaErrorType | null;
}

const IdeaActivityPostPopup: React.FC<PopupProps> = React.memo(
  ({ ideaId, open, onClose, sendIdeaActivity, loading, apiError }) => {
    // フォームの状態とハンドラを取得
    const { values, errors, handlers } = useIdeaActivityForm({
      storageId: ideaId,
    });

    // 投稿処理を行う
    const postActivity = useCallback(async () => {
      sendIdeaActivity({
        activity: {
          ideaId: ideaId,
          title: values.title,
          description: values.description,
        },
        onSuccess: () => {
          handlers.clearStorage();
          onClose();
          PageNavigation.goToIdeaPage(ideaId, { view: 'activity' });
        },
      });
    }, [values.title, values.description, ideaId]);

    const disabled =
      !values.title ||
      !values.description ||
      !!errors.title ||
      !!errors.description ||
      loading;

    return (
      <DefaultPopupIons open={open} onClose={onClose} size="large" className="">
        <div className="idea-activity-confirm-popup">
          <HeaderTitle
            title="Post Activity"
            subTitle="活動記録を投稿しよう"
            titleSize="large"
          />
          {apiError && (
            <ErrorCardMolecules isSP={false} text={apiError?.message || ''} />
          )}

          <section className="idea-activity-confirm-popup-main">
            <DefaultTextFieldIons
              width={'100%'}
              label="タイトル"
              placeholder="タイトルを入力してください"
              max={IDEA_ACTIVITY_TITLE_MAX_LENGTH}
              rows={1}
              value={values.title}
              error={errors.title}
              onChange={handlers.handleTitleChange}
            />
            <DefaultTextFieldIons
              width={'100%'}
              label="活動記録"
              placeholder="活動記録を入力してください"
              max={IDEA_ACTIVITY_DESCRIPTION_MAX_LENGTH}
              rows={4}
              value={values.description}
              error={errors.description}
              onChange={handlers.handleDescriptionChange}
            />
          </section>

          <RoundPurpleButtonIons
            width={'60%'}
            size="medium"
            disabled={disabled}
            onClick={postActivity}
          >
            投稿する
          </RoundPurpleButtonIons>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default IdeaActivityPostPopup;
