import React, { useCallback } from 'react';
import useOpen from '../../../../hooks/useOpen';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { deleteIdeaActivityAPIProps } from '../../../../services/api/idea/activity/deleteIdeaActivityAPI.type';
import { IdeaActivityType } from '../../../../types/api/idea/IdeaTypes';
import { formatDateToJapanese } from '../../../../utils/timeCalculator';
import RoundRedButtonIons from '../../../ions/button/round/Red/RoundRedButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import SettingButtonPopover from '../../setting/SettingButtonPopover';
import './ActivityCard.Molecules.css';

type ActivityCardProps = {
  activity: IdeaActivityType;
  deleteIdeaActivity: (input: deleteIdeaActivityAPIProps) => void;
};

export const ActivityCardMolecules: React.FC<ActivityCardProps> = React.memo(
  (props) => {
    const { activity } = props;
    const { open, handleChangeOpen } = useOpen();
    const { goToOthersUserPage } = usePageNavigation();
    const {
      open: openDelete,
      handleOpen: handleDeleteOpen,
      handleClose: handleDeleteClose,
    } = useOpen();

    const handleDelete = useCallback(() => {
      props.deleteIdeaActivity({
        activity: {
          activityId: activity.activityId,
          ideaId: activity.ideaId,
        },
      });
    }, [activity.activityId, activity.ideaId]);

    return (
      <div className="activity-card-molecules-container">
        <header className="activity-card-molecules-header">
          <UserIconImgIons
            img_src={activity.user.imgSrc}
            userName={activity.user.userName}
            size="small"
            onClick={() => {
              goToOthersUserPage(activity.user.userId, { mode: 'ideas' });
            }}
          />
          <div className="activity-card-molecules-header-texts">
            <BodyTextBoldIons size="small" text={activity.title} />
            <BodyTextIons
              size="extra-small"
              text={formatDateToJapanese(activity.createdAt, 'M月d日 HH時m分')}
            />
          </div>
          {activity.isOwner && (
            <SettingButtonPopover
              textSize="extra-small"
              options={[
                {
                  label: '削除する',
                  onClick: () => {
                    handleDeleteOpen();
                  },
                },
              ]}
            />
          )}
        </header>
        <main className="activity-card-molecules-main">
          <BodyTextIons
            rows={open ? undefined : 2}
            size="small"
            text={activity.description}
          />
          {!open && (
            <RoundWhiteButtonIons size="normal" onClick={handleChangeOpen}>
              もっと読む
            </RoundWhiteButtonIons>
          )}
        </main>

        <DefaultPopupIons
          open={openDelete}
          onClose={handleDeleteClose}
          size="normal"
          zIndex={1001}
        >
          <div className="idea-delete-confirm-popup">
            <header className={`idea-delete-confirm-popup-header `}>
              <BodyTextBoldIons
                text="Activitiy Delete"
                color="purple"
                size="large"
                className="idea-delete-confirm-popup-heade"
              />
              <BodyTextBoldIons text="活動記録の削除" size="small" />
            </header>

            <BodyTextIons
              color="red"
              size="small"
              bold="light"
              text="この活動記録を削除してもよろしいですか？※ 削除した活動記録は元に戻すことができません。"
            />

            <div className="idea-delete-confirm-popup-buttons">
              <RoundWhiteButtonIons
                width={'140px'}
                size="normal"
                onClick={handleDeleteClose}
              >
                キャンセル
              </RoundWhiteButtonIons>
              <RoundRedButtonIons
                width={'140px'}
                size="normal"
                onClick={handleDelete}
              >
                削除する
              </RoundRedButtonIons>
            </div>
          </div>
        </DefaultPopupIons>
      </div>
    );
  },
);
