import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useTextfield from '../../../hooks/useTextfield.hooks';
import { openLoginPopup } from '../../../redux/slice/loginPopupSlice';
import { openRegisterPopup } from '../../../redux/slice/registerPopupSlice';
import FullRoundRegisterButtonIons from '../../ions/button/fullRound/Purple/FullRoundRegisterButton.Ions';
import FullRoundLoginButtonIons from '../../ions/button/fullRound/White/FullRoundLoginButton.Ions';
import { WhiteTextBoldIons } from '../../ions/text/white/WhiteTextBold.Ions';
import DefaultSearchTextFieldIons from '../../ions/textfield/DefaultSearchTextField';
import './HeaderDrawer.Molecules.css';

type HeaderDrawerMoleculesProps = {
  onClose: () => void;
  isLoggedin: boolean;
  options: { label: string; onClick: () => void }[];
  onSearch: (value: string) => void;
};

export const HeaderDrawerMolecules: React.FC<HeaderDrawerMoleculesProps> =
  React.memo((props) => {
    const { value: search, handleChange: handleChangeSearch } =
      useTextfield('');
    const dispatch = useDispatch();
    const handleOpenLoginPopup = useCallback(() => {
      dispatch(openLoginPopup());
    }, [dispatch]);

    const handleOpenRegisterPopup = useCallback(() => {
      dispatch(openRegisterPopup());
    }, [dispatch]);

    return (
      <div className="header-drawer-molecules-container">
        <div className="header-drawer-molecules-close-icon">
          <CloseIcon
            sx={{ fontSize: 40, color: 'white' }}
            onClick={() => props.onClose()}
          />
        </div>
        <header className="header-drawer-molecules-search">
          <DefaultSearchTextFieldIons
            placeholder="みんなのアイデアを検索する"
            onChange={handleChangeSearch}
            onSearch={() => props.onSearch(search)}
            value={search}
          />
        </header>
        <main className="header-drawer-molecules-main">
          {props.options.map((option, index, array) => (
            <div
              className={`header-drawer-molecules-main-cell${index === array.length - 1 ? ' last' : ''}`}
              key={index}
              onClick={() => option.onClick()}
            >
              <WhiteTextBoldIons
                size="extra-small"
                text={option.label}
                className="header-drawer-molecules-main-text"
              />
            </div>
          ))}

          {!props.isLoggedin && (
            <div className="header-drawer-molecules-main-auth-buttons">
              <FullRoundLoginButtonIons
                padding={'8px 20px'}
                onClick={() => {
                  handleOpenLoginPopup();
                  props.onClose();
                }}
              />
              <FullRoundRegisterButtonIons
                padding={'8px 20px'}
                onClick={() => {
                  handleOpenRegisterPopup();
                  props.onClose();
                }}
              />
            </div>
          )}
        </main>
      </div>
    );
  });
