// useWebSocket.ts
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { viewRoomAPI } from '../../../../../services/api/room/view/viewRoomAPI';
import { UserDetailType } from '../../../../../types/api/user/UserTypes';
import {
  MyCorpPageModeQuery,
  MyPageModeQuery,
} from '../../../../../types/query/UserPageQuery';

interface UseWebSocketProps {
  mode: MyPageModeQuery | MyCorpPageModeQuery;
  user: UserDetailType | undefined;
  roomId: string | null;
  chatsQueryKey: any;
  roomQueryKey: any;
  roomsQueryKey: any;
}

export const useWebSocket = ({
  mode,
  user,
  roomId,
  chatsQueryKey,
  roomQueryKey,
  roomsQueryKey,
}: UseWebSocketProps) => {
  const queryClient = useQueryClient();

  // チャットルーム初回閲覧登録
  useEffect(() => {
    if (mode === 'chat' && roomId) {
      viewRoomAPI(roomId);
    }
  }, [mode, roomId]);

  useEffect(() => {
    let socket: Socket | null = null;

    if (mode === 'chat' && user && roomId) {
      // ソケットの名前空間をAPI側と一致させる必要がある。
      socket = io(process.env.REACT_APP_SOCKET_URL, {
        path: '/socket.io/',
        query: {
          userId: user.userId,
        },
        transports: ['websocket', 'polling'],
      });

      socket.on('chatUpdated', async (data: { roomId: string }) => {
        console.log('chatUpdated', data);
        console.log(data.roomId, roomId);
        if (data.roomId === roomId) {
          // ルームに既読をつける
          await viewRoomAPI(roomId);
          // チャットを再取得して更新
          console.log('invalidateQueries', chatsQueryKey);
          await queryClient.invalidateQueries({
            queryKey: chatsQueryKey,
          });
        }
      });

      socket.on('roomUpdated', async (data: { roomId: string }) => {
        if (data.roomId === roomId) {
          // ルーム情報を再取得して更新
          await queryClient.invalidateQueries({
            queryKey: roomQueryKey,
          });
          await queryClient.invalidateQueries({
            queryKey: roomsQueryKey,
          });
        }
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [mode, user, roomId]);
};
