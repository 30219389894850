import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScroll } from '../../../hooks/useScroll';
import useTextfield from '../../../hooks/useTextfield.hooks';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import CircleFixedPostButtonIons from '../../ions/button/circle/pink/CircleFixedPostButton.Ions';
import CircleFixedRequestButtonIons from '../../ions/button/circle/pink/CircleFixedRequestButton.Ions';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import SearchTextFieldSkairiIons from '../../ions/textfield/SearchTextFieldSakairi.Ions';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import CategoryNavTexts from '../../organisms/navTexts/CategoryNavTexts';
import IdeaSortNavTexts from '../../organisms/navTexts/IdeaSortNavTexts';
import UserNavTexts from '../../organisms/navTexts/UserNavTexts';
import './SearchTemplate.css';
import { SearchTemplateProps } from './SearchTemplate.type';

const SearchTemplateSP: React.FC<SearchTemplateProps> = (props) => {
  const { value, handleChange } = useTextfield();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  const { windowScrollToTop } = useScroll({
    behavior: 'smooth',
    delay: 50,
  });

  useEffect(() => {
    windowScrollToTop();
  }, [props.title_en, props.title, props.page]);

  return (
    <div className="search-template">
      <main className="search-template-main sp">
        <section className="search-template-main-ideas sp">
          <SearchTextFieldSkairiIons
            placeholder="キーワードを入力"
            value={value}
            onChange={handleChange}
            onSearch={(value) =>
              PageNavigation.goToSearchPage({ keyword: value })
            }
          />
          <HeaderTitleSakairi
            title={props.title_en}
            subTitle={props.title}
            titleSize="normal"
            className="sp"
          />
          <div className="search-template-main-ideas-grid">
            {props.ideas !== undefined ? (
              <IdeaCardGrid
                isSP
                card_text_size="extra-small"
                columns={2}
                ideas={props.ideas}
                page={props.page}
                maxPage={props.maxPage}
              />
            ) : (
              <div className="search-template-main-ideas-null sp">
                <DefaultSpinnerIons color="grey" />
              </div>
            )}
          </div>
        </section>
        <nav className="search-template-main-nav-texts sp">
          <BodyTextBoldIons text="アイデア一覧" size="normal" />
          <IdeaSortNavTexts />
          <UserNavTexts userId={props.userId || null} users={props.users} />
          <CategoryNavTexts categoryId={props.categoryId || -1} />
        </nav>
      </main>

      {user?.isCorp ? (
        <CircleFixedRequestButtonIons
          onClick={PageNavigation.goToContactRequestPage}
          size={'medium'}
          isMobile={true}
        />
      ) : (
        <CircleFixedPostButtonIons
          onClick={() => {
            dispatch(openIdeaPostPopup());
          }}
          size={'medium'}
          isMobile={true}
        />
      )}
    </div>
  );
};

export default SearchTemplateSP;
