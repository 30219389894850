import React from 'react';
import useTextfield from '../../../../../hooks/useTextfield.hooks';
import RadioButtonAtoms from '../../../../atoms/radio/RadioButton.Atoms';
import { RadioButtonSizeType } from '../../../../atoms/radio/RadioButton.type';
import { TextSizeType } from '../../../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import { BodySubTextIons } from '../../../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../../ions/textfield/DefaultTextField.Ions';
import { ImageUploadForm } from '../../../../molecules/form/ImageUploadForm';
import NumAndTitle from '../../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../../molecules/text/requireTitle/RequireAndTitle';
import './RadioButtonForm.css';

interface IdeaImageGeneFormProps {
  num?: number;
  size?: RadioButtonSizeType;
  require?: boolean | null;
  imgFile: File | null;
  imgSrc: string | null;
  text_size?: TextSizeType;
  generate: boolean;
  display_generate_form?: boolean;
  error: string | null;
  loading?: boolean;
  handleImgSrcChange: (file: string | null) => void;
  handleImgFileChange: (file: File | null) => void;
  handleChangeGenerate: (value: boolean) => void;
  isSP?: boolean;
}

const ImageGeneForm: React.FC<IdeaImageGeneFormProps> = ({
  num,
  size,
  text_size,
  generate,
  imgFile,
  imgSrc,
  loading,
  handleImgSrcChange,
  handleImgFileChange,
  require,
  handleChangeGenerate,
  display_generate_form = false,
  error,
  isSP,
}) => {
  const { value, handleChange } = useTextfield();
  return (
    <section className="radio-button-form-button-wrapper">
      {num && (
        <NumAndTitle
          num={num}
          title="アイデアの画像"
          num_size="small"
          title_size="small"
        />
      )}
      {(require === true || require === false) && (
        <RequireAndTitle
          title="アイデアの画像"
          require={require}
          hint={'タイトルや説明文から画像を\nAIで生成することができます。'}
          hint_placement={isSP ? 'top-right-center' : 'top-left-center'}
        />
      )}
      <RadioButtonAtoms
        checked={!generate}
        size={size}
        onChange={() => {
          handleChangeGenerate(false);
        }}
      >
        <div
          className={`radio-button-form-button-cell ${!generate ? 'selected' : ''} ${size} `}
        >
          <BodyTextIons
            text={'アイデアの画像を自分でアップロードする'}
            size={text_size}
            className="radio-button-form-button-text"
          />
        </div>
      </RadioButtonAtoms>
      <RadioButtonAtoms
        checked={generate}
        size={size}
        onChange={() => {
          handleChangeGenerate(true);
        }}
      >
        <div
          className={`radio-button-form-button-cell ${generate ? 'selected' : ''} ${size}`}
        >
          <BodyTextIons
            text={'アイデアの画像をAIが自動で生成する'}
            size={text_size}
            className="radio-button-form-button-text"
          />
        </div>
      </RadioButtonAtoms>

      {generate ? (
        display_generate_form ? (
          <div className="image-gene-form">
            {imgSrc !== null ? (
              <IdeaImgIons
                img_src={imgSrc}
                onDelete={() => handleImgSrcChange(null)}
              />
            ) : imgFile !== null ? (
              <IdeaImgIons
                img_src=""
                imgFile={imgFile}
                onDelete={() => handleImgFileChange(null)}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  aspectRatio: '7/3',
                  borderRadius: '5px',
                }}
              >
                <BodySubTextIons
                  size="extra-small"
                  text="画像がまだありません"
                />
              </div>
            )}

            <DefaultTextFieldIons
              value={value}
              placeholder={
                '出力したいアイデアの画像の特徴を記入\n(例: おしゃれなカフェの店内)'
              }
              onChange={handleChange}
              label={null}
              error={null}
              rows={2}
              width={'100%'}
              max={100}
              design_type="underline"
            />
            <RoundPurpleButtonIons width={'70%'}>生成</RoundPurpleButtonIons>
          </div>
        ) : null
      ) : (
        <ImageUploadForm
          loading={loading}
          imgFile={imgFile}
          imgSrc={imgSrc}
          onImageUpload={handleImgFileChange}
          error={error}
        />
      )}
    </section>
  );
};

export default ImageGeneForm;
