import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openLoginPopup } from '../../../../../redux/slice/loginPopupSlice';
import { RootState } from '../../../../../redux/store';
import { userFollowAPI } from '../../../../../services/api/user/follow/userFollowAPI';
import {
  FollowUserAPIProps,
  UnFollowUserAPIProps,
} from '../../../../../services/api/user/follow/userFollowAPI.type';
import { userUnFollowAPI } from '../../../../../services/api/user/follow/userUnFollowAPI';

export const useOthersPageActions = (input: { userQueryKey: any }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  // ユーザーをフォローする
  const handleFollowUser = useCallback((props: FollowUserAPIProps) => {
    if (!isLoggedIn) {
      dispatch(openLoginPopup() as any);
    } else {
      userFollowAPI(props.follow, async () => {
        queryClient.invalidateQueries(input.userQueryKey);
        if (props.onSuccess) props.onSuccess();
      });
    }
  }, []);

  // ユーザーのフォローを解除する
  const handleUnFollowUser = useCallback((props: UnFollowUserAPIProps) => {
    if (!isLoggedIn) {
      dispatch(openLoginPopup() as any);
    } else {
      userUnFollowAPI(props.follow, async () => {
        queryClient.invalidateQueries(input.userQueryKey);
        if (props.onSuccess) props.onSuccess();
      });
    }
  }, []);

  return {
    handleFollowUser,
    handleUnFollowUser,
  };
};
