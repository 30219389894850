import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { ScreenSizeType } from '../../../../types/screenSizeType';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import './IdeaApealAndStory.css';

interface TagsCheckboxGridProps {
  idea: IdeaDetailType;
  size?: ScreenSizeType;
}

const IdeaApealAndStory: React.FC<TagsCheckboxGridProps> = (props) => {
  const { idea } = props;
  return (
    <div className="idea-appeal-and-story-wrapper">
      <div className={`idea-appeal-and-story-cell ${props.size}`}>
        <div className="idea-appealpoints-cell">
          {idea.appealPoints.length > 0 && (
            <NumAndTitle num={1} title={idea.appealPoints[0]} />
          )}
          {idea.appealPoints.length > 1 && (
            <NumAndTitle num={2} title={idea.appealPoints[1]} />
          )}
          {idea.appealPoints.length > 2 && (
            <NumAndTitle num={3} title={idea.appealPoints[2]} />
          )}
        </div>
        <BodyTextIons
          text={idea.description}
          size="small"
          className="idea-story-text"
        />
      </div>
    </div>
  );
};

export default IdeaApealAndStory;
