import React from 'react';
import ImgAtoms from '../../atoms/img/Img.Atoms';
import { ImgAtomsProps } from '../../atoms/img/Img.type';
import './Img.Ions.css';

interface IdeaImgIonsProps extends ImgAtomsProps {
  isAdopted?: boolean;
}

const IdeaImgIons: React.FC<IdeaImgIonsProps> = React.memo((props) => {
  const { isAdopted, ...imgProps } = props;

  return (
    <div className="img-ions-wrapper">
      <ImgAtoms
        {...imgProps}
        img_src={process.env.REACT_APP_IMAGES_URL + props.img_src}
        className={`idea-img-ions ${props.className}`}
      />

      {isAdopted && (
        <div className="idea-img-ions-adopted-container">
          <ImgAtoms
            img_src={process.env.REACT_APP_PUBLIC_IMAGES_URL + 'adopted.png'}
            alt={props.alt + '-adopted'}
            className="idea-img-ions-adopted-img"
          />
        </div>
      )}
    </div>
  );
});

export default IdeaImgIons;
