import React from 'react';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { TagTextIons } from '../../../ions/text/tag/TagText.Ions';
import './TextTags.css';

interface HomeTitleAndSubtitleProps {
  tags: { label: string; value: string }[];
  size?: TextSizeType;
  className?: string;
}

const TextTags: React.FC<HomeTitleAndSubtitleProps> = React.memo((props) => {
  return (
    <section className="text-tags-molecules">
      {props.tags.map((tag: { label: string; value: string }) => (
        <TagTextIons
          key={tag.value}
          text={tag.label}
          size={props.size}
          color="purple"
          onClick={() =>
            PageNavigation.goToSearchPage({ categoryId: tag.value })
          }
          className={props.className}
        />
      ))}
    </section>
  );
});

export default TextTags;
