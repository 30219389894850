export const getIsLoggedInAPI = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/me/isLoggedIn`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // レスポンスデータを JSON としてパース
    const data = await response.json();

    return data; // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
