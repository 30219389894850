import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getRoomAPI } from '../../../services/api/room/get/getRoomAPI';
import { RoomCardType } from '../../../types/api/room/RoomTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetRoomResult = UseQueryResult<RoomCardType> & {
  queryKey: readonly ['room', string];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetRoom = (roomId?: string | null): UseGetRoomResult => {
  const queryKey = ['room', roomId] as const;

  const result = useQuery<RoomCardType>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!roomId,
    queryFn: () => {
      if (!roomId) throw new Error('roomId is required');
      return getRoomAPI(roomId);
    },
    // staleTime: 1000 * 60 * 60, // 1時間
    // gcTime: 1000 * 60 * 60, // 1時間
  });

  return {
    ...result,
    queryKey,
  } as UseGetRoomResult;
};
