import React from 'react';
import './Spinner.Atoms.css';
import { SpinnerProps } from './Spinner.type';

const SpinnerAtoms: React.FC<SpinnerProps> = React.memo(
  ({ className, size, color }) => {
    return <div className={`spinner-atoms ${size} ${color} ${className}`} />;
  },
);

export default SpinnerAtoms;
