import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openLoginPopup } from '../../redux/slice/loginPopupSlice';
import { createIdeaActivityAPI } from '../../services/api/idea/activity/createIdeaActivityAPI';
import { createIdeaActivityAPIProps } from '../../services/api/idea/activity/createIdeaActivityAPI.type';
import { deleteIdeaActivityAPI } from '../../services/api/idea/activity/deleteIdeaActivityAPI';
import { deleteIdeaActivityAPIProps } from '../../services/api/idea/activity/deleteIdeaActivityAPI.type';
import { adoptIdeaAPI } from '../../services/api/idea/adopt/adoptIdeaAPI';
import { AdoptIdeaAPIProps } from '../../services/api/idea/adopt/adoptIdeaAPI.type';
import { adoptIdeaRequestAPI } from '../../services/api/idea/adopt/adoptIdeaRequestAPI';
import { AdoptIdeaRequestAPIProps } from '../../services/api/idea/adopt/adoptIdeaRequestAPI.type';
import { createIdeaCommentAPI } from '../../services/api/idea/comment/createIdeaCommentAPI';
import { createIdeaCommentAPIProps } from '../../services/api/idea/comment/createIdeaCommentAPI.type';
import { deleteIdeaCommentAPI } from '../../services/api/idea/comment/deleteIdeaCommentAPI';
import { deleteIdeaCommentAPIProps } from '../../services/api/idea/comment/deleteIdeaCommentAPI.type';
import { likeIdeaCommentAPI } from '../../services/api/idea/comment/like/likeIdeaCommentAPI';
import {
  likeIdeaCommentAPIProps,
  unlikeIdeaCommentAPIProps,
} from '../../services/api/idea/comment/like/likeIdeaCommentAPI.type';
import { unlikeIdeaCommentAPI } from '../../services/api/idea/comment/like/unlikeIdeaCommentAPI';
import { CreateIdeaAPIProps } from '../../services/api/idea/create/createIdeaAPI.type';
import { deleteIdeaAPI } from '../../services/api/idea/delete/deleteIdeaAPI';
import { editIdeaAPI } from '../../services/api/idea/edit/editIdeaAPI';
import { editIdeaAPIProps } from '../../services/api/idea/edit/editIdeaAPI.type';
import { publishIdeaAPI } from '../../services/api/idea/edit/publishIdeaAPI';
import { unPublishIdeaAPI } from '../../services/api/idea/edit/unPublishIdeaAPI';
import { likeIdeaAPI } from '../../services/api/idea/like/likeIdeaAPI';
import { likeIdeaAPIProps } from '../../services/api/idea/like/likeIdeaAPI.type';
import { unlikeIdeaAPI } from '../../services/api/idea/like/unlikeIdeaAPI';
import { createRoomAPI } from '../../services/api/room/create/createRoomAPI';
import { createRoomAPIProps } from '../../services/api/room/create/createRoomAPI.type';
import { IdeaDetailType } from '../../types/api/idea/IdeaTypes';
import { InvestmentType } from '../../types/api/investment/InvestmentTypes';
import { RoomCardType } from '../../types/api/room/RoomTypes';
import { UserCardType } from '../../types/api/user/UserTypes';
import useLoading from '../useLoading';
import { usePageNavigation } from '../usePageNavigation';
import { createIdea } from './../../services/api/idea/create/createIdeaAPI';
import { IdeaErrorType } from './../../types/api/idea/IdeaError.type';
import { PageNavigation } from './../../utils/pageNavigation';

export interface IdeaAPILoadingState {
  sendIdeaActivitiy: boolean;
  deleteIdeaActivitiy: boolean;
  createIdea: boolean;
  adpotIdea: boolean;
  adoptIdeaRequest: boolean;
  editIdea: boolean;
}

export interface IdeaAPIErrors {
  sendIdeaActivitiy: IdeaErrorType | null;
  deleteIdeaActivitiy: IdeaErrorType | null;
  adoptIdea: IdeaErrorType | null;
  adoptIdeaRequest: IdeaErrorType | null;
  createIdea: IdeaErrorType | null;
  editIdea: IdeaErrorType | null;
}

export interface IdeaAPIHandlers {
  handleCreateIdea: (props: CreateIdeaAPIProps) => void;
  getMainButtonText: (props: {
    idea: IdeaDetailType;
    user: UserCardType | null;
  }) => string;
  handleClickMainButton: (props: {
    idea: IdeaDetailType;
    user: UserCardType | null;
    investment: InvestmentType;
  }) => void;
  handleLike: (props: likeIdeaAPIProps) => void;
  handleUnlike: (props: likeIdeaAPIProps) => void;
  handleSendIdeaComment: (props: createIdeaCommentAPIProps) => void;
  handleLikeIdeaComment: (props: likeIdeaCommentAPIProps) => void;
  handleUnlikeIdeaComment: (props: unlikeIdeaCommentAPIProps) => void;
  handleDeleteIdeaComment: (props: deleteIdeaCommentAPIProps) => void;
  handleSendIdeaActivity: (props: createIdeaActivityAPIProps) => void;
  handleDeleteIdeaActivity: (props: deleteIdeaActivityAPIProps) => void;
  handleEditIdea: (props: editIdeaAPIProps) => void;
  handleCreateRoomAndNavigate: (props: createRoomAPIProps) => void;
  handleIdeaPublish: (ideaId: string) => void;
  handleIdeaUnPublish: (ideaId: string) => void;
  handleDeleteIdea: (ideaId: string) => void;
  handleAdoptIdea: (props: AdoptIdeaAPIProps) => void;
  handleAdoptIdeaRequest: (props: AdoptIdeaRequestAPIProps) => void;
}

export const useIdeaActions = (input: {
  isLoggedIn: boolean;
  ideaKey?: any;
  ideaCommentsKey?: any;
  ideaActivitiesKey?: any;
}): {
  apiLoadings: IdeaAPILoadingState;
  apiErrors: IdeaAPIErrors;
  apiHandlers: IdeaAPIHandlers;
} => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { goToIdeaPage, goToMyPage } = usePageNavigation();

  // アイデアを採用する
  const {
    loading: adpotIdeaLoading,
    handleStart: handleStartAdoptIdea,
    handleEnd: handleEndAdoptIdea,
  } = useLoading(false);
  const [adoptIdeaError, setAdoptIdeaError] = useState<IdeaErrorType | null>(
    null,
  );
  const handleAdoptIdea = useCallback(async (props: AdoptIdeaAPIProps) => {
    if (!input.isLoggedIn) {
      dispatch(openLoginPopup());
    } else {
      handleStartAdoptIdea();
      const result = await adoptIdeaAPI({
        ideaId: props.ideaId,
        onSuccess: async () => {
          props.onSuccess && props.onSuccess();
        },
        onError: async () => {
          props.onError && props.onError();
        },
      });
      setAdoptIdeaError(result);
      handleEndAdoptIdea();
    }
  }, []);

  // アイデアの商品化を申請する
  const {
    loading: adpotIdeaRequestLoading,
    handleStart: handleStartAdoptIdeaRequest,
    handleEnd: handleEndAdoptIdeaRequest,
  } = useLoading(false);
  const [adoptIdeaRequestError, setAdoptIdeaRequestError] =
    useState<IdeaErrorType | null>(null);
  const handleAdoptIdeaRequest = useCallback(
    async (props: AdoptIdeaRequestAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        handleStartAdoptIdeaRequest();
        const result = await adoptIdeaRequestAPI({
          ideaId: props.ideaId,
          onSuccess: async () => {
            props.onSuccess && props.onSuccess();
          },
          onError: async () => {
            props.onError && props.onError();
          },
        });
        setAdoptIdeaRequestError(result);
        handleEndAdoptIdeaRequest();
      }
    },
    [],
  );

  // アイデアを削除する
  const handleDeleteIdea = useCallback(
    (ideaId: string) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        deleteIdeaAPI(ideaId, () => {
          window.location.href = '/';
        });
      }
    },
    [dispatch, input.isLoggedIn],
  );

  // アイデアを公開する
  const handleIdeaPublish = useCallback(
    (ideaId: string) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        publishIdeaAPI(ideaId, () => {
          window.location.reload();
        });
      }
    },
    [dispatch, input.isLoggedIn],
  );

  // アイデアを非公開する
  const handleIdeaUnPublish = useCallback(
    (ideaId: string) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        unPublishIdeaAPI(ideaId, () => {
          window.location.reload();
        });
      }
    },
    [dispatch, input.isLoggedIn],
  );

  // アイデアの生成タスクを作成する
  const {
    loading: createIdeaLoading,
    handleStart: handleCreateIdeaStart,
    handleEnd: handleCreateIdeaEnd,
  } = useLoading(false);
  const [createIdeaError, setCreateIdeaError] = useState<IdeaErrorType | null>(
    null,
  );
  const handleCreateIdea = useCallback(
    async (props: CreateIdeaAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        handleCreateIdeaStart();
        const result = await createIdea(props.task);
        // 1.5秒の遅延処理(タスク整備を待つために)
        setTimeout(async () => {
          console.log(result);
          // エラーならエラー処理、成功なら成功処理
          if (result?.type === 'error') {
            if (props.onError) props.onError();
            const { type, ..._result } = result;
            setCreateIdeaError(_result);
            handleCreateIdeaEnd();
          } else {
            if (props.onSuccess) props.onSuccess();
            setCreateIdeaError(null);
            if (result?.type === 'idea') {
              PageNavigation.goToIdeaPage(result.ideaId);
            }
            handleCreateIdeaEnd();
          }
        }, 1500);
      }
    },
    [dispatch, input.isLoggedIn],
  );

  // アイデアの作成者との部屋を作成して遷移する
  const handleCreateRoomAndNavigate = useCallback(
    (props: createRoomAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        createRoomAPI({
          room: props.room,
          onSuccess: (room: RoomCardType) => {
            if (props.onSuccess) props.onSuccess(room);
          },
        });
      }
    },
    [dispatch, input.isLoggedIn],
  );

  // メインボタンのテキスト
  const getMainButtonText = useCallback(
    (props: { idea: IdeaDetailType; user: UserCardType | null }): string => {
      const { idea, user } = props;
      return !idea.isPublic && idea.isOwner
        ? '公開する'
        : idea.isOwner
          ? '編集する'
          : user?.isCorp
            ? '詳しく聞く'
            : 'コメントする';
    },
    [dispatch, input.isLoggedIn],
  );

  // メインボタンをクリックした時の処理
  const handleClickMainButton = useCallback(
    (props: {
      idea: IdeaDetailType;
      user: UserCardType | null;
      investment: InvestmentType;
    }) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        const { idea, user } = props;
        !idea.isPublic && idea.isOwner
          ? handleIdeaPublish(idea.ideaId)
          : idea.isOwner
            ? PageNavigation.goToIdeaEditPage(idea.ideaId)
            : user?.isCorp
              ? handleCreateRoomAndNavigate({
                  room: {
                    name: idea.title,
                    userIds: [user.userId, idea.user.userId],
                  },
                  onSuccess: (room: RoomCardType) => {
                    goToMyPage({
                      mode: 'chat',
                      roomId: room.roomId,
                    });
                  },
                })
              : goToIdeaPage(idea.ideaId, { view: 'comment' });
      }
    },
    [dispatch, input.isLoggedIn],
  );

  // アイデアを編集する
  const {
    loading: editIdeaLoading,
    handleStart: handleEditIdeaStart,
    handleEnd: handleEditIdeaEnd,
  } = useLoading(false);
  const [editIdeaError, setEditIdeaError] = useState<IdeaErrorType | null>(
    null,
  );
  const handleEditIdea = useCallback(
    async (props: editIdeaAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        handleEditIdeaStart();
        const result = await editIdeaAPI(
          props.idea,
          () => {
            if (props.onSuccess) props.onSuccess();
          },
          () => {
            if (props.onError) props.onError();
          },
        );
        handleEditIdeaEnd();
        setEditIdeaError(result);
      }
    },
    [input.isLoggedIn],
  );

  // アイデアにいいねをする
  const handleLike = useCallback(
    (props: likeIdeaAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        likeIdeaAPI({
          ideaId: props.ideaId,
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: input.ideaKey,
            });
            if (props.onSuccess) props.onSuccess();
          },
        });
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // アイデアへのいいねを取り消す
  const handleUnlike = useCallback(
    (props: likeIdeaAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        unlikeIdeaAPI({
          ideaId: props.ideaId,
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: input.ideaKey,
            });
          },
        });
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // コメントを送信する
  const handleSendIdeaComment = useCallback(
    (props: createIdeaCommentAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        createIdeaCommentAPI(props.comment, () => {
          queryClient.invalidateQueries({
            queryKey: input.ideaCommentsKey,
          });
          queryClient.invalidateQueries({
            queryKey: input.ideaKey,
          });
          if (props.onSuccess) props.onSuccess();
        });
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // コメントにいいねをする
  const handleLikeIdeaComment = useCallback(
    (props: likeIdeaCommentAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        likeIdeaCommentAPI({
          info: {
            ideaId: props.info.ideaId,
            commentId: props.info.commentId,
          },
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: input.ideaCommentsKey,
            });
            if (props.onSuccess) props.onSuccess();
          },
        });
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // コメントへのいいねを取り消す
  const handleUnlikeIdeaComment = useCallback(
    (props: unlikeIdeaCommentAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        unlikeIdeaCommentAPI({
          info: {
            ideaId: props.info.ideaId,
            commentId: props.info.commentId,
          },
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: input.ideaCommentsKey,
            });
            if (props.onSuccess) props.onSuccess();
          },
        });
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // コメントを取り消す
  const handleDeleteIdeaComment = useCallback(
    (props: deleteIdeaCommentAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        deleteIdeaCommentAPI({
          info: {
            ideaId: props.info.ideaId,
            commentId: props.info.commentId,
          },
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: input.ideaCommentsKey,
            });
            queryClient.invalidateQueries({
              queryKey: input.ideaKey,
            });
            if (props.onSuccess) props.onSuccess();
          },
        });
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // 活動記録を送信する
  const {
    loading: sendIdeaActivityLoading,
    handleStart: handleStartSendIdeaActivity,
    handleEnd: handleEndSendIdeaActivity,
  } = useLoading(false);
  const [sendIdeaActivitiyError, setSendIdeaActivitiyError] =
    useState<IdeaErrorType | null>(null);

  const handleSendIdeaActivity = useCallback(
    async (props: createIdeaActivityAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        handleStartSendIdeaActivity();
        const result = await createIdeaActivityAPI(
          props.activity,
          () => {
            queryClient.invalidateQueries({
              queryKey: input.ideaActivitiesKey,
            });
            if (props.onSuccess) props.onSuccess();
            handleEndSendIdeaActivity();
          },
          () => {
            if (props.onError) props.onError();
            handleEndSendIdeaActivity();
          },
        );
        setSendIdeaActivitiyError(result);
        handleEndSendIdeaActivity();
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  // 活動記録を削除する
  const {
    loading: deleteIdeaActivityLoading,
    handleStart: handleStartDeleteIdeaActivity,
    handleEnd: handleEndDeleteIdeaActivity,
  } = useLoading(false);
  const [deleteIdeaActivitiyError, setDeleteIdeaActivitiyError] =
    useState<IdeaErrorType | null>(null);

  const handleDeleteIdeaActivity = useCallback(
    async (props: deleteIdeaActivityAPIProps) => {
      if (!input.isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        handleStartDeleteIdeaActivity();
        const result = await deleteIdeaActivityAPI(props.activity, () => {
          queryClient.invalidateQueries({
            queryKey: input.ideaActivitiesKey,
          });
          if (props.onSuccess) props.onSuccess();
          handleEndDeleteIdeaActivity();
        });
        setDeleteIdeaActivitiyError(result);
        handleEndDeleteIdeaActivity();
      }
    },
    [dispatch, input.ideaKey, input.isLoggedIn],
  );

  const apiLoadings: IdeaAPILoadingState = {
    sendIdeaActivitiy: sendIdeaActivityLoading,
    deleteIdeaActivitiy: deleteIdeaActivityLoading,
    adpotIdea: adpotIdeaLoading,
    adoptIdeaRequest: adpotIdeaRequestLoading,
    createIdea: createIdeaLoading,
    editIdea: editIdeaLoading,
  };

  const apiErrors: IdeaAPIErrors = {
    sendIdeaActivitiy: sendIdeaActivitiyError,
    deleteIdeaActivitiy: deleteIdeaActivitiyError,
    adoptIdea: adoptIdeaError,
    adoptIdeaRequest: adoptIdeaRequestError,
    createIdea: createIdeaError,
    editIdea: editIdeaError,
  };

  const apiHandlers: IdeaAPIHandlers = {
    handleCreateIdea,
    handleAdoptIdea,
    getMainButtonText,
    handleClickMainButton,
    handleLike,
    handleUnlike,
    handleSendIdeaComment,
    handleLikeIdeaComment,
    handleUnlikeIdeaComment,
    handleDeleteIdeaComment,
    handleSendIdeaActivity,
    handleDeleteIdeaActivity,
    handleEditIdea,
    handleCreateRoomAndNavigate,
    handleIdeaPublish,
    handleIdeaUnPublish,
    handleDeleteIdea,
    handleAdoptIdeaRequest,
  };

  return { apiLoadings, apiErrors, apiHandlers };
};
