import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetUserByToken } from '../../../hooks/api/user/useGetUserByToken';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import { tokenVerifyCorpAPI } from '../../../services/api/auth/verify/emailCorpVerifyAPI';
import CreateCorpTemplate from '../../templates/auth/verify/CreateCorpTemplate';

const CreateCorpByTokenPage = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [isTokenVerified, setIsTokenVerified] = React.useState<boolean | null>(
    null,
  );
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // 事業者情報の登録にはトークン認証が必要。
  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) setIsTokenVerified(false);
      else setIsTokenVerified(await tokenVerifyCorpAPI(token));
    };
    verifyEmail();
  }, [dispatch]);

  // トークンからユーザー情報を取得
  const { data: user } = useGetUserByToken({
    token,
    enabled: !!isTokenVerified,
  });

  // 入力値を管理
  const {
    values,
    handlers: formHandlers,
    errors: formErrors,
  } = useAuthForm({
    userName: user?.userName || '',
    corpName: '',
    email: user?.email || '',
    password: '',
  });

  // APIの呼び出し
  const {
    handlers: apiHandlers,
    errors: apiErrors,
    loadings: apiLoadings,
  } = useAuthActions({
    values: values,
  });

  return (
    <CreateCorpTemplate
      isTokenVerified={isTokenVerified}
      user={user}
      isSP={isXS}
      values={values}
      formErrors={formErrors}
      formHandlers={formHandlers}
      apihandlers={apiHandlers}
      apiError={apiErrors.createCorp}
      apiLoading={apiLoadings.createCorp}
      tokenId={token || ''}
      handleCreateCorp={() => apiHandlers.handleCreateCorpByToken(token || '')}
    />
  );
};

export default CreateCorpByTokenPage;
