import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';

// パスワードの再設定用のAPI
// 入力されたメールアドレスを元に、トークンを発行する
export const createTokenForPasswordAPI = async (
  email: string,
): Promise<AuthErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/password/reset`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email }),
      },
    );

    if (!response.ok) {
      const errorData: AuthErrorType = await response.json();
      return errorData;
    }

    return null;
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
