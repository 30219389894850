import React from 'react';
import CommentTextFieldAtoms from '../../atoms/textfield/comment/CommentTextField.Atoms';
import { CommentTextFieldAtomsProps } from '../../atoms/textfield/comment/CommentTextField.type';

const DefaultCommentTextFieldIons: React.FC<CommentTextFieldAtomsProps> =
  React.memo((props) => {
    return <CommentTextFieldAtoms {...props} />;
  });

export default DefaultCommentTextFieldIons;
