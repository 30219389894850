import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import HowToUseCorpTemplate from '../../templates/howToUse/HowToUseCorpTemplate';
import HowToUseTemplate from '../../templates/howToUse/HowToUseTemplate';
import LoginPage from '../login/LoginPage';

const SignupCompletePage = () => {
  const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  if (!user) {
    return null;
  }
  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return user.isCorp ? (
    <HowToUseCorpTemplate
      isSP={isXS}
      message="アカウントの登録が完了しました"
    />
  ) : (
    <HowToUseTemplate isSP={isXS} message="アカウントの登録が完了しました" />
  );
};

export default SignupCompletePage;
