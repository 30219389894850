import imageCompression from 'browser-image-compression';

export const compressImage = async (image: File): Promise<File | null> => {
  try {
    const compressedFile = await imageCompression(image, {
      maxSizeMB: 1.0, // 500KB以下に圧縮する
      maxWidthOrHeight: 2560, // 画像の最大幅または最大高さを指定
    });
    return compressedFile;
  } catch (error) {
    console.error('画像の圧縮中にエラーが発生しました:', error);
    return null;
  }
};
