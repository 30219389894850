import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React from 'react';
import { useSelector } from 'react-redux';
import useOpen from '../../../../hooks/useOpen';
import { RootState } from '../../../../redux/store';
import { createIdeaCommentAPIProps } from '../../../../services/api/idea/comment/createIdeaCommentAPI.type';
import { deleteIdeaCommentAPIProps } from '../../../../services/api/idea/comment/deleteIdeaCommentAPI.type';
import {
  likeIdeaCommentAPIProps,
  unlikeIdeaCommentAPIProps,
} from '../../../../services/api/idea/comment/like/likeIdeaCommentAPI.type';
import { IdeaCommentType } from '../../../../types/api/idea/IdeaTypes';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { formatTimeAgo } from '../../../../utils/timeCalculator';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import './CommentCardKubo.Molecules.css';
import { CommentCardKuboMoleculesReplyTextfield } from './CommentCardKuboReplyTextfield.Molecules';

type IdeaCardProps = {
  parentCommentId: string;
  comment: IdeaCommentType;
  role: string;
  isOwner?: boolean;
  ideaUserId: string;
  onSendComment: (input: createIdeaCommentAPIProps) => void;
  onLikeComment: (input: likeIdeaCommentAPIProps) => void;
  onDeleteComment: (input: deleteIdeaCommentAPIProps) => void;
  onUnlikeComment: (input: unlikeIdeaCommentAPIProps) => void;
};

export const CommentCardKuboMoleculesReply: React.FC<IdeaCardProps> =
  React.memo((props) => {
    const { comment } = props;
    const { user, isLoggedIn } = useSelector((state: RootState) => state.auth);
    const { open, handleOpen, handleClose } = useOpen();

    return (
      <div className="comment-card-kubo-molecules-full">
        <div className="comment-card-kubo-molecules-container reply">
          <div className="comment-card-kubo-molecules-header">
            <div className="comment-card-kubo-molecules-user-info">
              <UserIconImgIons
                img_src={comment.user.imgSrc}
                userName={comment.user.userName}
                alt="user-icon"
                size="large"
                onClick={() =>
                  user?.userId !== comment.user.userId
                    ? PageNavigation.goToOtherUserPage(comment.user.userId)
                    : PageNavigation.goToMyPage()
                }
              />

              <BodyTextIons
                text={comment.user.userName}
                className="comment-card-kubo-molecules-username"
              />
            </div>

            <div
              className="comment-card-kubo-molecules-like-wrapper"
              onClick={() => {
                if (comment.isLiked) {
                  props.onUnlikeComment({
                    info: {
                      ideaId: comment.ideaId,
                      commentId: comment.commentId,
                    },
                  });
                } else {
                  props.onLikeComment({
                    info: {
                      ideaId: comment.ideaId,
                      commentId: comment.commentId,
                    },
                  });
                }
              }}
            >
              {comment.isLiked ? (
                <FavoriteIcon
                  className="comment-card-kubo-molecules-like-icon liked animate-bounce"
                  sx={{
                    fontSize: 32,
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  className="comment-card-kubo-molecules-like-icon animate-bounce"
                  sx={{
                    fontSize: 32,
                  }}
                />
              )}
              <BodyTextIons
                text={props.comment.numOfLikes.toString()}
                className={`comment-card-kubo-molecules-like-text ${comment.isLiked ? 'liked' : ''}`}
              />
            </div>
          </div>
          <BodyTextIons
            text={comment.text}
            className="comment-card-kubo-molecules-text"
          />
          <div className="comment-card-kubo-molecules-time-wrapeer">
            <BodyTextIons
              text={formatTimeAgo(comment.createdAt)}
              className="comment-card-kubo-molecules-time-text"
            />
          </div>
        </div>

        <div className="comment-card-kubo-molecules-buttons">
          {user?.userId === comment.user.userId && (
            <BodyTextIons
              text="削除する"
              className="comment-card-kubo-molecules-button-text"
              onClick={() =>
                props.onDeleteComment({
                  info: {
                    ideaId: comment.ideaId,
                    commentId: comment.commentId,
                  },
                })
              }
            />
          )}
          {isLoggedIn && (
            <BodyTextIons
              text="返信する"
              className={`comment-card-kubo-molecules-button-text ${props.isOwner ? 'owner' : ''}`}
              onClick={handleOpen}
            />
          )}
        </div>

        {open && (
          <div className="comment-card-kubo-molecules-reply-textfield">
            <div style={{ width: '100%' }}>
              <CommentCardKuboMoleculesReplyTextfield
                ideaId={comment.ideaId}
                mentionUserId={comment.user.userId}
                mentionUserName={comment.user.userName}
                commentId={props.parentCommentId}
                isOwner={user?.userId === props.ideaUserId}
                onSendComment={props.onSendComment}
                onCancelReply={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    );
  });
