import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import React from 'react';
import ButtonAtoms from '../../../../atoms/button/Button.Atoms';
import { ButtonSizeType } from '../../../../atoms/button/Button.type';
import '../ButtonCircle.css';
import './CircleFixedPostButton.Ions.css';

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  isMobile?: boolean;
  size?: ButtonSizeType;
}

const CircleFixedRequestButtonIons: React.FC<ButtonProps> = React.memo(
  (props) => {
    return (
      <ButtonAtoms
        onClick={props.onClick}
        disabled={props.disabled}
        color="purple"
        className={`button-circle-ions ${props.size ?? 'small'} request-button-ions ${props.isMobile ? 'mobile' : ''}`}
        padding={0}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <ContentPasteIcon
            className={`post-button-ions-icon ${props.size ?? 'small'}`}
          />
          <span className={`post-button-ions-text ${props.size ?? 'small'}`}>
            {'お題を投稿'}
          </span>
        </div>
      </ButtonAtoms>
    );
  },
);

export default CircleFixedRequestButtonIons;
