import React from 'react';
import { PopoverPlacement } from '../../../atoms/popover/Popover.type';
import { TextSizeType } from '../../../atoms/text/Text.type';
import TooltipPopoverIons from '../../../ions/popover/TooltipPopover.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { OptionalTextIons } from '../../../ions/text/round/OptionalText.Ions';
import { RequireTextIons } from '../../../ions/text/round/RequireText.Ions';
import './RequireAndTitle.css';

interface HomeTitleAndSubtitleProps {
  require: boolean;
  title: string;
  require_size?: TextSizeType;
  title_size?: TextSizeType;
  hint?: string;
  hint_placement?: PopoverPlacement;
}

const RequireAndTitle: React.FC<HomeTitleAndSubtitleProps> = React.memo(
  (props) => {
    return (
      <section className="require-and-title-molecules">
        {props.require ? (
          <RequireTextIons size={props.require_size} />
        ) : (
          <OptionalTextIons size={props.require_size} />
        )}
        <BodyTextBoldIons
          text={props.title}
          size={props.title_size ?? 'small'}
        />
        {props.hint && (
          <TooltipPopoverIons
            placement={props.hint_placement}
            text={props.hint}
          />
        )}
      </section>
    );
  },
);

export default RequireAndTitle;
