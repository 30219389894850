import React from 'react';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './HeaderTitle.css';

interface HomeTitleAndSubtitleProps {
  title: string;
  titleSize: TextSizeType;
  subTitle: string;
  className?: string;
}

const HeaderTitle: React.FC<HomeTitleAndSubtitleProps> = React.memo((props) => {
  return (
    <div className={`header-title-molecules ${props.className}`}>
      <BodyTextBoldIons
        text={props.title}
        size={props.titleSize}
        className="header-title-molecules-title"
      />
      <BodyTextBoldIons
        text={props.subTitle}
        className="header-title-molecules-subtitle"
      />
    </div>
  );
});

export default HeaderTitle;
