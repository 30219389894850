import React from 'react';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../atoms/text/Text.type';
import DefaultCarouselIons from '../../ions/carousel/DefaultCarousel.Ions';
import { AboutCardMolecules } from '../../molecules/card/about/AboutCard.Molecules';
import './AboutCarousel.css';

interface IdeaCarouselProps {
  title: string | null;
  subTitle?: string;
  slide_min_width: number;
  card_width: number;
  ideas: IdeaCardType[];
  isSP: boolean;

  type?: 'title' | 'title_with_bg' | 'more';
  isTwoRows?: boolean;
  title_size?: TextSizeType;
  subTitle_size?: TextSizeType;
  button_size?: 'medium' | 'large' | 'extra-large';
  moreOnClick?: () => void;

  playOnInit?: boolean;
}

const AboutCarousel: React.FC<IdeaCarouselProps> = (props) => {
  const data = [
    {
      title: '画像付きアイデアを投稿',
      description:
        '投稿ボタンから、アイデアの詳細やイメージ画像を投稿してください',
      imgSrc: 'system-images/static/ABOUT-1.png',
    },
    {
      title: 'いいねやコメントが集まる',
      description:
        'いいねやコメントがつくと、あなたのアイデアが、より多くの企業の目にとまります',
      imgSrc: 'system-images/static/ABOUT-2.png',
    },
    {
      title: '企業とマッチング',
      description:
        'アイデアに共感する人々や企業と出会い、実現に向けた一歩を踏み出せます',
      imgSrc: 'system-images/static/ABOUT-3.png',
    },
    {
      title: '一部売上をGET',
      description:
        'アイデアが実現すれば、商品が売れる度あなたに売り上げの一部が還元されます',
      imgSrc: 'system-images/static/ABOUT-4.png',
    },
  ];

  return (
    <section className="about-calousel">
      <DefaultCarouselIons
        slide_min_width={props.slide_min_width}
        isTwoRows={false}
        viewPortPadding="10px 0px 0px 10px"
        items={[
          ...data.map((about, index: number) => (
            <AboutCardMolecules
              number={index + 1}
              title={about.title}
              description={about.description}
              imgSrc={about.imgSrc}
              width={props.card_width}
              height={props.card_width}
            />
          )),
        ]}
        loop={false}
        displayButton={false}
        playOnInit={false}
      />
    </section>
  );
};

export default AboutCarousel;
