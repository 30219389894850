import { useSearchParams } from 'react-router-dom';

const useSetQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setPageQuery = (num: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', num.toString());
    setSearchParams(newParams);
  };

  const setIsPublicQuery = (isPublic: boolean) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('isPublic', isPublic ? 'true' : 'false');
    setSearchParams(newParams);
  };

  const setThemeIdQuery = (requestId: string | null) => {
    const newParams = new URLSearchParams(searchParams);
    if (requestId === null) {
      newParams.delete('requestId');
      setSearchParams(newParams);
    } else {
      newParams.set('requestId', requestId);
      setSearchParams(newParams);
    }
  };

  return { setPageQuery, setIsPublicQuery, setThemeIdQuery };
};

export default useSetQuery;
