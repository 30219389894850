import FavoriteIcon from '@mui/icons-material/Favorite';
import React from 'react';
import ButtonAtoms from '../../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../../atoms/button/Button.type';
import '../ButtonRound.css';
import './RoundLikeButton.Ions.css';

interface ButtonProps extends Omit<ButtonAtomsProps, 'children'> {
  numOfLikes: number;
  liked?: boolean;
}

const RoundLikeButtonIons: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <ButtonAtoms
      {...props}
      className={`button-round-ions round-like-button-ions ${props.liked ? 'selected' : ''} `}
    >
      <div className="round-like-button-ions-wrapper">
        <FavoriteIcon />
        <span className="round-like-button-ions-text">いいね</span>
        <span
          className={`like-button-ions-num-of-likes ${props.disabled ? 'disabled' : props.liked ? 'selected' : ''}`}
        >
          {props.numOfLikes}
        </span>
      </div>
    </ButtonAtoms>
  );
});

export default RoundLikeButtonIons;
