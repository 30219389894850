import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { IdeaCardMolecules } from '../../../molecules/card/idea/IdeaCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import './IdeaCardGrid.css';

interface TagsCheckboxGridProps {
  page: number;
  maxPage: number;
  columns: number;
  ideas: IdeaCardType[];
  card_text_size?: 'extra-small' | 'small';
  isSP: boolean;
}

const IdeaCardGrid: React.FC<TagsCheckboxGridProps> = (props) => {
  return (
    <section className="card-grid-section">
      {(props.ideas || []).length ? (
        <div
          data-testid="card-grid"
          className={`card-grid-container columns-${props.columns}`}
        >
          {props.ideas.map((idea) => (
            <IdeaCardMolecules
              hover_effect={!props.isSP}
              idea={idea}
              text_size={props.card_text_size}
              width={'100%'}
            />
          ))}
        </div>
      ) : (
        <div className="card-grid-no-item">
          <h4 className="card-grid-no-item-text">
            アイデアが見つかりませんでした
          </h4>
        </div>
      )}

      {props.page > 0 && props.maxPage > 0 ? (
        <PageNationButtons num={props.page} max_num={props.maxPage} />
      ) : null}
    </section>
  );
};

export default IdeaCardGrid;
