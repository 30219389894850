import React from 'react';
import ButtonAtoms from '../../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../../atoms/button/Button.type';
import '../ButtonFullRound.css';

const FullRoundPurpleButtonIons: React.FC<ButtonAtomsProps> = React.memo(
  (props) => {
    return (
      <ButtonAtoms
        {...props}
        color="purple"
        className={'button-full-round-ions button-filled-purple'}
      />
    );
  },
);

export default FullRoundPurpleButtonIons;
