import MenuIcon from '@mui/icons-material/Menu';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import useOpen from '../../../hooks/useOpen';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import DrawerAtoms from '../../atoms/drawer/Drawer.Atoms';
import CircleRainbowButtonIons from '../../ions/button/circle/rainbow/CircleRainbowButton.Ions';
import FullRoundRegisterButtonIons from '../../ions/button/fullRound/Purple/FullRoundRegisterButton.Ions';
import FullRoundLoginButtonIons from '../../ions/button/fullRound/White/FullRoundLoginButton.Ions';
import NotifyBellButtonIons from '../../ions/button/notify/NotifyBellButton.Ions';
import NotifyMailButtonIons from '../../ions/button/notify/NotifyMailButton.Ions';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import ImgLogoIons from '../../ions/img/logo/ImgLogo.Ions';
import DefaultPopoverIons from '../../ions/popover/DefaultPopover.Ions';
import { HeaderDrawerMolecules } from '../../molecules/drawer/HeaderDrawer.Molecules';
import NotifyPopover from '../popover/notifyPopover/NotifyPopover';
import UserPopover from '../popover/userPopover/UserPopover';
import './AppHeader.css';

interface AppHeaderProps {
  logo_img_src: string;
  user_img_src: string;
}

const AppHeader: React.FC<AppHeaderProps> = React.memo((props) => {
  const { open, handleClose, handleOpen } = useOpen();
  // const { value: search, handleChange: handleChangeSearch } = useTextfield('');

  // ログイン状態・ユーザー情報・画面サイズ・通知情報を取得
  const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);
  const { isXS, isSM } = useSelector((state: RootState) => state.screenSize);
  const { notifications, unreadChatCount, unreadNotifyCount } = useSelector(
    (state: RootState) => state.notify,
  );

  // ログイン時の処理をまとめたhooks
  const { handlers } = useAuthActions();

  const onSearch = useCallback((value: string) => {
    PageNavigation.goToSearchPage({ keyword: value });
  }, []);

  return (
    <>
      <header className={`app-header ${isXS ? 'sp' : ''}`}>
        <ImgLogoIons
          size={isXS ? 'small' : isSM ? 'small' : 'medium'}
          img_src={props.logo_img_src}
          onClick={() => PageNavigation.goToHomePage()}
        />
        <div className={`app-header-icons ${isXS ? 'sp' : ''}`}>
          {!isXS && (
            <div className={`app-header-menu ${isSM ? 'small' : ''}`}>
              <label
                className={`app-header-menu-label ${isSM ? 'small' : ''}`}
                onClick={() => PageNavigation.goToHowToUsePage()}
              >
                一般ユーザーの方
              </label>
              <label
                className={`app-header-menu-label ${isSM ? 'small' : ''}`}
                onClick={() => PageNavigation.goToHowToUsePage(true)}
              >
                法人の方
              </label>
              <label
                className={`app-header-menu-label ${isSM ? 'small' : ''}`}
                onClick={() => PageNavigation.goToContactPage()}
              >
                お問い合わせ
              </label>
            </div>
          )}

          {isLoggedIn && (
            <>
              <DefaultPopoverIons
                trigger="click"
                content={
                  <NotifyPopover
                    isSP={isXS}
                    notifications={notifications || []}
                  />
                }
                placement="bottom-right-center"
              >
                <NotifyBellButtonIons
                  size={isXS ? 'large' : 'extra-large'}
                  notificationCount={unreadNotifyCount}
                />
              </DefaultPopoverIons>

              <NotifyMailButtonIons
                size={isXS ? 'large' : 'extra-large'}
                notificationCount={unreadChatCount}
                onClick={() => PageNavigation.goMyChatRoomPage()}
              />
            </>
          )}
          {isXS ? (
            <CircleRainbowButtonIons onClick={() => handleOpen()}>
              <MenuIcon className="app-header-menu-icon" />
            </CircleRainbowButtonIons>
          ) : isLoggedIn ? (
            <DefaultPopoverIons
              trigger="click"
              content={<UserPopover isSP={isXS} />}
              placement="bottom-right"
            >
              <UserIconImgIons
                userName={user?.userName || ''}
                img_src={user?.imgSrc || null}
                size={isXS ? 'medium' : 'large'}
              />
            </DefaultPopoverIons>
          ) : (
            <div className="auth-buttons">
              <FullRoundLoginButtonIons
                className={`app-header-auth-button ${isSM ? 'sm' : ''}`}
                onClick={handlers.handleOpenLogin}
              />
              <FullRoundRegisterButtonIons
                className={`app-header-auth-button ${isSM ? 'sm' : ''}`}
                onClick={handlers.handleOpenRegister}
              />
            </div>
          )}
        </div>
      </header>

      <DrawerAtoms
        isOpen={open}
        onClose={handleClose}
        direction="right"
        width="300px"
      >
        <HeaderDrawerMolecules
          isLoggedin={isLoggedIn}
          onClose={handleClose}
          onSearch={(value) => onSearch(value)}
          options={[
            {
              label: 'IDEA工房の使い方',
              onClick: () => PageNavigation.goToHowToUsePage(),
            },
            {
              label: 'IDEA工房の使い方(法人向け)',
              onClick: () => PageNavigation.goToHowToUsePage(true),
            },
            {
              label: 'IDEA一覧へ',
              onClick: () => PageNavigation.goToSearchPage(),
            },
            {
              label: 'お問い合わせ',
              onClick: () => PageNavigation.goToContactPage(),
            },
            ...(isLoggedIn
              ? [
                  {
                    label: 'マイページへ',
                    onClick: () => PageNavigation.goToMyPage({ mode: 'ideas' }),
                  },
                  {
                    label: 'ログアウト',
                    onClick: () => handlers.handleLogout(),
                  },
                ]
              : []),
          ]}
        />
      </DrawerAtoms>
    </>
  );
});

export default AppHeader;
