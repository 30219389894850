import { AuthErrorType } from '../../../../../types/api/auth/AuthError.type';
import { CreateCorpType } from './createCorpAPI.type';

export const createCorpAPI = async (
  input: CreateCorpType,
): Promise<AuthErrorType | null> => {
  try {
    const formData = new FormData();

    // 各プロパティを FormData に追加
    Object.entries(input).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        // Fileオブジェクトの場合はそのまま追加、それ以外は文字列として追加
        if (value instanceof Blob || value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
        console.log('key', key, 'value', value);
      }
    });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/corps`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      return errorData;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
