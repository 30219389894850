import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import AppFooterPC from './component/organisms/appFooter/AppFooterPC';
import AppFooterSP from './component/organisms/appFooter/AppFooterSP';
import AppHeader from './component/organisms/appHeader/AppHeader';
import TaskCardGrid from './component/organisms/grid/taskCardGrid/TaskCardGrid';
import ContactPage from './component/pages/contact/ContactPage';
import ContactSuccessPage from './component/pages/contact/ContactSuccessPage';
import ContactThemePage from './component/pages/contact/ContactThemePage';
import PriavcyPolicyPage from './component/pages/documents/PriavcyPolicyPage';
import SpecifiedCommercialPage from './component/pages/documents/SpecifiedCommercialPage';
import UserAgreementPage from './component/pages/documents/UserAgreementPage';
import AccountAlreadyExistsPage from './component/pages/error/AccountAlreadyExistsPage';
import GoogleAccountNotFoundPage from './component/pages/error/GoogleAccountNotFoundPage';
import NotFoundPage from './component/pages/error/NotFoundPage';
import HomePage from './component/pages/HomePage';
import HowToUsePage from './component/pages/HowToUsePage';
import IdeaAdoptCompletePage from './component/pages/idea/adopt/IdeaAdoptCompletePage';
import IdeaAdoptConfirmPage from './component/pages/idea/adopt/IdeaAdoptConfirmPage';
import IdeaAdoptRequestPage from './component/pages/idea/adopt/IdeaAdoptRequestPage';
import IdeaEditPage from './component/pages/idea/IdeaEditPage';
import IdeaPage from './component/pages/idea/IdeaPage';
import IdeaInvestCompletePage from './component/pages/idea/invest/IdeaInvestCompletePage';
import IdeaInvestConfirmPage from './component/pages/idea/invest/IdeaInvestConfirmPage';
import IdeaInvestDeleteCompletePage from './component/pages/idea/invest/IdeaInvestDeleteCompletePage';
import IdeaInvestDeleteConfirmPage from './component/pages/idea/invest/IdeaInvestDeleteConfirmPage';
import IdeaInvestPage from './component/pages/idea/invest/IdeaInvestPage';
import IdeaPostPopup from './component/pages/idea/popup/IdeaPostPopup';
import LoginPage from './component/pages/login/LoginPage';
import LoginPopup from './component/pages/login/popup/LoginPopup';
import NotificationsPage from './component/pages/notify/NotificationsPage';
import NotifyPage from './component/pages/notify/NotifyPage';
import RegisterPopup from './component/pages/register/popup/RegisterPopup';
import RegisterCorpPage from './component/pages/register/RegisterCorpPage';
import RegisterPage from './component/pages/register/RegisterPage';
import RequestPage from './component/pages/RequestPage';
import SearchPage from './component/pages/SearchPage';
import SettingEmailPage from './component/pages/settings/SettingEmailPage';
import SettingPasswordPage from './component/pages/settings/SettingPasswordPage';
import MyEditPage from './component/pages/users/my/MyEditPage';
import MyPage from './component/pages/users/my/MyPage';
import OthersPage from './component/pages/users/others/OthersPage';
import AccountVerifyPage from './component/pages/verify/accountVerifyCompletePage';
import CreateCorpByTokenPage from './component/pages/verify/CreateCorpPage';
import SignupCompletePage from './component/pages/verify/signupCompletePage';
import UpdatePasswordByTokenPage from './component/pages/verify/UpdatePasswordPage';
import UpdatePasswordSuccessPage from './component/pages/verify/UpdatePasswordSuccessPage';
import UpdateEmailByTokenPage from './component/pages/verify/VerifyEmailPage';
import VerifyEmailSendPage from './component/pages/verify/VerifyEmailSendPage';
import useResizeListener from './hooks/useResizeListener';
import { updateAuthThunk } from './redux/auth/thunk/updateAuthThunk';
import { updateCategoryThunk } from './redux/category/thunk/categoryThunk';
import { updateNotifyThunk } from './redux/notify/thunk/notifyThunk';
import { RootState } from './redux/store';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Hiragino Sans', // ヒラギノフォントを指定
      'Arial', // 代替フォント
      'sans-serif', // サンセリフフォント
    ].join(','),
  },
});

function AppRouter() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { isXS } = useSelector((state: RootState) => state.screenSize);

  // カテゴリー情報を取得(reduxに保持)
  useEffect(() => {
    dispatch(updateCategoryThunk() as any);
  }, [dispatch]);

  // ログイン状態の更新(reduxに保持)
  useEffect(() => {
    dispatch(updateAuthThunk() as any);
  }, [dispatch]);

  // 通知状態の更新(reduxに保持)
  useEffect(() => {
    dispatch(updateNotifyThunk() as any);
  }, [dispatch, isLoggedIn]);

  // リサイズイベントのリスナー
  useResizeListener();

  const router = createBrowserRouter([
    {
      element: (
        <>
          <AppHeader
            logo_img_src="REIDEA-LOGO.png"
            user_img_src="REIDEA-BANNER.jpg"
          />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* <ScrollRestoration /> */}
            <LoginPopup />
            <RegisterPopup />
            <IdeaPostPopup />

            <TaskCardGrid />
            <Outlet />
          </ThemeProvider>
          {isXS ? <AppFooterSP /> : <AppFooterPC />}
        </>
      ),
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        {
          path: '/ideas/:id',
          element: <IdeaPage />,
        },
        {
          path: '/ideas/:id/edit',
          element: <IdeaEditPage />,
        },
        {
          path: '/ideas/:id/adopt-request',
          element: <IdeaAdoptRequestPage />,
        },
        {
          path: '/ideas/:id/adopt-request/confirm',
          element: <IdeaAdoptConfirmPage />,
        },
        {
          path: '/ideas/:id/adopt-request/complete',
          element: <IdeaAdoptCompletePage />,
        },
        {
          path: '/ideas/:id/invest/:investId',
          element: <IdeaInvestPage />,
        },
        {
          path: '/ideas/:id/invest/:investId/confirm',
          element: <IdeaInvestConfirmPage />,
        },
        {
          path: '/ideas/:id/invest/:investId/delete/confirm',
          element: <IdeaInvestDeleteConfirmPage />,
        },
        {
          path: '/ideas/:id/invest/:investId/complete',
          element: <IdeaInvestCompletePage />,
        },
        {
          path: '/ideas/:id/invest/:investId/delete/complete',
          element: <IdeaInvestDeleteCompletePage />,
        },
        {
          path: '/search',
          element: <SearchPage />,
        },
        {
          path: '/how-to-use',
          element: <HowToUsePage />,
        },
        {
          path: '/users/my',
          element: <MyPage />,
        },
        {
          path: '/users/my/edit',
          element: <MyEditPage />,
        },
        {
          path: '/users/:userId',
          element: <OthersPage />,
        },
        {
          path: '/requests/:requestId',
          element: <RequestPage />,
        },
        {
          path: '/contact',
          element: <ContactPage />,
        },
        {
          path: '/contact/success',
          element: <ContactSuccessPage />,
        },
        {
          path: '/contact/theme',
          element: <ContactThemePage />,
        },
        {
          path: '/auth/google/account/not-found',
          element: <GoogleAccountNotFoundPage />,
        },
        {
          path: '/auth/account/already-exists',
          element: <AccountAlreadyExistsPage />,
        },
        {
          path: '/auth/verify/email/send',
          element: <VerifyEmailSendPage />,
        },
        {
          path: '/auth/setting/email',
          element: <SettingEmailPage />,
        },
        {
          path: '/auth/update/email/:token',
          element: <UpdateEmailByTokenPage />,
        },
        {
          path: '/auth/setting/password',
          element: <SettingPasswordPage />,
        },
        {
          path: '/auth/update/password/:token',
          element: <UpdatePasswordByTokenPage />,
        },
        {
          path: '/auth/update/password/:token/success',
          element: <UpdatePasswordSuccessPage />,
        },
        {
          path: '/auth/verify/:token',
          element: <AccountVerifyPage />,
        },
        {
          path: '/auth/create/corp/:token',
          element: <CreateCorpByTokenPage />,
        },
        {
          path: '/auth/signup/complete',
          element: <SignupCompletePage />,
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/register',
          element: <RegisterPage />,
        },
        {
          path: '/corp/register',
          element: <RegisterCorpPage />,
        },
        {
          path: '/notifications',
          element: <NotificationsPage />,
        },
        {
          path: '/notifications/:notifyId',
          element: <NotifyPage />,
        },
        {
          path: '/privacy-policy',
          element: <PriavcyPolicyPage />,
        },
        {
          path: '/specified-commercial-transaction',
          element: <SpecifiedCommercialPage />,
        },
        {
          path: '/user-agreement',
          element: <UserAgreementPage />,
        },
        // 404ページ
        {
          path: 'not-found',
          element: <NotFoundPage />,
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default AppRouter;
