import { getIdeaCommentsType } from './getIdeaCommentsAPI.type';

// アイデアのコメントを取得するAPI
export const getIdeaCommentsAPI = async (input: getIdeaCommentsType) => {
  const params = new URLSearchParams();

  // デフォルト値とクエリパラメータの設定
  params.set('limit', String(input?.limit || 25));
  // オプショナルなパラメータを追加
  if (input?.page) params.set('page', String(input.page));

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${input.ideaId}/comments?${params.toString()}`,
    { credentials: 'include' },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
