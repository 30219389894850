import React, { useEffect } from 'react';
import {
  ADDRESS_MAX_LENGTH,
  CORP_NAME_MAX_LENGTH,
  IDEA_CATEGORIES_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  USER_INTRODUCTION_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
  ZIPCODE_MAX_LENGTH,
} from '../../../../constants/max';
import { useScroll } from '../../../../hooks/useScroll';
import { PageNavigation } from '../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import ErrorCardMolecules from '../../../molecules/card/error/ErrorCard.Molecules';
import LoadingOverlaySpinner from '../../../molecules/spinner/LoadingOverlaySpinner';
import HeaderTitle from '../../../molecules/text/headerTitle/HeaderTitle';
import CategoriesCheckboxGrid from '../../../organisms/grid/tagCheckboxGrid/CategoriesCheckboxGrid';
import ImageForm from '../../../organisms/textfield/form/radioForm/ImageForm';
import RequireTextfield from '../../../organisms/textfield/RequireTextfield';
import './MyEditTemplate.css';
import { MyEditTemplateProps } from './MyEditTemplate.type';

// 事業者のメール認証と、詳細情報設定
const MyEditCorpTemplate: React.FC<MyEditTemplateProps> = React.memo(
  (props) => {
    const {
      isSP,
      apiErrors,
      values,
      formLoadings,
      formErrors,
      formHandlers,
      apiLoadings,
      apiHandlers,
    } = props;
    const apiError = apiErrors.updateMeCorp;
    const { windowScrollToTop } = useScroll();

    useEffect(() => {
      if (apiError !== null) windowScrollToTop();
    }, [apiError]);

    return (
      <div className={`my-edit-template-container ${isSP ? 'sp' : ''}`}>
        {apiLoadings.updateMeCorp && <LoadingOverlaySpinner />}
        <header className="my-edit-template-header">
          <HeaderTitle
            title="Edit Account"
            subTitle="ユーザー情報を変更する"
            titleSize="large"
          />
        </header>
        <main className={`my-edit-template-main ${isSP ? 'sp' : ''}`}>
          <form
            className={`my-edit-template-form`}
            onSubmit={(e) => e.preventDefault()}
          >
            {apiError?.message && (
              <ErrorCardMolecules
                text={apiError?.message}
                isSP={isSP}
                padding="5px 10px"
                margin="10px 0"
                bold="light"
              />
            )}
            <RequireTextfield
              label="会社名(ユーザーネーム)"
              placeholder="株式会社〇〇"
              isSP={isSP}
              text={values.userName}
              handleChange={formHandlers.handleUserNameChange}
              error={formErrors.userName}
              require={true}
              max={USER_NAME_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="会社名(正式名称)"
              placeholder="株式会社〇〇"
              text={values.corpName || ''}
              handleChange={formHandlers.handleCorpNameChange}
              error={formErrors.corpName}
              require={true}
              max={CORP_NAME_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="郵便番号"
              placeholder="000-0000"
              text={values.zipCode || ''}
              handleChange={formHandlers.handleZipCodeChange}
              error={formErrors.zipCode}
              require={true}
              max={ZIPCODE_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="所在地"
              placeholder="〇〇県〇〇区〇〇1-1-1 〇〇ビル1F"
              text={values.address || ''}
              handleChange={formHandlers.handleAddressChange}
              error={formErrors.address}
              require={true}
              max={ADDRESS_MAX_LENGTH}
            />
            <RequireTextfield
              isSP={isSP}
              label="電話番号"
              placeholder="000-0000-0000"
              text={values.phoneNumber || ''}
              handleChange={formHandlers.handlePhoneNumberChange}
              error={formErrors.phoneNumber}
              require={true}
              max={PHONE_NUMBER_MAX_LENGTH}
            />
            <div className="my-edit-template-secrets">
              <RequireTextfield
                isSP={isSP}
                label="メールアドレス(認証済み)"
                placeholder="example@sample.com"
                text={values.email || ''}
                handleChange={formHandlers.handleEmailChange}
                error={formErrors.email}
                require={true}
                disabled
              />
              <BodyTextIonsClickable
                onClick={PageNavigation.goToSettingEmailPage}
                size="small"
                text="メールアドレスの変更はこちら"
              />
            </div>
            <div className="my-edit-template-secrets">
              <RequireTextfield
                isSP={isSP}
                label="パスワード"
                placeholder="***********"
                text={values.password}
                handleChange={formHandlers.handlePasswordChange}
                error={null}
                require={true}
                disabled
              />
              <BodyTextIonsClickable
                onClick={PageNavigation.goToSettingPasswordPage}
                size="small"
                text="パスワードの変更はこちら"
              />
            </div>
            <RequireTextfield
              isSP={isSP}
              label="プロフィール文"
              placeholder="事業内容や特徴を記入\n(例: 〇〇事業を展開しています)"
              text={values.introduction || ''}
              handleChange={formHandlers.handleIntroductionChange}
              error={formErrors.introduction}
              require={true}
              componentType="textarea"
              rows={6}
              max={USER_INTRODUCTION_MAX_LENGTH}
            />
            <CategoriesCheckboxGrid
              categoryIds={values.categoryIds || []}
              error={formErrors.categoryIds}
              handleChange={(categoryId) =>
                formHandlers.handleCategoriesChange(categoryId)
              }
              columns={props.isSP ? 3 : 4}
              require={true}
              max={IDEA_CATEGORIES_MAX_LENGTH}
            />

            <ImageForm
              require={false}
              imgSrc={values.imgSrc || null}
              imgFile={values.img || null}
              loading={formLoadings.img}
              title="ユーザーアイコン画像"
              handleImgFileChange={formHandlers.handleImgFileChange}
              handleImgSrcChange={formHandlers.handleImgSrcChange}
              error={null}
              type="icon"
            />
            <ImageForm
              require={false}
              imgSrc={values.bannerImgSrc || null}
              imgFile={values.bannerImg || null}
              loading={formLoadings.bannerImg}
              title="マイページバナー画像"
              handleImgFileChange={formHandlers.handleBannerImgFileChange}
              handleImgSrcChange={formHandlers.handleBannerImgSrcChange}
              error={null}
              type="banner"
            />

            <RequireTextfield
              isSP={isSP}
              label="公式webサイトURL"
              placeholder="https://example.com"
              text={values.websiteUrl || ''}
              handleChange={formHandlers.handleWebsiteUrlChange}
              error={null}
              require={false}
            />

            <RequireTextfield
              isSP={isSP}
              label="SNSリンクURL1"
              placeholder="https://example.com"
              text={values.snsUrl1 || ''}
              handleChange={formHandlers.handleSnsUrl1Change}
              error={null}
              require={false}
            />
            <RequireTextfield
              isSP={isSP}
              label="SNSリンクURL2"
              placeholder="https://example.com"
              text={values.snsUrl2 || ''}
              handleChange={formHandlers.handleSnsUrl2Change}
              error={null}
              require={false}
            />
            <RequireTextfield
              isSP={isSP}
              label="SNSリンクURL3"
              placeholder="https://example.com"
              text={values.snsUrl3 || ''}
              handleChange={formHandlers.handleSnsUrl3Change}
              error={null}
              require={false}
            />
          </form>

          <div className="my-edit-template-submit-button">
            <RoundPurpleButtonIons
              width={'60%'}
              size={'normal'}
              onClick={apiHandlers.handleUpdateMeCorp}
            >
              アカウントを登録する
            </RoundPurpleButtonIons>
            <RoundWhiteButtonIons
              width={'60%'}
              size={'normal'}
              onClick={PageNavigation.goBack}
            >
              戻る
            </RoundWhiteButtonIons>
          </div>
        </main>
      </div>
    );
  },
);

export default MyEditCorpTemplate;
