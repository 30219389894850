import React from 'react';
import {
  AuthFormHandlers,
  AuthFormState,
} from '../../../hooks/auth/useAuthForm.hooks';
import { AuthErrorType } from '../../../types/api/auth/AuthError.type';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { SNSRoundButton } from '../../ions/sns/round/SNSRoundButton';
import { BodyTextIonsClickable } from '../../ions/text/body/BodyTextClickable.Ions';
import DefaultTextFieldIons from '../../ions/textfield/DefaultTextField.Ions';
import ErrorCardMolecules from '../../molecules/card/error/ErrorCard.Molecules';
import HeaderTitle from '../../molecules/text/headerTitle/HeaderTitle';
import './AuthTemplate.css';

interface PopupProps {
  isSP: boolean;
  apiError: AuthErrorType | null;
  values: AuthFormState;
  formHandlers: AuthFormHandlers;
  handleLogin: () => void;
  handleGoogleSignin: () => void;
  handleNavigateToRegister: () => void;
}

const LoginTemplate: React.FC<PopupProps> = React.memo((props) => {
  const {
    isSP,
    handleLogin,
    handleGoogleSignin,
    apiError,
    formHandlers,
    values,
  } = props;

  return (
    <div className={`auth-template-container ${isSP ? 'sp' : ''}`}>
      <div className={`auth-template-card ${isSP ? 'sp' : ''}`}>
        <header className={`auth-template-header `}>
          <HeaderTitle title="Log-in" subTitle="ログイン" titleSize="large" />
        </header>

        <main className={`auth-template-main `}>
          <form className={`auth-template-form `}>
            <DefaultTextFieldIons
              label="メールアドレスまたはユーザーネーム"
              size={isSP ? 'small' : 'medium'}
              value={values.userNameOrEmail || ''}
              onChange={formHandlers.handleUserNameOrEmailChange}
              error={null}
            />
            <DefaultTextFieldIons
              label="パスワード"
              size={isSP ? 'small' : 'medium'}
              value={values.password}
              type="password"
              onChange={formHandlers.handlePasswordChange}
              error={null}
            />
            {apiError?.message && (
              <ErrorCardMolecules
                text={apiError?.message}
                isSP={isSP}
                padding="5px 10px"
                margin="10px 0"
                bold="light"
              />
            )}
          </form>

          <nav className="auth-template-nav-links">
            <BodyTextIonsClickable
              text="パスワードを忘れた場合"
              size="extra-small"
              onClick={PageNavigation.goToSettingPasswordPage}
            />
            <BodyTextIonsClickable
              text="新規会員登録はこちら"
              size="extra-small"
              onClick={props.handleNavigateToRegister}
            />
          </nav>

          <div className="auth-template-submit-button">
            <RoundPurpleButtonIons
              width={'100%'}
              size={'normal'}
              onClick={handleLogin}
            >
              ログイン
            </RoundPurpleButtonIons>
          </div>
        </main>

        <section className={`auth-template-sns-buttons `}>
          {['Google'].map((type) => (
            <SNSRoundButton
              key={type}
              text="ログイン"
              type={type as any}
              size="large"
              onClick={() => {
                if (type === 'Google') {
                  handleGoogleSignin();
                }
              }}
            />
          ))}
        </section>
      </div>
    </div>
  );
});

export default LoginTemplate;
