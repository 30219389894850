export const countUnreadChatAPI = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/rooms/count-unread`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
