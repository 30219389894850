import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import React from 'react';
import ButtonAtoms from '../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../atoms/button/Button.type';
import './NotifyBellButton.Ions.css';

interface NotifyBellButtonProps extends Omit<ButtonAtomsProps, 'children'> {
  notificationCount?: number; // 通知数のプロップを追加
}

const NotifyBellButtonIons: React.FC<NotifyBellButtonProps> = React.memo(
  ({ notificationCount = 0, ...props }) => {
    return (
      <ButtonAtoms
        onClick={props.onClick}
        disabled={props.disabled}
        className="notify-button-ions"
      >
        <Badge
          badgeContent={notificationCount > 99 ? '99+' : notificationCount}
          color="error"
          invisible={notificationCount === 0} // 0件のときはバッジを非表示
        >
          <NotificationsNoneIcon
            className={`notify-button-icon-ions ${props.size}`}
          />
        </Badge>
      </ButtonAtoms>
    );
  },
);

export default NotifyBellButtonIons;
