import { useSelector } from 'react-redux';
import { useGetComments } from '../../hooks/api/idea/useGetComments';
import { useGetIdeas } from '../../hooks/api/idea/useGetIdeas';
import { useGetRequests } from '../../hooks/api/request/useGetRequests';
import { RootState } from '../../redux/store';
import HomeTemplate from '../templates/home/HomeTemplate';

const HomePage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  // アイデアの取得
  const { data: main_ideas1 } = useGetIdeas({
    sort: 'new',
    like: true,
    comment: true,
    view: true,
  });
  const { data: main_ideas2 } = useGetIdeas({
    sort: 'like',
    limit: 8,
    like: true,
    view: true,
    comment: true,
  });
  const { data: main_ideas3 } = useGetIdeas({
    categoryId: 1,
    limit: 12,
  });
  const { data: comments } = useGetComments({
    limit: 5,
    page: 1,
    enabled: true,
  });
  // お題一覧取得
  const { data: themes } = useGetRequests({
    enabled: true,
    limit: 7,
  });

  return (
    <HomeTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      main_ideas3={main_ideas3?.ideas || []}
      main_ideas1={main_ideas1?.ideas || []}
      main_ideas2={main_ideas2?.ideas || []}
      themes={themes || []}
      latest_comments={comments?.comments || []}
    />
  );
};

export default HomePage;
