import { getCategoriesAPI } from '../../../services/api/category/getCategoriesAPI';
import { AppDispatch, RootState } from '../../store';
import { setCategories } from '../categorySlice';

// ユーザー認証情報(セッション)を用いて、Redux ストアを更新する
export const updateCategoryThunk =
  () =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<boolean> => {
    try {
      const category = getState().category;
      // 期限切れか、カテゴリが見つからない時
      if (
        (category.expiresAt && category.expiresAt < Date.now()) ||
        (category?.categories || []).length === 0
      ) {
        const categories = await getCategoriesAPI(); // ログインユーザー情報を取得
        await dispatch(setCategories(categories)); // 成功時に Redux ストアを更新
      }
      return true;
    } catch (error) {
      return false;
    }
  };
