import { SCREEN_SIZES } from '../constants/screenSize';

export const getScreenSize = (width: number) => {
  return {
    isXS: width < SCREEN_SIZES.XS,
    isSM: width >= SCREEN_SIZES.XS && width < SCREEN_SIZES.SM,
    isNO: width >= SCREEN_SIZES.NO && width < SCREEN_SIZES.NO,
    isMD: width >= SCREEN_SIZES.SM && width < SCREEN_SIZES.MD,
    isLG: width >= SCREEN_SIZES.MD,
  };
};
