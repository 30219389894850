import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GetUserQueryType } from '../../../services/api/user/get/getMeAPI.type';
import { getUserAPI } from '../../../services/api/user/get/getUserAPI';
import { UserLimitedType } from '../../../types/api/user/UserTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetUserResult = UseQueryResult<UserLimitedType> & {
  queryKey: string[];
};

// ユーザー一覧情報を取得するためのカスタムフック
export const useGetUser = (
  userId?: string | null,
  query?: GetUserQueryType,
): UseGetUserResult => {
  const queryKey = ['user', userId];

  const result = useQuery<UserLimitedType>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: !!userId,
    queryFn: async () => {
      if (!userId) throw new Error('userId is required');
      return getUserAPI(userId, query);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetUserResult;
};
