import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetIdea } from '../../../../hooks/api/idea/useGetIdea';
import { useAuthGuard } from '../../../../hooks/auth/useAuthGuard';
import { RootState } from '../../../../redux/store';
import IdeaAdoptRequestCompleteTemplate from '../../../templates/ideaAdopt/complete/IdeaAdoptRequestCompleteTemplate';
import NotFoundTemplate from '../../../templates/not-found/NotFoundTemplate';

const IdeaAdoptCompletePage = () => {
  // 自動リダイレクト付きの認証
  const { user } = useAuthGuard(window.location.href);

  // アイデアを取得する
  const { id } = useParams();
  const { data: idea } = useGetIdea(id ?? '');

  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  // 企業アカウントでなければリダイレクトする
  // 企業アカウントであっても、お題が存在して && お題の投稿者でなければリダイレクトする
  if (
    user?.isCorp !== true ||
    (idea?.request && idea?.request.user.userId !== user.userId)
  ) {
    return <NotFoundTemplate />;
  }

  // アイデアの投稿者としてログインできていればアイデア編集画面を表示する
  return (
    <IdeaAdoptRequestCompleteTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
    />
  );
};

export default IdeaAdoptCompletePage;
