import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getInvestmentsAPI } from '../../services/api/investment/getInvestmentsAPI';
import { getInvestmentsType } from '../../services/api/investment/getInvestmentsAPI.type';
import { InvestmentType } from '../../types/api/investment/InvestmentTypes';

// 戻り値の型を拡張して queryKey を含める
type UseGetInvestmentsResult = UseQueryResult<InvestmentType[]> & {
  queryKey: readonly ['investments', getInvestmentsType];
};

export const useGetInvestments = (
  query: getInvestmentsType,
): UseGetInvestmentsResult => {
  const queryKey = ['investments', query] as const;

  const result = useQuery<InvestmentType[]>({
    queryKey, // 定義した queryKey を使用
    enabled: !!query.ideaId,
    queryFn: async () => {
      if (!query.ideaId) throw new Error('ideaId is required');
      return getInvestmentsAPI(query);
    },
  });

  return {
    ...result,
    queryKey,
  } as UseGetInvestmentsResult;
};
