import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import AppRouter from './router';
import './styles/index.css';
import './styles/reset.css';

const queryClient = new QueryClient();

// ローカルストレージを使用したPersister
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

// Persistの設定
export const persistOptions = {
  queryClient,
  persister: localStoragePersister,
  // 必要に応じてキャッシュの有効期限を設定
  maxAge: 1000 * 60 * 60 * 24, // 24時間
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persistOptions}
      >
        <CookiesProvider>
          <AppRouter />
        </CookiesProvider>
      </PersistQueryClientProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);
