import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getNotificationsAPI } from '../../../services/api/notifications/getNotificationsAPI';
import { GetNotificationsQueryType } from '../../../services/api/notifications/getNotificationsAPI.type';
import { NotifyCardType } from '../../../types/api/notifications/NotificationType';

// 戻り値の型を拡張して queryKey を含める
type UseGetNotificationsResult = UseQueryResult<{
  notifications: NotifyCardType[];
  numOfNotifications: number;
}> & {
  queryKey: string[];
};

// アイデア一覧情報を取得するためのカスタムフック
export const useGetNotifications = (
  query?: GetNotificationsQueryType & {
    enabled?: boolean;
  },
): UseGetNotificationsResult => {
  const queryKey = ['notifications'];

  const result = useQuery<{
    notifications: NotifyCardType[];
    numOfNotifications: number;
  }>({
    queryKey: queryKey, // sortの値も含めてキャッシュを管理
    enabled: query?.enabled,
    queryFn: async () => {
      return getNotificationsAPI(query);
    },
    // staleTime: 1000 * 60 * 10, // 10分間
    // gcTime: 1000 * 60 * 10, // 10分間
  });

  return {
    ...result,
    queryKey,
  } as UseGetNotificationsResult;
};
