import React from 'react';
import { SNSShareButton } from '../../../ions/sns/share/SNSShareButton';
import './SNSCircleButtons.css';

interface SNSButtonsProps {
  size?: 'small' | 'medium' | 'large';
  title: string;
}

const SNSCircleButtons: React.FC<SNSButtonsProps> = React.memo((props) => {
  const TYPE = ['Line', 'Facebook', 'Linkedin', 'Hatena', 'Email'];
  return (
    <section className="sns-circle-buttons-molecules">
      {TYPE.map((type) => (
        <SNSShareButton
          key={type}
          url={window.location.href}
          title={
            '【新アイデア】' +
            props.title +
            ' \n\n興味を持った方はこちらのリンクをタップして詳細をご覧ください👇\n' +
            window.location.href
          }
          type={type as any}
          size={props.size}
        />
      ))}
    </section>
  );
});

export default SNSCircleButtons;
