import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signoutThunk } from '../../../../redux/auth/thunk/signoutThunk';
import { RootState } from '../../../../redux/store';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { BodySelectedTextIons } from '../../../ions/text/body/BodySelectedText.Ions';
import './UserPopover.css';

interface UserPopoverProps {
  isSP?: boolean;
}

const UserPopover: React.FC<UserPopoverProps> = React.memo(() => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const handleLogout = useCallback(async () => {
    await dispatch(signoutThunk() as any);
    await PageNavigation.goToHomePage();
  }, [dispatch]);

  return (
    <section className="user-popover-wrapper">
      <nav className="user-popover-wrapper-nav-texts">
        <BodySelectedTextIons
          text="アイデアを探す"
          onClick={() => PageNavigation.goToSearchPage()}
        />
        {user?.isCorp && (
          <BodySelectedTextIons
            text="お題を投稿する"
            onClick={() => PageNavigation.goToContactRequestPage()}
          />
        )}
        <BodySelectedTextIons
          text="マイページへ"
          onClick={() => PageNavigation.goToMyPage({ mode: 'settings' })}
        />
        <BodySelectedTextIons text="ログアウト" onClick={handleLogout} />
      </nav>
    </section>
  );
});

export default UserPopover;
