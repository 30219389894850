import React, { useRef } from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { useScrollIntoViewWithOffset } from '../../../hooks/useScrollIntoViewWithOffset';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import {
  RequestCardType,
  RequestDetailType,
} from '../../../types/api/request/RequestTypes';
import {
  RequestPageModeQuery,
  requestPageModeQueryMap,
} from '../../../types/query/RequestPageQuery';
import { TextSizeType } from '../../atoms/text/Text.type';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import RequestCardMolecules from '../../molecules/card/request/RequestCard.Molecules';
import HeaderTitleSakairiCenter from '../../molecules/text/headerTitle/HeaderTitleSakairiCenter';
import RequestCarousel from '../../organisms/carousel/RequestCarousel';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import RequestDetail from '../../organisms/request/RequestDetail';
import RequestResult from '../../organisms/request/RequestResult';
import './RequestTemplate.css';

interface RequestTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  page?: number;
  maxPage?: number;
  mode: RequestPageModeQuery;

  request?: RequestDetailType;
  ideas: IdeaCardType[];
  adoptedIdeas: IdeaCardType[];
  other_requests: RequestCardType[];
}

const RequestTemplate: React.FC<RequestTemplateProps> = (props) => {
  const { request } = props;
  const isSP = props.size === 'sp';
  const { goToRequestPage } = usePageNavigation();

  const bottomRef = useRef<HTMLElement>(null);

  useScrollIntoViewWithOffset(
    bottomRef,
    props.mode === 'ideas' || props.mode === 'result',
    {
      offset: -160,
    },
    props.mode || undefined,
  );

  let theme_card_min_width: number;
  let main_width: string;
  let subTitle_size: TextSizeType;

  // レスポンシブ対応
  switch (props.size) {
    case 'sp':
      theme_card_min_width = 320;
      main_width = '90%';
      subTitle_size = 'extra-small';
      break;

    case 'small':
      theme_card_min_width = 360;
      main_width = '600px';
      subTitle_size = 'small';
      break;

    case 'medium':
      theme_card_min_width = 360;
      main_width = '700px';
      subTitle_size = 'small';
      break;

    case 'large':
      theme_card_min_width = 360;
      main_width = '800px';
      subTitle_size = 'small';
      break;

    default:
      theme_card_min_width = 360;
      main_width = '65%';
      subTitle_size = 'normal';
  }

  if (!request)
    return (
      <div className={`request-template-null`}>
        <DefaultSpinnerIons color="grey" />
      </div>
    );

  return (
    <div className={`request-template ${isSP ? '' : 'pc'}`}>
      <header className="request-template-header">
        <HeaderTitleSakairiCenter
          title={'THEME'}
          subTitle={'お題に答えてアイデアを実現'}
          titleSize={'large'}
        />
      </header>
      <main
        className={`request-template-main ${isSP ? '' : 'pc'}`}
        style={{ width: main_width }}
      >
        <section className={`request-template-main-about ${isSP ? '' : 'pc'}`}>
          <RequestCardMolecules
            isSP={isSP}
            request={request}
            banner={false}
            width={isSP ? '100%' : '60%'}
          />
        </section>

        <section
          className={`request-template-main-bottom ${isSP ? '' : 'pc'}`}
          ref={bottomRef}
        >
          <nav className="request-template-main-nav">
            {Object.entries(requestPageModeQueryMap).map(([key, value]) => (
              <button
                key={key}
                className={`request-template-main-nav-button ${isSP ? '' : 'pc'} ${props.mode === key ? 'selected' : ''}`}
                onClick={() => {
                  goToRequestPage(request.requestId, { mode: key });
                }}
              >
                <BodyTextBoldIons
                  text={value}
                  size={subTitle_size}
                  bold="light"
                  className={`request-template-main-nav-button-text ${isSP ? '' : 'pc'} ${props.mode === key ? 'selected' : ''}`}
                />
              </button>
            ))}
          </nav>

          {props.mode === 'detail' ? (
            <RequestDetail isSP={isSP} request={request} />
          ) : props.mode === 'ideas' ? (
            <div style={{ width: '100%', padding: isSP ? '10px 5px' : '20px' }}>
              <IdeaCardGrid
                isSP={isSP}
                card_text_size={isSP ? 'extra-small' : 'small'}
                ideas={props.ideas}
                columns={isSP ? 2 : 3}
                page={props.page || 1}
                maxPage={props.maxPage || 1}
              />
            </div>
          ) : props.mode === 'result' ? (
            <RequestResult
              isSP={isSP}
              ideas={props.adoptedIdeas}
              page={props.page || 1}
              maxPage={props.maxPage || 1}
            />
          ) : null}
        </section>
      </main>

      <div className="request-template-other-themes">
        <HeaderTitleSakairiCenter
          title="OTHER THEMES"
          titleSize={isSP ? 'large' : 'extra-large'}
          subTitle="他のお題にも挑戦してみよう"
        />
        <RequestCarousel
          display_link={true}
          slide_min_width={theme_card_min_width * 1.05}
          card_width={theme_card_min_width}
          requests={props.other_requests}
          otherTransparent={isSP}
          playOnInit={false}
          isBanner={false}
          prevAndNextButton={true}
        />
      </div>
    </div>
  );
};

export default RequestTemplate;
