import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { formatTimeAgo } from '../../../utils/timeCalculator';
import { TextSizeType } from '../../atoms/text/Text.type';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { ChatTextIons } from '../../ions/text/message/ChatText.Ions';
import './Chat.Molecules.css';

type ChatProps = {
  left?: boolean;
  userId: string;
  userName: string;
  userImgSrc: string;
  text: string | null;
  imgSrc: string | null;
  createdAt: string;
  size?: TextSizeType;
  isRead?: boolean;
  numOfRead?: number;
};

export const ChatMolecules: React.FC<ChatProps> = React.memo((props) => {
  const { goToOthersUserPage } = usePageNavigation();
  return (
    <div
      className={`chat-molecules-container ${props.left ? 'left' : 'right'}`}
    >
      <UserIconImgIons
        userName={props.userName}
        img_src={props.userImgSrc}
        size="small"
        onClick={() => {
          goToOthersUserPage(props.userId);
        }}
      />
      <div className={`chat-messages ${props.left ? 'left' : 'right'}`}>
        <ChatTextIons
          text={props.text || 'null'}
          size={props.size}
          left={props.left}
        />
        <div
          className={`chat-messages-read-time ${props.left ? 'left' : 'right'}`}
        >
          {!props.left && (
            <BodyTextIons
              text={
                props.isRead === true
                  ? '既読'
                  : props.isRead === false
                    ? ''
                    : props.numOfRead === 0
                      ? ''
                      : `既読 ${props.numOfRead}`
              }
              rows={1}
              size="tiny"
            />
          )}
          <BodyTextIons
            text={formatTimeAgo(props.createdAt)}
            rows={1}
            size="tiny"
          />
        </div>
      </div>
    </div>
  );
});
