import { useDispatch } from 'react-redux';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';
import { formatDateToJapanese } from '../../../utils/timeCalculator';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import UserIconAndName from '../../molecules/user/UserIconAndName';
import './RequestDetail.css';

interface TagsCheckboxGridProps {
  isSP: boolean;
  request: RequestDetailType;
}

const RequestDetail: React.FC<TagsCheckboxGridProps> = (props) => {
  const { request } = props;
  const dispatch = useDispatch();
  return (
    <div className={`request-details ${props.isSP ? 'sp' : ''}`}>
      <div className="request-details-cell">
        <BodyTextIons text={'お題の主催者'} size={'small'} color="grey" />
        <UserIconAndName
          userId={request.user.userId}
          userName={request.user.userName}
          user_img_src={request.user.imgSrc}
          text_size="normal"
          icon_size={'small'}
        />
      </div>

      <div className="request-details-cell">
        <BodyTextIons text={'お題のタイトル'} size={'small'} color="grey" />
        <BodyTextBoldIons text={request.title} size={'small'} bold="light" />
      </div>

      <div className="request-details-cell">
        <BodyTextIons text={'お題の詳細説明'} size={'small'} color="grey" />
        <BodyTextBoldIons
          text={request.description}
          size={'small'}
          bold="light"
        />
      </div>

      <div className="request-details-cell">
        <BodyTextIons text={'募集期限'} size={'small'} color="grey" />
        <BodyTextBoldIons
          text={
            request.deadline
              ? formatDateToJapanese(request.deadline)
              : '期限は設定されていません'
          }
          size={'small'}
          bold="light"
        />
      </div>

      <div className="request-details-cell">
        <BodyTextIons text={'アイデア採用特典'} size={'small'} color="grey" />
        <BodyTextBoldIons
          text={request.rewardDescription}
          size={'small'}
          bold="light"
        />
      </div>

      <div className="request-details-cell">
        <BodyTextIons text={'注意事項'} size={'small'} color="grey" />
        <ul className="idea-adopt-request-template-main-content-list">
          {[
            'アイデアの実現可能性や市場ニーズなどを総合的に検討した上で、商品化の可否を決定いたします。',
            'アイデアの採用は保証されません。状況により、複数のアイデアが採用される場合や、採用されない場合もございます。',
            '投稿されたアイデアの著作権や知的財産権については、当社の利用規約に準じます。',
            '採用されたアイデアの提案者には、別途ご連絡いたします。',
          ].map((item) => (
            <li>
              <BodyTextIons text={item} bold="light" size="extra-small" />
            </li>
          ))}
        </ul>
      </div>

      <RoundPurpleButtonIons
        onClick={() => {
          dispatch(openIdeaPostPopup());
        }}
        width={'100%'}
        padding={'10px 0'}
      >
        <BodyTextIons
          text={'このお題に投稿する'}
          color="white"
          bold="bold"
          size="small"
        />
      </RoundPurpleButtonIons>
    </div>
  );
};

export default RequestDetail;
