import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAuthActions } from '../../../hooks/auth/useAuthActions.hooks';
import { useAuthForm } from '../../../hooks/auth/useAuthForm.hooks';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import LoginTemplate from '../../templates/auth/LoginTemplate';

const LoginPage = () => {
  const { isXS } = useSelector((state: RootState) => state.screenSize);
  const [searchParams] = useSearchParams();
  const redirectUrl: string | null = searchParams.get('redirectUrl');

  // 入力値の処理
  const { values, handlers: formHandlers } = useAuthForm();

  // ログインのAPI処理
  const { handlers: apiHandlers, errors: apiErrors } = useAuthActions({
    values: values,
  });

  return (
    <LoginTemplate
      isSP={isXS}
      apiError={apiErrors.login}
      values={values}
      formHandlers={formHandlers}
      handleLogin={() =>
        apiHandlers.handleLogin({
          onSuccess: () => {
            if (redirectUrl) {
              window.location.href = decodeURIComponent(redirectUrl);
            } else {
              PageNavigation.goToHomePage();
            }
          },
        })
      }
      handleGoogleSignin={apiHandlers.handleGoogleSignin}
      handleNavigateToRegister={() => PageNavigation.goToSignUpPage()}
    />
  );
};

export default LoginPage;
