import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';

// メールアドレスの再設定用のAPI
// トークンを元に、保存されたメールアドレスで認証を完了する
export const verifyEmailAPI = async (
  token: string,
): Promise<AuthErrorType | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/email/verify/${token}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    if (!response.ok) {
      const errorData: AuthErrorType = await response.json();
      return errorData;
    }

    return null;
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
