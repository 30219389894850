import React from 'react';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import HeaderTitle from '../../molecules/text/headerTitle/HeaderTitle';
import EnTitleSection from '../../organisms/section/EnTitleSection';
import './ContactTemplate.css';

interface ContactThemeTemplateProps {
  isSP: boolean;
}

const ContactSuccessTemplate: React.FC<ContactThemeTemplateProps> = React.memo(
  (props) => {
    const { isSP } = props;

    return (
      <div className={`contact-template-container `}>
        <HeaderTitle
          title="Contact Send"
          subTitle="お問い合わせを送信しました"
          titleSize="large"
        />

        <section
          className={`contact-success-template-main ${isSP ? 'sp' : ''}`}
        >
          <EnTitleSection
            title="Check your email"
            subTitle="確認メールを送信しました"
            isSP={isSP}
          >
            <ul className="contact-success-template-list">
              {[
                'ご入力いただいたメールアドレスにお問い合わせ内容の確認メールを送信しました。',
                'メールが届かない場合は、迷惑メールフォルダをご確認ください。',
                `ご不明な点がございましたら、${process.env.REACT_APP_SUPPORTER_EMAIL} までお気軽にご連絡ください。`,
              ].map((item) => (
                <li>
                  <BodyTextIons text={item} bold="light" size="extra-small" />
                </li>
              ))}
            </ul>
            <RoundPurpleButtonIons
              width={'200px'}
              onClick={PageNavigation.goToHomePage}
            >
              トップページへ
            </RoundPurpleButtonIons>
          </EnTitleSection>
        </section>
      </div>
    );
  },
);

export default ContactSuccessTemplate;
