// アイデアのコメントを取得するAPI
export const getIdeaAPI = async (ideaId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ideas/${ideaId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
