import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  FollowUserAPIProps,
  UnFollowUserAPIProps,
} from '../../../services/api/user/follow/userFollowAPI.type';
import { UserCardType } from '../../../types/api/user/UserTypes';
import DefaultPopupIons from '../../ions/popup/DefaultPopup.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import UserIconAndName from '../../molecules/user/UserIconAndName';
import './FollowUsersConfirmPopup.css';

interface PopupProps {
  edit: boolean;
  mode: 'followers' | 'followedUsers';
  users: UserCardType[];
  open: boolean;
  onClose: () => void;
  handleFollowUser: (input: FollowUserAPIProps) => void;
  handleUnFollowUser: (input: UnFollowUserAPIProps) => void;
}

const FollowUsersConfirmPopup: React.FC<PopupProps> = React.memo(
  ({
    edit,
    open,
    onClose,
    mode,
    users,
    handleFollowUser,
    handleUnFollowUser,
  }) => {
    const { user: userMe } = useSelector((state: RootState) => state.auth);

    const followUser = (userId: string) => {
      handleFollowUser({
        follow: {
          userId,
        },
      });
    };

    const unFollowUser = (userId: string) => {
      handleUnFollowUser({
        follow: {
          userId,
        },
      });
    };

    return (
      <DefaultPopupIons open={open} onClose={onClose} size="large">
        <div className="follow-user-confirm-popup">
          <BodyTextBoldIons
            text={mode === 'followers' ? 'フォロワー' : 'フォロー中のユーザー'}
            size="normal"
          />
          <div className="follow-user-confirm-popup-users-container">
            {users.length === 0 ? (
              <BodyTextBoldIons
                text="ユーザーが見つかりませんでした"
                size="small"
                className="follow-user-confirm-popup-no-user"
              />
            ) : (
              [...users].map((user, index) => (
                <div className="follow-user-confirm-popup-user-cell">
                  <UserIconAndName
                    key={index}
                    userName={user.userName}
                    user_img_src={user.imgSrc}
                    userId={user.userId}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default FollowUsersConfirmPopup;
