import React from 'react';
import { PageNavigation } from '../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import StepNavigation from '../../organisms/navTexts/StepNavigation';
import EnTitleSection from '../../organisms/section/EnTitleSection';
import './HowToUseTemplate.css';

interface PopupProps {
  isSP: boolean;
  message?: string;
}

// メール認証完了後の遷移ページ
const HowToUseCorpTemplate: React.FC<PopupProps> = React.memo((props) => {
  const { isSP } = props;

  return (
    <div className={`how-to-use-template-container ${isSP ? 'sp' : ''}`}>
      <div className={`how-to-use-template ${isSP ? 'sp' : ''}`}>
        {props.message && (
          <BodyTextIons
            size="small"
            text={props.message}
            className="how-to-use-template-title"
          />
        )}
        <header className="how-to-use-template-header">
          <HeaderTitleSakairi
            title="How To Use"
            subTitle="RE-IDEAの使い方(事業者向け)"
            titleSize="medium"
          />
        </header>

        <StepNavigation
          steps={['お題を投稿', 'アイデアが集まる', 'アイデアの\n商品化を確定']}
          currentStep={3}
        />

        <section className="how-to-use-template-main">
          <EnTitleSection
            title="Post Your Theme"
            subTitle="① お題を投稿してみよう"
            isSP={isSP}
          >
            <section className="how-to-use-template-main-content">
              <ul className="how-to-use-template-list">
                {[
                  'まず、下記のボタンからお題投稿フォームに移動して「募集したいアイデア」について申請してください。',
                  '申請いただいた内容を元に、IDEA工房運営チームと相談しながら、お題ページを作成していきます。',
                  'その後、お題ページがIDEA工房に掲載されます。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>

              <RoundPurpleButtonIons
                onClick={PageNavigation.goToContactRequestPage}
                padding={'10px 20px'}
                width={'90%'}
              >
                <BodyTextIons
                  text="お題を投稿してみる"
                  size="small"
                  bold="bold"
                  color="white"
                />
              </RoundPurpleButtonIons>
            </section>
          </EnTitleSection>

          <EnTitleSection
            title="Gather Idea"
            subTitle="② アイデアが集まる"
            isSP={isSP}
          >
            <section className="how-to-use-template-main-content">
              <ul className="how-to-use-template-list">
                {[
                  'お題に対して、ユーザからさまざまなアイデアが寄せられます。',
                  '投稿されたアイデアは一覧で確認でき、いいね数、出資金額、閲覧数といった詳細も閲覧可能になります。',
                  '最も注目されているアイデアや、商品化したいと思える魅力的なアイデアを見つけましょう。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>
            </section>
          </EnTitleSection>

          <EnTitleSection
            title="Adopt Idea"
            subTitle="③ アイデアを商品化する"
            isSP={isSP}
          >
            <section className="how-to-use-template-main-content">
              <ul className="how-to-use-template-list">
                {[
                  '魅力的なアイデアが見つかったら、そのアイデアを商品化しましょう。',
                  'まずは商品化の申請を行い、IDEA工房運営チームと一緒に実現可能性をオンライン会議にて検討します。',
                  '申請が通ったら、お題の募集を締め切り、発案者・IDEA工房運営チームと共に商品化のプロセスを進めていきましょう。',
                ].map((item) => (
                  <li>
                    <BodyTextIons text={item} bold="light" size="extra-small" />
                  </li>
                ))}
              </ul>
            </section>
          </EnTitleSection>
        </section>
      </div>
    </div>
  );
});

export default HowToUseCorpTemplate;
