import { ContactError } from '../../../types/api/contact/ContactError.type';
import { createContactThemeAPIProps } from './createContactAPI.type';

export const createContactThemeAPI = async (
  input: createContactThemeAPIProps,
): Promise<ContactError | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/contacts/themes`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input.contact),
      },
    );

    if (!response.ok) {
      const errorData: ContactError = await response.json();
      input.onError && input.onError();
      return errorData;
    }

    input.onSuccess && input.onSuccess();
    return null;
  } catch (error) {
    return {
      code: 'UNKNOWN_ERROR',
      message: '予期せぬエラーが発生しました。',
    };
  }
};
