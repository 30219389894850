import { AuthErrorType } from '../../../../types/api/auth/AuthError.type';
import { UpdateMeCorpAPIProps } from './updateMeAPI.type';

export const updateMeCorpAPI = async (
  input: UpdateMeCorpAPIProps,
): Promise<AuthErrorType | null> => {
  try {
    const formData = new FormData();

    // 各プロパティを FormData に追加
    Object.entries(input.user).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        // Fileオブジェクトの場合はそのまま追加、それ以外は文字列として追加
        if (value instanceof Blob || value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
      }
    });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/corps/me`, {
      method: 'PUT',
      credentials: 'include',
      body: formData,
    });

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
      if (input.onError) input.onError();
      const result: AuthErrorType = await response.json();
      return result;
    } else {
      if (input.onSuccess) input.onSuccess();
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {
      code: 'UNKNOWN_ERROR',
      message: 'ユーザー情報の更新に失敗しました',
    };
  }
};
