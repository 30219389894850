import { GetUsersQueryType } from './getUsersAPI.type';

export const getUsersAPI = async (query?: GetUsersQueryType) => {
  try {
    const params = new URLSearchParams();
    if (query?.sort) params.set('sort', query.sort);
    if (query?.limit) params.set('limit', String(query.limit));

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users?${params.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );

    // HTTP ステータスコードが成功範囲外の場合のエラーチェック
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // API から取得したデータを返す
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // エラーを再スローして呼び出し元で処理できるようにする
  }
};
