import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCategoriesAPI } from '../../services/api/category/getCategoriesAPI';
import { CategoryType } from '../../types/api/category/CategoryTypes';

// アイデア一覧情報を取得するためのカスタムフック
export const useGetCategories = (): UseQueryResult<CategoryType[]> => {
  return useQuery<CategoryType[]>({
    queryKey: ['categories'], // sortの値も含めてキャッシュを管理
    queryFn: () => getCategoriesAPI(), // 関数を返すように修正
    staleTime: 1000 * 60 * 60 * 24, // 24時間
    gcTime: 1000 * 60 * 60 * 24, // 24時間
  });
};
