// リクエストを取得するAPI
export const getRequestAPI = async (requestId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/requests/${requestId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};
