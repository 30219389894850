import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  format,
  isValid,
  parseISO,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export function formatRemainingTime(deadline?: Date | string): string {
  let validDate: Date | null = null;

  if (typeof deadline === 'string') {
    try {
      validDate = parseISO(deadline);
    } catch (error) {
      return '不正な値';
    }
  } else if (deadline instanceof Date) {
    validDate = deadline;
  }

  if (!validDate || !isValid(validDate)) {
    return '不正な値';
  }

  const japanTimeZone = 'Asia/Tokyo';
  const now = toZonedTime(new Date(), japanTimeZone);
  const targetDate = toZonedTime(validDate, japanTimeZone);

  const minutesDiff = differenceInMinutes(targetDate, now);
  const hoursDiff = differenceInHours(targetDate, now);
  const daysDiff = differenceInDays(targetDate, now);
  const monthsDiff = differenceInMonths(targetDate, now);

  if (minutesDiff <= 0) {
    return '終了しました';
  } else if (minutesDiff <= 60) {
    return `あと${minutesDiff}分`;
  } else if (hoursDiff < 24) {
    return `あと${hoursDiff}時間`;
  } else if (daysDiff < 30) {
    return `あと${daysDiff}日`;
  } else if (monthsDiff < 12) {
    return `あと${monthsDiff}ヶ月`;
  } else {
    return `あと${Math.floor(monthsDiff / 12)}年以上`;
  }
}

export function formatDateToJapanese(
  date?: Date | string,
  structure = 'M月d日',
): string {
  let validDate: Date | null = null;

  if (typeof date === 'string') {
    try {
      validDate = parseISO(date);
    } catch (error) {
      return '不正な日付';
    }
  } else if (date instanceof Date) {
    validDate = date;
  }

  if (!validDate || !isValid(validDate)) {
    return '不正な日付';
  }

  const japanTimeZone = 'Asia/Tokyo';
  const japanDate = toZonedTime(validDate, japanTimeZone);

  return format(japanDate, structure ?? 'M月d日');
}

export function formatTimeAgo(date?: Date | string): string {
  let validDate: Date | null = null;

  if (typeof date === 'string') {
    try {
      validDate = parseISO(date);
    } catch (error) {
      return '不正な日付';
    }
  } else if (date instanceof Date) {
    validDate = date;
  }
  if (!validDate || !isValid(validDate)) {
    return '不正な日付';
  }

  const now = new Date();
  const diffMs = now.getTime() - validDate.getTime(); // 経過時間 (ミリ秒)
  const diffSeconds = Math.floor(diffMs / 1000); // 秒
  const diffMinutes = Math.floor(diffMs / 1000 / 60); // 分
  const diffHours = Math.floor(diffMinutes / 60); // 時間
  const diffDays = Math.floor(diffHours / 24); // 日
  const diffWeeks = Math.floor(diffDays / 7); // 週
  const diffMonths = Math.floor(diffDays / 30); // 月

  if (diffSeconds < 60) {
    return `${diffSeconds}秒前`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes}分前`;
  } else if (diffHours < 24) {
    return `${diffHours}時間前`;
  } else if (diffDays === 1) {
    return `昨日`;
  } else if (diffDays === 2) {
    return `一昨日`;
  } else if (diffDays < 7) {
    return `${getDayOfWeek(validDate)}`;
  } else if (diffWeeks < 4) {
    return `${diffDays}日前`;
  } else if (diffMonths < 12) {
    return `${diffWeeks}週間前`;
  } else {
    return `${diffMonths}ヶ月前`;
  }
}

function getDayOfWeek(date: Date): string {
  const daysOfWeek = [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ];
  return daysOfWeek[date.getDay()];
}
