import React from 'react';
import { NotifyType } from '../../../../types/api/notifications/NotificationType';
import { convertLinks } from '../../../../utils/convertLinks';
import { PageNavigation } from '../../../../utils/pageNavigation';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import './NotifyDetailCard.Molecules.css';

type NotifyCardMoleculesProps = {
  isSP: boolean;
  notifyId: string;
  isRead: boolean;
  type: NotifyType;
  title: string;
  description: string;
  date: string;
};

export const NotifyDetailCardMolecules: React.FC<NotifyCardMoleculesProps> =
  React.memo((props) => {
    return (
      <div className={`notify-detail-card-container `}>
        <div
          className={`notify-detail-card-nav-buttons ${props.isSP ? '' : 'pc'}`}
        >
          <BodyTextIonsClickable
            text="< 一覧へ戻る"
            onClick={() => PageNavigation.goToNotificationsPage()}
          />
        </div>
        <div className={`notify-detail-card-titles ${props.isSP ? '' : 'pc'}`}>
          <BodyTextIons
            text={
              props.type === 'chat.unread'
                ? '新着メッセージ'
                : props.type === 'idea.comment'
                  ? 'アイデアに新着コメント'
                  : props.type === 'idea.comment-reply'
                    ? 'コメントへの新しい返信'
                    : props.type === 'idea.apply-request'
                      ? '商品化申請'
                      : props.type === 'idea.applied'
                        ? '商品化確定'
                        : '通知'
            }
            size={'small'}
            className={`mini-notify-card-title ${props.type}`}
            bold="light"
          />
          <BodyTextBoldIons text={props.title} size={'small'} />
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {convertLinks(props.description)}
          </p>
          <BodySubTextIons size="small" bold="light" text={`${props.date}`} />
        </div>
      </div>
    );
  });

export default NotifyDetailCardMolecules;
