import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScroll } from '../../../hooks/useScroll';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import CircleFixedPostButtonIons from '../../ions/button/circle/pink/CircleFixedPostButton.Ions';
import CircleFixedRequestButtonIons from '../../ions/button/circle/pink/CircleFixedRequestButton.Ions';
import DefaultSpinnerIons from '../../ions/spinner/DefaultSpinner.Ions';
import HeaderTitleSakairi from '../../molecules/text/headerTitle/HeaderTitleSakairi';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import CategoryNavTexts from '../../organisms/navTexts/CategoryNavTexts';
import IdeaSortNavTexts from '../../organisms/navTexts/IdeaSortNavTexts';
import UserNavTexts from '../../organisms/navTexts/UserNavTexts';
import './SearchTemplate.css';
import { SearchTemplateProps } from './SearchTemplate.type';

interface SearchTemplatePCProps extends SearchTemplateProps {
  size?: 'small' | 'medium' | 'large' | 'none';
}

const SearchTemplatePC: React.FC<SearchTemplatePCProps> = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  let width: string;
  let columns: number;

  switch (props.size) {
    case 'small':
      width = '750px';
      columns = 3;
      break;
    case 'medium':
      width = '950px';
      columns = 3;
      break;
    case 'large':
      width = '1100px';
      columns = 4;
      break;
    default:
      width = '1000px';
      columns = 4;
  }

  const { windowScrollToTop } = useScroll({
    behavior: 'smooth',
    delay: 50,
  });

  useEffect(() => {
    windowScrollToTop();
  }, [props.title_en, props.title, props.page]);

  return (
    <div className="search-template pc">
      <main className="search-template-main" style={{ width: width }}>
        <nav className="search-template-main-nav-texts">
          <IdeaSortNavTexts />
          <UserNavTexts userId={props.userId || null} users={props.users} />
          <CategoryNavTexts categoryId={props.categoryId || -1} />
        </nav>
        <section className="search-template-main-ideas">
          <HeaderTitleSakairi
            title={props.title_en}
            subTitle={props.title}
            titleSize="normal"
            className="sp"
          />
          <div className="search-template-main-ideas-grid">
            {props.ideas !== undefined ? (
              <IdeaCardGrid
                isSP={false}
                card_text_size="extra-small"
                columns={columns}
                ideas={props.ideas}
                page={props.page}
                maxPage={props.maxPage}
              />
            ) : (
              <div className="search-template-main-ideas-null ">
                <DefaultSpinnerIons color="grey" />
              </div>
            )}
          </div>
        </section>
      </main>
      {user?.isCorp ? (
        <CircleFixedRequestButtonIons
          onClick={PageNavigation.goToContactRequestPage}
          size={'large'}
          isMobile={false}
        />
      ) : (
        <CircleFixedPostButtonIons
          onClick={() => {
            dispatch(openIdeaPostPopup());
          }}
          size={'large'}
          isMobile={false}
        />
      )}
    </div>
  );
};

export default SearchTemplatePC;
