import React from 'react';
import ButtonAtoms from '../../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../../atoms/button/Button.type';
import '../ButtonFullRound.css';

const FullRoundRegisterButtonIons: React.FC<
  Omit<ButtonAtomsProps, 'children'>
> = React.memo((props) => {
  return (
    <ButtonAtoms
      {...props}
      color="purple"
      className={`button-full-round-ions round-register-button-ions ${props.className}`}
    >
      新規会員登録
    </ButtonAtoms>
  );
});

export default FullRoundRegisterButtonIons;
